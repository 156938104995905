import { useTranslation } from 'react-i18next'
import React, { FC, useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { MenuPages, switchMenuPage, toggleMenu, updateIsSideMenuOpen } from '../Menu/MenuSlice'
import { useAppDispatch } from '../../Reducers/hooks'
import { HelpPages, switchHelpPage } from './helpSlice'
import { Icon, MenuDrawer, MenuHeader } from '../../Common/common-index'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    textAlign: 'center',
    alignItems: 'center',
    padding: theme.spacing(0, 4),
    backgroundColor: theme.palette.background.default,
  },
  paperContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  messageDescription: {
    fontFamily: 'Graphik',
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '24px',
  },
  successIcon: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '40px',
    '& svg': {
      maxHeight: '93px !important',
      maxWidth: '93px !important',
      minHeight: '93px !important',
      minWidth: '93px !important',
    },
  },
}))

const HelpDeskSuccessContainer: FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const [menuOpen, setMenuOpen] = useState(true)

  useEffect(() => {
    dispatch(updateIsSideMenuOpen(true))
  }, [])

  // Header Actions
  const OnCloseClick = () => {
    setMenuOpen(false)
    dispatch(toggleMenu(false))
    dispatch(updateIsSideMenuOpen(false))
    dispatch(switchMenuPage(MenuPages.Menu))
    dispatch(switchHelpPage(HelpPages.HelpMenuPage))
  }

  const OnGoBack = () => {
    setMenuOpen(false)
    dispatch(switchHelpPage(HelpPages.HelpDeskFormPage))
  }

  return (
    <form className={classes.paperContainer}>
      <MenuDrawer
        isOpen={menuOpen}
        drawerHeader={
          <MenuHeader
            title={t('helpMenu.helpDesk.title')}
            onGoBack={OnGoBack}
            onClose={OnCloseClick}
          />
        }
      >
        <div className={classes.container}>
          <Grid
            item
            xs={12}
            container
            justifyContent='center'
            alignItems='center'
            direction='column'
          >
            <Grid item>
              <div className={classes.successIcon}>
                <Icon iconName={'successCheck'} iconSize={'105'} />
              </div>
              <Typography variant={'subtitle1'} className={classes.messageDescription}>
                {t('helpMenu.faq.success')}
              </Typography>
            </Grid>
          </Grid>
        </div>
      </MenuDrawer>
    </form>
  )
}
export default HelpDeskSuccessContainer
