import React from 'react'
import { useLocation } from 'react-router-dom'
import RealErrorBoundary from './RealErrorBoundary'

function usePrevious<T>(value: T) {
  const ref = React.useRef(value)

  React.useEffect(() => {
    ref.current = value
  }, [value])

  return ref.current
}
// this "fake" error boundary will reset the "real" error boundary
// whenever a pathname change happens _after_ an error
const ErrorBoundary: React.FC = ({ children }) => {
  const [key, setKey] = React.useState(0)
  const { pathname } = useLocation()
  const previousPathname = usePrevious(pathname)
  const [trackPathChange, setTrackPathChange] = React.useState(false)

  React.useEffect(() => {
    if (trackPathChange && previousPathname !== pathname) {
      setKey((key) => key + 1)
      setTrackPathChange(false)
    }
  }, [trackPathChange, previousPathname, pathname])

  return (
    <RealErrorBoundary key={key} setTrackPathChange={setTrackPathChange}>
      {children}
    </RealErrorBoundary>
  )
}

export default ErrorBoundary
