/* eslint-disable no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export enum RecruitingDashboardPages {
  RecruitingDashboardMainMenu,
  RecruitingDashboarChatPage,
  RecruitingDashboardUserListPage,
  RecruitingDashboardProfilePage,
  RecruitingDashboardBadgeListPage,
}

export interface PassportBadgesState {
  activeRecruitingDashboardPage: RecruitingDashboardPages
  selectedRecruitingUserId?: number
  selectedRecruitingUserData?: any
  isRecruitingDetailsViewActive?: boolean
  hasNotificationPageOpen?: boolean
  isRecruitingMobileViewActive?: boolean
}

const initialState: PassportBadgesState = {
  activeRecruitingDashboardPage: RecruitingDashboardPages.RecruitingDashboardMainMenu,
  selectedRecruitingUserId: undefined,
  selectedRecruitingUserData: {},
  isRecruitingDetailsViewActive: false,
  hasNotificationPageOpen: false,
  isRecruitingMobileViewActive: false,
}

export const recruitingDashboardSlice = createSlice({
  name: 'recruiting',
  initialState,
  reducers: {
    updateSelectedRecruitingUserData: (state, action: PayloadAction<any>) => {
      state.selectedRecruitingUserData = action.payload
    },
    updateSelectedRecruitingUserId: (state, action: PayloadAction<any>) => {
      state.selectedRecruitingUserId = action.payload
    },
    updateIsRecruitingDetailsViewActive: (state, action: PayloadAction<boolean>) => {
      state.isRecruitingDetailsViewActive = action.payload
    },
    updateHasNotificationPageOpen: (state, action: PayloadAction<boolean>) => {
      state.hasNotificationPageOpen = action.payload
    },
    updateIsRecruitingMobileViewActive: (state, action: PayloadAction<boolean>) => {
      state.isRecruitingMobileViewActive = action.payload
    },
    switchRecruitingDashboardPage: (state, action: PayloadAction<RecruitingDashboardPages>) => {
      state.activeRecruitingDashboardPage = action.payload
    },
  },
})

export const {
  switchRecruitingDashboardPage,
  updateSelectedRecruitingUserId,
  updateSelectedRecruitingUserData,
  updateIsRecruitingDetailsViewActive,
  updateHasNotificationPageOpen,
  updateIsRecruitingMobileViewActive,
} = recruitingDashboardSlice.actions

export default recruitingDashboardSlice.reducer
