/* eslint-disable no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CallType } from '../../Services/Models/CallModel'

export interface CallState {
  callStarted: CallType
}

const initialState: CallState = {
  callStarted: null,
}

export const callSlice = createSlice({
  name: 'call',
  initialState,
  reducers: {
    setCallStarted: (state, action: PayloadAction<CallType>) => {
      state.callStarted = action.payload
    },
  },
})

export const { setCallStarted } = callSlice.actions

export default callSlice.reducer
