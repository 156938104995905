import React, { FC, useEffect, useState } from 'react'
import { useToasts } from 'react-toast-notifications'
import { Socket } from 'socket.io-client'
import { useAppSelector } from '../../Reducers/hooks'
import { MessagingTopics, MessagePayload } from '../../Services/services-index'

export const RoomChatFeedContainer: FC<any> = () => {
  const { addToast, removeToast } = useToasts()
  const currentUser = useAppSelector((state) => state.auth.currentUser)
  let toastStack: any = []
  const socket: Socket | null = useAppSelector((state) => state.socket.socket)

  const [hasChatListener, setHasChatListener] = useState<boolean>(false)

  // Add new messages to toast notification
  useEffect(() => {
    if (currentUser && socket) {
      if (hasChatListener) {
        return
      }
      const { event_code } = currentUser
      const chatTopic = `${MessagingTopics.CHAT}_${event_code}`
      if (chatTopic) {
        setHasChatListener(true)
      }
      socket.on(chatTopic, (data: string) => {
        const messageJson: MessagePayload = JSON.parse(data)
        if (messageJson) {
          if (
            messageJson.room &&
            messageJson.action !== 'delete' &&
            messageJson.action !== 'start'
          ) {
            toastStack.push({
              content: messageJson.message,
              id: messageJson.id,
              appearance: 'info',
            })
            if (toastStack.length > 4) {
              const deletedToast = toastStack.shift()
              removeToast(deletedToast.id, () => {
                setTimeout(() => {
                  addToast(messageJson.message, { appearance: 'info', id: messageJson.id })
                }, 250)
              })
            } else {
              addToast(messageJson.message, { appearance: 'info', id: messageJson.id })
            }
            setTimeout(() => {
              toastStack = [...toastStack.filter((toast: any) => toast.id !== messageJson.id)]
            }, 10000)
            return
          }
          return
        }
      })
    }
  }, [socket])
  return <></>
}
export default RoomChatFeedContainer
