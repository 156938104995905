import React, { FC } from 'react'

import { useAppSelector } from '../../Reducers/hooks'
import TokenListContainer from './TokenListContainer'
import { TokenWalletPages } from './tokenWalletSlice'

const TokenWalletContainer: FC = () => {
  const activeTokenWalletPage = useAppSelector((state) => state.tokenWallet.activeTokenWalletPage)

  switch (activeTokenWalletPage) {
    case TokenWalletPages.TokenWalletListPage:
      return <TokenListContainer />
    default: {
      return null
    }
  }
}

export default TokenWalletContainer
