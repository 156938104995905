import React, { FC } from 'react'

interface MicrophoneHoverProps {
  id?: string
}

const MicrophoneHover: FC<MicrophoneHoverProps> = ({ id }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      viewBox='0 0 186 186'
    >
      <defs>
        <filter
          id={`Ellipse_95_${id}`}
          x='0'
          y='0'
          width='186'
          height='186'
          filterUnits='userSpaceOnUse'
        >
          <feOffset in='SourceAlpha' />
          <feGaussianBlur stdDeviation='8' result='blur' />
          <feFlood flood-color='#a122fa' />
          <feComposite operator='in' in2='blur' />
          <feComposite in='SourceGraphic' />
        </filter>
      </defs>
      <g id={`${id}'Component_44_1`} data-name='Component 44 1' transform='translate(24 24)'>
        <g id={`${id}Group_794`} data-name='Group 794' transform='translate(-1361 -1060)'>
          <g transform='matrix(1, 0, 0, 1, 1337, 1036)' filter={`url(#Ellipse_95_${id})`}>
            <circle
              id='Ellipse_95-2'
              data-name='Ellipse 95'
              cx='69'
              cy='69'
              r='69'
              transform='translate(24 24)'
              fill='#fff'
            />
          </g>
          <g
            id='Ellipse_96'
            data-name='Ellipse 96'
            transform='translate(1361 1060)'
            fill='none'
            stroke='#fff'
            stroke-width='3'
          >
            <circle cx='69' cy='69' r='69' stroke='none' />
            <circle cx='69' cy='69' r='67.5' fill='none' />
          </g>
        </g>
        <g id='Microphone-Icon' transform='translate(50 41)'>
          <path
            id='Path_173'
            data-name='Path 173'
            d='M17.591,8.542C9.547,8.542,3,1.7,3-6.7V-20.762C3-29.162,9.547-36,17.591-36s14.591,6.838,14.591,15.238V-6.7C32.183,1.7,25.635,8.542,17.591,8.542Zm0-42.2A12.615,12.615,0,0,0,5.245-20.762V-6.7C5.245.337,10.857,6.2,17.591,6.2,24.513,6.2,29.938.337,29.938-6.7V-20.762C29.938-27.99,24.513-33.656,17.591-33.656Z'
            transform='translate(1.608 36)'
            fill='#7517c0'
            fill-rule='evenodd'
          />
          <path
            id='Path_174'
            data-name='Path 174'
            d='M19.2,5.111C8.47,5.111,0-3.791,0-15.066v-4.747A1.112,1.112,0,0,1,1.129-21q1.129,0,1.129,1.187v4.747c0,9.89,7.529,17.8,16.94,17.8,9.6,0,16.94-7.912,16.94-17.8v-4.747A1.112,1.112,0,0,1,37.269-21Q38.4-21,38.4-19.813v4.747C38.4-3.791,29.928,5.111,19.2,5.111Z'
            transform='translate(0 44.039)'
            fill='#7517c0'
            fill-rule='evenodd'
          />
          <path
            id='Path_175'
            data-name='Path 175'
            d='M12.768,3.216c-.384,0-.768-.576-.768-1.152V-4.848C12-5.616,12.384-6,12.768-6q.768,0,.768,1.152V2.064C13.536,2.64,13.28,3.216,12.768,3.216Z'
            transform='translate(6.431 53.078)'
            fill='#7517c0'
            fill-rule='evenodd'
          />
        </g>
      </g>
    </svg>
  )
}

export default MicrophoneHover
