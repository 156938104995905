import React, { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles, Theme } from '@material-ui/core/styles'
import MenuList from '@material-ui/core/MenuList'
import { Slide } from '@material-ui/core'

import { useAppDispatch } from '../../Reducers/hooks'
import { MenuPages, switchMenuPage, toggleMenu, updateIsSideMenuOpen } from '../Menu/MenuSlice'
import { HelpPages, switchHelpPage } from './helpSlice'
import { MenuDrawer, MenuHeader, MenuListItem } from '../../Common/common-index'
import { createHtmlIdString } from '../../Utils/utils'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: '0',
    minWidth: '270px',
  },
  helpMenuItem: {
    alignItems: 'center',
    display: 'flex',
    height: '80px',
    verticalAlign: 'middle',
    '& div[class*="MuiListItemText-root"]': {
      paddingLeft: theme.spacing(5),
    },
    '& div[class*="MuiButtonBase-root"]': {
      '&:hover': { height: '80px' },
    },
  },
}))

export const HelpMenuContainer: FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const helpMenuItems = [
    {
      name: t('helpMenu.userManual.title'),
      onClick: () => dispatch(switchHelpPage(HelpPages.HelpUserManualpage)),
    },
    {
      name: t('helpMenu.helpDesk.title'),
      onClick: () => dispatch(switchHelpPage(HelpPages.HelpDeskFormPage)),
    },
    {
      name: t('helpMenu.faq.menuTitle'),
      onClick: () => dispatch(switchHelpPage(HelpPages.HelpFaqPage)),
    },
    {
      name: t('helpMenu.privacyPolicy.title'),
      onClick: () => dispatch(switchHelpPage(HelpPages.HelpPrivacy)),
    },
    {
      name: t('helpMenu.cookiePolicy.title'),
      onClick: () => dispatch(switchHelpPage(HelpPages.HelpCookiePolicyPage)),
    },
  ]

  useEffect(() => {
    dispatch(updateIsSideMenuOpen(true))
  }, [])

  // Display Help menu items
  const renderMenuItems = () => {
    return helpMenuItems?.map((menuItem: any) => {
      return (
        <div className={classes.helpMenuItem} key={menuItem.name}>
          <MenuListItem
            id={createHtmlIdString('help-menu-item-', menuItem.name)}
            text={menuItem.name}
            onClick={menuItem.onClick}
          />
        </div>
      )
    })
  }

  const onCloseClick = () => {
    dispatch(toggleMenu(false))
    dispatch(updateIsSideMenuOpen(false))
    dispatch(switchMenuPage(MenuPages.Menu))
    dispatch(switchHelpPage(HelpPages.HelpMenuPage))
  }

  const onGoBack = () => {
    dispatch(switchMenuPage(MenuPages.Menu))
    dispatch(toggleMenu(true))
  }

  return (
    <div>
      <MenuDrawer
        isOpen={true}
        drawerHeader={
          <MenuHeader title={t('helpMenu.title')} onGoBack={onGoBack} onClose={onCloseClick} />
        }
      >
        <Slide direction='left' in={true} mountOnEnter unmountOnExit>
          <MenuList className={classes.container}>{helpMenuItems && renderMenuItems()}</MenuList>
        </Slide>
      </MenuDrawer>
    </div>
  )
}

export default HelpMenuContainer
