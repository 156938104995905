import React, { FC } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'

import OAuth2Container from '../../../Containers/AuthenticationContainer/OAuth2Container/OAuth2Container'
import AuthLayout from '../../../Containers/AuthenticationContainer/Auth/AuthLayout'

const useStyles = makeStyles(() =>
  createStyles({
    containerWrapper: {
      height: '100vh',
    },
  })
)

export const OAuth2Page: FC = () => {
  const classes = useStyles()

  return (
    <AuthLayout>
      <div className={classes.containerWrapper}>
        <OAuth2Container />
      </div>
    </AuthLayout>
  )
}
export default OAuth2Page
