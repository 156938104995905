import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'
import counterReducer from '../Components/counter/counterSlice'
import authReducer from './authSlice'
import userReducer from './userSlice'
import menuReducer from '../Containers/Menu/MenuSlice'
import chatReducer from '../Containers/Chat/ChatSlice'
import userAvatarReducer from '../Containers/UserAvatar/UserAvatarSlice'
import socketReducer from './socketSlice'
import passportBadgesReducer from '../Containers/PassportContainer/PassportBadgesSlice'
import eventNotificationReducer from '../Containers/EventNotificationContainer/EventNotificationSlice'
import businessCardsReducer from '../Containers/BusinessCards/businessCardsSlice'
import settingsReducer from '../Containers/SettingsContainer/SettingsSlice'
import profileSettingsReducer from '../Containers/ProfileSettings/profileSettingsSlice'
import roomChatReducer from '../Containers/RoomChat/RoomChatSlice'
import preferencesReducer from './preferencesSlice'
import roomReducer from '../Containers/RoomContainer/RoomSlice'
import helpReducer from '../Containers/HelpContainer/helpSlice'
import miniMapReducer from '../Containers/MiniMapContainer/MiniMapSlice'
import callReducer from '../Containers/CallContainer/CallSlice'
import toolButtonReducer from '../Containers/ToolButtonsContainer/ToolButtonSlice'
import userListReducer from '../Containers/UserList/UserListSlice'
import recruitingDashboardReducer from '../Containers/RecruitingDashboard/RecruitingDashboardSlice'
import tokenWalletReducer from '../Containers/TokenWallet/tokenWalletSlice'

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    auth: authReducer,
    menu: menuReducer,
    chat: chatReducer,
    userAvatar: userAvatarReducer,
    user: userReducer,
    socket: socketReducer,
    businessCards: businessCardsReducer,
    passport: passportBadgesReducer,
    settings: settingsReducer,
    eventNotification: eventNotificationReducer,
    profileSettings: profileSettingsReducer,
    roomChat: roomChatReducer,
    preferences: preferencesReducer,
    room: roomReducer,
    help: helpReducer,
    miniMap: miniMapReducer,
    call: callReducer,
    toolButtons: toolButtonReducer,
    userList: userListReducer,
    recruiting: recruitingDashboardReducer,
    tokenWallet: tokenWalletReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }), // .concat(logger),
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
