import React, { FC } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import Typography from '@material-ui/core/Typography'

import FormTextField from '../FormTextField/FormTextField'
import { ShadowedButton } from '../../../Common/common-index'

interface forgotPasswordProps {
  emailId: string | undefined
  emailIdFieldError?: boolean | undefined
  errorMessage?: string | undefined
  onEmailIdValueChange: () => void
  onClick?: () => void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      '& .MuiTextField-root': {
        boxSizing: 'border-box',
        justifyContent: 'center',
        margin: theme.spacing(1, 0),
        padding: theme.spacing(0, 3),
        width: '100%',
        [theme.breakpoints.up('md')]: {
          padding: theme.spacing(0),
          width: '400px',
        },
      },
    },
    headerWrapper: {
      fontFamily: 'Graphik',
      fontWeight: 'bold',
      textAlign: 'start',
      width: '100%',
      padding: theme.spacing(5, 0),
      [theme.breakpoints.up('md')]: {
        width: '400px',
      },
    },
    header: {
      lineHeight: '32px',
      [theme.breakpoints.up('md')]: {
        width: '100%',
      },
    },
    alignCenter: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    button: {
      marginTop: theme.spacing(2),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        maxWidth: '400px',
      },
    },
    disable: {
      backgroundColor: theme.palette.grey[200],
      pointerEvents: 'none',
    },
    disableFont: {
      color: theme.palette.background.default,
    },
    errorStatus: {
      fontSize: '14px',
      color: theme.palette.error.main,
      display: 'flex',
      flexDirection: 'row-reverse',
      padding: theme.spacing(0),
      [theme.breakpoints.up('md')]: {
        marginLeft: 0,
        padding: 0,
        maxWidth: '400px',
      },
    },
  })
)
export const ForgotPasswordForm: FC<forgotPasswordProps> = ({
  emailId,
  emailIdFieldError,
  errorMessage,
  onEmailIdValueChange,
  onClick,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <form className={classes.root}>
      <div className={classes.headerWrapper}>
        <Typography className={classes.header} variant={'h4'} data-testid='title'>
          {t('authPages.forgotPassword.title')}
        </Typography>
      </div>
      <FormTextField
        title={t('authPages.forgotPassword.label')}
        placeholder={t('authPages.forgotPassword.placeholder')}
        value={emailId}
        autoComplete={'on'}
        isPasswordField={false}
        error={emailIdFieldError}
        errorMessage={t('authPages.forgotPassword.errorMessage')}
        inputProps={{
          maxLength: 2000,
        }}
        onChange={onEmailIdValueChange}
        id='forgot-password-email'
      />
      {errorMessage && errorMessage.length && (
        <Typography variant='subtitle1' className={classes.errorStatus}>
          {errorMessage}
        </Typography>
      )}
      <ShadowedButton
        text={t('authPages.forgotPassword.sendButton')}
        buttonStyle={'default'}
        wrapperClasses={classes.button}
        disabled={!emailId}
        onClick={onClick}
        id='forgot-password-send'
      ></ShadowedButton>
    </form>
  )
}
export default ForgotPasswordForm
