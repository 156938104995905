import React, { FC, useEffect, useState } from 'react'
import Avatar from '@material-ui/core/Avatar'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { useAppDispatch, useAppSelector } from '../../Reducers/hooks'
import { fetchProfilePictureAsync } from './UserAvatarSlice'
import { fetchUserSummaryAsync } from '../../Reducers/userSlice'
import clsx from 'clsx'
import PeopleGroupIcon from '../../Assets/icons/PeopleGroup-Icon.svg'
import { Tooltip } from '@material-ui/core'
import { User } from '../../Services/services-index'
import { TooltipPlacementOptions } from '../../Utils/general.model'

interface UserAvatarProps {
  userId?: number
  hasUnreadMessage?: boolean
  enableBorder?: boolean
  isNotClickable?: boolean
  isGroup?: boolean
  isSquare?: boolean
  height?: number
  width?: number
  onClick?: () => void
  tooltipPlacement?: TooltipPlacementOptions
}

const useStyles = makeStyles(() =>
  createStyles({
    unread: {
      background: '#54b8f9',
      borderRadius: '50%',
      height: 12,
      width: 12,
      marginTop: '-8px',
      marginLeft: '0px',
      zIndex: 2,
      border: '1px solid white',
    },
    avatar: {
      width: 50,
      height: 50,
    },
    border: {
      border: '3px solid white',
    },
    square: {
      borderRadius: '0',
    },
    userClick: {
      cursor: 'pointer',
    },
    noAction: {
      pointerEvents: 'none',
    },
  })
)

const UserAvatar: FC<UserAvatarProps> = ({
  userId,
  hasUnreadMessage,
  enableBorder,
  isNotClickable,
  isGroup,
  isSquare,
  height,
  width,
  onClick,
  tooltipPlacement,
}) => {
  const classes = useStyles()
  const dispatch = useAppDispatch()

  const profilePictureList = useAppSelector((state) => state.userAvatar.profilePictureList)
  const userList = useAppSelector((state) => state.user.userList)
  const selectedPreferences = useAppSelector((state) => state.preferences.selectedPreferences)

  const [profilePicturePath, setProfilePicturePath] = useState('')
  const [image64Src, setImage64Src] = useState('')

  const showCompany = selectedPreferences?.show_company?.value === 'yes' ? true : false
  const showJobTitle = selectedPreferences?.show_job_title?.value === 'yes' ? true : false

  useEffect(() => {
    if (!isGroup && typeof userId !== 'undefined') {
      const userProfilePath = userList[userId.toString()]?.profile_picture

      if (!userProfilePath) {
        dispatch(fetchUserSummaryAsync(userId))
      } else {
        setProfilePicturePath(userProfilePath)
      }
    }
  }, [userId, userList])

  useEffect(() => {
    if (!isGroup && typeof userId !== 'undefined') {
      const userProfile = profilePictureList[userId]

      if (!userProfile && profilePicturePath) {
        const id = userId
        dispatch(fetchProfilePictureAsync({ userId: id, profilePicturePath }))
      } else {
        setImage64Src(userProfile)
      }
    } else if (isGroup) {
      setImage64Src(PeopleGroupIcon)
    }
  }, [userId, profilePictureList, profilePicturePath])

  const getTooltipContent = (user: User) => {
    let content = ''
    if (!isGroup && user && tooltipPlacement) {
      if (user?.firstname && user?.lastname) content = user.firstname + ' ' + user.lastname

      if (showCompany && user?.company) content += ' - ' + user.company

      if (showJobTitle && user?.job_title) content += ' - ' + user.job_title
    }

    return content
  }

  return (
    <>
      {userId && (
        <Tooltip
          title={getTooltipContent(userList[(userId as number)?.toString()])}
          placement={tooltipPlacement}
        >
          <Avatar
            id={'user-avatar-' + userId ? userId + '' : ''}
            alt={`${userList[(userId as number).toString()]?.firstname} ${
              userList[(userId as number).toString()]?.lastname
            }'s profile picture`}
            src={image64Src}
            className={clsx(
              classes.avatar,
              enableBorder && classes.border,
              isSquare && classes.square,
              onClick && classes.userClick,
              isNotClickable && classes.noAction
            )}
            style={{ height: height || '20px', width: width || '20px' }}
            onClick={onClick}
          ></Avatar>
        </Tooltip>
      )}
      {hasUnreadMessage && <div className={classes.unread}></div>}
    </>
  )
}

export default UserAvatar
