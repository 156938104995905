/* eslint-disable no-unused-vars */
import axios from 'axios'
import { Tag, User, Query } from '../Models/user.model'
import Config from '../../config.json'

const reactAppDomain = Config.VSF_K8S_API_URL
const apiVersion = Config.VSF_K8S_API_VERSION
const servicePrefix = `${reactAppDomain}/user${apiVersion}`

enum ActionTypes {
  ACCEPT = 'accept',
  REJECT = 'reject',
}

export const getSimilarUsers = (roomId: number, userId: number) => {
  return axios.get(`${servicePrefix}/users/matching/room/${roomId}/user/${userId}`)
}

export const editUser = (user: User, editUserId: number, emailOnly?: boolean) => {
  const params = {
    emailOnly: emailOnly || true,
  }
  return axios.patch(`${servicePrefix}/user/${editUserId}`, user, { params })
}

export const activateUser = (userId: number) => {
  return axios.patch(`${servicePrefix}/user/${userId}/activate`)
}

export const deactivateUser = (userId: number) => {
  return axios.patch(`${servicePrefix}/user/${userId}/deactivate`)
}

export const getDeactivatedUsers = () => {
  return axios.get(`${servicePrefix}/users/deactivated`)
}

export const createTag = (userId: number, tags: Tag[]) => {
  const body = {
    tags: tags,
  }
  return axios.post(`${servicePrefix}/user/${userId}/tags`, body)
}

export const updateTag = (tagId: number, body: Tag) => {
  return axios.post(`${servicePrefix}/user/tag/${tagId}`, body)
}

export const deleteTag = (userId: number, tag: Tag) => {
  const body = {
    data: {
      tag_name: tag.tag_name,
      type: tag.type,
    },
  }
  return axios.delete(`${servicePrefix}/user/${userId}/tag`, body)
}

export const getUser = (userId: number) => {
  return axios.get(`${servicePrefix}/user/${userId}`)
}

export const getUserList = (query?: Query) => {
  let params = {}
  if (query) params = query
  return axios.get(`${servicePrefix}/users`, {
    params,
  })
}

export const getUserSummary = (userId: number) => {
  return axios.get(`${servicePrefix}/user/${userId}/summary`)
}

export const getUsersSummary = (userIds: Array<number>) => {
  let ids = ''
  userIds.forEach((userId) => {
    if (ids) {
      ids = ids + ',' + userId.toString()
    } else {
      ids = userId.toString()
    }
  })

  return axios.get(`${servicePrefix}/users/summary`, { params: { ids } })
}

export const getUsersInEvent = (eventCode: string) => {
  return axios.get(`${servicePrefix}/users/event/${eventCode}`)
}

export const getLoggedUsersInEvent = (eventCode: string, userRole?: string) => {
  const params = {
    role: userRole,
  }
  return axios.get(`${servicePrefix}/users/logged/event/${eventCode}`, { params })
}

export const getUsersInRoom = (roomId: number) => {
  return axios.get(`${servicePrefix}/users/room/${roomId}`)
}

export const postUserPicture = (profilePicture: FormData) => {
  const body = {
    profile_picture: profilePicture,
  }
  return axios.post(`${servicePrefix}/user/picture`, body)
}

export const patchUserPicture = (profilePicture: any, userID: number) => {
  const data = new FormData()
  data.append('profile_picture', profilePicture)

  return axios.patch(`${servicePrefix}/user/${userID}/picture`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export const deleteUserPicture = (userId: number) => {
  return axios.delete(`${servicePrefix}/user/${userId}/picture`)
}

export const getProfilePicture = (path: string) => {
  return axios.get(`${servicePrefix}/profilepicture${path}`, {
    headers: {
      Accept: 'image/*',
    },
    responseType: 'blob',
  })
}

export const updateTutorial = (userId: number, lastLogin: string) => {
  const body = {
    last_login: lastLogin,
  }
  return axios.post(`${servicePrefix}/user/${userId}/updateTutorial`, body)
}

export const reportUser = (
  reportForUserId: number,
  reported_by_user_id: number,
  summary?: string
) => {
  if (summary === '') {
    summary = undefined
  }
  return axios.post(`${servicePrefix}/report/user/` + reportForUserId, {
    reported_by_user_id,
    summary,
  })
}

export const getReportedUsers = () => {
  return axios.get(`${servicePrefix}/reported-users`)
}

export const AcceptUserReport = (userId: number, validationNotes?: string) => {
  const payload = {
    validationNotes,
  }
  return axios.patch(`${servicePrefix}/report/user/${userId}`, payload, {
    params: { action: ActionTypes.ACCEPT },
  })
}

export const RejectUserReport = (userId: number, validationNotes?: string) => {
  const payload = {
    validationNotes,
  }
  return axios.patch(`${servicePrefix}/report/user/${userId}`, payload, {
    params: { action: ActionTypes.REJECT },
  })
}

export const importUsersFromFile = (formDate: FormData) => {
  return axios.post(`${servicePrefix}/importusers`, formDate)
}

export const downloadUsers = () => {
  return axios.get(`${servicePrefix}/export-users`, { responseType: 'blob' })
}

export const getRecruitingUserData = (userId: number) => {
  return axios.get(`${servicePrefix}/user/${userId}/dashboard `)
}

export const patchRecruitingUserStatus = (userId: number, statusId: number, eventCode: string) => {
  const payload = {
    status_id: statusId,
    event_code: eventCode,
  }
  return axios.patch(`${servicePrefix}/user/${userId}/recruitingstatus`, payload)
}

export const getUserWallet = () => {
  return axios.get(`${servicePrefix}/user/wallet`)
}
