import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { makeStyles, Theme } from '@material-ui/core/styles'
import { SkillsComponent } from '../../Components/ProfileSettings/Skills'
import { useAppDispatch, useAppSelector } from '../../Reducers/hooks'
import { MenuPages, switchMenuPage, toggleMenu, updateIsSideMenuOpen } from '../Menu/MenuSlice'
import { ProfileSettingPages, switchProfilePage, updateSettings } from './profileSettingsSlice'
import { MenuDrawer, MenuHeader } from '../../Common/common-index'
import { Tag, userService } from '../../Services/services-index'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  paperContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
}))

export const SkillsContainer: FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const currentUser = useAppSelector((state) => state.auth.currentUser)
  const profileSettings = useAppSelector((state) => state.profileSettings.userProfile)

  const [inputValue, setInputValue] = useState<string>('')
  const [currentSkills, setCurrentSkills] = useState<Tag[]>([])
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [successMessage, setSuccessMessage] = useState<string>('')

  useEffect(() => {
    dispatch(updateIsSideMenuOpen(true))
  }, [currentSkills])

  useEffect(() => {
    if (profileSettings) {
      let skills: any[] = []
      skills = profileSettings?.tags.filter((tag: Tag) => tag.type === 'skill')
      if (skills?.length) {
        setCurrentSkills(skills)
      }
    }
  }, [profileSettings])

  // Skill input value
  const changeInputValue = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value)
    if (event.target.value.match(/^[\p{L}\p{N}\-&#@\+\s\.]+$/u)) {
      setErrorMessage('')
    } else {
      setErrorMessage('Skill contains forbidden characters')
    }
  }

  // To add the Key value on enter
  const onKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Enter' && !errorMessage) {
      addSkill()
      e.preventDefault()
    }
  }

  // Add new skills to previous list
  const addSkill = () => {
    if (currentUser?.id && inputValue && profileSettings) {
      userService
        .createTag(currentUser.id, [{ tag_name: inputValue, type: 'skill' }])
        .then((res) => {
          if (inputValue) {
            !currentSkills
              ? setCurrentSkills([{ tag_name: inputValue, type: 'skill' }])
              : setCurrentSkills([...currentSkills, { tag_name: inputValue, type: 'skill' }])
            const newTags: any = {
              ...profileSettings,
              tags: [...profileSettings.tags, { tag_name: inputValue, type: 'skill' }],
            }
            dispatch(updateSettings(newTags))
          }
          setInputValue('')
          setErrorMessage('')
          renderSuccessMessage(res)
        })
        .catch((error) => {
          const errorText = error?.response?.data?.message
          setErrorMessage(errorText)
        })
    }
  }

  // Remove skill from the list
  const postDelete = (chipName?: string) => {
    if (currentUser?.id && chipName && profileSettings) {
      userService
        .deleteTag(currentUser.id, { tag_name: chipName.toString(), type: 'skill' })
        .then((res) => {
          if (res) {
            const newSkillList = currentSkills.filter((item) => item.tag_name !== chipName)
            if (newSkillList) {
              setCurrentSkills(newSkillList)
            }
            let interestList = []
            interestList = profileSettings?.tags.filter((tag: Tag) => tag.type === 'interest')
            const newTags: any = {
              ...profileSettings,
              tags: [...interestList, ...newSkillList],
            }
            dispatch(updateSettings(newTags))
            renderSuccessMessage(res)
          }
          setErrorMessage('')
        })
        .catch((error) => {
          const errorText = error?.response?.data?.message
          setErrorMessage(textFormat(errorText))
        })
    }
  }

  const renderSuccessMessage = (value: any) => {
    if (value) {
      setSuccessMessage(textFormat(value.data?.message))

      // Turn of success message
      setTimeout(() => {
        setSuccessMessage('')
      }, 3500)
    }
  }

  // To modify first letter of message as "high case"
  const textFormat = (value: any) => {
    if (value) {
      return value.charAt(0).toUpperCase() + value.slice(1)
    }
    return
  }

  // Header Actions
  const OnCloseClick = () => {
    dispatch(toggleMenu(false))
    dispatch(updateIsSideMenuOpen(false))
    dispatch(switchMenuPage(MenuPages.Menu))
    dispatch(switchProfilePage(ProfileSettingPages.ProfileSettingsHome))
  }

  const OnGoBack = () => {
    dispatch(switchProfilePage(ProfileSettingPages.ProfileSettingsHome))
  }

  return (
    <form className={classes.paperContainer}>
      <MenuDrawer
        isOpen={true}
        drawerHeader={
          <MenuHeader
            title={t('profileSettingsPage.menu.skills')}
            onGoBack={OnGoBack}
            onClose={OnCloseClick}
          />
        }
      >
        <div className={classes.container}>
          <SkillsComponent
            inputValue={inputValue}
            errorMessage={errorMessage}
            successMessage={successMessage}
            currentSkills={currentSkills}
            addSkill={addSkill}
            postDelete={(e) => postDelete(e)}
            changeInputValue={(e) => changeInputValue(e)}
            onKeyDown={(e: any) => onKeyPress(e)}
          ></SkillsComponent>
        </div>
      </MenuDrawer>
    </form>
  )
}
export default SkillsContainer
