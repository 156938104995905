import React, { FC } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { useTranslation } from 'react-i18next'

import { AuthPageFooter } from '../../../Components/Authentication/AuthPageFooter/AuthPageFooter'
import SuccessConfirmationForm from '../../../Components/Authentication/SuccessConfirmationForm/SuccessConfirmationForm'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& div[class*="MuiGrid-spacing"]': {
        margin: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
          height: '100vh',
        },
      },
      '& div[class*="MuiGrid-item"]': {
        padding: '0 24px',
      },
    },
    item: {
      width: '100%',
    },
  })
)

const ForgotPasswordSuccessContainer: FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <div className={classes.root}>
      <Grid container justifyContent='center' alignItems='center' direction='column' spacing={6}>
        <Grid item className={classes.item}>
          <SuccessConfirmationForm
            title={t('authPages.forgotPassword.title')}
            successMessage={t('authPages.forgotPassword.successMessage')}
            buttonName={t('authPages.forgotPassword.backToLogin')}
          />
        </Grid>
        <Grid item className={classes.item}>
          <AuthPageFooter showRegisterPageInfo={true}></AuthPageFooter>
        </Grid>
      </Grid>
    </div>
  )
}

export default ForgotPasswordSuccessContainer
