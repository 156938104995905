import React, { FC, useEffect, useState } from 'react'
import { Link, Typography } from '@material-ui/core'
import Slide from '@material-ui/core/Slide'
import { makeStyles, Theme } from '@material-ui/core/styles'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { updateCurrentUserStatus } from '../../Reducers/authSlice'
import { useAppDispatch, useAppSelector } from '../../Reducers/hooks'
import { Icon } from '../../Common/common-index'
import { localStorageService, messagingService, UserStatus } from '../../Services/services-index'

const useStyles = makeStyles((theme: Theme) => ({
  paperContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    padding: theme.spacing(0, 4),
    minWidth: '270px',
    height: '100%',
  },
  paper: {
    cursor: 'pointer',
  },
  title: {
    fontSize: '15px',
    padding: theme.spacing(4, 0),
  },
  links: {
    display: 'flex',
    flexDirection: 'column',
    height: 'initial',
    '& a': { lineHeight: '2.52' },
  },
  mediumWeight: {
    cursor: 'pointer',
    fontFamily: 'Graphik-Medium',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
  },
  menuItemWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
  statusIcon: {
    width: '14px',
    height: '14px',
    borderRadius: '50%',
    marginRight: '10px',
  },
  online: {
    backgroundColor: '#21D627',
  },
  away: { backgroundColor: '#FDC42F' },
  doNotDisturb: {
    marginTop: '15px',
    '& svg': {
      minHeight: '14px !important',
      minWidth: '14px !important',
    },
  },
  statusContainer: {
    display: 'inline-flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  menuItem: {
    color: '#000000',
    margin: theme.spacing(2.5, 0),
  },
}))

export const ProfileStatusComponent: FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const currentUser = useAppSelector((state) => state.auth.currentUser)
  const socket = useAppSelector((state) => state.socket.socket)
  const roomId = useAppSelector((state) => state.room.activeRoomId)

  const [currentUserStatus, setCurrentUserStatus] = useState<string>()

  useEffect(() => {
    const activeUserStatus = localStorageService.getLocalStorageItemValue('activeUserStatus')
    if (activeUserStatus) {
      setCurrentUserStatus(activeUserStatus)
    }
  }, [currentUserStatus])

  const changeUserStatus = (status: string) => {
    if (currentUser && socket && status) {
      dispatch(updateCurrentUserStatus(status))
      setCurrentUserStatus(status)
      messagingService.emitMessagingSocket(
        'user_status_changes',
        {
          room_id: roomId,
          user_id: currentUser.id,
          user_status: status,
        },
        socket
      )
    }
  }

  return (
    <Slide direction='left' in={true} mountOnEnter unmountOnExit>
      <div className={classes.paperContainer}>
        <Typography variant={'body2'} className={classes.title} data-testid='title'>
          {t('profileSettingsPage.statusChange.info')}
        </Typography>
        <div className={classes.links}>
          <Link
            id='profile-status-change-available'
            variant={'subtitle2'}
            className={classes.mediumWeight}
            onClick={() => changeUserStatus('available')}
          >
            <div className={classes.menuItemWrapper}>
              <div className={clsx(classes.online, classes.statusIcon)}></div>
              <div className={classes.statusContainer}>
                <Typography variant={'subtitle2'} className={classes.menuItem}>
                  {t('profileSettingsPage.statusChange.active')}
                </Typography>
                {currentUserStatus === UserStatus.AVAILABLE && <CheckCircleIcon />}
              </div>
            </div>
          </Link>
          <Link
            id='profile-status-change-busy'
            variant={'subtitle2'}
            className={classes.mediumWeight}
            onClick={() => changeUserStatus('busy')}
          >
            <div className={classes.menuItemWrapper}>
              <div className={classes.doNotDisturb}>
                <Icon iconName={'doNotDisturb'} />
              </div>
              <div className={classes.statusContainer}>
                <Typography
                  variant={'subtitle2'}
                  data-testid='titlebusy'
                  className={classes.menuItem}
                >
                  {t('profileSettingsPage.statusChange.notDisturb')}
                </Typography>
                {currentUserStatus === UserStatus.BUSY && <CheckCircleIcon />}
              </div>
            </div>
          </Link>
        </div>
      </div>
    </Slide>
  )
}

export default ProfileStatusComponent
