import React, { FC, useEffect, useState } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

import { MenuDrawer, MenuHeader } from '../../Common/common-index'
import { useAppDispatch, useAppSelector } from '../../Reducers/hooks'
import { MenuPages, switchMenuPage, toggleMenu, updateIsSideMenuOpen } from '../Menu/MenuSlice'
import {
  switchRecruitingDashboardPage,
  RecruitingDashboardPages,
  updateIsRecruitingDetailsViewActive,
  updateSelectedRecruitingUserData,
  updateSelectedRecruitingUserId,
  updateIsRecruitingMobileViewActive,
} from './RecruitingDashboardSlice'
import RecruitingDashboardProfileContainer from './RecruitingDashboardProfileContainer'

const useStyles = makeStyles((theme: Theme) => ({
  paperContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '400px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
}))

export const RecruitingDashboardUserProfileContainer: FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const selectedRecruitingUserData = useAppSelector(
    (state) => state.recruiting.selectedRecruitingUserData
  )
  const [userData] = useState<any>(selectedRecruitingUserData)

  useEffect(() => {}, [userData, selectedRecruitingUserData])

  const OnCloseClick = () => {
    dispatch(updateIsRecruitingMobileViewActive(false))
    dispatch(updateIsRecruitingDetailsViewActive(false))
    dispatch(updateSelectedRecruitingUserId(undefined))
    dispatch(updateSelectedRecruitingUserData({}))

    dispatch(toggleMenu(false))
    dispatch(updateIsSideMenuOpen(false))
    dispatch(switchMenuPage(MenuPages.Menu))
    dispatch(switchRecruitingDashboardPage(RecruitingDashboardPages.RecruitingDashboardMainMenu))
  }

  const OnGoBack = () => {
    dispatch(
      switchRecruitingDashboardPage(RecruitingDashboardPages.RecruitingDashboardUserListPage)
    )
  }

  return (
    <MenuDrawer
      isOpen={true}
      drawerHeader={
        <MenuHeader
          title={t('recruiting.candidatePage.headerTitle')}
          onGoBack={OnGoBack}
          onClose={OnCloseClick}
        />
      }
    >
      <div className={classes.paperContainer}>
        <div className={classes.container}>
          <RecruitingDashboardProfileContainer />
        </div>
      </div>
    </MenuDrawer>
  )
}

export default RecruitingDashboardUserProfileContainer
