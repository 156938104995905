import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles, Theme } from '@material-ui/core/styles'
import MenuList from '@material-ui/core/MenuList'

import { useAppDispatch, useAppSelector } from '../../Reducers/hooks'
import { MenuPages, switchMenuPage, toggleMenu, updateIsSideMenuOpen } from '../Menu/MenuSlice'
import {
  setNewsfeedNotifications,
  SettingsPages,
  switchSettingsPage,
  toggleBackgroundMusic,
  toggleNewsfeedNotifications,
  toggleThirdPersonView,
} from './SettingsSlice'
import { Icon, MenuDrawer, MenuHeader, MenuListItem } from '../../Common/common-index'
import { localStorageService } from '../../Services/services-index'
import { createHtmlIdString } from '../../Utils/utils'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  settingsRow: {
    verticalAlign: 'middle',
    height: '80px',
    display: 'flex',
    alignItems: 'center',
  },
}))

export const SettingsListContainer: FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const [menuOpen, setMenuOpen] = useState(true)
  const [roomChatFeedState, setRoomChatFeedState] = useState(false)
  const [backgroundMusicState, setBackgroundMusicState] = useState<boolean>(false)

  const backgroundMusicOn = useAppSelector((state) => state.settings.backgroundMusicOn)
  const newsfeedNotificationsOn = useAppSelector((state) => state.settings.newsfeedNotificationsOn)
  const thirdPersonViewOn = useAppSelector((state) => state.settings.thirdPersonView)

  const preferences = useAppSelector((state) => state.preferences.selectedPreferences)

  const showBackgroundMusicSettings = preferences?.background_music?.value === 'yes'
  const showNewsfeedSettings = preferences?.room_chat?.value === 'yes'
  const showLanguageSettings = false
  const showCameraToggleSettings = preferences?.hide_camera_toggle?.value === 'no'

  useEffect(() => {
    const roomChatFeedState: any =
      localStorageService.getLocalStorageItemValue('enableRoomChatFeed')
    if (roomChatFeedState) {
      roomChatFeedState === 'true' ? setRoomChatFeedState(true) : setRoomChatFeedState(false)
      roomChatFeedState === 'true'
        ? dispatch(setNewsfeedNotifications(true))
        : dispatch(setNewsfeedNotifications(false))
    }
  }, [roomChatFeedState, newsfeedNotificationsOn])

  useEffect(() => {
    const backgroundMusicStatus: any =
      localStorageService.getLocalStorageItemValue('backgroundMusicStatus')
    if (backgroundMusicStatus) {
      backgroundMusicStatus === 'true'
        ? setBackgroundMusicState(true)
        : setBackgroundMusicState(false)
    }
  }, [backgroundMusicOn, backgroundMusicState])

  const settingsItems = [
    {
      name: 'toggle-newsfeed-notifications',
      content: roomChatFeedState
        ? t('settings.turnOffNewsfeedNotifications')
        : t('settings.turnOnNewsfeedNotifications'),
      icon: roomChatFeedState ? (
        <Icon iconName={'notificationBell'} iconSize={'24px'} />
      ) : (
        <Icon iconName={'notificationBellOff'} iconSize={'24px'} />
      ),
      onClick: () => dispatch(toggleNewsfeedNotifications()),
      show: showNewsfeedSettings,
    },
    {
      name: 'toggle-background-music',
      content: backgroundMusicState
        ? t('settings.turnOffBackgroundMusic')
        : t('settings.turnOnBackgroundMusic'),
      icon: backgroundMusicState ? (
        <Icon iconName={'musicNote'} iconSize={'28px'} />
      ) : (
        <Icon iconName={'musicNoteOff'} iconSize={'28px'} />
      ),
      onClick: () => dispatch(toggleBackgroundMusic()),
      show: showBackgroundMusicSettings,
    },
    {
      name: 'toggle-third-person-view',
      content: thirdPersonViewOn
        ? t('settings.turnOffThirdPersonView')
        : t('settings.turnOnThirdPersonView'),
      icon: thirdPersonViewOn ? (
        <Icon iconName={'switchTPP'} iconSize={'28px'} />
      ) : (
        <Icon iconName={'switchFPP'} iconSize={'28px'} />
      ),
      onClick: () => dispatch(toggleThirdPersonView()),
      show: showCameraToggleSettings,
    },
    {
      name: 'change-language',
      content: t('settings.changeLanguage'),
      icon: <Icon iconName={'flag'} iconSize={'25px'} />,
      onClick: () => dispatch(switchSettingsPage(SettingsPages.Language)),
      show: showLanguageSettings,
    },
  ]

  const onCloseClick = () => {
    setMenuOpen(false)
    dispatch(toggleMenu(false))
    dispatch(updateIsSideMenuOpen(false))
    dispatch(switchMenuPage(MenuPages.Menu))
  }

  const onGoBack = () => {
    setMenuOpen(false)
    dispatch(switchMenuPage(MenuPages.Menu))
    dispatch(toggleMenu(true))
  }

  return (
    <MenuDrawer
      isOpen={menuOpen}
      drawerHeader={
        <MenuHeader title={t('settings.title')} onGoBack={onGoBack} onClose={onCloseClick} />
      }
    >
      <div className={classes.container}>
        <MenuList>
          {settingsItems
            .filter((setting) => setting.show)
            .map((setting) => (
              <div className={classes.settingsRow} key={setting.name}>
                <MenuListItem
                  id={createHtmlIdString('settings-list-', setting.name)}
                  key={setting.name}
                  text={setting.content}
                  startIcon={setting.icon}
                  onClick={setting.onClick}
                />
              </div>
            ))}
        </MenuList>
      </div>
    </MenuDrawer>
  )
}
