import React, { FC, useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import ResetSetPasswordForm from '../../../Components/Authentication/ResetSetPassword/ResetSetPasswordForm'
import { useHistory, useLocation } from 'react-router-dom'
import { AuthPageFooter } from '../../../Components/Authentication/AuthPageFooter/AuthPageFooter'
import { authService } from '../../../Services/services-index'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& div[class*="MuiGrid-spacing"]': {
        margin: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
          height: '100vh',
        },
      },
      '& div[class*="MuiGrid-item"]': {
        padding: '0 24px',
      },
    },
    item: {
      width: '100%',
    },
  })
)
function useQuery() {
  return new URLSearchParams(decodeURI(useLocation().search))
}

const ResetSetPasswordContainer: FC = () => {
  const classes = useStyles()
  const history = useHistory()
  const query = useQuery()

  const [errorResponse, setErrorResponse] = useState('')

  const { mutate, error, isError, isSuccess } = useMutation((params: any) =>
    authService.changePassword2(params)
  )

  const handleResetPasswordSuccess = () => {
    history.push('/auth/reset-password-success')
  }
  useEffect(() => {
    if (isError) {
      setErrorResponse((error as any)?.response?.data?.message)
      history.push('/auth/reset-password-success')
    }
  }, [isError])

  useEffect(() => {
    if (isSuccess) {
      //const { user_data } = (data as any).data
      handleResetPasswordSuccess()
    }
  }, [isSuccess])

  let username: string
  const usernameQuery = query.get('username')
  if (!usernameQuery) {
    username = ''
  } else {
    username = usernameQuery
  }

  const onSubmit = (oldPassword: string, newPassword: string) => {
    mutate({
      username: username,
      oldPassword: oldPassword,
      newPassword: newPassword,
    })
  }

  return (
    <div className={classes.root}>
      <Grid container justifyContent='center' alignItems='center' direction='column' spacing={6}>
        <Grid item className={classes.item}>
          <ResetSetPasswordForm onSubmit={onSubmit} registerFailMsg={errorResponse} />
        </Grid>
        <Grid item className={classes.item}>
          <AuthPageFooter />
        </Grid>
      </Grid>
    </div>
  )
}
export default ResetSetPasswordContainer
