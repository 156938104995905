import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import { useAppDispatch, useAppSelector } from '../../Reducers/hooks'
import { MenuPages, switchMenuPage, toggleMenu, updateIsSideMenuOpen } from '../Menu/MenuSlice'
import {
  PassportPages,
  StoreItem,
  switchPassportPage,
  updateGoodiesList,
  updatePassportBadges,
} from './PassportBadgesSlice'
import {
  ConfirmationDialog,
  Icon,
  MenuDrawer,
  MenuHeader,
  ShadowedButton,
} from '../../Common/common-index'
import { eventService } from '../../Services/services-index'
import GoodiesItem from '../../Components/Passport/GoodiesItem'

const useStyles = makeStyles((theme: Theme) => ({
  paperContainer: {
    height: '100%',
    backgroundColor: theme.palette.background.default,
  },
  container: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingBottom: '32px',
  },
  headerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(8, 4),
    backgroundColor: theme.palette.background.default,
  },
  pageTitle: {
    fontSize: '15px',
    fontFamily: 'Graphik',
    fontWeight: 'bold',
    lineHeight: '30px',
  },
  pageDescription: {
    fontSize: '15px',
    fontFamily: 'Graphik',
    lineHeight: '25px',
  },
  coninsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(0, 4),
    justifyContent: 'space-between',
  },
  shippingWrapper: {
    padding: theme.spacing(0, 4, 5.5, 4),
  },
  redeemButton: {
    margin: theme.spacing(5, 0, 2, 0),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      maxWidth: '400px',
    },
  },
  coninsIconWrapper: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
  },
  coinsCount: {
    marginRight: '10px',
  },
  coinTitle: {
    fontSize: '15px',
    fontFamily: 'Graphik-Semibold',
    lineHeight: '30px',
  },
  description: {
    fontSize: '15px',
    fontFamily: 'Graphik-Regular',
    lineHeight: '22px',
  },
  goodies: {
    padding: theme.spacing(0, 4),
  },
  emptyList: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '200px',
  },
}))

export const RedeemCoinsContainer: FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const goodiesList = useAppSelector((state) => state.passport.goodiesList)
  const preferences = useAppSelector((state) => state.preferences.specificPreferences)
  const activePassportBadges = useAppSelector((state) => state.passport.passportBadges)

  const [errorMessage, setErrorMessage] = useState<string>('')
  const [selectedItem, setSelectedItem] = useState<StoreItem>()
  const [purchaseDialogOpen, setPurchaseDialogOpen] = useState<boolean>(false)
  const [confirmaitonMessageDialogOpen, setConfirmaitonMessageDialogOpen] = useState<boolean>(false)
  const [purchaseSuccessFull, setPurchaseSuccessFull] = useState<boolean>(false)
  const [shippingVendorPreference, setShippingVendorPreference] = useState<any>()

  useEffect(() => {
    if (preferences) {
      setShippingVendorPreference(preferences['achievement_shipping_url'])
    }
  }, [preferences])

  useEffect(() => {
    dispatch(updateIsSideMenuOpen(true))
    if (Object.keys(goodiesList).length === 0) {
      eventService
        .getStoreItems()
        .then((res: any) => {
          if (res) {
            dispatch(updateGoodiesList(res.data))
          }
        })
        .catch((error: any) => console.log(error))
    }
  }, [goodiesList?.length])

  useEffect(() => {}, [purchaseDialogOpen])

  // Render available goodies items list
  const renderGoodiesList = () => {
    if (!goodiesList || goodiesList?.length === 0) {
      return
    }

    if (goodiesList) {
      return goodiesList.map((item: StoreItem) => {
        if (!item?.id) return

        return (
          <GoodiesItem
            key={item.id}
            storeItem={item}
            onBuyClick={() => onBuyClick(item.id)}
          ></GoodiesItem>
        )
      })
    }
    return
  }

  // On buy button click
  const onBuyClick = (itemId: number) => {
    if (!itemId) return
    const getSelectedItem = goodiesList?.filter((item: StoreItem) => item.id === itemId)

    if (activePassportBadges?.coin_balance && getSelectedItem) {
      const coinBalanceCheck = activePassportBadges?.coin_balance >= getSelectedItem[0].coin_price
      if (!coinBalanceCheck) {
        setPurchaseSuccessFull(false)
        setSelectedItem(getSelectedItem[0])
        setConfirmaitonMessageDialogOpen(true)
      } else {
        setSelectedItem(getSelectedItem[0])
        setPurchaseDialogOpen(!purchaseDialogOpen)
      }
    }
  }

  // Open purchase confirmation dialog
  const purchaseDialog = () => {
    return (
      <>
        <ConfirmationDialog
          titleText={t('passport.purchaseDialog.title')}
          contentText={t('passport.purchaseDialog.descriptions', {
            goodiesPrice: selectedItem?.coin_price,
            selectedGoodieItem: selectedItem?.name,
          })}
          cancelText={t('commonButtons.cancel')}
          confirmText={t('commonButtons.confirm')}
          open={purchaseDialogOpen}
          errorMessage={errorMessage}
          onConfirm={() => onSelectedItemPurchase()}
          onCancel={() => onCancelClick()}
        />
      </>
    )
  }

  // Open purchase success/error message dialog
  const confirmationDialog = () => {
    return (
      <>
        <ConfirmationDialog
          titleText={
            purchaseSuccessFull
              ? t('passport.confirmationDialog.successTitle')
              : t('passport.confirmationDialog.errorTitle')
          }
          contentText={
            purchaseSuccessFull
              ? t('passport.confirmationDialog.successMessage', {
                  selectedGoodieItem: selectedItem?.name,
                  goodiesPrice: selectedItem?.coin_price,
                })
              : t('passport.confirmationDialog.errorMessage', {
                  selectedGoodieItem: selectedItem?.name,
                })
          }
          confirmText={t('commonButtons.close')}
          open={confirmaitonMessageDialogOpen}
          errorMessage={errorMessage}
          onConfirm={() => {
            setConfirmaitonMessageDialogOpen(false)
            setSelectedItem(undefined)
          }}
        />
      </>
    )
  }

  // Trigger eventservice on selected item purchase
  const onSelectedItemPurchase = () => {
    if (activePassportBadges?.coin_balance && selectedItem) {
      const coinBalanceCheck = activePassportBadges?.coin_balance >= selectedItem.coin_price
      if (coinBalanceCheck) {
        eventService
          .purchaseItem(selectedItem.id, selectedItem.coin_price)
          .then((data: any) => {
            if (data.status === 200) {
              setPurchaseDialogOpen(false)
              setPurchaseSuccessFull(true)
              setConfirmaitonMessageDialogOpen(true)
              fetchPassport()
            }
          })
          .catch((error: any) => {
            const errorText = error?.response?.data?.message
            setErrorMessage(errorText)
          })
      } else {
        setConfirmaitonMessageDialogOpen(true)
        setPurchaseSuccessFull(false)
      }
    }
  }

  // Fetch passport API for get user actual coin balance
  const fetchPassport = () => {
    eventService
      .getPassport()
      .then((res: any) => {
        if (res) {
          dispatch(updatePassportBadges(res.data))
        }
      })
      .catch((error: any) => console.log(error))
  }

  // On Cancel button click
  const onCancelClick = () => {
    setSelectedItem(undefined)
    setPurchaseDialogOpen(false)
    setErrorMessage('')
  }

  // On Shipping Details button click
  const onShippingDetails = () => {
    if (shippingVendorPreference) {
      window.open(shippingVendorPreference?.value)
    }
  }

  // Header Actions
  const OnCloseClick = () => {
    dispatch(toggleMenu(false))
    dispatch(updateIsSideMenuOpen(false))
    dispatch(switchMenuPage(MenuPages.Menu))
    dispatch(switchPassportPage(PassportPages.PassportMainPage))
  }

  const OnGoBack = () => {
    dispatch(switchPassportPage(PassportPages.PassportMainPage))
  }

  return (
    <MenuDrawer
      isOpen={true}
      drawerHeader={
        <MenuHeader
          title={t('passport.redeemCoins.title')}
          onGoBack={OnGoBack}
          onClose={OnCloseClick}
        />
      }
    >
      <div className={classes.paperContainer}>
        <div className={classes.headerWrapper}>
          <Typography variant={'h6'} className={classes.pageTitle}>
            {t('passport.redeemCoins.pageTitle')}
          </Typography>
          <Typography variant={'body1'} className={classes.pageDescription}>
            {t('passport.redeemCoins.pageDescription')}
          </Typography>
        </div>
        <div className={classes.coninsWrapper}>
          <Typography variant={'h6'} className={classes.coinTitle}>
            {t('passport.redeemCoins.myCoins')}
          </Typography>
          <div className={classes.coninsIconWrapper}>
            <Typography variant={'h6'} className={`${classes.description} ${classes.coinsCount}`}>
              {activePassportBadges?.coin_balance}
            </Typography>
            <Icon iconName={'coin'} iconSize={'36px'} />
          </div>
        </div>
        <div className={classes.shippingWrapper}>
          <ShadowedButton
            id='shipping-deatials-button'
            buttonStyle={'default'}
            text={t('passport.redeemCoins.shippingDeatials')}
            wrapperClasses={classes.redeemButton}
            onClick={onShippingDetails}
          ></ShadowedButton>
        </div>

        {goodiesList && goodiesList.length !== 0 && (
          <>
            <div className={classes.goodies}>
              <Typography variant={'h6'} className={classes.pageTitle}>
                {t('passport.redeemCoins.goodies')}
              </Typography>
            </div>
            <div className={classes.container}>{renderGoodiesList()}</div>
            {purchaseDialogOpen && purchaseDialog()}
            {confirmaitonMessageDialogOpen &&
              (purchaseSuccessFull || !purchaseSuccessFull) &&
              confirmationDialog()}
          </>
        )}
        {!goodiesList?.length && (
          <div className={classes.emptyList}>
            <Typography variant={'body1'}>{t('passport.redeemCoins.emptyList')}</Typography>
          </div>
        )}
      </div>
    </MenuDrawer>
  )
}

export default RedeemCoinsContainer
