import React, { FC, useEffect, useState } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import { MenuDrawer } from '../../Common/common-index'
import RecruitingDashboardUserListContainer from './RecruitingDashboardUserListContainer'
import RecruitingDashboardChatContainer from './RecruitingDashboardChatContainer'
import RecruitingDashboardProfileContainer from './RecruitingDashboardProfileContainer'
import { useAppDispatch, useAppSelector } from '../../Reducers/hooks'
import { updateIsRecruitingMobileViewActive } from './RecruitingDashboardSlice'

const useStyles = makeStyles((theme: Theme) => ({
  paperContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    flexWrap: 'nowrap',
    width: '100%',
    height: '100%',
  },
  userListWrapper: {
    height: '100%',
    width: '400px',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  candidateWrapper: {
    width: 'calc(100vw - 800px)',
    height: '100%',
    display: 'flex',
    minWidth: '400px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      display: 'none',
    },
  },
  chatWrapper: {
    height: '100%',
    width: '400px',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      display: 'none',
    },
  },
  userListMobileWrapper: {
    height: '100%',
    width: '400px',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}))

export const RecruitingDashboardContainer: FC = () => {
  const classes = useStyles()
  const dispatch = useAppDispatch()

  const isRecruitingDetailsViewActive = useAppSelector(
    (state) => state.recruiting.isRecruitingDetailsViewActive
  )

  const [isMobile, setIsMobile] = useState(false)
  const [isFullScreenViewActive, setIsFullScreenViewActive] = useState(true)

  //Handle the screen size
  const handleResize = () => {
    if (window.innerWidth < 960) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
  }, [isMobile])

  useEffect(() => {
    if (isMobile) {
      setIsFullScreenViewActive(false)
      dispatch(updateIsRecruitingMobileViewActive(true))
    } else {
      setIsFullScreenViewActive(true)
      dispatch(updateIsRecruitingMobileViewActive(false))
    }
  }, [isMobile, isRecruitingDetailsViewActive, isFullScreenViewActive])

  return (
    <div className={classes.paperContainer}>
      <MenuDrawer isOpen={true} isFullMode={true}>
        <Grid className={classes.container} container>
          {isFullScreenViewActive && (
            <>
              <Grid
                container
                justifyContent={'center'}
                alignItems={'center'}
                className={classes.userListWrapper}
              >
                <RecruitingDashboardUserListContainer />
              </Grid>
              <Grid
                container
                justifyContent={'center'}
                alignItems={'center'}
                className={classes.candidateWrapper}
              >
                <RecruitingDashboardProfileContainer />
              </Grid>
              <Grid
                container
                justifyContent={'center'}
                alignItems={'center'}
                className={classes.chatWrapper}
              >
                <RecruitingDashboardChatContainer />
              </Grid>
            </>
          )}
          {!isFullScreenViewActive && (
            <Grid
              container
              justifyContent={'center'}
              alignItems={'center'}
              className={classes.userListMobileWrapper}
            >
              <RecruitingDashboardUserListContainer />
            </Grid>
          )}
        </Grid>
      </MenuDrawer>
    </div>
  )
}

export default RecruitingDashboardContainer
