import React, { FC, useState, useEffect } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Typography, TextareaAutosize, Chip, Divider, Theme } from '@material-ui/core'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../../Reducers/hooks'

interface Params {
  inputError?: string
  hasNoteFunction?: boolean
  updateRecruitingUserNote?: () => void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    userProfileWrapper: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: '100%',
      outline: 'none',
    },
    skills: {
      marginBottom: '24px',

      '& .labels': {
        display: 'flex',
        flexWrap: 'wrap',

        '& button': {
          margin: '0 8px 8px 0',
          outline: 'none',
        },
      },
      '& p[class*="body2"]': { marginTop: '10px' },
    },
    contactInfo: {
      borderBottom: '1px solid $color-ui-grey-2',
      paddingBottom: '16px',
      marginBottom: '24px',
      outline: 'none',
      '&:nth-child(1)': {
        '& p[class*="body2"]': { marginTop: '10px' },
      },
    },
    editNoteForm: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: '10px',
      width: '100%',
    },
    textarea: {
      ...theme.typography.caption,
      fontFamily: 'Graphik',
      fontSize: '14px',
      minWidth: '230px',
      minHeight: '100px',
      resize: 'vertical',
      width: 'calc(100% - 36px)',
      padding: theme.spacing(2),
    },
    noteMessage: {
      height: '100%',
      marginTop: '5px',
      overflow: 'hidden',
      whiteSpace: 'normal',
      width: '320px',
    },
    chips: {
      margin: '10px 10px 0 0',
      '& [class*="MuiChip-label"]': {
        maxWidth: '300px',
        padding: '12px',
      },
    },
    purple: {
      backgroundColor: theme.palette.primary.light,
    },
    notesHeader: {
      display: 'inline-flex',
      justifyContent: 'space-between',
      width: '100%',
      alignItems: 'center',
    },
    divider: {
      margin: '20px 0',
    },
    notes: {
      marginBottom: '20px',
    },
    buttonItem: {
      cursor: 'pointer',
    },
  })
)

export const UserProfile: FC<Params> = ({ inputError, hasNoteFunction }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const selectedRecruitingUserData = useAppSelector(
    (state) => state.recruiting.selectedRecruitingUserData
  )

  const [editedNoteText, setEditedNoteText] = useState(selectedRecruitingUserData?.notes)

  const [isEditingNote, setIsEditingNote] = useState(false)
  const [profileContent, setProfileContent] = useState<any>()

  useEffect(() => {
    if (selectedRecruitingUserData !== undefined) {
      setProfileContent(renderProfileData(selectedRecruitingUserData))
    }
  }, [selectedRecruitingUserData])

  useEffect(() => {}, [selectedRecruitingUserData, isEditingNote, editedNoteText])

  const keyDownEvent = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      return (e.currentTarget as HTMLAreaElement).click()
    }
  }

  const changeInputValue = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (event && selectedRecruitingUserData) {
      event.target?.value
        ? setEditedNoteText(event.target?.value)
        : setEditedNoteText(selectedRecruitingUserData.notes)
    }
  }

  // Display recruiting user information
  const renderProfileData = (activeUserProfile: any) => {
    return (
      <>
        <div className={classes.contactInfo}>
          <Typography variant={'subtitle1'}>
            {t('recruiting.candidatePage.profile.info.title')}
          </Typography>
          {(activeUserProfile.phone_number ||
            activeUserProfile.email ||
            activeUserProfile.webpage ||
            activeUserProfile.linkedin) && (
            <>
              <Typography variant={'body2'}>{activeUserProfile.phone_number}</Typography>
              <Typography variant={'body2'}>{activeUserProfile.email}</Typography>
              <Typography variant={'body2'}>{activeUserProfile.webpage}</Typography>
              <Typography variant={'body2'}>{activeUserProfile.linkedin}</Typography>
            </>
          )}
          {!activeUserProfile.phone_number &&
            !activeUserProfile.email &&
            !activeUserProfile.webpage &&
            !activeUserProfile.linkedin && (
              <Typography variant={'body2'}>
                {t('recruiting.candidatePage.profile.info.noInfo')}
              </Typography>
            )}
        </div>
        <div className={classes.skills}>
          <Typography variant={'subtitle1'}>
            {t('recruiting.candidatePage.profile.skills.title')}
          </Typography>
          {activeUserProfile?.skills && (
            <div className='labels'>
              {activeUserProfile.skills.map((everySkill?: string, index?: any) => (
                <Chip
                  key={index + everySkill}
                  label={everySkill}
                  title={everySkill}
                  className={clsx(classes.chips, classes.purple)}
                />
              ))}
            </div>
          )}
          {!activeUserProfile?.skills?.length && (
            <Typography variant={'body2'}>
              {t('recruiting.candidatePage.profile.skills.noSkills')}
            </Typography>
          )}
        </div>
        <div className={classes.skills}>
          <Typography variant={'subtitle1'}>
            {t('recruiting.candidatePage.profile.interests.title')}
          </Typography>
          {activeUserProfile?.interests && (
            <div className='labels' role='list'>
              {activeUserProfile.interests.map((everyInterest?: string, index?: any) => (
                <Chip
                  key={index + everyInterest}
                  label={everyInterest}
                  title={everyInterest}
                  className={classes.chips}
                  variant={'outlined'}
                  color={'primary'}
                />
              ))}
            </div>
          )}
          {!activeUserProfile?.interests?.length && (
            <Typography variant={'body2'}>
              {t('recruiting.candidatePage.profile.interests.noInterests')}
            </Typography>
          )}
        </div>
        {hasNoteFunction && (
          <>
            <div className={classes.notes} tab-index='0'>
              <Divider className={classes.divider} />
              <div className={classes.notesHeader}>
                <Typography variant={'subtitle1'}>
                  {t('recruiting.candidatePage.profile.note.title')}
                </Typography>
                {!isEditingNote && (
                  <Typography
                    id='recruiting-user-notes-edit-open'
                    className={classes.buttonItem}
                    variant={'button'}
                    color={'primary'}
                    aria-controls='edit-notes'
                    onClick={() => setIsEditingNote(!isEditingNote)}
                    onKeyDown={(e: any) => keyDownEvent(e)}
                  >
                    {t('commonButtons.edit')}
                  </Typography>
                )}
                {isEditingNote && (
                  <Typography
                    id='recruiting-user-notes-edit-done'
                    className={classes.buttonItem}
                    variant={'button'}
                    aria-controls='save-notes'
                    onClick={() => setIsEditingNote(!isEditingNote)}
                    onKeyDown={(e: any) => keyDownEvent(e)}
                  >
                    {t('commonButtons.done')}
                  </Typography>
                )}
              </div>
              {isEditingNote && (
                <div className={classes.editNoteForm}>
                  <TextareaAutosize
                    id='recruiting-user-notes-edit-area'
                    minRows={3}
                    className={classes.textarea}
                    placeholder={editedNoteText}
                    value={editedNoteText}
                    maxLength={2000}
                    onChange={(e) => changeInputValue(e)}
                  />
                  {inputError && <p>{inputError}</p>}
                </div>
              )}
              {!isEditingNote && (
                <div>
                  <Typography variant={'body2'} className={classes.noteMessage}>
                    {activeUserProfile.notes}
                  </Typography>
                </div>
              )}
            </div>
          </>
        )}
        <Divider className={classes.divider} />
      </>
    )
  }

  return <div className={classes.userProfileWrapper}>{profileContent}</div>
}

export default UserProfile
