import React, { FC } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { Icon } from '../../Common/common-index'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiTextField-root': {
        boxSizing: 'border-box',
        margin: theme.spacing(1, 0),
        padding: theme.spacing(0, 3),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
          padding: theme.spacing(0),
          margin: theme.spacing(0),
          width: '100%',
        },
      },
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      height: '100%',
    },
    header: {
      display: 'flex',
      lineHeight: '32px',
      justifyContent: 'center',
      margin: theme.spacing(5, 0, 3, 0),
      [theme.breakpoints.up('sm')]: {
        width: '100%',
      },
    },
    contentWrapper: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center',
      padding: theme.spacing(0, 4),
      maxWidth: '400px',
      minWidth: '250px',
    },
    messageDescription: {
      fontFamily: 'Graphik',
      fontSize: '16px',
      lineHeight: '24px',
    },
    successIcon: {
      display: 'flex',
      justifyContent: 'center',
      '& svg': {
        maxHeight: '93px !important',
        maxWidth: '93px !important',
        minHeight: '93px !important',
        minWidth: '93px !important',
      },
    },
    buttonWrapper: {
      maxWidth: '400px',
      position: 'relative',
      width: '90%',
      minWidth: '250px',
      [theme.breakpoints.up('sm')]: {
        maxWidth: '400px',
      },
    },
    button: {
      borderRadius: 0,
      color: 'primary',
      transition: 'all 0.3s ease-in-out',
      height: '46px',
      width: '100%',
      zIndex: 1,
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        transform: 'translate(-5px, -3px)',
      },
      [theme.breakpoints.up('sm')]: {
        margin: theme.spacing(1, 0),
      },
    },
    buttonShadow: {
      backgroundColor: theme.palette.grey[500],
      bottom: '-6px',
      height: '100%',
      left: 6,
      position: 'absolute',
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        bottom: 0,
        height: 'calc(100% - 10px)',
      },
    },
  })
)

interface PropsInput {
  title?: string
  successMessage?: string
  backToLogin?: boolean
}

const SuccessMessageComponent: FC<PropsInput> = ({ title, successMessage, backToLogin }) => {
  const classes = useStyles()
  const history = useHistory()
  const { t } = useTranslation()

  return (
    <form className={classes.root}>
      <div className={classes.contentWrapper}>
        <div className={classes.successIcon}>
          <Icon iconName={'successCheck'} iconSize={'105'} />
        </div>
        {title && (
          <Typography className={classes.header} variant={'h4'}>
            {title}
          </Typography>
        )}
        {successMessage && (
          <Typography variant={'subtitle1'} className={classes.messageDescription}>
            {successMessage}
          </Typography>
        )}
      </div>
      {backToLogin && (
        <div className={classes.buttonWrapper}>
          <Button
            id='success-message-submit'
            variant='contained'
            color='primary'
            type={'submit'}
            className={classes.button}
            onClick={() => history.push('/auth/login')}
          >
            {t('profileSettingsPage.deleteUser.sucess.backToLogin')}
          </Button>
          <Typography className={classes.buttonShadow} />
        </div>
      )}
    </form>
  )
}
export default SuccessMessageComponent
