import React, { FC } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Slide from '@material-ui/core/Slide'
import { Token } from '../../../Containers/TokenWallet/tokenWalletSlice'
import { ShadowedButton } from '../Buttons/ShadowedButton'
import { useTranslation } from 'react-i18next'

interface Params {
  token?: Token
}

const useStyles = makeStyles((theme: Theme) => ({
  token: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(0, 4),
    outline: 'none',
    marginTop: '20px',
  },
  tokenContainer: {
    display: 'inline-flex',
    boxShadow: '0px 0px 10px #0000002E',
    opacity: '1',
    outline: 'none',
    padding: theme.spacing(3),
    height: '100%',
  },
  tokenInformation: {
    textAlign: 'left',
    width: '100%',
    marginLeft: '10px',
  },
  header: {
    fontFamily: 'Graphik',
    fontSize: '15px',
    fontWeight: 'bold',
    marginBottom: '12px',
    paddingRight: '12px',
  },
  body: { fontSize: '15px', fontFamily: 'Graphik' },
  buttonWrapper: {
    width: '45%',
    padding: '16px',
  },
  linkButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end',
  },
  infoRow: { display: 'flex', flexDirection: 'row', flexFlow: 'wrap', wordBreak: 'break-all' },
}))

export const TokenComponent: FC<Params> = ({ token }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const handleClick = () => {}

  return (
    <Slide direction='left' in={true} mountOnEnter unmountOnExit>
      {token && (
        <div className={classes.token}>
          <div className={classes.tokenContainer}>
            <div className={classes.tokenInformation}>
              <div className={classes.infoRow}>
                <Typography variant={'body1'} className={classes.header}>
                  {t('tokenWalletPage.tokenWalletContainer.access')}
                </Typography>
                <Typography variant={'body2'} className={classes.body}>
                  {token.hotspot_label}
                </Typography>
              </div>
              <div className={classes.infoRow}>
                <Typography variant={'body1'} className={classes.header}>
                  {t('tokenWalletPage.tokenWalletContainer.code')}
                </Typography>
                <Typography variant={'body2'} className={classes.body}>
                  {token.access_code}
                </Typography>
              </div>
              <div className={classes.infoRow}>
                <Typography variant={'body1'} className={classes.header}>
                  {t('tokenWalletPage.tokenWalletContainer.tokenID')}
                </Typography>
                <Typography variant={'body2'} className={classes.body}>
                  {token.token_id}
                </Typography>
              </div>
              <div className={classes.linkButton}>
                <ShadowedButton
                  id='link-button'
                  text={t('tokenWalletPage.tokenWalletContainer.linkButton')}
                  buttonStyle={'default'}
                  autoFocus={true}
                  hasDialogButtonShadow={true}
                  wrapperClasses={classes.buttonWrapper}
                  onClick={handleClick}
                ></ShadowedButton>
              </div>
            </div>
          </div>
        </div>
      )}
    </Slide>
  )
}

export default TokenComponent
