import React, { FC } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'

import AuthLayout from '../../../Containers/AuthenticationContainer/Auth/AuthLayout'
import ResetPasswordContainer from '../../../Containers/AuthenticationContainer/ResetPasswordContainer/ResetSetPasswordContainer'

const useStyles = makeStyles(() =>
  createStyles({
    containerWrapper: {
      height: '100vh',
    },
  })
)

const AuthResetPasswordPage: FC = () => {
  const classes = useStyles()

  return (
    <AuthLayout>
      <div className={classes.containerWrapper}>
        <ResetPasswordContainer />
      </div>
    </AuthLayout>
  )
}
export default AuthResetPasswordPage
