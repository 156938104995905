import { Button, createStyles, makeStyles, Typography } from '@material-ui/core'
import React, { FC, MouseEventHandler } from 'react'
import theme from '../../theme'

// TODO: replace other uses of this button type with this template, add disabled attribute & styling
export interface ShadowedButtonProps {
  id?: string
  text?: string
  title?: string
  classes?: string
  wrapperClasses?: string
  autoFocus?: boolean
  disabled?: boolean
  hasDialogButtonShadow?: boolean
  buttonStyle?: 'default' | 'dark'
  onClick?: MouseEventHandler<HTMLButtonElement>
}

const useStyles = makeStyles(() =>
  createStyles({
    buttonWrapper: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
    },
    button: {
      backgroundColor: theme.palette.primary.main,
      borderRadius: 0,
      boxShadow: 'none',
      height: '46px',
      textTransform: 'none',
      transition: 'all 0.4s ease-in-out',
      width: '100%',
      zIndex: 1,
      '&:hover': {
        boxShadow: 'none',
        backgroundColor: theme.palette.primary.main,
        transform: 'translate(-5px, -3px)',
      },
    },
    buttonDark: {
      backgroundColor: 'black',
      color: 'white',
      marginLeft: 10,
      '&:hover': {
        backgroundColor: 'black',
      },
    },
    buttonShadow: {
      backgroundColor: theme.palette.grey[500],
      bottom: '-6px',
      height: '46px',
      left: '6px',
      position: 'absolute',
      width: '100%',
    },
    dialogButtonShadow: {
      bottom: '9px',
      left: '18px',
      width: '88%',
    },
    disable: {
      backgroundColor: theme.palette.grey[200],
      pointerEvents: 'none',
    },
    disableFont: {
      color: theme.palette.background.default,
    },
  })
)

export const ShadowedButton: FC<ShadowedButtonProps> = (props) => {
  const classes = useStyles()

  return (
    <div className={`${classes.buttonWrapper} ${props.wrapperClasses}`}>
      <Button
        variant={'contained'}
        id={props.id}
        className={`
        ${props.disabled ? `${classes.disable} ${classes.button}` : classes.button}
        ${props.classes}
        ${props.buttonStyle === 'dark' ? classes.buttonDark : ''}`}
        title={props.title}
        autoFocus={!!props.autoFocus}
        onClick={props.onClick}
      >
        <Typography variant='subtitle2' className={props.disabled ? classes.disableFont : ''}>
          {props.text}
        </Typography>
      </Button>
      <Typography
        className={
          props.hasDialogButtonShadow
            ? `${classes.dialogButtonShadow} ${classes.buttonShadow}`
            : classes.buttonShadow
        }
      />
    </div>
  )
}
