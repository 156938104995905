import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { useAppDispatch } from '../../Reducers/hooks'
import { HelpPages, switchHelpPage } from './helpSlice'
import { MenuPages, switchMenuPage, toggleMenu, updateIsSideMenuOpen } from '../Menu/MenuSlice'
import HelpDeskForm from '../../Components/Help/HelpDeskForm'
import { MenuDrawer, MenuHeader, ShadowedButton } from '../../Common/common-index'
import { eventService } from '../../Services/services-index'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(4),
  },
  paperContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  submitButton: {
    marginTop: theme.spacing(5),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      maxWidth: '400px',
    },
  },
}))

export const HelpDeskContainer: FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const [phoneNumberValue, setPhoneNumberValue] = useState<string>('')
  const [phoneNumberFieldHasError, setPhoneNumberFieldHasError] = useState<boolean>(false)

  const [emailValue, setEmailValue] = useState<string>('')
  const [emailFieldHasError, setEmailFieldHasError] = useState<boolean>(false)

  const [textAreaValue, setTextAreaValue] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState<string>('')

  useEffect(() => {
    dispatch(updateIsSideMenuOpen(true))
  }, [
    phoneNumberValue,
    emailValue,
    textAreaValue,
    phoneNumberFieldHasError,
    emailFieldHasError,
    errorMessage,
  ])

  // On phone number filed value change
  const onPhoneNumberChange = (event: any) => {
    if (event) {
      setPhoneNumberValue(event)
    } else {
      setPhoneNumberValue('')
      setPhoneNumberFieldHasError(false)
    }
  }

  // On Email filed value change
  const onEmailInputChange = (event: any) => {
    if (event) {
      setEmailValue(event)
    } else {
      setEmailValue('')
      setEmailFieldHasError(false)
    }
  }

  // On Text Area Value change
  const onTextAreaValueChange = (event?: ChangeEvent<HTMLInputElement>) => {
    if (event?.target?.value) {
      setTextAreaValue(event.target.value)
    } else {
      setTextAreaValue('')
      setErrorMessage('')
    }
  }

  // On form submit
  const onSubmit = (e: any) => {
    e.preventDefault()
    if (phoneNumberValue && textAreaValue && emailValue) {
      // Regex control
      const phoneNumberReg = new RegExp(
        '((?:\\+|00)[17](?: |\\-)?|(?:\\+|00)[1-9]\\d{0,2}(?: |\\-)?|(?:\\+|00)1\\-\\d{3}(?: |\\-)?)?(0\\d|\\([0-9]{3}\\)|[1-9]{0,3})(?:((?: |\\-)[0-9]{2}){4}|((?:[0-9]{2}){4})|((?: |\\-)[0-9]{3}(?: |\\-)[0-9]{4})|([0-9]{7}))'
      )
      const emailReg = new RegExp('[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}$')
      const isPhoneNumberValid = phoneNumberReg.test(phoneNumberValue)
      const isEmailValid = emailReg.test(emailValue)

      if (!isPhoneNumberValid || !isEmailValid || textAreaValue?.length < 10) {
        !isPhoneNumberValid ? setPhoneNumberFieldHasError(true) : setPhoneNumberFieldHasError(false)
        !isEmailValid ? setEmailFieldHasError(true) : setEmailFieldHasError(false)
        textAreaValue?.length < 10
          ? setErrorMessage(t('helpMenu.helpDesk.textAreaError'))
          : setErrorMessage('')
        return
      } else {
        eventService
          .contactHelpDesk(phoneNumberValue, textAreaValue)
          .then((data: any) => {
            if (data.status === 200) {
              dispatch(switchHelpPage(HelpPages.HelpDeskSuccessPage))
              onFormFieldsReset()
            }
          })
          .catch((error: any) => {
            const errorText = error?.response?.data?.message
            setErrorMessage(errorText)
          })
      }
    }
  }

  // To reset form input fields values
  const onFormFieldsReset = () => {
    setPhoneNumberValue('')
    setEmailValue('')
    setTextAreaValue('')
    setErrorMessage('')
  }

  // User Actions
  const OnCloseClick = () => {
    onFormFieldsReset()
    dispatch(switchMenuPage(MenuPages.Menu))
    dispatch(toggleMenu(false))
    dispatch(switchHelpPage(HelpPages.HelpMenuPage))
    dispatch(updateIsSideMenuOpen(false))
  }

  const OnGoBack = () => {
    dispatch(switchHelpPage(HelpPages.HelpMenuPage))
    onFormFieldsReset()
  }

  return (
    <div className={classes.paperContainer}>
      <MenuDrawer
        isOpen={true}
        drawerHeader={
          <MenuHeader
            title={t('helpMenu.helpDesk.title')}
            onGoBack={OnGoBack}
            onClose={OnCloseClick}
          />
        }
      >
        <div className={classes.container}>
          <HelpDeskForm
            phoneNumberValue={phoneNumberValue}
            emailValue={emailValue}
            textAreaInput={textAreaValue}
            phoneNumberFieldHasError={phoneNumberFieldHasError}
            emailFieldHasError={emailFieldHasError}
            errorMessage={errorMessage}
            onPhoneNumberChange={onPhoneNumberChange}
            onEmailChange={onEmailInputChange}
            onTextAreaValueChange={onTextAreaValueChange}
          ></HelpDeskForm>
          <ShadowedButton
            id='help-menu-faq-submit'
            text={t('helpMenu.faq.button')}
            buttonStyle={'default'}
            wrapperClasses={classes.submitButton}
            disabled={!(phoneNumberValue && emailValue && textAreaValue)}
            onClick={onSubmit}
          ></ShadowedButton>
        </div>
      </MenuDrawer>
    </div>
  )
}

export default HelpDeskContainer
