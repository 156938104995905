import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Room, RoomContent, DisabledPopups } from '../../Services/services-index'

export interface RoomState {
  activeRoomId?: number
  room?: Room
  roomContent?: RoomContent
  disabledPopups: DisabledPopups[]
  roomRefreshTrigger: number
  isMultiplayerRoom: boolean
}

const initialState: RoomState = {
  activeRoomId: undefined,
  room: undefined,
  roomContent: undefined,
  disabledPopups: [],
  roomRefreshTrigger: 0,
  isMultiplayerRoom: false,
}

export const roomSlice = createSlice({
  name: 'room',
  initialState,
  reducers: {
    updateRoom: (state, action: PayloadAction<Room>) => {
      state.room = action.payload
      state.activeRoomId = action.payload.id
    },
    updateRoomContent: (state, action: PayloadAction<RoomContent>) => {
      state.roomContent = { ...state.roomContent, ...action.payload }
    },
    updateDisabledPopups: (state, action: PayloadAction<DisabledPopups>) => {
      state.disabledPopups.push(action.payload)
    },
    triggerRoomRefresh: (state) => {
      state.roomRefreshTrigger = state.roomRefreshTrigger + 1
    },
    updateIsMultiplayerRoom: (state, action: PayloadAction<boolean>) => {
      state.isMultiplayerRoom = action.payload
    },
  },
})

export const {
  updateRoom,
  updateRoomContent,
  updateDisabledPopups,
  triggerRoomRefresh,
  updateIsMultiplayerRoom,
} = roomSlice.actions

export default roomSlice.reducer
