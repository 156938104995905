/* eslint-disable no-unused-vars */
export const enum AgendaType {
  EVENT = 'event',
  ROOM = 'room',
  PRESENTER = 'presenter',
}

export interface Agenda {
  name: string
  description: string
  start_time: Date
  end_time: Date
  presenters: number[]
  type: string
  media_id: number
  long_description: string
}
