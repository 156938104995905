/* eslint-disable no-unused-vars */
export enum PreferenceType {
  ALL = 'all',
  SPECIFIC = 'specific',
}

export interface Event {
  name: string
  code: string
  mode: string
  description?: string
}

export interface OpenHours {
  from_timestamp: string
  to_timestamp: string
}

export interface Preference {
  name?: string
  value?: string
  category?: string
  user_id?: number
  role_id?: number
  room_id?: number
  room?: number
  event_code?: string
  from_date?: string
  to_date?: string
  open_hours?: string
  type?: PreferenceType
  subcategory?: string
}

export interface Bot {
  name: string
  service?: string
  service_url?: string
  service_token?: string
}

export interface Email {
  recipient: string
  subject: string
  body: string
}

export interface Passport {
  coin_balance?: number
  passport_complete?: boolean
  passport_claimed?: boolean
  badges?: Badge[]
}

export interface Badge {
  id?: number
  title?: string
  event_code?: string
  description?: string
  room_id?: number
  content_id?: number
  action_type?: string
  badge_complete?: boolean
  coin_value?: number
  multi_action?: boolean
}
