import React, { FC } from 'react'
import { makeStyles, createStyles } from '@material-ui/core/styles'

import { Dialog } from '@material-ui/core'
import { ShadowedButton } from '../Buttons/ShadowedButton'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(() =>
  createStyles({
    mediaBlock: {
      height: '100%',
      width: '100%',
      display: 'flex',
      overflowY: 'hidden',
      justifyContent: 'center',
      alignItems: 'center',
    },
    image: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
    video: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      pointerEvents: 'none',
    },
    dialogExitContainer: {
      cursor: 'pointer',
      position: 'absolute',
      top: '20px',
      right: '20px',
      display: 'flex',
    },
    transitionContent: {
      width: '100%',
      height: '100%',
      margin: '0px',
      maxWidth: '100%',
      maxHeight: '100%',
      borderRadius: '0',
    },
  })
)

export interface TransitionDialogProps {
  open: boolean | undefined
  mediaSrcOrPath: any | undefined
  mediaType: string | undefined
  onClose: () => void
}

export const TransitionDialog: FC<TransitionDialogProps> = ({
  open = false,
  mediaSrcOrPath,
  mediaType,
  onClose,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Dialog
      id='transition-dialog-dialog'
      open={open}
      onClose={onClose}
      PaperProps={{
        classes: {
          root: classes.transitionContent,
        },
      }}
    >
      {mediaSrcOrPath && (
        <div className={classes.mediaBlock}>
          {mediaType === 'video' && (
            <video
              className={classes.video}
              onEnded={onClose}
              autoPlay
              crossOrigin='anonymous'
              src={mediaSrcOrPath}
            ></video>
          )}
        </div>
      )}
      <div className={classes.dialogExitContainer}>
        <ShadowedButton
          id='transition-dialog-close'
          text={t('commonButtons.skipVideo')}
          title={t('commonButtons.skipVideo')}
          buttonStyle={'default'}
          autoFocus={true}
          onClick={onClose}
        ></ShadowedButton>
      </div>
    </Dialog>
  )
}
