import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

import { Dropdown, Icon, MenuHeader } from '../../Common/common-index'
import { useAppDispatch, useAppSelector } from '../../Reducers/hooks'
import UserProfile from '../../Components/Recruiting/UserProfile'
import { Divider, Theme, Typography } from '@material-ui/core'
import UserAvatar from '../UserAvatar/UserAvatar'
import PassportBadge from '../../Components/Passport/PassportBadge'
import { userService } from '../../Services/services-index'
import {
  RecruitingDashboardPages,
  switchRecruitingDashboardPage,
  updateIsRecruitingMobileViewActive,
  updateIsRecruitingDetailsViewActive,
  updateSelectedRecruitingUserData,
  updateSelectedRecruitingUserId,
} from './RecruitingDashboardSlice'
import { MenuPages, switchMenuPage, toggleMenu, updateIsSideMenuOpen } from '../Menu/MenuSlice'

const useStyles = makeStyles((theme: Theme) => ({
  paperContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
  },
  headerWrapper: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    display: 'flex',
    justifyContent: 'center',
    height: '80px',
    position: 'fixed',
    top: '0',
    width: 'calc(100vw - 800px)',
    minWidth: '400px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    '& header[class*="menuBar"]': { alignItems: 'center' },
  },
  contentWrapper: {
    height: 'calc(100% - 80px)',
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: '80px',
    width: 'calc(100vw - 800px)',
    minWidth: '400px',
    overflowX: 'hidden',
    overflowY: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  contentHeader: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '400px',
  },
  contentBody: {
    display: 'flex',
    flexDirection: 'row',
    padding: '0 0 0 32px',
  },
  contentBodyWrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    minWidth: '400px',
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'column',
    },
  },
  flexColumnWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  flexRowWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  achievements: {
    display: 'flex',
    flexDirection: 'column',
    width: '400px',
    marginTop: '150px',
    [theme.breakpoints.down('lg')]: {
      marginTop: '0px',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  badgeItem: {
    '& div[class*="passportBadge"]': { padding: '0 16px 0 0', width: '400px' },
  },
  achievementHeader: {
    padding: '0 32px 16px 32px',
    [theme.breakpoints.down('lg')]: {
      padding: '32px 0',
    },
    [theme.breakpoints.down('md')]: {
      padding: '32px 0',
    },
  },
  userProfileWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '400px',
    outline: 'none',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  shortInfo: {
    margin: '8px 0 40px 0',
    display: 'inline-flex',
    alignItems: 'center',
    outline: 'none',

    '& h2': {
      fontSize: '18px',
      lineHeight: '18px',
      marginBottom: '10px',
    },
  },
  userInfo: {
    marginLeft: '25px',
  },
  gray: { color: '#969696' },
  smallMarginTop: {
    marginTop: '5px',
  },
  mediumMarginTop: {
    marginTop: '10px',
  },
  statusChangeButton: {
    cursor: 'pointer',
    margin: '8px 0 16px 0',
  },
  divider: {
    margin: '20px 0',
  },
  errorStatus: {
    fontSize: '14px',
    color: '#b71c1c',
    display: 'flex',
  },
  successStatus: {
    color: '#460073',
    fontWeight: 'bold',
  },
  emptyMessage: {
    fontWeight: 'normal',
  },
  noStatus: {
    marginTop: '10px',
  },
  mobileActionButton: {
    cursor: 'pointer',
    marginRight: '8px',
  },
  cursor: {
    cursor: 'pointer',
  },
}))

export interface RecruitingStatus {
  id: number
  name: string
}

export const RecruitingDashboardProfileContainer: FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const currentUser = useAppSelector((state) => state.auth.currentUser)

  const selectedRecruitingUserId = useAppSelector(
    (state) => state.recruiting.selectedRecruitingUserId
  )
  const isRecruitingMobileViewActive = useAppSelector(
    (state) => state.recruiting.isRecruitingMobileViewActive
  )
  const isRecruitingDetailsViewActive = useAppSelector(
    (state) => state.recruiting.isRecruitingDetailsViewActive
  )
  const selectedRecruitingUserData = useAppSelector(
    (state) => state.recruiting.selectedRecruitingUserData
  )

  const [recruitingStatus, setRecruitingStatus] = useState<string>('')
  const [recruitingUserStatusId, setRecruitingUserStatusId] = useState<any>()
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [successMessage, setSuccessMessage] = useState<string>('')
  const [statusListOpen, setStatusListOpen] = useState<boolean>(false)
  const [userData, setUserData] = useState<any>()
  const [userProfileContent, setUserProfileContent] = useState<any>()

  useEffect(() => {
    if (selectedRecruitingUserId !== undefined) {
      getRecruitingUserDate()
    }
  }, [selectedRecruitingUserId])

  useEffect(() => {
    if (userData !== undefined) {
      setUserProfileContent(renderUserProfileContent())
    }
  }, [
    selectedRecruitingUserId,
    userData,
    statusListOpen,
    recruitingStatus,
    errorMessage,
    successMessage,
    selectedRecruitingUserData,
  ])

  useEffect(() => {
    if (userData !== undefined) {
      getUserRecruitingStatus()
    }
  }, [userData?.recruiting_status_id])

  // Get selected recruiting user data
  const getRecruitingUserDate = () => {
    if (selectedRecruitingUserId) {
      userService
        .getRecruitingUserData(selectedRecruitingUserId)
        .then((res: any) => {
          if (res && res.status === 200 && res?.data) {
            setUserData(res?.data)
            setErrorMessage('')
            dispatch(updateSelectedRecruitingUserData(res?.data))

            // Turn of success message
            setTimeout(() => {
              setSuccessMessage('')
            }, 3000)
          }
        })
        .catch((error: any) => {
          const errorText = error?.response?.data?.message
          setErrorMessage(errorText)
        })
    }
  }

  // Display candidate info header
  const renderUserImage = () => {
    return (
      <>
        <div className={classes.contentHeader}>
          <div className={classes.shortInfo}>
            <UserAvatar
              userId={selectedRecruitingUserId}
              isSquare={true}
              height={100}
              width={100}
            />
            <div className={classes.userInfo}>
              <Typography variant={'subtitle2'}>
                {userData?.firstname} {userData.lastname}
              </Typography>
              <Typography variant={'body2'} className={classes.smallMarginTop}>
                {userData?.job_title}
              </Typography>
              <Typography variant={'body2'} className={classes.gray}>
                {userData?.company}
              </Typography>
            </div>
            <Typography variant={'body2'}>{userData?.personal_quote}</Typography>
          </div>
        </div>
      </>
    )
  }

  // Display candidate last loggedIn information
  const renderLoggedInInfo = () => {
    return (
      <>
        <div className={classes.flexColumnWrapper}>
          <Typography variant={'subtitle1'}>
            {t('recruiting.candidatePage.loggedInInfo.lastLogIn')}
          </Typography>
          <Typography className={classes.smallMarginTop} variant={'body2'}>
            {new Date(userData?.last_login).toDateString()}&nbsp;-&nbsp;
            {new Date(userData?.last_login).toLocaleTimeString()}
          </Typography>
          <Typography className={classes.smallMarginTop} variant={'body2'}>
            {t('recruiting.candidatePage.loggedInInfo.currently')} {userData?.user_status}
          </Typography>
          <Typography className={classes.mediumMarginTop} variant={'subtitle1'}>
            {t('recruiting.candidatePage.loggedInInfo.recruitingStatus')}
          </Typography>
          {!statusListOpen && userData?.recruiting_status_id !== null && (
            <>
              <Typography className={classes.smallMarginTop} variant={'body2'}>
                {recruitingStatus}
              </Typography>
              <Typography
                id='change-recruiting-status'
                className={classes.statusChangeButton}
                variant={'button'}
                color={'primary'}
                aria-controls='edit-dropdown'
                onClick={() => setStatusListOpen(!statusListOpen)}
              >
                {t('recruiting.candidatePage.loggedInInfo.changeStatus')}
              </Typography>
            </>
          )}
          {statusListOpen && userData?.recruiting_status_id !== null && (
            <div className={classes.statusChangeButton}>
              <Dropdown
                itemsList={userData?.recruiting_statuses}
                selecteditem={recruitingUserStatusId}
                variant={'outlined'}
                onOptionChange={onChangeRecruitingStatus}
              ></Dropdown>
            </div>
          )}
          {userData?.recruiting_status_id === null && (
            <Typography className={classes.noStatus} variant={'body2'}>
              {t('recruiting.candidatePage.profile.info.noStatus')}
            </Typography>
          )}
          {errorMessage && renderConfirmationMessage(errorMessage)}
          {successMessage && renderConfirmationMessage(successMessage)}
          <Divider className={classes.divider} />
        </div>
      </>
    )
  }

  // Get selected recruiting process status
  const getUserRecruitingStatus = () => {
    return (
      userData &&
      userData?.recruiting_statuses?.find((statusItem: any) => {
        if (statusItem?.id === userData?.recruiting_status_id) {
          setRecruitingStatus(statusItem?.name)
          setRecruitingUserStatusId(userData?.recruiting_status_id)
        }
        return
      })
    )
  }

  // To update recruiting status
  const onChangeRecruitingStatus = (event?: ChangeEvent<any>) => {
    if (event && event.target) {
      const selectedType = event.target.value
      const statusValue = userData?.recruiting_statuses.find((t: any) => t?.id === selectedType)
      if (selectedType && statusValue && selectedRecruitingUserId && currentUser) {
        userService
          .patchRecruitingUserStatus(
            selectedRecruitingUserId,
            selectedType,
            currentUser?.event_code
          )
          .then((res: any) => {
            if (res && res.status === 200 && statusValue) {
              setStatusListOpen(false)
              setRecruitingStatus(statusValue?.name)
              setRecruitingUserStatusId(selectedType)
              setSuccessMessage(res?.data?.message)
              setErrorMessage('')

              // Turn of success message
              setTimeout(() => {
                setSuccessMessage('')
              }, 3000)
            }
          })
          .catch((error: any) => {
            const errorText = error?.response?.data?.message
            setErrorMessage(errorText)

            // Turn of success message
            setTimeout(() => {
              setErrorMessage('')
            }, 3000)
          })
      }
    }
    return
  }

  // Render available user badge list
  const renderUserBadges = () => {
    return (
      <div className={classes.achievements}>
        <Typography className={classes.achievementHeader} variant={'subtitle1'}>
          {t('recruiting.candidatePage.achievements.title')}
        </Typography>
        {userData &&
          userData?.badges &&
          userData.badges.map((badgeValue: any) => {
            return (
              <div className={classes.badgeItem}>
                <PassportBadge passportBadge={badgeValue} key={badgeValue.id} />
              </div>
            )
          })}
        {!userData.badges?.length && (
          <Typography className={classes.achievementHeader} variant={'body2'}>
            {t('recruiting.candidatePage.achievements.noBadges')}
          </Typography>
        )}
      </div>
    )
  }

  // TODO: "Recruiting Note" code is added and will be used in the future.
  // Once the requirements are clear update the below conditions accordingly.

  // Render available user badge list
  const renderProfileInfo = () => {
    return <UserProfile hasNoteFunction={false} updateRecruitingUserNote={() => null}></UserProfile>
  }

  const onNewConversationClick = () => {
    dispatch(switchRecruitingDashboardPage(RecruitingDashboardPages.RecruitingDashboarChatPage))
  }

  const onShowAchievementsClick = () => {
    dispatch(
      switchRecruitingDashboardPage(RecruitingDashboardPages.RecruitingDashboardBadgeListPage)
    )
  }

  const renderMobileActions = () => {
    return (
      <div className={classes.flexColumnWrapper}>
        <div className={classes.flexRowWrapper} onClick={onNewConversationClick}>
          <div className={classes.mobileActionButton}>
            <Icon iconName={'chat'} iconSize={'20px'} />
          </div>
          <Typography
            id='recruiting-new-conversation'
            className={classes.cursor}
            variant={'button'}
            color={'primary'}
            aria-controls='new-conversation'
          >
            {t('recruiting.candidatePage.profile.newConversation')}
          </Typography>
        </div>
        <div
          className={`${classes.flexRowWrapper} ${classes.mediumMarginTop}`}
          onClick={onShowAchievementsClick}
        >
          <div className={classes.mobileActionButton}>
            <Icon iconName={'achievementReceived'} iconSize={'20px'} />
          </div>
          <Typography
            id='recruiting-show-achievements'
            className={classes.cursor}
            variant={'button'}
            color={'primary'}
            aria-controls='show-achievements'
          >
            {t('recruiting.candidatePage.profile.showAchievements')}
          </Typography>
        </div>
      </div>
    )
  }

  // To display success or error messages
  const renderConfirmationMessage = (message?: string) => {
    return (
      <>
        <Typography
          variant='body2'
          className={
            errorMessage ? classes.errorStatus : `${classes.errorStatus} ${classes.successStatus}`
          }
        >
          {message}
        </Typography>
      </>
    )
  }

  const renderUserProfileContent = () => {
    return (
      <div className={classes.contentWrapper}>
        <div className={classes.contentBody}>
          <div className={classes.contentBodyWrapper}>
            <div className={classes.userProfileWrapper}>
              {renderUserImage()}
              {isRecruitingMobileViewActive && (
                <>
                  <Divider className={classes.divider} />
                  {renderMobileActions()}
                  <Divider className={classes.divider} />
                </>
              )}
              {renderLoggedInInfo()}
              {renderProfileInfo()}
            </div>
            {!isRecruitingMobileViewActive && renderUserBadges()}
          </div>
        </div>
      </div>
    )
  }

  // User Header Actions
  const OnCloseClick = () => {
    dispatch(updateIsRecruitingMobileViewActive(false))
    dispatch(updateIsRecruitingDetailsViewActive(false))
    dispatch(updateSelectedRecruitingUserId(undefined))
    dispatch(updateSelectedRecruitingUserData({}))

    dispatch(toggleMenu(false))
    dispatch(updateIsSideMenuOpen(false))
    dispatch(switchMenuPage(MenuPages.Menu))
    dispatch(switchRecruitingDashboardPage(RecruitingDashboardPages.RecruitingDashboardMainMenu))
  }

  const OnGoBack = () => {
    dispatch(switchMenuPage(MenuPages.Menu))
    dispatch(toggleMenu(true))
  }

  return (
    <div className={classes.paperContainer}>
      <div className={classes.headerWrapper}>
        <MenuHeader
          title={t('recruiting.candidatePage.headerTitle')}
          hasActionButtons={isRecruitingMobileViewActive ? true : false}
          hasDivider={true}
          fullDivider={true}
          onGoBack={OnGoBack}
          onClose={OnCloseClick}
        />
      </div>
      {!errorMessage && isRecruitingDetailsViewActive && userProfileContent}
      {!errorMessage && !isRecruitingDetailsViewActive && (
        <Typography variant={'body1'}>{t('recruiting.candidatePage.noUserSelected')}</Typography>
      )}
      {errorMessage && renderConfirmationMessage(errorMessage)}
    </div>
  )
}

export default RecruitingDashboardProfileContainer
