import React, { FC, useEffect, useState } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

import { MenuDrawer, MenuHeader } from '../../Common/common-index'
import { useAppDispatch, useAppSelector } from '../../Reducers/hooks'
import { MenuPages, switchMenuPage, toggleMenu, updateIsSideMenuOpen } from '../Menu/MenuSlice'
import ChatMessagesContainer from '../Chat/ChatMessagesContainer'
import {
  RecruitingDashboardPages,
  switchRecruitingDashboardPage,
  updateIsRecruitingMobileViewActive,
  updateIsRecruitingDetailsViewActive,
  updateSelectedRecruitingUserData,
  updateSelectedRecruitingUserId,
} from './RecruitingDashboardSlice'
import { Typography } from '@material-ui/core'
import EventNotificationContainer from '../EventNotificationContainer/EventNotificationContainer'
import { startConversationAsync } from '../../Reducers/socketSlice'
import { Conversation } from '../../Services/services-index'
import { updateActiveConversationId, updateActiveConversation } from '../Chat/ChatSlice'

const useStyles = makeStyles((theme: Theme) => ({
  paperContainer: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    '& div[class*="header"]': { minHeight: '80px' },
  },
  noChat: {
    marginTop: '-25px',
  },
}))

export const RecruitingDashboardChatContainer: FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const selectedRecruitingUserId = useAppSelector(
    (state) => state.recruiting.selectedRecruitingUserId
  )
  const hasNotificationPageOpen = useAppSelector(
    (state) => state.recruiting.hasNotificationPageOpen
  )
  const selectedRecruitingUserData = useAppSelector(
    (state) => state.recruiting.selectedRecruitingUserData
  )
  const isRecruitingMobileViewActive = useAppSelector(
    (state) => state.recruiting.isRecruitingMobileViewActive
  )

  const [hasChatUser, setHasChatUser] = useState<boolean>(false)
  const [userData] = useState<any>(selectedRecruitingUserData)

  useEffect(() => {
    if (selectedRecruitingUserId !== undefined) {
      dispatch(startConversationAsync({ receiverIds: [selectedRecruitingUserId] }))
      setHasChatUser(true)
    } else {
      setHasChatUser(false)
    }
  }, [selectedRecruitingUserId])

  useEffect(() => {}, [
    hasChatUser,
    hasNotificationPageOpen,
    userData,
    isRecruitingMobileViewActive,
    selectedRecruitingUserData,
  ])

  const OnCloseClick = () => {
    dispatch(updateIsRecruitingMobileViewActive(false))
    dispatch(updateIsRecruitingDetailsViewActive(false))
    dispatch(updateSelectedRecruitingUserId(undefined))
    dispatch(updateSelectedRecruitingUserData({}))

    dispatch(updateActiveConversationId(''))
    dispatch(updateActiveConversation({} as Conversation))

    dispatch(switchMenuPage(MenuPages.Menu))
    dispatch(toggleMenu(false))
    setHasChatUser(false)
    dispatch(updateIsSideMenuOpen(false))
    dispatch(switchRecruitingDashboardPage(RecruitingDashboardPages.RecruitingDashboardMainMenu))
  }

  const OnGoBack = () => {
    dispatch(switchRecruitingDashboardPage(RecruitingDashboardPages.RecruitingDashboardProfilePage))
  }

  return (
    <div className={classes.paperContainer}>
      <MenuDrawer
        isOpen={true}
        drawerHeader={
          <MenuHeader
            title={t('recruiting.chatPage.headerTitle')}
            onGoBack={isRecruitingMobileViewActive ? OnGoBack : undefined}
            hasDivider={true}
            fullDivider={true}
            onClose={OnCloseClick}
          />
        }
      >
        {hasChatUser && (
          <>
            {!hasNotificationPageOpen && <ChatMessagesContainer isRecruitingDashboardChat={true} />}
            {hasNotificationPageOpen && (
              <EventNotificationContainer isRecruitingDashboardNotification={true} />
            )}
          </>
        )}
        {!hasChatUser && (
          <div className={`${classes.paperContainer} ${classes.noChat}`}>
            <Typography variant={'body1'}>{t('recruiting.chatPage.noUserSelect')} </Typography>
          </div>
        )}
      </MenuDrawer>
    </div>
  )
}

export default RecruitingDashboardChatContainer
