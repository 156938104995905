/* eslint-disable no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { localStorageService } from '../../Services/services-index'

export enum SettingsPages {
  SettingsList,
  Language,
}

const getLocalBackgroundMusicState = () => {
  const backgroundMusicState: any =
    localStorageService.getLocalStorageItemValue('backgroundMusicStatus')
  return backgroundMusicState?.length && backgroundMusicState === 'false' ? false : true
}

const getThirdPersonViewState = () => {
  const thirdPersonViewState: any = localStorageService.getLocalStorageItemValue('thirdPersonView')
  return thirdPersonViewState?.length && thirdPersonViewState === 'true' ? true : false
}

export interface SettingsState {
  activeSettingsPage: SettingsPages
  backgroundMusicOn: boolean
  newsfeedNotificationsOn: boolean
  language: string
  miniMap: boolean
  showMiniMapControls: boolean
  thirdPersonView?: boolean
}

const getLocalMiniMapState = () => {
  const enableMiniMapState: any = localStorageService.getLocalStorageItemValue('enableMiniMap')
  return enableMiniMapState === 'true' ? true : false
}

const initialState: SettingsState = {
  activeSettingsPage: SettingsPages.SettingsList,
  backgroundMusicOn: getLocalBackgroundMusicState(),
  newsfeedNotificationsOn: false,
  language: 'en',
  miniMap: getLocalMiniMapState(),
  showMiniMapControls: true,
  thirdPersonView: getThirdPersonViewState(),
}

export const SettingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    switchSettingsPage: (state, action: PayloadAction<SettingsPages>) => {
      state.activeSettingsPage = action.payload
    },
    setBackgroundMusic: (state, action: PayloadAction<boolean>) => {
      state.backgroundMusicOn = action.payload
      localStorageService.setLocalStorageItemValue(
        'backgroundMusicStatus',
        JSON.stringify(state.backgroundMusicOn)
      )
    },
    toggleBackgroundMusic: (state) => {
      state.backgroundMusicOn = !state.backgroundMusicOn
      localStorageService.setLocalStorageItemValue(
        'backgroundMusicStatus',
        JSON.stringify(state.backgroundMusicOn)
      )
    },
    setNewsfeedNotifications: (state, action: PayloadAction<boolean>) => {
      state.newsfeedNotificationsOn = action.payload
      localStorageService.setLocalStorageItemValue(
        'enableRoomChatFeed',
        JSON.stringify(state.newsfeedNotificationsOn)
      )
    },
    toggleNewsfeedNotifications: (state) => {
      state.newsfeedNotificationsOn = !state.newsfeedNotificationsOn
      localStorageService.setLocalStorageItemValue(
        'enableRoomChatFeed',
        JSON.stringify(state.newsfeedNotificationsOn)
      )
    },
    setLanguage: (state, action: PayloadAction<string>) => {
      state.language = action.payload
    },
    setMiniMap: (state, action: PayloadAction<boolean>) => {
      state.miniMap = action.payload
      localStorageService.setLocalStorageItemValue('enableMiniMap', JSON.stringify(state.miniMap))
    },
    toggleMiniMap: (state) => {
      state.miniMap = !state.miniMap
      localStorageService.setLocalStorageItemValue('enableMiniMap', JSON.stringify(state.miniMap))
    },
    updateShowMiniMapControls: (state, action: PayloadAction<boolean>) => {
      state.showMiniMapControls = action.payload
    },
    toggleThirdPersonView: (state) => {
      state.thirdPersonView = !state.thirdPersonView
      localStorageService.setLocalStorageItemValue(
        'thirdPersonView',
        JSON.stringify(state.thirdPersonView)
      )
    },
  },
})

export const {
  switchSettingsPage,
  setBackgroundMusic,
  toggleBackgroundMusic,
  setNewsfeedNotifications,
  toggleNewsfeedNotifications,
  setLanguage,
  setMiniMap,
  toggleMiniMap,
  updateShowMiniMapControls,
  toggleThirdPersonView,
} = SettingsSlice.actions

export default SettingsSlice.reducer
