import axios from 'axios'
import { Bot, Email, OpenHours, Preference, PreferenceType } from '../Models/event.model'
import { FrontendTypes } from '../services-index'
import Config from '../../config.json'

const reactAppDomain = Config.VSF_K8S_API_URL
const apiVersion = Config.VSF_K8S_API_VERSION
const servicePrefix = `${reactAppDomain}/event${apiVersion}`

export const createEvent = (body: Event) => {
  return axios.post(`${servicePrefix}/event`, body)
}

export const postOpeningHours = (eventCode: string, body: OpenHours[]) => {
  return axios.post(`${servicePrefix}/event/${eventCode}/openhours`, body)
}

export const getOpeningHours = (eventCode: string) => {
  return axios.get(`${servicePrefix}/event/${eventCode}/openhours`)
}

export const updateEvent = (
  eventCode: string,
  name?: string,
  mode?: string,
  description?: string
) => {
  if (!name && !mode && !description) {
    return
  }
  const body = {}
  if (name) Object.assign(body, { name })
  if (mode) Object.assign(body, { mode })
  if (description) Object.assign(body, { description })
  return axios.patch(`${servicePrefix}/event/${eventCode}`, body)
}

export const getAllEvents = () => {
  return axios.get(`${servicePrefix}/events`)
}

export const creatFaq = (frontend: FrontendTypes, body: FormData, eventCode?: string) => {
  const query = {
    frontend,
  }
  if (eventCode) Object.assign(query, { event_code: eventCode })
  return axios.post(`${servicePrefix}/faqs`, body, {
    params: query,
  })
}

export const getFaqsForEvent = async (frontend: FrontendTypes, csv: boolean, eventCode: string) => {
  return await axios.get(`${servicePrefix}/faqs/event/${eventCode}`, {
    params: { csv, frontend },
  })
}

export const deleteFaq = (faqId: string) => {
  return axios.get(`${servicePrefix}/faqs/${faqId}`)
}

export const contactHelpDesk = (phoneNumber: string, message: string) => {
  return axios.post(`${servicePrefix}/help`, { phoneNumber, message })
}

export const postPreference = (body: Preference) => {
  return axios.post(`${servicePrefix}/preference`, body)
}

export const patchPreferences = (id: number, body: Preference) => {
  return axios.patch(`${servicePrefix}/preference/${id}`, body)
}

export const deletePreference = (id: number) => {
  return axios.delete(`${servicePrefix}/preference/${id}`)
}

export const getPreference = (body?: any) => {
  return axios.get(`${servicePrefix}/preference`, { params: body })
}

export const getAllPreferences = async () => {
  const body = {
    type: PreferenceType.ALL,
  }
  const response = await getPreference(body).then((res) => res)
  return response
}

export const getSpecificPreferences = (room?: number, frontend?: FrontendTypes) => {
  const body = {
    type: PreferenceType.SPECIFIC,
    room,
    frontend,
  }
  getPreference(body)
}

export const getPreferenceByCategoryOrName = (
  type: PreferenceType,
  name?: string,
  category?: string,
  eventCode?: string,
  frontend?: FrontendTypes
) => {
  const body = {
    type,
  }
  if (name) Object.assign(body, { name })
  if (category) Object.assign(body, { category })
  if (eventCode) Object.assign(body, { event_code: eventCode })
  if (frontend) Object.assign(body, { frontend })
  return getPreference(body)
}

export const createBot = (body: Bot) => {
  return axios.post(`${servicePrefix}/bot`, body)
}

export const updateBot = (body: Bot) => {
  return axios.patch(`${servicePrefix}/bot`, body)
}

export const deleteBot = (body: Bot) => {
  return axios.delete(`${servicePrefix}/bot`, { params: body })
}

export const createEmail = (body: Email) => {
  return axios.post(`${servicePrefix}/email`, body)
}

export const getPassport = () => {
  return axios.get(`${servicePrefix}/passport`)
}

export const claimPassport = () => {
  return axios.patch(`${servicePrefix}/passport`)
}

export const getStoreItems = () => {
  return axios.get(`${servicePrefix}/store/items`)
}

export const purchaseItem = (item_id: number, coin_price: number) => {
  return axios.post(`${servicePrefix}/store/purchase`, { item_id, coin_price })
}
