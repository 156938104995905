import { FormControlLabel, makeStyles, Radio, RadioProps, Theme } from '@material-ui/core'
import React, { FC } from 'react'

const useStyles = makeStyles((theme: Theme) => ({
  radioButton: {
    color: theme.palette.grey[300],
    '&$checked': {
      color: theme.palette.primary.main,
    },
  },
  checked: {},
  radioLabel: {
    fontWeight: 500, // Should be Graphik-Medium, but @font-faces don't seem to be working
    fontSize: '18px',
  },
}))

export interface BaseRadioProps extends RadioProps {
  value: string
  label: string
}

export const BaseRadio: FC<BaseRadioProps> = (props) => {
  const classes = useStyles()

  return (
    <FormControlLabel
      classes={{
        label: classes.radioLabel,
      }}
      value={props.value}
      control={
        <Radio
          {...props}
          disableRipple
          classes={{
            root: classes.radioButton,
            checked: classes.checked,
          }}
        />
      }
      label={props.label}
    />
  )
}
