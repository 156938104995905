import React, { FC } from 'react'

import { useAppSelector } from '../../Reducers/hooks'
import HelpDeskContainer from './HelpDeskContainer'
import HelpDeskSuccessContainer from './HelpDeskSuccessContainer'
import { HelpPages } from './helpSlice'
import HelpMenuContainer from './HelpMenuContainer'
import HelpUserManualContainer from './HelpUserManualContainer'
import HelpFaqContainer from './HelpFaqContainer'
import HelpPrivacy from './HelpPrivacyPolicy'
import HelpCookiePolicyContainer from './HelpCookiePolicyContainer'

const HelpContainer: FC = () => {
  const activeHelpPage = useAppSelector((state) => state.help.activeHelpPage)

  switch (activeHelpPage) {
    case HelpPages.HelpMenuPage:
      return <HelpMenuContainer />
    case HelpPages.HelpUserManualpage:
      return <HelpUserManualContainer />
    case HelpPages.HelpDeskFormPage:
      return <HelpDeskContainer />
    case HelpPages.HelpDeskSuccessPage:
      return <HelpDeskSuccessContainer />
    case HelpPages.HelpFaqPage:
      return <HelpFaqContainer />
    case HelpPages.HelpPrivacy:
      return <HelpPrivacy />
    case HelpPages.HelpCookiePolicyPage:
      return <HelpCookiePolicyContainer />
    default: {
      return null
    }
  }
}

export default HelpContainer
