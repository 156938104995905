import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from '../../Reducers/hooks'
import { MenuPages, switchMenuPage, toggleMenu, updateIsSideMenuOpen } from '../Menu/MenuSlice'
import { ProfileSettingPages, switchProfilePage } from './profileSettingsSlice'
import { MenuDrawer, MenuHeader, ShadowedButton } from '../../Common/common-index'
import { ChangeProfilePicture } from '../../Components/ProfileSettings/ChangeProfilePicture'
import { eventService, Preference, userService, w3dsService } from '../../Services/services-index'
import { fetchUserSummaryAsync } from '../../Reducers/userSlice'
import { FormControl, Grid, List, Typography } from '@material-ui/core'
import { setAvatar, setProfilePictureUpdate } from '../ToolButtonsContainer/ToolButtonSlice'
import { ToggleItemButton } from '../../Common/Components/Radio/ToggleItemButton'
import { getThumbnailImagePath } from '../../Services/Services/w3ds.service'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.background.default,
      height: '100%',
    },
    paperContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
    },
    avatarDesignTitle: {
      alignItems: 'left',
      fontSize: '14px',
      margin: '30px',
      marginBottom: theme.spacing(0),
    },
    avatarContainer: {
      backgroundColor: theme.palette.background.default,
      flex: 4,
      marginTop: '5px',
      width: '100%',
    },
    formControl: {
      width: '100%',
    },
    avatarList: {
      alignItems: 'flex-start',
      display: 'flex',
      flexDirection: 'row',
      margin: '5px',
      fontSize: '14px',
      color: theme.palette.primary.main,
    },
    buttonWrapper: {
      position: 'fixed',
      width: 'min(20vh, 320px)',
      bottom: '30px',
      right: '40px',
    },
    buttonContainerSave: {
      zIndex: 1,
      '& button[class*="makeStyles-button"]': {
        width: '20vh',
      },
    },
    profileContainer: {
      flex: 1,
      width: '100%',
    },
    footer: {
      minHeight: '80px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexBasis: 'auto',
      backgroundColor: theme.palette.background.default,
    },
  })
)
export interface Props {
  roomIframeRef: React.RefObject<HTMLIFrameElement>
}

type Avatar = {
  default: boolean
  description: string
  id: number
  name: string
  path: string
  size: number
  thumbnail: string
}

export const AppearanceSettingsContainer: FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const currentUser = useAppSelector((state) => state.auth.currentUser)
  const [currentUserId] = useState<number>(currentUser?.id as number)
  const currentProfilePicture = useAppSelector((state) =>
    state.user.userList[currentUserId]
      ? state.user.userList[currentUserId].profile_picture
      : '/atrium/profilepictures/default1.jpg'
  )

  const [errorMessage, setErrorMessage] = useState<string>('')
  const [successMessage, setSuccessMessage] = useState<string>('')
  const [userHasProfilePicture, setUserHasProfilePicture] = useState<boolean>(
    currentProfilePicture !== '/atrium/profilepictures/default1.jpg'
  )
  const preferences = useAppSelector((state) => state.preferences.specificPreferences)
  const selectedAvatarSetting = useAppSelector((state) => state.toolButtons.selectedAvatar)
  const [displayAvatarList, setDisplayAvatarList] = useState<Avatar[]>()
  const [selectedAvatar, setSelectedAvatar] = useState<any>()

  const eventCode = currentUser?.event_code

  const USER_SELECTED_AVATAR_PREFNAME = 'user_selected_avatar'

  useEffect(() => {
    dispatch(updateIsSideMenuOpen(true))
  })

  useEffect(() => {
    if (preferences) {
      // Set selected avatar from local storage or from preference
      let preselectedAvatar: string | undefined = undefined
      if (selectedAvatarSetting) {
        preselectedAvatar = selectedAvatarSetting
      } else {
        const userSelectedAvatar = preferences[USER_SELECTED_AVATAR_PREFNAME]
        if (userSelectedAvatar) {
          preselectedAvatar = userSelectedAvatar.value
        }
      }
      if (preselectedAvatar) {
        setSelectedAvatar(preselectedAvatar)
      }

      // Get avatars
      if (eventCode) {
        // Get & set avatars by event code or without depending on event preference
        const avatarsPromise =
          preferences['use_event_avatars']?.value === 'yes'
            ? w3dsService.getAvatars(eventCode)
            : w3dsService.getAvatars()
        avatarsPromise
          .then((response: any) => {
            if (response && response?.data) {
              const avatars = response?.data as Array<Avatar>
              adjustPathOfAvatars(avatars)
              // Set the selected avatar to the default avatar
              if (!preselectedAvatar) {
                const defaultAvatar = avatars.filter((avatar) => avatar.default === true)
                if (defaultAvatar && defaultAvatar.length > 0) {
                  setSelectedAvatar(defaultAvatar[0].id)
                }
              }
            }
          })
          .catch((err) => console.log(err))
      }
    }
  }, [preferences])

  const adjustPathOfAvatars = (avatars: Array<Avatar>) => {
    const avatarPromises = avatars.map(async (avatar) => {
      avatar.thumbnail = avatar.thumbnail.startsWith('/')
        ? avatar.thumbnail
        : `/${avatar.thumbnail}`
      avatar.thumbnail = await getThumbnailImagePath(avatar.thumbnail)
      return avatar
    })

    Promise.all(avatarPromises)
      .then((avatarsWithAdjustedPath) => {
        setDisplayAvatarList(avatarsWithAdjustedPath)
      })
      .catch((error) => {
        console.error(error)
      })
  }
  // On new profile picture update
  const updateProfilePicture = (e: any) => {
    e.stopPropagation()
    if (e) {
      const file = e.target?.files[0]
      userService
        .patchUserPicture(file, currentUserId)
        .then((res) => {
          if (res?.status === 200) {
            dispatch(fetchUserSummaryAsync(currentUserId))
            dispatch(setProfilePictureUpdate(true))
            setUserHasProfilePicture(true)
            setSuccessMessage(t('profileSettingsPage.changePicturePage.updateMessage'))
            setErrorMessage('')

            // Turn of success message
            setTimeout(() => {
              setSuccessMessage('')
            }, 3500)
          }
        })
        .catch((error) => {
          const errorText = error?.response?.data?.message
          setErrorMessage(errorText)
          setSuccessMessage('')
        })
    }
  }

  // On active profile picture remove
  const removeProfilePicture = () => {
    userService
      .deleteUserPicture(currentUserId)
      .then((res) => {
        if (res?.status === 200) {
          dispatch(fetchUserSummaryAsync(currentUserId))
          dispatch(setProfilePictureUpdate(true))
          setUserHasProfilePicture(false)
          setSuccessMessage(t('profileSettingsPage.changePicturePage.removeMessage'))
          setErrorMessage('')

          // Turn of success message
          setTimeout(() => {
            setSuccessMessage('')
          }, 3500)
        }
      })
      .catch((error) => {
        const errorText = error?.response?.data?.message
        setErrorMessage(errorText)
        setSuccessMessage('')
      })
  }

  const OnCloseClick = () => {
    dispatch(toggleMenu(false))
    dispatch(updateIsSideMenuOpen(false))
    dispatch(switchMenuPage(MenuPages.Menu))
    dispatch(switchProfilePage(ProfileSettingPages.ProfileSettingsHome))
  }

  const OnGoBack = () => {
    dispatch(switchProfilePage(ProfileSettingPages.ProfileSettingsHome))
  }

  const onAvatarChange = (selectedValue: number) => {
    setSelectedAvatar(selectedValue)
  }

  const onSave = () => {
    const body: Preference = {
      value: selectedAvatar ? selectedAvatar + '' : '',
      name: USER_SELECTED_AVATAR_PREFNAME,
      category: 'general',
      user_id: currentUser?.id,
      event_code: currentUser?.event_code.toString(),
    }
    eventService
      .postPreference(body)
      .then((data: any) => {
        if (data.status === 200) {
          dispatch(setAvatar(selectedAvatar))
        }
      })
      .catch((error: any) => {
        console.log(error)
      })
  }

  return (
    <form className={classes.paperContainer}>
      <MenuDrawer
        isOpen={true}
        drawerHeader={
          <MenuHeader
            title={t('profileSettingsPage.changeAvatarPage.changeAppearance')}
            onGoBack={OnGoBack}
            onClose={OnCloseClick}
          />
        }
      >
        <div className={classes.container}>
          <div className={classes.profileContainer}>
            <ChangeProfilePicture
              currentUserId={currentUserId}
              errorMessage={errorMessage}
              successMessage={successMessage}
              userHasProfilePicture={userHasProfilePicture}
              updateProfilePicture={(e) => updateProfilePicture(e)}
              removeProfilePicture={() => removeProfilePicture()}
            ></ChangeProfilePicture>
          </div>
          <Typography className={classes.avatarDesignTitle} variant={'h6'}>
            {t('profileSettingsPage.changeAvatarPage.avatarDesign')}
          </Typography>
          {displayAvatarList && displayAvatarList.length > 0 && (
            <div className={classes.formControl}>
              <FormControl className={classes.avatarContainer}>
                <List component='nav' aria-label='main mailbox folders'>
                  {displayAvatarList.map((avatar) => (
                    <ToggleItemButton
                      onClickEvent={() => onAvatarChange(avatar.id)}
                      checkSelected={() => (selectedAvatar ? selectedAvatar : '') === avatar.id}
                      value={`${avatar.id}`}
                      label={`${avatar.name}`}
                      key={`${avatar.name}`}
                      path={avatar.thumbnail}
                    />
                  ))}
                </List>
              </FormControl>
            </div>
          )}
          <Grid item className={classes.footer} xs={12}>
            <div className={classes.buttonContainerSave}>
              <ShadowedButton
                text={t('commonButtons.save')}
                onClick={onSave}
                buttonStyle={'default'}
                wrapperClasses={classes.buttonWrapper}
              ></ShadowedButton>
            </div>
          </Grid>
        </div>
      </MenuDrawer>
    </form>
  )
}
