import React, { FC, useState, useEffect } from 'react'
import Dropdown from '../Dropdown/Dropdown'
import Dialog from '@material-ui/core/Dialog'
import Typography from '@material-ui/core/Typography'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { ShadowedButton } from '../Buttons/ShadowedButton'
import { DialogActions } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      alignItems: 'left',
      padding: 20,
      borderRadius: '8px',
      overflowX: 'hidden',
    },
    dialogTitle: {
      marginBottom: '40px',
      display: 'flex',
      justifyContent: 'left',
      fontFamily: 'Graphik-Semibold',
      textAlign: 'left',
    },
    dialogContent: {
      fontFamily: 'Graphik-Regular',
      fontSize: '21px',
      lineHeight: '28px',
      display: 'flex',
      justifyContent: 'left',
      textAlign: 'left',
      overflowX: 'hidden',
    },
    dialogDropdown: {
      marginTop: '10px',
    },
    dialogActions: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
      marginTop: '40px',
      alignItems: 'center',
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
      },
    },
    buttonWrapper: {
      width: '65%',
      padding: '16px',
      [theme.breakpoints.up('sm')]: {
        width: '50%',
      },
      '& button[class*="makeStyles-buttonDark"]': {
        marginLeft: 0,
      },
    },
    errorStatus: {
      color: '#b71c1c',
    },
  })
)

interface ListItem {
  value: number
  name: string
}

interface Instance {
  instance: number
  users: number
}

export interface RoomInstancesDialogProps {
  // eslint-disable-next-line no-unused-vars
  onConfirm: (instance: number) => void
  instances: Instance[]
  maxUsers: number
  isError: boolean
}

const RoomInstancesDialog: FC<RoomInstancesDialogProps> = ({
  onConfirm,
  instances,
  maxUsers,
  isError,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [selectedInstance, setselectedInstance] = useState<number>(instances[0]?.instance)
  const [cancelDisabled, setCancelDisabled] = useState<boolean>()
  const [itemsList, setItemsList] = useState<ListItem[]>()

  const createItemsList = () => {
    setItemsList(
      instances?.map((instance: Instance) => ({
        value: instance?.instance,
        name: `${instance?.instance} : ${instance?.users}/${maxUsers} users`,
      }))
    )
  }

  const onCancel = () => {
    window.location.href = '/lobby'
  }

  useEffect(() => {
    window.location.pathname.includes('/lobby') ? setCancelDisabled(true) : setCancelDisabled(false)
    createItemsList()
  }, [])

  return (
    <>
      <Dialog
        disableEscapeKeyDown
        classes={{
          paper: classes.paper,
        }}
        maxWidth='xs'
        aria-labelledby='room-instances-dialog-title'
        open={true}
      >
        <DialogTitle
          id='room-instances-dialog-title'
          className={classes.dialogTitle}
          disableTypography={true}
        >
          <Typography variant={'h3'}>{t('roomInstancesDialog.title')}</Typography>
        </DialogTitle>
        {instances && selectedInstance && (
          <>
            <DialogContent className={classes.dialogContent}>
              {t('roomInstancesDialog.text')}
            </DialogContent>
            <DialogContent className={classes.dialogDropdown}>
              <Dropdown
                itemsList={itemsList}
                selecteditem={selectedInstance}
                onOptionChange={(e) => setselectedInstance(e.target.value)}
              ></Dropdown>
            </DialogContent>
          </>
        )}
        {isError && (
          <DialogContent className={`${classes.dialogContent} ${classes.errorStatus}`}>
            {t('roomInstancesDialog.errorMessage')}
          </DialogContent>
        )}
        <DialogActions disableSpacing>
          <div className={classes.dialogActions}>
            <ShadowedButton
              id='room-instances-dialog-cancel'
              text={t('commonButtons.cancel')}
              buttonStyle={'dark'}
              disabled={cancelDisabled}
              hasDialogButtonShadow={true}
              wrapperClasses={classes.buttonWrapper}
              onClick={() => onCancel()}
            ></ShadowedButton>
            <ShadowedButton
              id='room-instances-dialog-confirm'
              text={t('commonButtons.proceed')}
              buttonStyle={'default'}
              disabled={isError}
              hasDialogButtonShadow={true}
              wrapperClasses={classes.buttonWrapper}
              onClick={() => onConfirm(selectedInstance)}
            ></ShadowedButton>
          </div>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default RoomInstancesDialog
