import React, { FC, useEffect, useState } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { useHistory } from 'react-router-dom'
import ForgotPasswordForm from '../../../Components/Authentication/ForgotPassword/ForgotPasswordForm'
import { AuthPageFooter } from '../../../Components/Authentication/AuthPageFooter/AuthPageFooter'
import { authService } from '../../../Services/services-index'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    item: {
      width: '100%',
    },
    root: {
      '& div[class*="MuiGrid-spacing"]': {
        margin: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
          height: '100vh',
        },
      },
      '& div[class*="MuiGrid-item"]': {
        padding: '0 24px',
      },
    },
  })
)

export const ForgotPasswordContainer: FC = () => {
  const classes = useStyles()
  const history = useHistory()

  const [emailId, setEmailIdValue] = useState<string>('')
  const [emailIdFieldError, setEmailIdFieldError] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')

  // On EmailId field  value change
  const onEmailIdValueChange = (value?: any | undefined) => {
    setEmailIdValue(value)
    if (value) {
      setEmailIdFieldError(false)
      setErrorMessage('')
    }
    if (!value) {
      setEmailIdFieldError(true)
      setErrorMessage('')
    }
  }

  useEffect(() => {}, [emailId, emailIdFieldError, errorMessage])

  // On Submit button click
  const onSubmit = (e?: any) => {
    e.preventDefault()
    onPasswordResetRequest()
  }

  const onPasswordResetRequest = () => {
    authService
      .resetPassword(emailId)
      .then((data: any) => {
        if (data.status === 200) {
          history.push('/auth/forgot-password-success')
          setEmailIdValue('')
          setErrorMessage('')
          setEmailIdFieldError(false)
        }
      })
      .catch((error: any) => {
        const errorCode = error?.response?.data?.error_code
        const errorText = error?.response?.data?.message
        setErrorMessage(errorText)
        setEmailIdFieldError(true)
        if (errorCode === 401.2) {
          setTimeout(() => {
            history.push('/auth/login')
          }, 4000)
        }
      })
  }

  return (
    <div className={classes.root}>
      <Grid container justifyContent='center' alignItems='center' direction='column' spacing={6}>
        <Grid item className={classes.item}>
          <ForgotPasswordForm
            emailId={emailId}
            errorMessage={errorMessage}
            emailIdFieldError={emailIdFieldError}
            onEmailIdValueChange={onEmailIdValueChange}
            onClick={onSubmit}
          ></ForgotPasswordForm>
        </Grid>
        <Grid item className={classes.item}>
          <AuthPageFooter showRegisterPageInfo={true}></AuthPageFooter>
        </Grid>
      </Grid>
    </div>
  )
}
export default ForgotPasswordContainer
