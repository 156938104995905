import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { makeStyles, Theme } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

interface Params {
  isPassportIsReadyToClaim?: boolean
  onClaimBadgeClick?: () => void
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.default,
  },
  claimButton: {
    borderRadius: '0',
    cursor: 'pointer',
    display: 'flex',
    height: '55px',
    justifyContent: 'center',
    margin: theme.spacing(8, 4, 2, 0),
    width: '100%',
  },
  pageTitle: {
    fontSize: '15px',
    fontFamily: 'Graphik',
    fontWeight: 'bold',
    lineHeight: '30px',
  },
  pageDescription: {
    fontSize: '15px',
    fontFamily: 'Graphik',
    lineHeight: '25px',
  },
}))

export const RewardPage: FC<Params> = ({ isPassportIsReadyToClaim, onClaimBadgeClick }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <div className={classes.container}>
      <Typography variant={'h6'} className={classes.pageTitle}>
        {t('passport.rewardPage.pageTitle')}
      </Typography>
      <Typography variant={'body1'} className={classes.pageDescription}>
        {t('passport.rewardPage.pageDescription')}
      </Typography>
      {isPassportIsReadyToClaim && (
        <Button
          id='passport-claim-badge'
          variant='contained'
          color='primary'
          className={classes.claimButton}
          onClick={onClaimBadgeClick}
        >
          {t('passport.rewardPage.rewardClaimButton')}
        </Button>
      )}
    </div>
  )
}

export default RewardPage
