import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { makeStyles, Theme } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

import { useAppDispatch, useAppSelector } from '../../Reducers/hooks'
import { MenuPages, switchMenuPage, toggleMenu, updateIsSideMenuOpen } from '../Menu/MenuSlice'
import {
  BusinessCardPages,
  switchBusinessCardPagePage,
  updateIsBusinessCardRequestSent,
  updateApprovedBusinessCard,
  updateNonApprovedBusinessCard,
} from './businessCardsSlice'
import { RequestBusinessCard } from '../../Components/BusinessCards/RequestBusinessCard'
import { ChatPages, switchChatPage } from '../Chat/ChatSlice'
import { businessCardService, MessageTypes, messagingService } from '../../Services/services-index'
import { MenuDrawer, MenuHeader } from '../../Common/common-index'

const useStyles = makeStyles((theme: Theme) => ({
  paperContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  container: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    padding: theme.spacing(0, 4),
  },
  errorStatus: {
    fontSize: '14px',
    color: '#b71c1c',
    display: 'flex',
    marginTop: theme.spacing(3),
  },
}))

export const RequestBusinessCardContainer: FC = ({}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const currentUser = useAppSelector((state) => state.auth.currentUser)
  const requestBusinessCardFromUserId = useAppSelector(
    (state) => state.businessCards.requestBusinessCardFromUserId
  )

  const nonApprovedBusinessCard = useAppSelector(
    (state) => state.businessCards.nonApprovedBusinessCard
  )

  const roomId = useAppSelector((state) => state.room.activeRoomId)
  const socket = useAppSelector((state) => state.socket.socket)

  const [nonApprovedView, setNonApprovedView] = useState<boolean>(true)
  const [errorMessage, setErrorMessage] = useState<string>('')

  useEffect(() => {
    dispatch(updateIsSideMenuOpen(true))
    if (currentUser && currentUser.id && requestBusinessCardFromUserId) {
      businessCardService
        .checkBusinessCardApproved(requestBusinessCardFromUserId, currentUser.id)
        .then((response: any) => {
          if (response?.data) {
            if (
              response.data?.approved === true &&
              response.data?.user_id === requestBusinessCardFromUserId
            ) {
              dispatch(updateApprovedBusinessCard(response.data))
              dispatch(switchBusinessCardPagePage(BusinessCardPages.BusinessCardDetailspage))
            }
            if (response.data?.approved === false || response.data?.approved === null) {
              setNonApprovedView(true)
              dispatch(updateNonApprovedBusinessCard(response.data))
            }
          }
        })
        .catch((error: any) => console.log(error))
    }
  }, [nonApprovedView])

  const onBusinessCardRequest = () => {
    dispatch(updateIsBusinessCardRequestSent(true))
    if (currentUser?.id && requestBusinessCardFromUserId) {
      businessCardService
        .sendBusinessCardRequest(requestBusinessCardFromUserId, currentUser.id)
        .then((res: any) => {
          if (res?.status === 200 && currentUser?.id) {
            setErrorMessage('')
            requestBusinessCard(requestBusinessCardFromUserId, currentUser.id)
          }
        })
        .catch((error: any) => {
          const errorText = error?.response?.data?.message
          setErrorMessage(errorText)
        })
    }
  }

  const requestBusinessCard = (receiverIds: any, userId: number) => {
    const cardValue = {
      type: 'text',
      id: '1',
      buttons: [],
    }
    const messagePayload: any = {
      from: userId,
      to: [receiverIds],
      room: roomId,
      timestamp: new Date().getTime().toString(),
      type: MessageTypes.text,
      cards: cardValue,
    }
    if (socket && messagePayload) {
      messagingService.emitMessagingSocket(
        `chat_${currentUser?.event_code}`,
        messagePayload,
        socket
      )
    }
  }

  // Header Actions
  const OnCloseClick = () => {
    dispatch(switchMenuPage(MenuPages.Menu))
    dispatch(toggleMenu(false))
    dispatch(updateIsSideMenuOpen(false))
    dispatch(updateIsBusinessCardRequestSent(false))
    dispatch(switchBusinessCardPagePage(BusinessCardPages.BusinessCardsListPage))
  }

  const OnGoBack = () => {
    dispatch(switchMenuPage(MenuPages.Chat))
    dispatch(switchChatPage(ChatPages.ChatMessage))
    dispatch(updateIsBusinessCardRequestSent(false))
  }

  return (
    <div className={classes.paperContainer}>
      <MenuDrawer
        isOpen={true}
        drawerHeader={
          <MenuHeader
            title={t('businessCardsPage.businessCard.title')}
            onGoBack={OnGoBack}
            onClose={OnCloseClick}
          />
        }
      >
        <div className={classes.container}>
          {nonApprovedView && (
            <RequestBusinessCard
              businessCard={nonApprovedBusinessCard}
              onBusinessCardRequest={onBusinessCardRequest}
            ></RequestBusinessCard>
          )}
          {errorMessage && (
            <Typography variant='body2' className={classes.errorStatus}>
              {errorMessage}
            </Typography>
          )}
        </div>
      </MenuDrawer>
    </div>
  )
}

export default RequestBusinessCardContainer
