import React, { FC, useEffect, useState } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

import { MenuDrawer, MenuHeader } from '../../Common/common-index'
import { useAppDispatch, useAppSelector } from '../../Reducers/hooks'
import { MenuPages, switchMenuPage, toggleMenu, updateIsSideMenuOpen } from '../Menu/MenuSlice'
import { Typography } from '@material-ui/core'
import PassportBadge from '../../Components/Passport/PassportBadge'
import {
  switchRecruitingDashboardPage,
  RecruitingDashboardPages,
  updateIsRecruitingMobileViewActive,
} from './RecruitingDashboardSlice'

const useStyles = makeStyles((theme: Theme) => ({
  paperContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  achievements: {
    display: 'flex',
    flexDirection: 'column',
    width: '400px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  achievementHeader: {
    padding: '0 32px 16px 32px',
    [theme.breakpoints.down('md')]: {
      padding: '32px',
    },
  },
}))

export const RecruitingDashboardBadgeListContainer: FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const selectedRecruitingUserData = useAppSelector(
    (state) => state.recruiting.selectedRecruitingUserData
  )
  const [userData] = useState<any>(selectedRecruitingUserData)

  useEffect(() => {}, [userData, selectedRecruitingUserData])

  const OnCloseClick = () => {
    dispatch(updateIsRecruitingMobileViewActive(false))

    dispatch(toggleMenu(false))
    dispatch(updateIsSideMenuOpen(false))
    dispatch(switchMenuPage(MenuPages.Menu))
    dispatch(switchRecruitingDashboardPage(RecruitingDashboardPages.RecruitingDashboardMainMenu))
  }

  const OnGoBack = () => {
    dispatch(switchRecruitingDashboardPage(RecruitingDashboardPages.RecruitingDashboardProfilePage))
  }

  return (
    <MenuDrawer
      isOpen={true}
      drawerHeader={
        <MenuHeader
          title={t('recruiting.candidatePage.achievements.candiAchievements')}
          hasDivider={true}
          onGoBack={OnGoBack}
          onClose={OnCloseClick}
        />
      }
    >
      <div className={classes.paperContainer}>
        <div className={classes.achievements}>
          <Typography className={classes.achievementHeader} variant={'subtitle1'}>
            {t('recruiting.candidatePage.achievements.title')}
            {''} {t('commonWords.of')} {userData?.firstname} {userData.lastname}
          </Typography>
          {userData &&
            userData?.badges &&
            userData.badges.map((badgeValue: any) => {
              return <PassportBadge passportBadge={badgeValue} key={badgeValue.id} />
            })}
          {!userData.badges?.length && (
            <Typography className={classes.achievementHeader} variant={'body2'}>
              {t('recruiting.candidatePage.achievements.noBadges')}
            </Typography>
          )}
        </div>
      </div>
    </MenuDrawer>
  )
}

export default RecruitingDashboardBadgeListContainer
