// Http services
import * as authService from './Services/auth.service'
import * as conversationsService from './Services/conversations.service'
import * as eventService from './Services/event.service'
import * as businessCardService from './Services/business-card.service'
import * as presentationService from './Services/presentation.service'
import * as contentService from './Services/content.service'
import * as userService from './Services/user.service'
import * as w3dsService from './Services/w3ds.service'

// Socket services
import * as messagingService from './Services/messaging.service'

// Storage services
import * as localStorageService from './Services/local-storage.service'

// Models
import { ProviderResponse, Provider } from './Models/auth.model'
import {
  User,
  UserStatus,
  UserRole,
  Query,
  Report,
  Role,
  ScreenAccessRight,
  Tag,
} from './Models/user.model'
import {
  MessageBasicPayload,
  MessageTypes,
  MessagePayload,
  MessageActions,
  BadgeEventMessage,
  SceneEventMessage,
  BadgeEventDetail,
  MessageCard,
  SceneEventDetail,
  SceneEventSettingsDetail,
} from './Models/message.model'
import { Conversation } from './Models/conversation.model'
import { FrontendTypes, MessagingTopics } from './Models/app.model'
import {
  Content,
  DisabledPopups,
  EmailAssets,
  FilesType,
  Media,
  MediaType,
  Room,
  RoomContent,
  RoomFileType,
  Shape,
} from './Models/content.model'
import {
  PreferenceType,
  Event,
  OpenHours,
  Bot,
  Preference,
  Badge,
  Email,
  Passport,
} from './Models/event.model'
import { Agenda, AgendaType } from './Models/presentation.model'
import { BusinessCard } from './Models/business-card.model'

// Utils
import axiosInterceptor from './Utils/axiosInterceptors'

import * as dotenv from 'dotenv'
import path from 'path'

dotenv.config({ path: path.join(__dirname, '/.env') })

// Services
export {
  authService,
  localStorageService,
  messagingService,
  conversationsService,
  eventService,
  w3dsService,
  businessCardService,
  presentationService,
  contentService,
  userService,
}

// Models / interfaces
export type {
  // Business card model
  BusinessCard,
  // Content model
  Room,
  Content,
  RoomContent,
  DisabledPopups,
  Media,
  EmailAssets,
  // Conversation model
  Conversation,
  // Event model
  Preference,
  Event,
  OpenHours,
  Bot,
  Email,
  Passport,
  Badge,
  // Message model
  MessageCard,
  MessageBasicPayload,
  MessagePayload,
  SceneEventMessage,
  SceneEventDetail,
  SceneEventSettingsDetail,
  BadgeEventMessage,
  BadgeEventDetail,
  // Presentation model
  Agenda,
  // User model
  Role,
  Tag,
  Query,
  Report,
  ScreenAccessRight,
  User,
  //Auth model
  ProviderResponse,
  Provider,
}

// Models / enums
export {
  // App model
  FrontendTypes,
  MessagingTopics,
  // Content model
  MediaType,
  Shape,
  RoomFileType,
  FilesType,
  // Event model
  PreferenceType,
  // Message model
  MessageTypes,
  MessageActions,
  // Presentation model
  AgendaType,
  // User model
  UserStatus,
  UserRole,
}

// Utils
export { axiosInterceptor }
