import { createTheme } from '@material-ui/core/styles'
import './common-index.css'

const theme = createTheme({
  transitions: {
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      // most basic recommended timing
      standard: 300,
      // this is to be used in complex animations
      complex: 375,
      // recommended when something is entering screen
      enteringScreen: 225,
      // recommended when something is leaving screen
      leavingScreen: 195,
    },
    easing: {
      // This is the most common easing curve.
      easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
      // Objects enter the screen at full velocity from off-screen and
      // slowly decelerate to a resting point.
      easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
      // Objects leave the screen at full velocity. They do not decelerate when off-screen.
      easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
      // The sharp curve is used by objects that may return to the screen at any time.
      sharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
    },
  },
  typography: {
    fontFamily: 'Graphik-Regular',
    h1: {
      fontSize: '60px',
      fontFamily: 'Graphik-Black',
      lineHeight: 1,
    },
    h2: {
      fontSize: '40px',
      fontFamily: 'Graphik-Bold',
      lineHeight: 1,
    },
    h3: {
      fontSize: '32px',
      fontFamily: 'Graphik-Bold',
      lineHeight: 1,
    },
    h4: {
      fontSize: '26px',
      fontFamily: 'Graphik-Bold',
      lineHeight: 1,
    },
    h5: {
      fontFamily: 'Graphik-Semibold',
      fontSize: '18px',
      lineHeight: 1.4,
      fontWeight: 600,
    },
    h6: {
      fontFamily: 'Graphik-Semibold',
      fontSize: '16px',
      lineHeight: 1.4,
    },
    subtitle1: {
      fontSize: '14px',
      fontFamily: 'Graphik-Bold',
      lineHeight: 1,
    },
    subtitle2: {
      fontSize: '18px',
      fontFamily: 'Graphik-Semibold',
      lineHeight: 1,
    },
    body1: {
      fontSize: '18px',
      fontWeight: 'normal',
      lineHeight: 1.4,
      fontFamily: 'Graphik-Regular',
    },
    body2: {
      fontSize: '14px',
      fontWeight: 'normal',
      lineHeight: 1.75,
      fontFamily: 'Graphik-Regular',
    },
    caption: {
      fontSize: '14px',
      lineHeight: 1.75,
      fontFamily: 'Graphik-Regular',
    },
    button: {
      fontSize: '14px',
      fontWeight: 'normal',
      lineHeight: 2.45,
      fontFamily: 'Graphik-Regular',
      color: '#904488',
      textTransform: 'none',
    },
  },
  palette: {
    primary: {
      main: '#460073',
      light: '#904488',
    },
    secondary: {
      main: '#00baff',
    },
    background: {
      default: '#ffffff',
      paper: '#f2f2f2',
    },
    grey: {
      100: '#bebebe',
      200: '#969696',
      300: '#707070',
      400: '#5a5a5a',
      500: '#00000020',
    },
    error: {
      main: '#D32F2F',
    },
    warning: {
      main: '#FFC400',
    },
    info: {
      main: '#004dff',
    },
    success: {
      main: '#54E342',
    },
  },
  shape: {
    borderRadius: 18,
  },
})

export default theme
