import React, { FC, useEffect, useState } from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../Reducers/hooks'
import { updateCurrentUser } from '../../../Reducers/authSlice'
import { fetchPreferences } from '../../../Reducers/preferencesSlice'
import { axiosInterceptor, localStorageService, User } from '../../../Services/services-index'

const ProtectedRouter: FC<RouteProps> = ({ children, ...rest }) => {
  const currentUser = useAppSelector((state) => state.auth.currentUser)
  const dispatch = useAppDispatch()
  const [hasLoggedIn, setHasLoggedIn] = useState<boolean | null>(null)
  const preferences = useAppSelector((state) => state.preferences.preferences)
  let currentUserParsed: User

  useEffect(() => {
    if (currentUser) {
      if (hasLoggedIn && !preferences) {
        dispatch(fetchPreferences({ type: 'specific' }))
        return
      } else {
        axiosInterceptor(currentUser)
        setHasLoggedIn(true)
      }
    } else {
      const user: any = localStorageService.getLocalStorageItemValue('currentUser')
      if (user) {
        currentUserParsed = JSON.parse(user)
        axiosInterceptor(currentUserParsed)
        setHasLoggedIn(true)
      } else {
        setHasLoggedIn(false)
      }
      dispatch(updateCurrentUser(currentUserParsed))
    }
  }, [currentUser, hasLoggedIn])

  if (hasLoggedIn === null) {
    return null
  }

  return (
    <Route
      {...rest}
      render={({ location }: { location: any }) =>
        hasLoggedIn ? (
          location.pathname === '/' ? (
            <Redirect to={{ pathname: '/lobby', state: { from: location } }} />
          ) : (
            children
          )
        ) : (
          <Redirect
            to={{
              pathname: '/auth/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  )
}

export default ProtectedRouter
