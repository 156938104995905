import React from 'react'
import { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import Slide from '@material-ui/core/Slide'
import { Typography } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import { useAppSelector } from '../../Reducers/hooks'
import UserAvatar from '../../Containers/UserAvatar/UserAvatar'
import { BusinessCard } from '../../Containers/BusinessCards/businessCardsSlice'
import { Icon } from '../../Common/common-index'

interface Params {
  businessCard?: BusinessCard
  errorMessage?: string
  onBusinessCardRequest: () => void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    businessCardDetail: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: '100%',
      outline: 'none',
    },
    shortInfo: {
      margin: '25px 0 50px 0',
      display: 'inline-flex',
      alignItems: 'center',
      outline: 'none',

      '& h2': {
        fontSize: '18px',
        lineHeight: '18px',
        marginBottom: '10px',
      },
    },
    userInfo: {
      marginLeft: '25px',
    },
    gray: { color: '#969696' },
    smallMarginTop: {
      marginTop: '5px',
    },
    buttonItem: {
      cursor: 'pointer',
      marginLeft: '10px',
    },
    requestCard: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      textAlign: 'center',
      height: '100%',
    },
    requestInfo: {
      fontSize: '16px',
    },
    requestButton: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'row',
      marginTop: '30px',
    },
    errorStatus: {
      fontSize: '14px',
      color: '#b71c1c',
      display: 'flex',
      marginTop: theme.spacing(3),
    },
  })
)

export const RequestBusinessCard: FC<Params> = ({
  businessCard,
  errorMessage,
  onBusinessCardRequest,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const isBusinessCardRequestSent = useAppSelector(
    (state) => state.businessCards.isBusinessCardRequestSent
  )

  useEffect(() => {}, [businessCard, isBusinessCardRequestSent])

  return (
    <>
      {businessCard && (
        <Slide direction='left' in={true} mountOnEnter unmountOnExit>
          <div className={classes.businessCardDetail}>
            <div className={classes.shortInfo}>
              <UserAvatar userId={businessCard.user_id} isSquare={true} height={100} width={100} />
              <div className={classes.userInfo}>
                <Typography variant={'subtitle2'}>
                  {businessCard.firstname} {businessCard.lastname}
                </Typography>
                <Typography variant={'body2'} className={classes.smallMarginTop}>
                  {businessCard.job_title}
                </Typography>
                <Typography variant={'body2'} className={classes.gray}>
                  {businessCard.company}
                </Typography>
              </div>
              <Typography variant={'body2'}>{businessCard.personal_quote}</Typography>
            </div>
            <div className={classes.requestCard}>
              <Typography variant={'body2'} className={classes.requestInfo}>
                {t('businessCardsPage.requestCard.info')}
              </Typography>
              <div className={classes.requestButton}>
                <Icon iconName={'businessCards'} iconSize={'25px'} />
                <Typography
                  id='business-card-request'
                  className={classes.buttonItem}
                  variant={'button'}
                  color={'primary'}
                  aria-controls='edit-notes'
                  onClick={onBusinessCardRequest}
                >
                  {isBusinessCardRequestSent
                    ? t('businessCardsPage.requestCard.requestSent')
                    : t('businessCardsPage.requestCard.requestBusinessCard')}
                </Typography>
              </div>
            </div>
            {errorMessage && (
              <Typography variant='body2' className={classes.errorStatus}>
                {errorMessage}
              </Typography>
            )}
          </div>
        </Slide>
      )}
    </>
  )
}
