import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import TextField from '@material-ui/core/TextField'

export interface InputProps {
  titleInput?: string
  testAreaInput?: string
  isEventNotificationPageOpen?: boolean
  characterCount?: number
  onTitleChange?: () => void
  onTextAreaChange?: () => void
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '& div[class*="MuiFormControl-root"]': {
      '& input': {
        fontSize: '14px',
        fontFamily: 'Graphik-Regular',
      },
      '& label': {
        fontSize: '14px',
        fontFamily: 'Graphik-Regular',
      },
      width: '100%',
    },
  },
  pageTitle: {
    fontSize: '15px',
    fontFamily: 'Graphik',
    fontWeight: 'bold',
    lineHeight: '30px',
  },
  pageDescription: {
    fontSize: '15px',
    fontFamily: 'Graphik',
    lineHeight: '25px',
  },
  inputWrapper: {
    marginTop: '58px',
  },
  input: {
    ...theme.typography.caption,
    flex: 1,
    width: '100%',
    '& .MuiInputLabel-formControl': {
      fontFamily: 'Graphik',
      fontSize: '14px',
    },
  },
  textarea: {
    ...theme.typography.caption,
    fontFamily: 'Graphik',
    minWidth: '230px',
    minHeight: '50px',
    maxHeight: '400px',
    marginTop: '40px',
    resize: 'vertical',
    width: 'calc(100% - 32px)',
    padding: theme.spacing(2),
  },
  maxLimitInfo: {
    display: 'flex',
    flexDirection: 'row-reverse',
    fontFamily: 'Graphik',
    lineHeight: '16px',
    marginTop: '12px',
    opacity: '0.5',
  },
  warning: {
    color: 'red',
    opacity: '1',
  },
}))

export const EventNotification: FC<InputProps> = ({
  titleInput,
  testAreaInput,
  isEventNotificationPageOpen,
  characterCount,
  onTitleChange,
  onTextAreaChange,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <div className={classes.container}>
      {isEventNotificationPageOpen && (
        <>
          <Typography variant={'h6'} className={classes.pageTitle}>
            {t('eventNotification.InputForm.title')}
          </Typography>
          <Typography variant={'body1'} className={classes.pageDescription}>
            {t('eventNotification.InputForm.description')}
          </Typography>

          <div className={classes.inputWrapper}>
            <TextField
              id='event-notificatiion-title'
              label={t('eventNotification.InputForm.titlePlaceholder')}
              variant='standard'
              className={classes.input}
              autoComplete={'off'}
              inputProps={{
                'aria-label': `${t('eventNotification.InputForm.titlePlaceholder')}`,
                classes: {
                  input: classes.input,
                },
                maxLength: 2000,
              }}
              value={titleInput}
              onChange={onTitleChange}
            />
            <TextareaAutosize
              id='event-notificatiion-body'
              className={classes.textarea}
              maxLength={500}
              minRows={4}
              placeholder={t('eventNotification.InputForm.textAreaPlaceholder')}
              value={testAreaInput}
              onChange={onTextAreaChange}
            />
            <Typography
              variant={'caption'}
              className={
                characterCount === 500
                  ? `${classes.maxLimitInfo} ${classes.warning}`
                  : classes.maxLimitInfo
              }
            >
              {characterCount}/{t('eventNotification.InputForm.maxLimitInfo')}
            </Typography>
            <Typography variant={'caption'} className={classes.maxLimitInfo}>
              {t('eventNotification.InputForm.linkInfo')}
            </Typography>
          </div>
        </>
      )}
    </div>
  )
}

export default EventNotification
