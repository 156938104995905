import React, { FC, ReactElement, useEffect, useState } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { useAppDispatch, useAppSelector } from '../../Reducers/hooks'
import { ChatToolbar, Icon } from '../../Common/common-index'
import { setCallStarted } from '../CallContainer/CallSlice'
import { CallType } from '../../Services/Models/CallModel'
import { updateCurrentUserButtonState } from '../ToolButtonsContainer/ToolButtonSlice'

export interface ChatToolbarContainerProps {
  participantsCount?: number | undefined
  toolbarType?: string | undefined
  onParticipantsClick?: () => void
  onAddUserClick?: () => void
  onBusinessCardClick: () => void
  onNotificationClick?: () => void
  onReportUserClick?: () => void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    participants: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
    },
    userCount: {
      fontSize: '16px',
      color: theme.palette.primary.main,
      marginRight: '8px',
    },
    noAction: {
      opacity: '0.35',
      cursor: 'default',
      pointerEvents: 'none',
    },
    businessCard: {
      marginTop: '10px',
    },
  })
)

const ChatToolbarContainer: FC<ChatToolbarContainerProps> = ({
  toolbarType,
  onParticipantsClick,
  onAddUserClick,
  onBusinessCardClick,
  onNotificationClick,
  onReportUserClick,
}) => {
  const classes = useStyles()
  const dispatch = useAppDispatch()

  const selectedPreferences = useAppSelector((state) => state.preferences.selectedPreferences)
  const callsAllowed = selectedPreferences?.calls?.value === 'yes' ? true : false
  const preferences = useAppSelector((state) => state.preferences.selectedPreferences)
  const groupConversation = useAppSelector((state) => state.chat.groupConversation)

  const [toolbarItems, setToolbarItems] = useState<ReactElement[]>([])

  function handleCall(type: CallType) {
    dispatch(
      updateCurrentUserButtonState({
        muted: true,
        videoEnabled: false,
      })
    )
    dispatch(setCallStarted(type))
  }

  useEffect(() => {
    const usedIcons: string[] = []
    toolbarItems.forEach((icon) => usedIcons.push(icon.props.iconName))
    if (!toolbarItems[0] && toolbarType) {
      if (groupConversation?.isGroup) {
        setToolbarItems([...toolbarItems, getAddUserIcon(), getParticipantsIcon()])
      } else if (!groupConversation?.isGroup) {
        if (
          preferences &&
          preferences['private_chat'] &&
          preferences['private_chat'].value === 'no'
        ) {
          setToolbarItems([...toolbarItems, getBusinessCardIcon()])
        } else {
          setToolbarItems([...toolbarItems, getAddUserIcon(), getBusinessCardIcon()])
        }
      } else if (toolbarType === 'roomChat') {
        setToolbarItems([...toolbarItems, getParticipantsIcon()])
      }
      if (toolbarType === 'recruiting') {
        setToolbarItems([getPhoneIcon(), getVideoCallIcon(), getNotificationIcon()])
      }
      return
    }
    if (callsAllowed && !usedIcons.includes('phone') && !usedIcons.includes('camera')) {
      setToolbarItems([...toolbarItems, getPhoneIcon(), getVideoCallIcon()])
      return
    }
    if (
      !usedIcons.includes('reportUser') &&
      !groupConversation?.isGroup &&
      toolbarType !== 'roomChat'
    ) {
      setToolbarItems([...toolbarItems, getReportUserIcon()])
    }
  }, [callsAllowed, toolbarItems])

  // To display "Audio call" Icon
  const getPhoneIcon = () => {
    return (
      <Icon
        id='chat-toolbar-audio-call'
        iconName={'phone'}
        title={'Audio Call'}
        svgIconProps={{
          color: 'primary',
        }}
        iconButtonProps={{
          size: 'medium',
          disableRipple: true,
        }}
        onClick={() => {
          handleCall('audio')
        }}
        disableBackground
      />
    )
  }

  // To display "Video call" Icon
  const getVideoCallIcon = () => {
    return (
      <Icon
        id='chat-toolbar-video-call'
        iconName={'camera'}
        title={'Video Call'}
        svgIconProps={{
          color: 'primary',
        }}
        iconButtonProps={{
          size: 'medium',
          disableRipple: true,
        }}
        onClick={() => {
          handleCall('video')
        }}
        disableBackground
      />
    )
  }

  // To Display "AddPerson" Icon
  const getAddUserIcon = () => {
    return (
      <>
        <Icon
          id='chat-toolbar-add-user'
          iconName={'addPerson'}
          title={'Add Persons'}
          svgIconProps={{
            color: 'primary',
          }}
          iconButtonProps={{ size: 'medium', disableRipple: true }}
          onClick={onAddUserClick}
          disableBackground
        />
      </>
    )
  }

  // To Display "Request BusinessCard" Icon
  const getBusinessCardIcon = () => {
    return (
      <>
        <div className={classes.businessCard}>
          <Icon
            id='chat-toolbar-request-business-card'
            iconName={'requestBusinessCard'}
            title={'Business Card'}
            svgIconProps={{
              color: 'primary',
            }}
            iconSize={'24px'}
            iconButtonProps={{ disableRipple: true }}
            onClick={onBusinessCardClick}
            disableBackground
          />
        </div>
      </>
    )
  }

  // To Display "Report User" Icon
  const getReportUserIcon = () => {
    return (
      <Icon
        id='chat-toolbar-report-user'
        iconName={'reportUser'}
        title={'Report User'}
        svgIconProps={{
          color: 'primary',
        }}
        iconButtonProps={{ disableRipple: true }}
        onClick={onReportUserClick}
        disableBackground
      />
    )
  }

  // To Display "Participants list" Icon
  const getParticipantsIcon = () => {
    return (
      <>
        <div
          id='chat-toolbar-participants'
          className={classes.participants}
          onClick={onParticipantsClick}
        >
          <span className={classes.userCount}>{groupConversation?.count}</span>
          <Icon
            iconName={'group'}
            title={'Show Participants'}
            svgIconProps={{
              color: 'primary',
            }}
            iconButtonProps={{ size: 'medium', disableRipple: true }}
            disableBackground
          />
        </div>
      </>
    )
  }

  // To Display "NotificationIcon" Icon
  const getNotificationIcon = () => {
    return (
      <>
        <div className={classes.businessCard}>
          <Icon
            id='chat-toolbar-notification'
            iconName={'notificationAlert'}
            title={'Notification'}
            svgIconProps={{
              color: 'primary',
            }}
            iconSize={'24px'}
            iconButtonProps={{ disableRipple: true }}
            onClick={onNotificationClick}
            disableBackground
          />
        </div>
      </>
    )
  }

  return <ChatToolbar items={toolbarItems} gridSize={toolbarType !== 'roomChat'} />
}

export default ChatToolbarContainer
