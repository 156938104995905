import * as CryptoJS from 'crypto-js'

// To store data in localStorage
export const setLocalStorageItemValue = (key: string, value: any) => {
  const encryptedValue = encrypt(key, value)
  localStorage.setItem(key, encryptedValue)
}

// Get the data from localStorage
export const getLocalStorageItemValue = (key: string) => {
  const localStoredItem = localStorage.getItem(key)
  return decrypt(key, localStoredItem)
}

// Clear the localStorage
export const clearLocalStorageItemValue = (key: string) => {
  return localStorage.removeItem(key)
}

// Encrypt the localstorage data
export const encrypt = (key: string, value: any) => {
  if (key && value) {
    const encrypted = CryptoJS.AES.encrypt(value, key)
    return encrypted.toString()
  }

  return ''
}

// Decrypt the encrypted data
export const decrypt = (key: string, value: any) => {
  if (key && value) {
    const decrypted = CryptoJS.AES.decrypt(value, key)
    return decrypted.toString(CryptoJS.enc.Utf8)
  }

  return ''
}
