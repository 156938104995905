import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles, Theme } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import RadioGroup from '@material-ui/core/RadioGroup'
import { useAppDispatch } from '../../Reducers/hooks'
import { MenuPages, switchMenuPage, toggleMenu, updateIsSideMenuOpen } from '../Menu/MenuSlice'
import { setLanguage, SettingsPages, switchSettingsPage } from './SettingsSlice'
import { MenuDrawer, MenuHeader, BaseRadio, ShadowedButton } from '../../Common/common-index'
import { Slide } from '@material-ui/core'
import { createHtmlIdString } from '../../Utils/utils'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: '0 32px',
  },
  settingsRow: {
    verticalAlign: 'middle',
    height: '80px',
    display: 'flex',
    alignItems: 'center',
  },
  buttonContainer: {
    marginTop: '20px',
  },
  rowSpacing: {
    margin: '40px 0',
  },
  radioSpacing: {
    marginBottom: '20px',
  },
}))

export const LanguageSettingsContainer: FC = () => {
  const classes = useStyles()
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()

  const [menuOpen, setMenuOpen] = useState(true)

  const currentLanguage = i18n.language
  dispatch(setLanguage(currentLanguage))
  let selectedLanguage = currentLanguage

  const languages = [
    { name: 'English', code: 'en' },
    { name: 'Deutsch', code: 'de' },
  ]

  useEffect(() => {
    dispatch(updateIsSideMenuOpen(true))
  }, [])

  const OnCloseClick = () => {
    setMenuOpen(false)
    dispatch(toggleMenu(false))
    dispatch(updateIsSideMenuOpen(false))
    dispatch(switchMenuPage(MenuPages.Menu))
  }

  const OnGoBack = () => {
    dispatch(switchSettingsPage(SettingsPages.SettingsList))
  }

  const onLanguageChange = (e: ChangeEvent<HTMLInputElement>) => {
    selectedLanguage = e.target.value
  }

  const onSave = () => {
    i18n.changeLanguage(selectedLanguage)
    dispatch(setLanguage(selectedLanguage))
  }

  return (
    <div>
      <MenuDrawer
        isOpen={menuOpen}
        drawerHeader={
          <MenuHeader
            title={t('settings.changeLanguage')}
            onGoBack={OnGoBack}
            onClose={OnCloseClick}
          />
        }
      >
        <Slide direction='left' in={true} mountOnEnter unmountOnExit>
          <div className={classes.container}>
            <div className={classes.rowSpacing}>{t('settings.changeLanguagePrompt')}</div>
            <FormControl>
              <RadioGroup
                id='language-settings-radio-group'
                aria-label='language'
                defaultValue={currentLanguage}
                name='radio-buttons-group'
                onChange={onLanguageChange}
              >
                {languages.map((lang) => (
                  <span className={classes.radioSpacing} key={lang.name}>
                    <BaseRadio
                      id={createHtmlIdString('language-settings-radio-', lang.code)}
                      value={lang.code}
                      label={lang.name}
                    />
                  </span>
                ))}
              </RadioGroup>
            </FormControl>
            <div className={classes.buttonContainer}>
              <ShadowedButton
                id='language-settings-save'
                text={t('commonButtons.save')}
                onClick={onSave}
              ></ShadowedButton>
            </div>
          </div>
        </Slide>
      </MenuDrawer>
    </div>
  )
}
