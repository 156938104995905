import React, { FC, useState } from 'react'
import TextField, { TextFieldProps } from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import InputAdornment from '@material-ui/core/InputAdornment'
import Grid from '@material-ui/core/Grid'
import { Icon } from '../../common-index'

type Props = Omit<TextFieldProps, 'onChange'> & {
  label?: string
  title?: string
  placeholder?: string
  value?: string
  autoComplete?: string
  isPasswordField?: boolean
  error?: boolean
  errorMessage?: string
  id: string
  // eslint-disable-next-line no-unused-vars
  onChange: (value: string) => void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& div[class*="MuiFormControl-root"]': {
        '& input': {
          fontSize: '14px',
          fontFamily: 'Graphik-Regular',
        },
        '& label': {
          fontSize: '14px',
          fontFamily: 'Graphik-Regular',
        },
      },
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    label: {
      textAlign: 'left',
      fontSize: '16px',
      fontFamily: 'Graphik-Regular',
      [theme.breakpoints.up('sm')]: {
        width: '100%',
        padding: theme.spacing(0),
      },
    },
    input: {
      ...theme.typography.caption,
      flex: 1,
      height: '36px',
      width: '100%',
    },
    visibilityIcon: {
      position: 'absolute',
      right: '-2%',
      top: '50%',
      transform: 'translateY(-50%)',
      '& svg': {
        color: '#212121',
        height: '15px !important',
        width: '15px !important',
      },
    },
    errorStatus: {
      fontSize: '12px',
      color: '#b71c1c',
      display: 'flex',
      flexDirection: 'row-reverse',
      marginBottom: '-21px',
      padding: theme.spacing(0),
      [theme.breakpoints.up('sm')]: {
        marginLeft: 0,
        padding: 0,
      },
    },
    errorLable: {
      color: theme.palette.error.main,
    },
  })
)

export const FormInputField: FC<Props> = ({
  label,
  title,
  placeholder,
  value,
  autoComplete,
  isPasswordField,
  error,
  errorMessage,
  id,
  onChange,
}) => {
  const classes = useStyles()
  const [isPasswordVisible, setPassworVisible] = useState(false)

  const validateType = () => {
    if (isPasswordField) {
      return isPasswordVisible ? 'text' : 'password'
    }
    return 'text'
  }

  return (
    <div className={classes.root}>
      <Grid container direction='column' justifyContent='center'>
        <Grid item xs={12}>
          <Typography
            className={error ? `${classes.label} ${classes.errorLable}` : classes.label}
            variant='body1'
          >
            {title}
          </Typography>
          <TextField
            label={label}
            placeholder={placeholder}
            variant='standard'
            inputProps={{
              'aria-label': `${placeholder}`,
              maxLength: 2000,
              classes: {
                input: classes.input,
              },
            }}
            value={value}
            id={id}
            onChange={(e) => onChange(e.target.value)}
            autoComplete={autoComplete}
            error={error}
            type={validateType()}
            InputProps={{
              endAdornment: isPasswordField && (
                <InputAdornment position='end'>
                  <Icon
                    title={isPasswordVisible ? 'Hide password' : 'Show password'}
                    iconName={isPasswordVisible ? 'visibilityOff' : 'visiblePassword'}
                    onClick={() => setPassworVisible((visible) => !visible)}
                    iconButtonProps={{
                      'aria-label': 'password visibility',
                      classes: {
                        root: classes.visibilityIcon,
                      },
                    }}
                    svgIconProps={{
                      color: 'primary',
                    }}
                  />
                </InputAdornment>
              ),
            }}
          />
          {error && (
            <Typography variant='body2' className={classes.errorStatus}>
              {errorMessage}
            </Typography>
          )}
        </Grid>
      </Grid>
    </div>
  )
}

export default FormInputField
