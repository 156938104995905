/* eslint-disable no-unused-vars */

export interface Role {
  role: string
  room?: string
  mode?: string
  room_id?: number
}

export interface Tag {
  tag_name: string
  type: string
}

export interface Query {
  limit?: number
  page?: number
  event_code?: string
  username?: string
  email?: string
  firstname?: string
  lastname?: string
  company?: string
  job_title?: string
}

export interface Report {
  reported_by_user_id: number
  summary?: string
}

export interface ScreenAccessRight {
  id: number
  name: string
  frontend_name: string
  role_id: number
  type: string
  default: boolean
}

export enum UserStatus {
  AVAILABLE = 'available',
  BUSY = 'busy',
  OFFLINE = 'offline',
}

export interface User {
  email?: string
  firstname: string
  lastname: string
  password?: string
  profile_picture?: string
  profile_picture_id?: number
  event_code: string
  job_title?: string
  company?: string
  phone_number?: string
  linkedin?: string
  webpage?: string
  personal_quote?: string
  tags?: Tag[]
  username?: string
  event_name?: string
  active: boolean
  jwt_token?: string
  id?: number
  roles?: Role[]
  room_access?: number[]
  screen_access_rights?: ScreenAccessRight[]
  last_login?: string
  frontend?: string
  session_expires?: string
  user_status?: UserStatus
  bot_id?: number
  breakout_group?: string
}

export enum UserRole {
  Visitor = 'visitor',
  Exhibitor = 'exhibitor',
  EventAdmin = 'event-admin',
  Admin = 'admin',
}
