/* eslint-disable no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export enum ProfileSettingPages {
  ProfileSettingsHome,
  ChangeStatusPage,
  ProfileInformationPage,
  SkillsPage,
  InterestsPage,
  ChangePasswordpage,
  ExportPrivateChatsPage,
  PasswordChangeSuccessPage,
  AppearanceSettingsPage,
  AccountDeleteSuccessPage,
}
export interface UserProfile {
  id: number
  firstname: string
  lastname: string
  email: string
  profile_picture_id: number
  job_title: string
  company: string
  phone_number: string
  linkedin: string
  webpage: string
  personal_quote: string
  tags: [
    {
      tag_name: string
      type: string
    }
  ]
  profile_picture: string
}

export interface ProfileSettingsState {
  userProfile: UserProfile | undefined
  activeProfilePage: ProfileSettingPages
}

const initialState: ProfileSettingsState = {
  userProfile: undefined,
  activeProfilePage: ProfileSettingPages.ProfileSettingsHome,
}

export const profileSettingsSlice = createSlice({
  name: 'profileSettings',
  initialState,
  reducers: {
    updateSettings: (state, action: PayloadAction<UserProfile>) => {
      state.userProfile = action.payload
    },
    switchProfilePage: (state, action: PayloadAction<ProfileSettingPages>) => {
      state.activeProfilePage = action.payload
    },
  },
})

export const { updateSettings, switchProfilePage } = profileSettingsSlice.actions

export default profileSettingsSlice.reducer
