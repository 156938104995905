import React, { FC, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../Reducers/hooks'
import { updateMapImg, updateMiniMap } from './MiniMapSlice'
import { toggleMiniMap, updateShowMiniMapControls } from '../SettingsContainer/SettingsSlice'
import { contentService, FilesType, localStorageService } from '../../Services/services-index'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Icon } from '../../Common/common-index'
import { MiniMapDialog } from '../../Components/MiniMap/MiniMapDialog'

const styles = makeStyles(() =>
  createStyles({
    button: {
      position: 'absolute',
      height: '56px',
      width: '56px',
      '& svg': {
        height: '56px !important',
        width: '56px !important',
      },
    },
    miniMapOn: {
      right: '0px',
      bottom: '0px',
    },
    miniMapOff: {
      top: '-65px',
      right: '-7px',
    },
    iconDefault: {
      position: 'absolute',
      top: '7.45px',
      '& svg': {
        height: '41.55px !important',
      },
    },
    iconToggle: {
      cursor: 'pointer',
      '& div:nth-of-type(1)': { display: 'none' },
      '&:hover div:nth-of-type(2)': { display: 'none' },
      '&:hover div:nth-of-type(1)': { display: 'block' },
    },
    errorStatus: {
      alignItems: 'center',
      color: '#b71c1c',
      display: 'flex',
      fontSize: '14px',
      height: '100%',
      justifyContent: 'center',
    },
  })
)

export const MiniMapContainer: FC = () => {
  const classes = styles()
  const dispatch = useAppDispatch()

  const [errorMessage, setErrorMessage] = useState<string>('')
  const [enableMiniMapState, setEnableMiniMapState] = useState<string>('')
  const [isMobile, setIsMobile] = useState(false)
  const [isMinimapCalled, setMinimapCalled] = useState(false)
  const [isMinimapFileCalled, setMinimapFileCalled] = useState(false)

  const currentUser = useAppSelector((state) => state.auth.currentUser)
  const miniMap = useAppSelector((state) => state.settings.miniMap)
  const miniMapResponse = useAppSelector((state) => state.miniMap.miniMapResponse)
  const mapImg = useAppSelector((state) => state.miniMap.mapImg)
  const showMiniMapControls = useAppSelector((state) => state.settings.showMiniMapControls)

  useEffect(() => {
    const eventCode = currentUser?.event_code
    if (!isMinimapCalled && !miniMapResponse && !errorMessage && eventCode) {
      setMinimapCalled(true)
      contentService
        .getMiniMap(eventCode)
        .then((res: any) => {
          if (res.status == 200) {
            dispatch(updateMiniMap(res?.data))
            setErrorMessage('')
          } else {
            setErrorMessage('res code: ' + res)
          }
        })
        .catch((error: any) => {
          const errorText = error?.response?.data?.message
          setErrorMessage(errorText)
        })
    }
    if (!isMinimapFileCalled && !mapImg && !errorMessage && eventCode) {
      setMinimapFileCalled(true)
      contentService
        .getMiniMapFile(FilesType.MINI_MAP, eventCode)
        .then((res: any) => {
          const userManualBlob = new Blob([res?.data])
          const url = URL.createObjectURL(userManualBlob)
          dispatch(updateMapImg(url))
          setErrorMessage('')
        })
        .catch((error: any) => {
          const errorText = error?.response?.data?.message
          setErrorMessage(errorText)
        })
    }
  }, [miniMapResponse, mapImg, errorMessage])

  useEffect(() => {
    window.addEventListener('resize', handleResize)
  }, [isMobile, showMiniMapControls])

  //Handle the screen size
  const handleResize = () => {
    if (window.innerWidth < 960) {
      setIsMobile(true)
      dispatch(updateShowMiniMapControls(false))
    } else {
      setIsMobile(false)
      dispatch(updateShowMiniMapControls(true))
    }
  }

  useEffect(() => {
    const miniMapState = localStorageService.getLocalStorageItemValue('enableMiniMap')
    if (miniMapState) {
      setEnableMiniMapState(miniMapState)
    }
  }, [enableMiniMapState, miniMap])

  return (
    <>
      {!isMobile && (
        <>
          <MiniMapDialog
            buttonOn={
              <div
                className={`${classes.button} ${classes.miniMapOn} ${classes.iconToggle}`}
                color='inherit'
                onClick={() => dispatch(toggleMiniMap())}
              >
                <div>
                  <Icon
                    id={'mini-map-on'}
                    iconName={'miniMapOnHover'}
                    title={'mini map on'}
                    iconSize='56px'
                  />
                </div>
                <div className={classes.iconDefault}>
                  <Icon
                    id={'mini-map-on'}
                    iconName={'miniMapOn'}
                    title={'mini map on'}
                    iconSize='56px'
                  />
                </div>
              </div>
            }
            buttonOff={
              <div
                className={`${classes.button} ${classes.miniMapOff} ${classes.iconToggle}`}
                color='inherit'
                onClick={() => dispatch(toggleMiniMap())}
              >
                <div>
                  <Icon
                    id={'mini-map-off'}
                    iconName={'miniMapOffHover'}
                    title={'mini map off'}
                    iconSize='56px'
                  />
                </div>
                <div className={classes.iconDefault}>
                  <Icon
                    id={'mini-map-off'}
                    iconName={'miniMapOff'}
                    title={'mini map off'}
                    iconSize='56px'
                  />
                </div>
              </div>
            }
            errorMessage={errorMessage}
          />
        </>
      )}
    </>
  )
}

export default MiniMapContainer
