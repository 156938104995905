import { createStyles, makeStyles } from '@material-ui/core'
import React, { FC, useEffect } from 'react'
import { useAppSelector } from '../../Reducers/hooks'
import { JitsiError } from '../../Services/Models/CallModel'
import Config from '../../config.json'

const useStyles = makeStyles(() =>
  createStyles({
    jitsiDiv: {
      height: '100%',
      width: '100%',
    },
  })
)

export interface JitsiComponentProps {
  conversationId: string
  jitsiJwtToken: string
  audioOnly: boolean
  handleClose: () => void
}

const JitsiComponent: FC<JitsiComponentProps> = ({
  conversationId,
  audioOnly,
  jitsiJwtToken,
  handleClose,
}) => {
  const classes = useStyles()

  const currentUser = useAppSelector((state) => state.auth.currentUser)
  const jitsiUrl = Config.VSF_K8S_JITSI_HOST
  let JitsiMeetExternalAPI: any

  useEffect(() => {
    const options: any = {
      roomName: `Virtual-Show-Floor-${conversationId}`,
      jwt: jitsiJwtToken,
      configOverwrite: {
        startWithVideoMuted: audioOnly,
        disableDeepLinking: true,
      },
      parentNode: document.getElementById('jitsi-container'),
    }

    if (!(window as any).JitsiMeetExternalAPI) {
      console.error('Jitsi API not found')
      return
    }

    JitsiMeetExternalAPI = new (window as any).JitsiMeetExternalAPI(jitsiUrl, options)

    JitsiMeetExternalAPI.executeCommand('subject', 'VSF Video Call')
    JitsiMeetExternalAPI.executeCommand('displayName', `${currentUser?.username}`)
    JitsiMeetExternalAPI.executeCommand('avatarUrl', `${currentUser?.profile_picture}`)

    JitsiMeetExternalAPI.addEventListener('readyToClose', () => {
      handleClose()
    })

    JitsiMeetExternalAPI.addEventListener('errorOccurred', (error: JitsiError) => {
      console.error(error)
    })
  }, [])

  return <div id='jitsi-container' className={classes.jitsiDiv}></div>
}
export default JitsiComponent
