import React, { FC } from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'
import { MenuDrawer } from '../../Common/common-index'
import { DrawerPositions } from '../../Common/Components/MenuDrawer/MenuDrawer'

import UserListContainer from '../UserList/UserListContainer'

const useStyles = makeStyles((theme: Theme) => ({
  paperContainer: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.background.paper,
  },
}))

export const RecruitingDashboardUserListContainer: FC = () => {
  const classes = useStyles()

  return (
    <MenuDrawer isOpen={true} drawerPosition={DrawerPositions.LEFT}>
      <div className={classes.paperContainer}>
        <UserListContainer isRecruitingDashboardUserList={true} />
      </div>
    </MenuDrawer>
  )
}

export default RecruitingDashboardUserListContainer
