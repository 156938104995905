import { useTranslation } from 'react-i18next'
import React, { FC, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { MenuPages, switchMenuPage, toggleMenu, updateIsSideMenuOpen } from '../Menu/MenuSlice'
import { useAppDispatch } from '../../Reducers/hooks'
import { Icon, MenuDrawer, MenuHeader } from '../../Common/common-index'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(4, 4, 0, 4),
    height: '100%',
    backgroundColor: theme.palette.background.default,
  },
  paperContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.default,
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  claimButton: {
    borderRadius: '0',
    cursor: 'pointer',
    display: 'flex',
    height: '55px',
    justifyContent: 'center',
    margin: theme.spacing(8, 4, 2, 0),
    width: '100%',
  },
  pageTitle: {
    fontSize: '15px',
    fontFamily: 'Graphik',
    fontWeight: 'bold',
    lineHeight: '30px',
  },
  pageDescription: {
    fontSize: '15px',
    fontFamily: 'Graphik',
    lineHeight: '25px',
  },
  rewardClaimed: {
    cursor: 'default',
    opacity: '0.5',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  confettiIcon: {
    alignSelf: 'center',
    display: 'flex',
    margin: theme.spacing(12, 0, 4, 0),
    '& svg': {
      height: '93px',
      width: '93px !important',
    },
  },
  participationMessage: {
    alignSelf: 'center',
    display: 'flex',
    fontFamily: 'Graphik',
    textAlign: 'center',
  },
}))

const PassportClaimedContainer: FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(updateIsSideMenuOpen(true))
  }, [])

  // Header Actions
  const OnCloseClick = () => {
    dispatch(toggleMenu(false))
    dispatch(updateIsSideMenuOpen(false))
    dispatch(switchMenuPage(MenuPages.Menu))
  }

  const OnGoBack = () => {
    dispatch(switchMenuPage(MenuPages.Menu))
    dispatch(toggleMenu(true))
  }

  return (
    <form className={classes.paperContainer}>
      <MenuDrawer
        isOpen={true}
        drawerHeader={
          <MenuHeader title={t('passport.title')} onGoBack={OnGoBack} onClose={OnCloseClick} />
        }
      >
        <div className={classes.container}>
          <Grid item xs={12} container direction='column'>
            <div className={classes.wrapper}>
              <Typography variant={'h6'} className={classes.pageTitle}>
                {t('passport.rewardPage.pageTitle')}
              </Typography>
              <Typography variant={'body1'} className={classes.pageDescription}>
                {t('passport.rewardPage.pageDescription')}
              </Typography>
              <Button
                id='passport-claimed-button'
                variant='contained'
                color='primary'
                className={`${classes.rewardClaimed} ${classes.claimButton}`}
              >
                {t('passport.rewardPage.rewardClaimedButton')}
              </Button>
            </div>
            <div className={classes.wrapper}>
              <div className={classes.confettiIcon}>
                <Icon iconName={'confetti'} iconSize={'93px'} />
              </div>
              <Typography variant={'h5'} className={classes.participationMessage}>
                {t('passport.rewardPage.participationMessage')}
              </Typography>
            </div>
            <Grid item></Grid>
          </Grid>
        </div>
      </MenuDrawer>
    </form>
  )
}
export default PassportClaimedContainer
