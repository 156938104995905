import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Grid from '@material-ui/core/Grid'
import MenuList from '@material-ui/core/MenuList'
import Typography from '@material-ui/core/Typography'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import UserAvatar from '../UserAvatar/UserAvatar'
import { useAppDispatch, useAppSelector } from '../../Reducers/hooks'
import { MenuPages, switchMenuPage, toggleMenu, updateIsSideMenuOpen } from '../Menu/MenuSlice'
import { switchRoomChatPage, RoomChatPages } from './RoomChatSlice'
import { ChatMenuItem, MenuDrawer, MenuHeader } from '../../Common/common-index'
import { User } from '../../Services/services-index'
import { createHtmlIdString } from '../../Utils/utils'

const userStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.background.default,
      minHeight: '100%',
      flexWrap: 'nowrap',
    },
    chatList: {
      flexGrow: 1,
      flexBasis: 0,
    },
    emptyMessage: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      height: '100%',
    },
    avatar: {
      border: '3px solid white',
      width: '50px',
      height: '50px',
    },
  })
)

const RoomChatParticipantListContainer: FC = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const classes = userStyles()

  const roomConversation = useAppSelector((state) => state.roomChat.activeConversation)
  const [roomParticipants, setRoomParticipants] = useState<User[]>([])

  useEffect(() => {
    dispatch(updateIsSideMenuOpen(true))
    if (roomConversation) {
      setRoomParticipants(roomConversation.participants)
    }
  }, [roomConversation])

  const displayRoomParticipants = () => {
    if (roomParticipants?.length === 0 || undefined) {
      return (
        <div className={classes.emptyMessage}>
          <Typography>{'Participant list is empty.!'}</Typography>
        </div>
      )
    }

    if (roomParticipants) {
      return roomParticipants.map((participant: User) => {
        const title = `${participant.firstname} ${participant.lastname}`
        return (
          <MenuList disablePadding>
            <ChatMenuItem
              id={createHtmlIdString('room-chat-participant-list-menu-item-', title)}
              key={participant.id}
              title={title}
              onClick={() => undefined}
              avatar={
                <UserAvatar
                  userId={participant.id as number}
                  hasUnreadMessage={false}
                  width={50}
                  height={50}
                  tooltipPlacement='bottom'
                />
              }
            />
          </MenuList>
        )
      })
    }
    return
  }

  // User Header Actions
  const OnCloseClick = () => {
    dispatch(toggleMenu(false))
    dispatch(updateIsSideMenuOpen(false))
    dispatch(switchMenuPage(MenuPages.Menu))
    dispatch(switchRoomChatPage(RoomChatPages.RoomChatMessage))
  }

  const OnGoBack = () => {
    dispatch(switchRoomChatPage(RoomChatPages.RoomChatMessage))
  }

  return (
    <MenuDrawer
      isOpen={true}
      drawerHeader={
        <MenuHeader
          title={t('chatPage.chatParticipantList.roomTitle')}
          onGoBack={OnGoBack}
          onClose={OnCloseClick}
        />
      }
    >
      <Grid container direction='column' justifyContent='center' className={classes.container}>
        <Grid item xs={12} className={classes.chatList}>
          {displayRoomParticipants()}
        </Grid>
      </Grid>
    </MenuDrawer>
  )
}

export default RoomChatParticipantListContainer
