import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRouteMatch } from 'react-router-dom'
import clsx from 'clsx'

import { Typography, Slide } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'

import { useAppDispatch, useAppSelector } from '../../Reducers/hooks'
import { updateSettings } from './profileSettingsSlice'
import { ProfileSettingPages, switchProfilePage } from './profileSettingsSlice'
import { MenuPages, switchMenuPage, toggleMenu, updateIsSideMenuOpen } from '../Menu/MenuSlice'
import { MenuDrawer, MenuHeader, Icon } from '../../Common/common-index'
import { localStorageService, userService, UserStatus } from '../../Services/services-index'
import { ProfileSettingsMenu } from '../../Components/ProfileSettings/ProfileSettingsMenu'
import UserAvatar from '../UserAvatar/UserAvatar'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    [theme.breakpoints.down('md')]: {
      paddingBottom: '32px',
    },
  },
  paperContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  profileWrapper: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
  },
  userInfo: {
    margin: '40px 0',
    padding: theme.spacing(0, 4),
    display: 'flex',
    flexDirection: 'row',
  },
  imageWrapper: {
    marginRight: '12px',
  },
  linksSection: {
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
  },
  statusInfoContainer: {
    alignItems: 'center',
    display: 'inline-flex',
    flexDirection: 'row',
    justifyContent: 'start',
  },
  statusIcon: {
    width: '14px',
    height: '14px',
    borderRadius: '50%',
    margin: theme.spacing(1.25),
  },
  online: {
    backgroundColor: '#21D627',
  },
  away: { backgroundColor: '#FDC42F' },
  doNotDisturb: {
    marginTop: '15px',
    '& svg': {
      minHeight: '14px !important',
      minWidth: '14px !important',
    },
  },
  errorStatus: {
    fontSize: '14px',
    color: '#b71c1c',
    display: 'flex',
    marginTop: theme.spacing(3),
  },
}))

export const ProfileSettingsContainer: FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const match = useRouteMatch()

  const [userDeleted, setUserDeleted] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [currentUserStatus, setCurrentUserStatus] = useState<string>('')

  const currentUser = useAppSelector((state) => state.auth.currentUser)
  const userSettings = useAppSelector((state) => state.profileSettings.userProfile)

  useEffect(() => {
    if (!userSettings && currentUser && currentUser.id) {
      userService
        .getUser(currentUser.id)
        .then((res) => {
          if (res) {
            dispatch(updateSettings(res.data?.user))
            setErrorMessage('')
          }
        })
        .catch((error) => {
          const errorText = error?.response?.data?.message
          setErrorMessage(errorText)
        })
    }
  }, [userSettings, match.url])

  useEffect(() => {
    dispatch(updateIsSideMenuOpen(true))
    const activeUserStatus = localStorageService.getLocalStorageItemValue('activeUserStatus')
    if (activeUserStatus) {
      setCurrentUserStatus(activeUserStatus)
    }
  }, [currentUserStatus])

  const deleteUser = () => {
    if (currentUser && currentUser.id) {
      userService
        .deactivateUser(currentUser.id)
        .then((res) => {
          if (res.status === 200) {
            setUserDeleted(true)
            dispatch(switchProfilePage(ProfileSettingPages.AccountDeleteSuccessPage))
            setErrorMessage('')
          }
        })
        .catch((error) => {
          const errorText = error?.response?.data?.message
          setErrorMessage(errorText)
        })
    }
  }

  // Header Actions
  const OnCloseClick = () => {
    dispatch(toggleMenu(false))
    dispatch(updateIsSideMenuOpen(false))
    dispatch(switchMenuPage(MenuPages.Menu))
  }

  const OnGoBack = () => {
    dispatch(switchMenuPage(MenuPages.Menu))
    dispatch(toggleMenu(true))
  }

  return (
    <div className={classes.paperContainer}>
      <MenuDrawer
        isOpen={true}
        drawerHeader={
          <MenuHeader
            title={t('profileSettingsPage.title')}
            onGoBack={OnGoBack}
            onClose={OnCloseClick}
          />
        }
      >
        <Slide direction='left' in={true} mountOnEnter unmountOnExit>
          <div className={classes.container}>
            {!userDeleted && (
              <div className={classes.profileWrapper}>
                <div className={classes.userInfo}>
                  <div className={classes.imageWrapper}>
                    <UserAvatar
                      userId={currentUser?.id}
                      hasUnreadMessage={false}
                      width={80}
                      height={80}
                    />
                  </div>
                  <div className={classes.statusInfoContainer}>
                    {userSettings?.firstname && (
                      <Typography variant={'h4'}>
                        {userSettings.firstname + ' ' + userSettings.lastname}
                      </Typography>
                    )}
                    <div aria-label={`'user status' ${currentUserStatus}`}>
                      {currentUserStatus === UserStatus.AVAILABLE && (
                        <div className={clsx(classes.online, classes.statusIcon)}></div>
                      )}
                      {currentUserStatus === UserStatus.BUSY && (
                        <div className={clsx(classes.doNotDisturb, classes.statusIcon)}>
                          <Icon iconName={'doNotDisturb'} />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className={classes.linksSection}>
                  <ProfileSettingsMenu
                    errorMessage={errorMessage}
                    deleteAccount={deleteUser}
                  ></ProfileSettingsMenu>
                </div>
                {errorMessage && (
                  <Typography variant='body2' className={classes.errorStatus}>
                    {errorMessage}
                  </Typography>
                )}
              </div>
            )}
          </div>
        </Slide>
      </MenuDrawer>
    </div>
  )
}
