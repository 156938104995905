/* eslint-disable no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export enum BusinessCardPages {
  BusinessCardsListPage,
  BusinessCardDetailspage,
  RequestBusinessCardPage,
}

export interface BusinessCard {
  user_id: number
  firstname: string
  lastname: string
  approved: boolean
  message: string
  profile_picture_id: number
  profile_picture_path: string
  email: string
  personal_quote: string
  phone_number: string
  webpage: string
  linkedin: string
  job_title: string
  company: string
  notes: string
  card_id: number
  interest: [string]
  skill: [string]
  categories: []
}

export interface BusinessCardsState {
  businessCards: BusinessCard[]
  approvedBusinessCard: BusinessCard | undefined
  nonApprovedBusinessCard: BusinessCard | undefined
  requestBusinessCardFromUserId: number | undefined
  isBusinessCardApproved: boolean
  isChatMenuBusinessCardView: boolean
  isBusinessCardRequestSent: boolean
  isBusinessCardRequestApproved: boolean
  isBusinessCardDeleted: boolean
  isBusinessCardChanged: boolean
  activeBusinessCardPage: BusinessCardPages
}

const initialState: BusinessCardsState = {
  businessCards: [],
  approvedBusinessCard: undefined,
  nonApprovedBusinessCard: undefined,
  requestBusinessCardFromUserId: undefined,
  isBusinessCardApproved: false,
  isChatMenuBusinessCardView: false,
  isBusinessCardRequestSent: false,
  isBusinessCardRequestApproved: false,
  isBusinessCardDeleted: false,
  isBusinessCardChanged: false,
  activeBusinessCardPage: BusinessCardPages.BusinessCardsListPage,
}

export const businessCardsSlice = createSlice({
  name: 'businessCards',
  initialState,
  reducers: {
    updateBusinessCards: (state, action: PayloadAction<any>) => {
      state.businessCards = action.payload
    },
    updateApprovedBusinessCard: (state, action: PayloadAction<BusinessCard>) => {
      state.approvedBusinessCard = action.payload
    },
    updateNonApprovedBusinessCard: (state, action: PayloadAction<BusinessCard>) => {
      state.nonApprovedBusinessCard = action.payload
    },
    updateRequestBusinessCardFromUserId: (state, action: PayloadAction<number>) => {
      state.requestBusinessCardFromUserId = action.payload
    },
    updateIsBusinessCardApproved: (state, action: PayloadAction<boolean>) => {
      state.isBusinessCardApproved = action.payload
    },
    updateIsChatMenuBusinessCardView: (state, action: PayloadAction<boolean>) => {
      state.isChatMenuBusinessCardView = action.payload
    },
    updateIsBusinessCardRequestSent: (state, action: PayloadAction<boolean>) => {
      state.isBusinessCardRequestSent = action.payload
    },
    updateIsBusinessCardRequestApproved: (state, action: PayloadAction<boolean>) => {
      state.isBusinessCardRequestApproved = action.payload
    },
    updateIsBusinessCardDeleted: (state, action: PayloadAction<boolean>) => {
      state.isBusinessCardDeleted = action.payload
    },
    switchBusinessCardPagePage: (state, action: PayloadAction<BusinessCardPages>) => {
      state.activeBusinessCardPage = action.payload
    },
    updateIsBusinessCardChanged: (state, action: PayloadAction<boolean>) => {
      state.isBusinessCardChanged = action.payload
    },
  },
})

export const {
  updateBusinessCards,
  updateApprovedBusinessCard,
  updateNonApprovedBusinessCard,
  updateRequestBusinessCardFromUserId,
  updateIsBusinessCardApproved,
  updateIsChatMenuBusinessCardView,
  updateIsBusinessCardRequestSent,
  updateIsBusinessCardRequestApproved,
  updateIsBusinessCardDeleted,
  switchBusinessCardPagePage,
  updateIsBusinessCardChanged,
} = businessCardsSlice.actions

export default businessCardsSlice.reducer
