import React, { FC } from 'react'
import { ThemeProvider } from '@material-ui/core/styles'
import { I18nProviderWrapper } from './I18nProvider'
import ErrorBoundary from '../Components/ErrorBoundary/ErrorBoundary'
import theme from '../theme'

const Providers: FC = ({ children }) => {
  return (
    <ErrorBoundary>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ErrorBoundary>
  )
}

export { I18nProviderWrapper }

export default Providers
