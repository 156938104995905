import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DropdownItem } from '../../Common/common-index'

export interface EventState {
  audienceOptions: DropdownItem[]
  notificationTypes: DropdownItem[]
  messageDismissDurationTypes: DropdownItem[]
}

const initialState: EventState = {
  audienceOptions: [],
  notificationTypes: [],
  messageDismissDurationTypes: [],
}

export const eventNotificationSlice = createSlice({
  name: 'eventNotification',
  initialState,
  reducers: {
    updateAudienceOptions: (state, action: PayloadAction<any>) => {
      state.audienceOptions = action.payload
    },
    updateNotificationTypes: (state, action: PayloadAction<any>) => {
      state.notificationTypes = action.payload
    },
    updateMessageDismissDurationTypes: (state, action: PayloadAction<DropdownItem[]>) => {
      state.messageDismissDurationTypes = action.payload
    },
  },
})

export const { updateAudienceOptions, updateNotificationTypes, updateMessageDismissDurationTypes } =
  eventNotificationSlice.actions

export default eventNotificationSlice.reducer
