import React, { FC } from 'react'
import Typography from '@material-ui/core/Typography'

import { makeStyles, Theme } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { createHtmlIdString } from '../../../Utils/utils'

export interface DropdownItem {
  value?: number
  name?: string
  id?: number
}

export interface InputProps {
  title?: string
  itemsList?: DropdownItem[]
  selecteditem?: number
  variant?: any
  // eslint-disable-next-line no-unused-vars
  onOptionChange: (value?: any) => void
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& .MuiSelect-select': {
      fontFamily: 'Graphik',
      fontSize: '16px',
      backgroundColor: theme.palette.background.default,
    },
    '& div[class*="MuiOutlinedInput-root"]': {
      borderRadius: '0',
      backgroundColor: theme.palette.background.default,
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  listLabel: {
    fontFamily: 'Graphik',
    fontWeight: 'bold',
    paddingBottom: '10px',
  },
}))

export const Dropdown: FC<InputProps> = ({
  title,
  variant = 'standard',
  itemsList,
  selecteditem,
  onOptionChange,
}) => {
  const classes = useStyles()

  return (
    <div className={`${classes.root} ${classes.container}`}>
      {title && (
        <Typography variant={'body2'} className={classes.listLabel}>
          {title}
        </Typography>
      )}
      <FormControl variant={variant} size='small'>
        <Select
          value={selecteditem}
          onChange={(e) => onOptionChange(e)}
          inputProps={{
            classes: {
              root: classes.root,
            },
          }}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
          }}
        >
          {itemsList &&
            itemsList.map((item: DropdownItem) => (
              <MenuItem
                value={item.value || item.id}
                key={item.value || item.id}
                id={createHtmlIdString('dropdown-menu-item-', item.name ? item.name : '')}
              >
                {item.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </div>
  )
}

export default Dropdown
