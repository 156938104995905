import React, { FC, useEffect, useState } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Typography from '@material-ui/core/Typography'
import { eventService, PreferenceType, OpenHours } from '../../Services/services-index'

const useStyles = makeStyles(() =>
  createStyles({
    item: {
      width: '80%',
    },
    welcomeHeader: {
      marginTop: '43px',
    },
  })
)
function useQuery() {
  return new URLSearchParams(decodeURI(useLocation().search))
}

function getEventCodeQuery(query: URLSearchParams) {
  const eventCodeQuery = query.get('event')
  let eventCode: string
  if (!eventCodeQuery) {
    eventCode = ''
  } else {
    eventCode = eventCodeQuery
  }
  return eventCode
}
const LoginContainer: FC = () => {
  const classes = useStyles()
  const query = useQuery()
  const { t } = useTranslation()
  const history = useHistory()

  const eventCode = getEventCodeQuery(query)
  const [openHoursMessage, setOpenHoursMessage] = useState<string[]>([])
  const TIMEZONE_ABBR_DICT = [
    { abbr: 'A', longName: 'Alpha Time Zone' },
    { abbr: 'ACDT', longName: 'Australian Central Daylight Time' },
    { abbr: 'ACST', longName: 'Australian Central Standard Time' },
    { abbr: 'ACT', longName: 'Acre Time' },
    { abbr: 'ACT', longName: 'Australian Central Time' },
    { abbr: 'ACWST', longName: 'Australian Central Western Standard Time' },
    { abbr: 'ADT', longName: 'Arabia Daylight Time' },
    { abbr: 'ADT', longName: 'Atlantic Daylight Time' },
    { abbr: 'AEDT', longName: 'Australian Eastern Daylight Time' },
    { abbr: 'AEST', longName: 'Australian Eastern Standard Time' },
    { abbr: 'AET', longName: 'Australian Eastern Time' },
    { abbr: 'AFT', longName: 'Afghanistan Time' },
    { abbr: 'AKDT', longName: 'Alaska Daylight Time' },
    { abbr: 'AKST', longName: 'Alaska Standard Time' },
    { abbr: 'ALMT', longName: 'Alma-Ata Time' },
    { abbr: 'AMST', longName: 'Amazon Summer Time' },
    { abbr: 'AMST', longName: 'Armenia Summer Time' },
    { abbr: 'AMT', longName: 'Amazon Time' },
    { abbr: 'AMT', longName: 'Armenia Time' },
    { abbr: 'ANAST', longName: 'Anadyr Summer Time' },
    { abbr: 'ANAT', longName: 'Anadyr Time' },
    { abbr: 'AQTT', longName: 'Aqtobe Time' },
    { abbr: 'ART', longName: 'Argentina Time' },
    { abbr: 'AST', longName: 'Arabia Standard Time' },
    { abbr: 'AST', longName: 'Atlantic Standard Time' },
    { abbr: 'AT', longName: 'Atlantic Time' },
    { abbr: 'AWDT', longName: 'Australian Western Daylight Time' },
    { abbr: 'AWST', longName: 'Australian Western Standard Time' },
    { abbr: 'AZOST', longName: 'Azores Summer Time' },
    { abbr: 'AZOT', longName: 'Azores Time' },
    { abbr: 'AZST', longName: 'Azerbaijan Summer Time' },
    { abbr: 'AZT', longName: 'Azerbaijan Time' },
    { abbr: 'AoE', longName: 'Anywhere on Earth' },
    { abbr: 'B', longName: 'Bravo Time Zone' },
    { abbr: 'BNT', longName: 'Brunei Darussalam Time' },
    { abbr: 'BOT', longName: 'Bolivia Time' },
    { abbr: 'BRST', longName: 'Brasília Summer Time' },
    { abbr: 'BRT', longName: 'Brasília Time' },
    { abbr: 'BST', longName: 'Bangladesh Standard Time' },
    { abbr: 'BST', longName: 'Bougainville Standard Time' },
    { abbr: 'BST', longName: 'British Summer Time' },
    { abbr: 'BTT', longName: 'Bhutan Time' },
    { abbr: 'C', longName: 'Charlie Time Zone' },
    { abbr: 'CAST', longName: 'Casey Time' },
    { abbr: 'CAT', longName: 'Central Africa Time' },
    { abbr: 'CCT', longName: 'Cocos Islands Time' },
    { abbr: 'CDT', longName: 'Central Daylight Time' },
    { abbr: 'CDT', longName: 'Cuba Daylight Time' },
    { abbr: 'CEST', longName: 'Central European Summer Time' },
    { abbr: 'CET', longName: 'Central European Time' },
    { abbr: 'CET', longName: 'Central European Standard Time' },
    { abbr: 'CHADT', longName: 'Chatham Island Daylight Time' },
    { abbr: 'CHAST', longName: 'Chatham Island Standard Time' },
    { abbr: 'CHOST', longName: 'Choibalsan Summer Time' },
    { abbr: 'CHOT', longName: 'Choibalsan Time' },
    { abbr: 'CHUT', longName: 'Chuuk Time' },
    { abbr: 'CIDST', longName: 'Cayman Islands Daylight Saving Time' },
    { abbr: 'CIST', longName: 'Cayman Islands Standard Time' },
    { abbr: 'CKT', longName: 'Cook Island Time' },
    { abbr: 'CLST', longName: 'Chile Summer Time' },
    { abbr: 'CLT', longName: 'Chile Standard Time' },
    { abbr: 'COT', longName: 'Colombia Time' },
    { abbr: 'CST', longName: 'Central Standard Time' },
    { abbr: 'CST', longName: 'China Standard Time' },
    { abbr: 'CST', longName: 'Cuba Standard Time' },
    { abbr: 'CT', longName: 'Central Time' },
    { abbr: 'CVT', longName: 'Cape Verde Time' },
    { abbr: 'CXT', longName: 'Christmas Island Time' },
    { abbr: 'ChST', longName: 'Chamorro Standard Time' },
    { abbr: 'D', longName: 'Delta Time Zone' },
    { abbr: 'DAVT', longName: 'Davis Time' },
    { abbr: 'DDUT', longName: "Dumont-d'Urville Time" },
    { abbr: 'E', longName: 'Echo Time Zone' },
    { abbr: 'EASST', longName: 'Easter Island Summer Time' },
    { abbr: 'EAST', longName: 'Easter Island Standard Time' },
    { abbr: 'EAT', longName: 'Eastern Africa Time' },
    { abbr: 'ECT', longName: 'Ecuador Time' },
    { abbr: 'EDT', longName: 'Eastern Daylight Time' },
    { abbr: 'EEST', longName: 'Eastern European Summer Time' },
    { abbr: 'EET', longName: 'Eastern European Time' },
    { abbr: 'EGST', longName: 'Eastern Greenland Summer Time' },
    { abbr: 'EGT', longName: 'East Greenland Time' },
    { abbr: 'EST', longName: 'Eastern Standard Time' },
    { abbr: 'ET', longName: 'Eastern Time' },
    { abbr: 'F', longName: 'Foxtrot Time Zone' },
    { abbr: 'FET', longName: 'Further-Eastern European Time' },
    { abbr: 'FJST', longName: 'Fiji Summer Time' },
    { abbr: 'FJT', longName: 'Fiji Time' },
    { abbr: 'FKST', longName: 'Falkland Islands Summer Time' },
    { abbr: 'FKT', longName: 'Falkland Island Time' },
    { abbr: 'FNT', longName: 'Fernando de Noronha Time' },
    { abbr: 'G', longName: 'Golf Time Zone' },
    { abbr: 'GALT', longName: 'Galapagos Time' },
    { abbr: 'GAMT', longName: 'Gambier Time' },
    { abbr: 'GET', longName: 'Georgia Standard Time' },
    { abbr: 'GFT', longName: 'French Guiana Time' },
    { abbr: 'GILT', longName: 'Gilbert Island Time' },
    { abbr: 'GMT', longName: 'Greenwich Mean Time' },
    { abbr: 'GST', longName: 'Gulf Standard Time' },
    { abbr: 'GST', longName: 'South Georgia Time' },
    { abbr: 'GYT', longName: 'Guyana Time' },
    { abbr: 'H', longName: 'Hotel Time Zone' },
    { abbr: 'HDT', longName: 'Hawaii-Aleutian Daylight Time' },
    { abbr: 'HKT', longName: 'Hong Kong Time' },
    { abbr: 'HOVST', longName: 'Hovd Summer Time' },
    { abbr: 'HOVT', longName: 'Hovd Time' },
    { abbr: 'HST', longName: 'Hawaii Standard Time' },
    { abbr: 'I', longName: 'India Time Zone' },
    { abbr: 'ICT', longName: 'Indochina Time' },
    { abbr: 'IDT', longName: 'Israel Daylight Time' },
    { abbr: 'IOT', longName: 'Indian Chagos Time' },
    { abbr: 'IRDT', longName: 'Iran Daylight Time' },
    { abbr: 'IRKST', longName: 'Irkutsk Summer Time' },
    { abbr: 'IRKT', longName: 'Irkutsk Time' },
    { abbr: 'IRST', longName: 'Iran Standard Time' },
    { abbr: 'IST', longName: 'India Standard Time' },
    { abbr: 'IST', longName: 'Irish Standard Time' },
    { abbr: 'IST', longName: 'Israel Standard Time' },
    { abbr: 'JST', longName: 'Japan Standard Time' },
    { abbr: 'K', longName: 'Kilo Time Zone' },
    { abbr: 'KGT', longName: 'Kyrgyzstan Time' },
    { abbr: 'KOST', longName: 'Kosrae Time' },
    { abbr: 'KRAST', longName: 'Krasnoyarsk Summer Time' },
    { abbr: 'KRAT', longName: 'Krasnoyarsk Time' },
    { abbr: 'KST', longName: 'Korea Standard Time' },
    { abbr: 'KUYT', longName: 'Kuybyshev Time' },
    { abbr: 'L', longName: 'Lima Time Zone' },
    { abbr: 'LHDT', longName: 'Lord Howe Daylight Time' },
    { abbr: 'LHST', longName: 'Lord Howe Standard Time' },
    { abbr: 'LINT', longName: 'Line Islands Time' },
    { abbr: 'M', longName: 'Mike Time Zone' },
    { abbr: 'MAGST', longName: 'Magadan Summer Time' },
    { abbr: 'MAGT', longName: 'Magadan Time' },
    { abbr: 'MART', longName: 'Marquesas Time' },
    { abbr: 'MAWT', longName: 'Mawson Time' },
    { abbr: 'MDT', longName: 'Mountain Daylight Time' },
    { abbr: 'MHT', longName: 'Marshall Islands Time' },
    { abbr: 'MMT', longName: 'Myanmar Time' },
    { abbr: 'MSD', longName: 'Moscow Daylight Time' },
    { abbr: 'MSK', longName: 'Moscow Standard Time' },
    { abbr: 'MST', longName: 'Mountain Standard Time' },
    { abbr: 'MT', longName: 'Mountain Time' },
    { abbr: 'MUT', longName: 'Mauritius Time' },
    { abbr: 'MVT', longName: 'Maldives Time' },
    { abbr: 'MYT', longName: 'Malaysia Time' },
    { abbr: 'N', longName: 'November Time Zone' },
    { abbr: 'NCT', longName: 'New Caledonia Time' },
    { abbr: 'NDT', longName: 'Newfoundland Daylight Time' },
    { abbr: 'NFDT', longName: 'Norfolk Daylight Time' },
    { abbr: 'NFT', longName: 'Norfolk Time' },
    { abbr: 'NOVST', longName: 'Novosibirsk Summer Time' },
    { abbr: 'NOVT', longName: 'Novosibirsk Time' },
    { abbr: 'NPT', longName: 'Nepal Time' },
    { abbr: 'NRT', longName: 'Nauru Time' },
    { abbr: 'NST', longName: 'Newfoundland Standard Time' },
    { abbr: 'NUT', longName: 'Niue Time' },
    { abbr: 'NZDT', longName: 'New Zealand Daylight Time' },
    { abbr: 'NZST', longName: 'New Zealand Standard Time' },
    { abbr: 'O', longName: 'Oscar Time Zone' },
    { abbr: 'OMSST', longName: 'Omsk Summer Time' },
    { abbr: 'OMST', longName: 'Omsk Standard Time' },
    { abbr: 'ORAT', longName: 'Oral Time' },
    { abbr: 'P', longName: 'Papa Time Zone' },
    { abbr: 'PDT', longName: 'Pacific Daylight Time' },
    { abbr: 'PET', longName: 'Peru Time' },
    { abbr: 'PETST', longName: 'Kamchatka Summer Time' },
    { abbr: 'PETT', longName: 'Kamchatka Time' },
    { abbr: 'PGT', longName: 'Papua New Guinea Time' },
    { abbr: 'PHOT', longName: 'Phoenix Island Time' },
    { abbr: 'PHT', longName: 'Philippine Time' },
    { abbr: 'PKT', longName: 'Pakistan Standard Time' },
    { abbr: 'PMDT', longName: 'Pierre & Miquelon Daylight Time' },
    { abbr: 'PMST', longName: 'Pierre & Miquelon Standard Time' },
    { abbr: 'PONT', longName: 'Pohnpei Standard Time' },
    { abbr: 'PST', longName: 'Pacific Standard Time' },
    { abbr: 'PST', longName: 'Pitcairn Standard Time' },
    { abbr: 'PT', longName: 'Pacific Time' },
    { abbr: 'PWT', longName: 'Palau Time' },
    { abbr: 'PYST', longName: 'Paraguay Summer Time' },
    { abbr: 'PYT', longName: 'Paraguay Time' },
    { abbr: 'PYT', longName: 'Pyongyang Time' },
    { abbr: 'Q', longName: 'Quebec Time Zone' },
    { abbr: 'QYZT', longName: 'Qyzylorda Time' },
    { abbr: 'R', longName: 'Romeo Time Zone' },
    { abbr: 'RET', longName: 'Reunion Time' },
    { abbr: 'ROTT', longName: 'Rothera Time' },
    { abbr: 'S', longName: 'Sierra Time Zone' },
    { abbr: 'SAKT', longName: 'Sakhalin Time' },
    { abbr: 'SAMT', longName: 'Samara Time' },
    { abbr: 'SAST', longName: 'South Africa Standard Time' },
    { abbr: 'SBT', longName: 'Solomon Islands Time' },
    { abbr: 'SCT', longName: 'Seychelles Time' },
    { abbr: 'SGT', longName: 'Singapore Time' },
    { abbr: 'SRET', longName: 'Srednekolymsk Time' },
    { abbr: 'SRT', longName: 'Suriname Time' },
    { abbr: 'SST', longName: 'Samoa Standard Time' },
    { abbr: 'SYOT', longName: 'Syowa Time' },
    { abbr: 'T', longName: 'Tango Time Zone' },
    { abbr: 'TAHT', longName: 'Tahiti Time' },
    { abbr: 'TFT', longName: 'French Southern and Antarctic Time' },
    { abbr: 'TJT', longName: 'Tajikistan Time' },
    { abbr: 'TKT', longName: 'Tokelau Time' },
    { abbr: 'TLT', longName: 'East Timor Time' },
    { abbr: 'TMT', longName: 'Turkmenistan Time' },
    { abbr: 'TOST', longName: 'Tonga Summer Time' },
    { abbr: 'TOT', longName: 'Tonga Time' },
    { abbr: 'TRT', longName: 'Turkey Time' },
    { abbr: 'TVT', longName: 'Tuvalu Time' },
    { abbr: 'U', longName: 'Uniform Time Zone' },
    { abbr: 'ULAST', longName: 'Ulaanbaatar Summer Time' },
    { abbr: 'ULAT', longName: 'Ulaanbaatar Time' },
    { abbr: 'UTC', longName: 'Coordinated Universal Time' },
    { abbr: 'UYST', longName: 'Uruguay Summer Time' },
    { abbr: 'UYT', longName: 'Uruguay Time' },
    { abbr: 'UZT', longName: 'Uzbekistan Time' },
    { abbr: 'V', longName: 'Victor Time Zone' },
    { abbr: 'VET', longName: 'Venezuelan Standard Time' },
    { abbr: 'VLAST', longName: 'Vladivostok Summer Time' },
    { abbr: 'VLAT', longName: 'Vladivostok Time' },
    { abbr: 'VOST', longName: 'Vostok Time' },
    { abbr: 'VUT', longName: 'Vanuatu Time' },
    { abbr: 'W', longName: 'Whiskey Time Zone' },
    { abbr: 'WAKT', longName: 'Wake Time' },
    { abbr: 'WARST', longName: 'Western Argentine Summer Time' },
    { abbr: 'WAST', longName: 'West Africa Summer Time' },
    { abbr: 'WAT', longName: 'West Africa Time' },
    { abbr: 'WEST', longName: 'Western European Summer Time' },
    { abbr: 'WET', longName: 'Western European Time' },
    { abbr: 'WFT', longName: 'Wallis and Futuna Time' },
    { abbr: 'WGST', longName: 'Western Greenland Summer Time' },
    { abbr: 'WGT', longName: 'West Greenland Time' },
    { abbr: 'WIB', longName: 'Western Indonesian Time' },
    { abbr: 'WIT', longName: 'Eastern Indonesian Time' },
    { abbr: 'WITA', longName: 'Central Indonesian Time' },
    { abbr: 'WST', longName: 'West Samoa Time' },
    { abbr: 'WST', longName: 'Western Sahara Summer Time' },
    { abbr: 'WT', longName: 'Western Sahara Standard Time' },
    { abbr: 'X', longName: 'X-ray Time Zone' },
    { abbr: 'Y', longName: 'Yankee Time Zone' },
    { abbr: 'YAKST', longName: 'Yakutsk Summer Time' },
    { abbr: 'YAKT', longName: 'Yakutsk Time' },
    { abbr: 'YAPT', longName: 'Yap Time' },
    { abbr: 'YEKST', longName: 'Yekaterinburg Summer Time' },
    { abbr: 'YEKT', longName: 'Yekaterinburg Time' },
    { abbr: 'Z', longName: 'Zulu Time Zone' },
  ]
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  function convertDate(date: string) {
    const newDate = new Date(date)
    const timeString =
      days[newDate.getDay()] +
      ', ' +
      newDate.getDate() +
      ' ' +
      months[newDate.getMonth()] +
      ' ' +
      newDate.getFullYear() +
      ' ' +
      ('0' + newDate.getHours()).slice(-2) +
      ':' +
      ('0' + newDate.getMinutes()).slice(-2) +
      ' ' +
      getTimeZoneName(newDate)
    return timeString
  }
  const extractTimeZoneAbbr = (datetime: Date) => {
    datetime = datetime || new Date()
    const timeZoneMatched = `${datetime}`.match(/\((.*?)\)\s*$/)
    if (timeZoneMatched) {
      const timeZoneFormatter = (r: string) =>
        r.replace(/[^\w]/gi, ' ').replace(/\s+/gi, ' ').replace(/^\s*/, '').replace(/\s*$/, '')
      const longstr = timeZoneFormatter(timeZoneMatched[1]).toLowerCase()
      let found = null
      TIMEZONE_ABBR_DICT.forEach((e) => {
        if (timeZoneFormatter(e.longName).toLowerCase() === longstr) found = e.abbr
      })
      return found || longstr
    } else {
      if (window.Intl) return `${new window.Intl.DateTimeFormat().resolvedOptions().timeZone}`
      else
        return `GMT${datetime.getTimezoneOffset() < 0 ? '' : '+'}${
          datetime.getTimezoneOffset() / 60
        }`
    }
  }

  function getTimeZoneName(timeZoneDate: Date) {
    return extractTimeZoneAbbr(timeZoneDate)
  }

  function checkOpenHours() {
    if (eventCode) {
      eventService
        .getPreference({
          type: PreferenceType.SPECIFIC,
          name: 'login',
          event_code: eventCode,
        })
        .then((res: any) => {
          const currentTime = new Date().getTime()
          const eventOpenHours: OpenHours[] = res?.data[0]['event_open_hours']
          let isWithinOpenHours = false
          const tempOpenHoursMessage: string[] = []
          eventOpenHours?.forEach((hours) => {
            if (hours.from_timestamp && hours.to_timestamp) {
              if (
                new Date(hours.from_timestamp).getTime() < currentTime &&
                new Date(hours.to_timestamp).getTime() > currentTime
              ) {
                isWithinOpenHours = true
              }

              tempOpenHoursMessage.push(
                hours.from_timestamp !== '' && hours.to_timestamp !== ''
                  ? (tempOpenHoursMessage.length > 0 ? ' and from ' : ' from ') +
                      convertDate(hours.from_timestamp) +
                      ' to ' +
                      convertDate(hours.to_timestamp)
                  : ''
              )
            }
          })
          setOpenHoursMessage(tempOpenHoursMessage)

          if (isWithinOpenHours || !eventOpenHours) {
            history.push(`/auth/login?event=${eventCode}`)
          }
        })
    } else {
      history.push('/')
    }
  }

  useEffect(() => {
    checkOpenHours()
  }, [])

  return (
    <Grid container justifyContent='center' alignItems='center' direction='column' spacing={6}>
      <Grid item className={(classes.item, classes.welcomeHeader)}>
        <Typography variant='h2'>{t('eventInfo.information')}</Typography>
      </Grid>
      <Grid item className={classes.item}>
        <Typography variant='h5'>{t('eventInfo.eventClosed')}</Typography>
      </Grid>
      <Grid item className={classes.item}>
        <Typography variant='body1'>
          {openHoursMessage.length ? t('eventInfo.eventWillOpen') : ''}
          {openHoursMessage}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default LoginContainer
