import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Socket } from 'socket.io-client'
import { sendMessage, startConversation } from '../Utils/chat.utils'
import { ChatPages, switchChatPage } from '../Containers/Chat/ChatSlice'
import {
  MessageActions,
  MessageBasicPayload,
  MessageTypes,
  messagingService,
} from '../Services/services-index'

export interface SocketState {
  socket: Socket | null
}

const initialState: SocketState = {
  socket: null,
}

// To Start a direct conversation with One-To-One or One-To-Group
export const startConversationAsync = createAsyncThunk(
  'socket/startConversationAsync',
  async (payload: { receiverIds: number[]; botId?: number }, { dispatch, getState }) => {
    console.log('Start chat with: ', payload.receiverIds)
    const state: any = getState()
    const { socket, auth } = state
    const { event_code, id } = auth.currentUser
    if (socket?.socket) {
      startConversation(socket.socket, `chat_${event_code}`, id, payload.receiverIds, payload.botId)
      dispatch(switchChatPage(ChatPages.ChatMessage))
    }
  }
)

// To Send a input message for direct chat via socket
export const sendMessageAsync = createAsyncThunk(
  'socket/sendMessageAsync',
  async (
    payload: {
      receiverIds: number[]
      inputValue: string
      botId?: number
    },
    { getState }
  ) => {
    const state: any = getState()
    const { socket, auth } = state
    const { event_code, id } = auth.currentUser

    if (socket.socket) {
      sendMessage(
        socket.socket,
        `chat_${event_code}`,
        id,
        payload.receiverIds,
        payload.inputValue,
        undefined,
        payload.botId
      )
    }
  }
)

// To Start a Room Conversation with One-To-One or One-To-Group
export const startRoomConversationAsync = createAsyncThunk(
  'socket/startRoomConversationAsync',
  async (payload: { roomId: number }, { getState }) => {
    const state: any = getState()
    const { socket, auth } = state
    const { event_code, id } = auth.currentUser
    if (socket?.socket) {
      const receiverIds: number[] = []

      const messagePayload: MessageBasicPayload = {
        from: id,
        to: receiverIds,
        timestamp: new Date().getTime().toString(),
        type: MessageTypes.text,
        action: MessageActions.start,
        room: payload.roomId,
      }
      messagingService.emitMessagingSocket(`chat_${event_code}`, messagePayload, socket.socket)
    }
  }
)

// To Send a input message for roomChat via socket
export const sendRoomMessageAsync = createAsyncThunk(
  'socket/sendRoomMessageAsync',
  async (
    payload: {
      receiverIds: number[]
      inputValue: string
      roomId: number
    },
    { getState }
  ) => {
    const state: any = getState()
    const { socket, auth } = state
    const { event_code, id } = auth.currentUser
    if (socket.socket) {
      sendMessage(
        socket.socket,
        `chat_${event_code}`,
        id,
        payload.receiverIds,
        payload.inputValue,
        payload.roomId
      )
    }
  }
)

export const socketSlice = createSlice({
  name: 'socket',
  initialState,
  reducers: {
    updateSocket: (state, action: PayloadAction<any>) => {
      state.socket = action.payload
    },
  },
})

export const { updateSocket } = socketSlice.actions

export default socketSlice.reducer
