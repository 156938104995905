import React, { FC } from 'react'

import { Theme } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'

import { Icon } from '../../Common/common-index'
import { useAppSelector } from '../../Reducers/hooks'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    disableAction: {
      pointerEvents: 'none',
    },
    button: {
      height: '56px',
      width: '56px',
      '& svg': {
        height: '56px !important',
        width: '56px !important',
      },
    },
    buttonMicrophone: {
      cursor: 'pointer',
      '& div:nth-of-type(1)': { display: 'none' },
      '&:hover div:nth-of-type(2)': { display: 'none' },
      '&:hover div:nth-of-type(1)': { display: 'block' },
    },
    buttonVideo: {
      cursor: 'pointer',
      '& div:nth-of-type(1)': { display: 'none' },
      '&:hover div:nth-of-type(2)': { display: 'none' },
      '&:hover div:nth-of-type(1)': { display: 'block' },
    },
    IconsWrapper: {
      display: 'flex',
      flexDirection: 'column',
      padding: '0 10px',
    },
    iconItem: {
      padding: theme.spacing(2, 0),
    },
  })
)

export interface Props {
  hasLoudSpeakerAccess?: boolean
  onToggleMic?: () => void
  onExpandView?: () => void
  onLoadSpeaker?: () => void
  onToggleVideo?: () => void
}

export const ToolButtons: FC<Props> = ({ onToggleVideo, onToggleMic }) => {
  const classes = useStyles()

  const currentUser = useAppSelector((state) => state.toolButtons.currentUser)
  const videoAllowed = useAppSelector((state) => state.toolButtons.videoAllowed)
  const microphoneAllowed = useAppSelector((state) => state.toolButtons.microphoneAllowed)
  const callStarted = useAppSelector((state) => state.call.callStarted)
  const activeUserMuted = currentUser?.muted
  const activeUserVideoEnabled = currentUser?.videoEnabled

  return (
    <>
      {currentUser && (
        <div className={`${classes.buttonContainer} ${callStarted && classes.disableAction}`}>
          {microphoneAllowed && (
            <div className={classes.button} onClick={onToggleMic}>
              {activeUserMuted ? (
                <div className={classes.buttonMicrophone}>
                  <div>
                    <Icon
                      id={'mic-muted'}
                      iconName={'muteMicrophoneHover'}
                      title={'Unmute Microphone'}
                      iconSize='56px'
                    />
                  </div>
                  <div>
                    <Icon
                      id={'mic-muted'}
                      iconName={'muteMicrophone'}
                      title={'Unmute Microphone'}
                      iconSize='56px'
                    />
                  </div>
                </div>
              ) : (
                <div className={classes.buttonMicrophone}>
                  <div>
                    <Icon
                      id={'mic-unmuted'}
                      iconName={'microphoneHover'}
                      title={'Mute Microphone'}
                      iconSize='56px'
                    />
                  </div>
                  <div>
                    <Icon
                      id={'mic-unmuted'}
                      iconName={'microphone'}
                      title={'Mute Microphone'}
                      iconSize='56px'
                    />
                  </div>
                </div>
              )}
            </div>
          )}

          {videoAllowed && (
            <div className={classes.button} onClick={onToggleVideo}>
              {activeUserVideoEnabled ? (
                <div className={classes.buttonVideo}>
                  <div>
                    <Icon
                      id={'mute-video'}
                      iconName={'videoHover'}
                      title={'Mute Video'}
                      iconSize='56px'
                    />
                  </div>
                  <div>
                    <Icon
                      id={'mute-video'}
                      iconName={'video'}
                      title={'Mute Video'}
                      iconSize='56px'
                    />
                  </div>
                </div>
              ) : (
                <div className={classes.buttonVideo}>
                  <div>
                    <Icon
                      id={'unmute-video'}
                      iconName={'muteVideoHover'}
                      title={'Unmute Video'}
                      iconSize='56px'
                    />
                  </div>
                  <div>
                    <Icon
                      id={'unmute-video'}
                      iconName={'muteVideo'}
                      title={'Unmute Video'}
                      iconSize='56px'
                    />
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </>
  )
}
