import React, { FC, useEffect, useState } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import { Icon } from '../../Common/common-index'
import { Chip } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.default,
      borderRadius: '10px',
      padding: '12px',
      transition: 'all 0.3s ease-in-out',
      minWidth: '150px',
      maxWidth: '400px',
      boxShadow: '0px 0px 10px #0000002E',
      position: 'relative',
      marginLeft: '10px',
      marginTop: '10px',
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'row',
    },
    contentWrapper: {
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.up('sm')]: {
        width: '350px',
      },
      paddingLeft: '10px',
    },
    messageWrapper: {
      margin: 'auto',
      // Compensate width of the close icon to align title and message
      paddingRight: '40px',
    },
    message: {
      fontSize: '14px',
      fontFamily: 'Graphik',
      textAlign: 'start',
    },
    titleWrapper: {
      display: 'flex',
      flexDirection: 'row',
    },
    title: {
      textAlign: 'center',
      width: '100%',
      fontSize: '16px',
      fontFamily: 'Graphik',
      fontWeight: 'bold',
    },
    iconWrapper: {
      padding: '4px 4px 4px 8px',
      justifyContent: 'center',
      alignSelf: 'center',
      maxHeight: '41px',
      maxWidth: '41px',
    },
    icon: {
      '& svg': {
        maxHeight: '41px !important',
        maxWidth: '41px !important',
        minHeight: '41px !important',
        minWidth: '41px !important',
      },
    },
    closeIcon: {
      background: 'none',
      marginTop: '-10px',
      marginRight: '-6px',
    },
  })
)

export const EventNotificationFeed: FC<any> = ({ children, title, appearance, onDismiss }) => {
  const classes = useStyles()

  const [notificationIconName, setNotificationIconName] = useState<any>('')
  const [notificationTitle, setNotificationTitle] = useState<any>('')

  const getNotificationIcon = () => {
    if (appearance) {
      setNotificationTitle(title)
      if (appearance === 'info') {
        setNotificationIconName('info')
        return
      } else if (appearance === 'error') {
        setNotificationIconName('errorCross')
        return
      } else if (appearance === 'success') {
        setNotificationIconName('successCheck')
        return
      } else if (appearance === 'warning') {
        setNotificationIconName('warning')
        return
      }
      return setNotificationIconName('')
    }
  }

  useEffect(() => {
    getNotificationIcon()
  }, [notificationIconName, notificationTitle])

  return (
    <>
      {children && appearance && (
        <div className={`${classes.wrapper} ${classes.root}`}>
          <div className={`${classes.iconWrapper} ${classes.icon}`}>
            <Icon iconName={notificationIconName} iconSize={'41'} />
          </div>
          <div className={classes.contentWrapper}>
            <div className={classes.titleWrapper}>
              <Typography variant={'body1'} className={classes.title}>
                {notificationTitle}
              </Typography>
              <Chip
                id='event-notification-delete'
                className={classes.closeIcon}
                onDelete={() => onDismiss()}
              ></Chip>
            </div>
            <div className={classes.messageWrapper}>
              <Typography variant={'body2'} className={classes.message}>
                {children}
              </Typography>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default EventNotificationFeed
