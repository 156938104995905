/* eslint-disable no-unused-vars */
export enum MessageTypes {
  audio = 'audio',
  video = 'video',
  text = 'text',
}

export enum MessageActions {
  start = 'start',
  end = 'end',
  delete = 'delete',
}

export interface MessageCard {
  type: string
  id: string
  buttons: any[]
}

export interface MessageBasicPayload {
  from: number
  to: number[]
  timestamp: string
  type?: MessageTypes
  action?: MessageActions
  message?: string
  room?: number
  bot?: number
}

export interface MessagePayload extends MessageBasicPayload {
  id: string
  conversation: string
  room?: number
  bot?: number
  cards?: MessageCard
  attachment?: {
    roomId: string
    id: string
  }
  token?: string
  user_summary_info?: any
}

export interface SceneEventMessage {
  user_id: number | null
  event: string | null
  room_id: number | null
  content_id: number | null
  timestamp: string | null
  object: Object | null
}

export interface SceneEventDetail {
  contentId: string
  type: string
}

export interface SceneEventSettingsDetail {
  contentId: string
  playBackgroundMusic: boolean
}

export interface BadgeEventMessage {
  achievement_attribute: string | null
  content_id: number | null
  room_id: number | null
  user_id: number | null
  event_code: string | null
}

export interface BadgeEventDetail {
  contentId: string
  achievement_attribute: string
}
