import React, { FC, useEffect, useState } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { Icon } from '../../Common/common-index'
import { useTranslation } from 'react-i18next'
import Button from '@material-ui/core/Button'
import { StoreItem } from '../../Containers/PassportContainer/PassportBadgesSlice'
import { contentService, FilesType } from '../../Services/services-index'
import { useAppSelector } from '../../Reducers/hooks'
import placeholder from '../../Assets/images/Placeholder.png'

interface Params {
  storeItem?: StoreItem
  onBuyClick?: () => void
}

const useStyles = makeStyles((theme: Theme) => ({
  goodiesContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '30px',
    outline: 'none',
    padding: theme.spacing(0, 4),
  },
  goodiesWrapper: {
    alignItems: 'center',
    display: 'inline-flex',
    outline: 'none',
    height: '100%',
  },
  thumbnail: {
    alignItems: 'center',
    color: theme.palette.primary.main,
    display: 'flex',
    height: '66px',
    justifyContent: 'center',
    marginRight: '12px',
    width: '70px',
    boxShadow: '0px 0px 10px #0000002E',
  },
  image: {
    height: '66px',
    width: '70px',
    objectFit: 'cover',
  },
  itemDetails: {
    width: '80%',
  },
  titleWrapper: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  itemTitle: {
    fontFamily: 'Graphik',
    fontSize: '18px',
    fontWeight: 'bold',
    marginBottom: '7px',
  },
  itemDescription: {
    color: theme.palette.grey[200],
    fontFamily: 'Graphik',
    fontSize: '15px',
  },
  coinWrapper: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    height: '30px',
  },
  coinCount: {
    margin: '0 3px',
  },
  points: {
    fontSize: '14px',
    fontFamily: 'Graphik-Regular',
    lineHeight: '22px',
  },
  buyButton: {
    borderRadius: '0',
    cursor: 'pointer',
    display: 'flex',
    height: '37px',
    maxWidth: '95px',
  },
  errorStatus: {
    fontSize: '14px',
    color: '#b71c1c',
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(1),
  },
}))

export const GoodiesItem: FC<Params> = ({ storeItem, onBuyClick }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const currentUser = useAppSelector((state) => state.auth.currentUser)

  const [thumbnail, setThumbnail] = useState<any>()
  const [errorMessage, setErrorMessage] = useState<string>('')

  useEffect(() => {
    const eventCode = currentUser?.event_code
    if (storeItem && eventCode) {
      getFile(eventCode, storeItem.filename)
    }
  }, [])

  const getFile = (eventCode: string, fileName: string) => {
    if (fileName?.length && eventCode) {
      contentService
        .getThumbnail(FilesType.THUMBNAIL, eventCode, fileName)
        .then((res: any) => {
          const image = URL.createObjectURL(res?.data)
          setThumbnail(image)
        })
        .catch((error: any) => {
          const errorText = error?.response?.data?.message
          setErrorMessage(errorText)
        })
    }
    if (fileName === null || fileName === undefined) {
      setThumbnail(placeholder)
    }
  }

  return (
    <div className={classes.goodiesContainer}>
      <div className={classes.goodiesWrapper}>
        <div className={classes.thumbnail}>
          {thumbnail && <img className={classes.image} src={thumbnail} />}
        </div>

        <div className={classes.itemDetails}>
          <div className={classes.titleWrapper}>
            <Typography variant={'h2'} className={classes.itemTitle}>
              {storeItem?.name}
            </Typography>
            <div className={classes.coinWrapper}>
              <Icon iconName={'miniCoinGold'} iconSize={'16px'} />
              <Typography className={`${classes.coinCount} ${classes.points}`}>
                {storeItem?.coin_price}
              </Typography>
            </div>
          </div>
          <div className={classes.titleWrapper}>
            <Typography variant={'h2'} className={classes.itemDescription}>
              {storeItem?.description}
            </Typography>
            <Button
              id='goodies-buy-button'
              variant='contained'
              color='primary'
              className={classes.buyButton}
              onClick={onBuyClick}
            >
              {t('passport.redeemCoins.buy')}
            </Button>
          </div>
        </div>
      </div>
      {errorMessage && <Typography className={classes.errorStatus}>{errorMessage}</Typography>}
    </div>
  )
}

export default GoodiesItem
