import io, { Socket } from 'socket.io-client'
import { Observable } from 'rxjs'
import { User } from '../Models/user.model'
import Config from '../../config.json'

const reactAppDomain = Config.VSF_K8S_API_URL

export const onConnectMessagingSocket = (user: User, roomId: number | undefined) => {
  const messagingSocket = io(`${reactAppDomain}`, {
    reconnection: true,
    reconnectionDelay: 500,
    reconnectionAttempts: Infinity,
    reconnectionDelayMax: 3000,
    withCredentials: true,
    path: '/messaging',
    query: {
      frontend: 'visitor',
      userId: user?.id,
      roomId: roomId,
    },
    auth: {
      jwt_token: user?.jwt_token || '',
    },
  })
  return messagingSocket
}

export const listenMessagingSocket = (eventName: string, socketConnection: Socket) => {
  return new Observable((subscriber) => {
    socketConnection.on(eventName, (data) => {
      subscriber.next(data)
    })
  })
}

export const emitMessagingSocket = (
  socketTopic: string,
  data: any,
  socketConnection: Socket,
  onSuccess?: () => void,
  onError?: () => void
) => {
  if (typeof data !== 'string') {
    data = JSON.stringify(data)
  }
  socketConnection.emit(socketTopic, data, (statusCode: any) => {
    if (statusCode && statusCode?.statusCode === 200) {
      if (onSuccess) {
        onSuccess()
      }
    }
    if (statusCode && statusCode?.statusCode === 400) {
      if (onError) {
        onError()
      }
    }
  })
}

export const removeAllListeners = (socketConnection: Socket, eventName?: string) => {
  if (socketConnection) {
    if (eventName) {
      socketConnection.off(eventName)
    } else {
      socketConnection.off()
    }
  }
}
