import React, { FC } from 'react'

interface MuteMicrophoneHoverProps {
  id?: string
}

const MuteMicrophoneHover: FC<MuteMicrophoneHoverProps> = ({ id }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      viewBox='0 0 186 186'
    >
      <defs>
        <filter id={`Ellipse_95_${id}`} x='0' y='0' filterUnits='userSpaceOnUse'>
          <feOffset in='SourceAlpha' />
          <feGaussianBlur stdDeviation='8' result='blur' />
          <feFlood flood-color='#a122fa' />
          <feComposite operator='in' in2='blur' />
          <feComposite in='SourceGraphic' />
        </filter>
      </defs>
      <g id='Component_45_1' data-name='Component 45 – 1' transform='translate(24 24)'>
        <g id='Group_794' data-name='Group 794' transform='translate(-1361 -1060)'>
          <g transform='matrix(1, 0, 0, 1, 1337, 1036)' filter={`url(#Ellipse_95_${id})`}>
            <circle
              id='Ellipse_95-2'
              data-name='Ellipse 95'
              cx='69'
              cy='69'
              r='69'
              transform='translate(24 24)'
              fill='#fff'
            />
          </g>
          <g
            id='Ellipse_96'
            data-name='Ellipse 96'
            transform='translate(1361 1060)'
            fill='none'
            stroke='#fff'
            stroke-width='3'
          >
            <circle cx='69' cy='69' r='69' stroke='none' />
            <circle cx='69' cy='69' r='67.5' fill='none' />
          </g>
        </g>
        <g id='MuteMicrophone-Icon' transform='translate(44 43)'>
          <path
            id='Path_167'
            data-name='Path 167'
            d='M22.5-.428C15.059-.428,9-7.051,9-15.188v-5.677A1.044,1.044,0,0,1,10.039-22q1.039,0,1.039,1.135v5.677C11.077-8.376,16.271-2.7,22.5-2.7a10.474,10.474,0,0,0,3.635-.757,1.079,1.079,0,0,1,1.385.757,1.321,1.321,0,0,1-.692,1.514A16.051,16.051,0,0,1,22.5-.428Z'
            transform='translate(3.852 42.134)'
            fill='#7517c0'
            fill-rule='evenodd'
          />
          <path
            id='Path_168'
            data-name='Path 168'
            d='M34.085-2.923c-.182,0-.364-.178-.546-.178a1.109,1.109,0,0,1-.546-1.423,11.5,11.5,0,0,0,1.093-4.8v-12.8A11.758,11.758,0,0,0,22.063-33.866a12.323,12.323,0,0,0-10.93,6.758,1.159,1.159,0,0,1-1.457.533A.921.921,0,0,1,9.311-28a13.792,13.792,0,0,1,12.751-8A14.061,14.061,0,0,1,36.271-22.129v12.8A12.394,12.394,0,0,1,35-3.634,1.206,1.206,0,0,1,34.085-2.923Z'
            transform='translate(3.906 36)'
            fill='#7517c0'
            fill-rule='evenodd'
          />
          <path
            id='Path_169'
            data-name='Path 169'
            d='M29.158-5.18c-.173,0-.345-.188-.518-.188a1.1,1.1,0,0,1-.345-1.507,20.17,20.17,0,0,0,1.9-8.475v-4.52A1.04,1.04,0,0,1,31.229-21q1.035,0,1.035,1.13v4.52a20.591,20.591,0,0,1-2.243,9.6C29.848-5.557,29.5-5.18,29.158-5.18Z'
            transform='translate(12.227 42.572)'
            fill='#7517c0'
            fill-rule='evenodd'
          />
          <path
            id='Path_170'
            data-name='Path 170'
            d='M23.752,3.448C13.832,3.448,6-4.886,6-15.444v-4.445A1.035,1.035,0,0,1,7.044-21q1.044,0,1.044,1.111v4.445c0,9.261,6.962,16.669,15.664,16.669A14.383,14.383,0,0,0,31.584-1a.886.886,0,0,1,1.392.37A1,1,0,0,1,32.628.855,16.648,16.648,0,0,1,23.752,3.448Z'
            transform='translate(2.538 42.572)'
            fill='#7517c0'
            fill-rule='evenodd'
          />
          <path
            id='Path_171'
            data-name='Path 171'
            d='M18-5h1.438V2.191H18Z'
            transform='translate(7.796 49.582)'
            fill='#7517c0'
          />
          <path
            id='Path_172'
            data-name='Path 172'
            d='M49.035,13.87a1.394,1.394,0,0,1-.727-.353L.336-33.085a1.287,1.287,0,0,1,0-1.589,1.4,1.4,0,0,1,1.635,0l47.973,46.6a1.287,1.287,0,0,1,0,1.589A1.41,1.41,0,0,1,49.035,13.87Z'
            transform='translate(-0.063 36.465)'
            fill='#7517c0'
            fill-rule='evenodd'
          />
        </g>
      </g>
    </svg>
  )
}

export default MuteMicrophoneHover
