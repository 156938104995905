import React from 'react'
import { ThemeProvider } from '@material-ui/core/styles'
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline'
import './Common/common-index.css'
import { useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import './App.css'
import ProtectedRouter from './Containers/AuthenticationContainer/Auth/ProtectedRouter'
import ChatContainer from './Containers/Chat/ChatContainer'
import EventNotificationContainer from './Containers/EventNotificationContainer/EventNotificationContainer'
import MenuContainer from './Containers/Menu/MenuContainer'
import { MenuPages } from './Containers/Menu/MenuSlice'
import ProfileSettings from './Containers/ProfileSettings/ProfileSettings'
import ProtectedPageContainer from './Containers/ProtectedPageContainer/ProtectedPageContainer'
import RoomChatContainer from './Containers/RoomChat/RoomChatContainer'
import SettingsContainer from './Containers/SettingsContainer/SettingsContainer'
import ToastNotificationContainer from './Containers/ToastNotificationContainer/ToastNotificationContainer'
import AuthForgotPasswordPage from './Pages/Auth/AuthForgotPassword/AuthForgotPasswordPage'
import AuthForgotPasswordSuccessPage from './Pages/Auth/AuthForgotPassword/AuthForgotPasswordSuccessPage'
import AuthLogin from './Pages/Auth/AuthLogin/AuthLogin'
import AuthRegistration from './Pages/Auth/AuthRegistration/AuthRegistration'
import AuthResetPasswordPage from './Pages/Auth/AuthResetPassword/AuthResetPasswordPage'
import AuthResetPasswordSuccessPage from './Pages/Auth/AuthResetPassword/AuthResetPasswordSuccessPage'
import { OAuth2Page } from './Pages/Auth/OAuth2Page/OAuth2Page'
import RoomPage from './Pages/Room/RoomPage'
import { useAppDispatch, useAppSelector } from './Reducers/hooks'
import Error from './Pages/Auth/Error/Error'
import HelpContainer from './Containers/HelpContainer/HelpContainer'
import EventInfo from './Pages/Event/EventInfo'
import AuthRegistrationSuccessPage from './Pages/Auth/AuthRegistration/AuthRegistrationSuccessPage'
import PassportMenuContainer from './Containers/PassportContainer/PassportMenuContainer'
import { fetchUserSummaryAsync } from './Reducers/userSlice'
import i18n from './i18n'
import MiniMapContainer from './Containers/MiniMapContainer/MiniMapContainer'
import BusinessCardsContainer from './Containers/BusinessCards/BusinessCardsContainer'
import { Preference } from './Services/Models/event.model'
import {
  authService,
  localStorageService,
  messagingService,
  ScreenAccessRight,
  UserRole,
} from './Services/services-index'
import { I18nProviderWrapper, Icon, theme as muiTheme } from './Common/common-index'
import { updateCurrentUserStatus } from './Reducers/authSlice'
import { t } from 'i18next'
import UserListContainer from './Containers/UserList/UserListContainer'
import RecruitingDashboardMenuContainer from './Containers/RecruitingDashboard/RecruitingDashboardMenuContainer'
import TokenWalletContainer from './Containers/TokenWallet/TokenWalletContainer'
import FatalErrorBoundary from './Common/Components/ErrorBoundary/FatalErrorBoundary'
import ErrorBoundaryPath from './Common/Components/ErrorBoundary/ErrorBoundaryPath'
import { setMiniMap } from './Containers/SettingsContainer/SettingsSlice'

export interface ProtectedRoute {
  path: string
  name: string
  isActiveRoute: boolean
  component?: JSX.Element
  icon?: JSX.Element
  exact?: boolean
  menuPage?: MenuPages | undefined
  isMenuItem?: boolean
  hasAccess?: boolean
  endElIcon?: JSX.Element
  count?: number
}

function App() {
  const dispatch = useAppDispatch()
  // Collect store values
  const activeMenuPage = useAppSelector((state) => state.menu.activeMenuPage)
  const currentUser = useAppSelector((state) => state.auth.currentUser)
  const socket = useAppSelector((state) => state.socket.socket)
  const preferences = useAppSelector((state) => state.preferences.selectedPreferences)
  const miniMap = useAppSelector((state) => state.settings.miniMap)
  const isMultiplayerRoom = useAppSelector((state) => state.room.isMultiplayerRoom)

  // Initiate local state values
  const [roomChatPreference, setRoomChatPreference] = useState<any>()
  const [privateChatPreference, setPrivateChatPreference] = useState<any>()
  const [businessCardPreference, setBusinessCardPreference] = useState<any>()
  const [passportPreference, setPassportPreference] = useState<any>()
  const [miniMapPreference, setMiniMapPreference] = useState<any>()
  const [miniMapState, setMiniMapState] = useState<boolean>(false)
  const [miniMapAtStartPreference, setMiniMapAtStartPreference] = useState<any>()
  const [tokenWalletPreference, setTokenWalletPreference] = useState<any>()

  // Validate User access for menu items by "Screen access right"
  const validateAccess = (value?: string) => {
    const accessRights = currentUser?.screen_access_rights
    if (accessRights !== undefined) {
      const hasRight = accessRights.some((item: ScreenAccessRight) => item?.frontend_name === value)
      return hasRight
    }
    return
  }

  // Validate User access for menu items by "Preference"
  const validatePreference = (preference: Preference) => {
    if (preference?.value === 'yes' || preference?.value === 'auto') {
      return true
    }
    return false
  }

  // Validate User role
  const validateUserRole = (userRole: UserRole, mode?: String) => {
    const userRoles = currentUser?.roles
    if (mode) {
      return userRoles?.some((role) => role.role === userRole && role.mode === mode)
    }
    return userRoles?.some((role) => role.role === userRole)
  }

  const protectedRoutes: ProtectedRoute[] = [
    {
      path: '/',
      component: <RoomPage />,
      name: 'home',
      icon: <ChatBubbleOutlineIcon />,
      isActiveRoute: true,
      exact: true,
    },
    {
      path: '/profile/settings',
      name: 'profile-settings',
      isActiveRoute: false,
      exact: true,
      menuPage: MenuPages.ProfileSetting,
      hasAccess: validateAccess('/profile'),
    },
    {
      path: '/room-chat',
      name: t('roomChatPage.title'),
      isActiveRoute: false,
      exact: true,
      icon: <Icon iconName={'roomChat'} iconSize={'25px'} />,
      menuPage: MenuPages.RoomChat,
      component: <RoomChatContainer />,
      hasAccess: validatePreference(roomChatPreference),
    },
    {
      path: '/user-list',
      name: t('userList.title'),
      isActiveRoute: false,
      exact: true,
      icon: <Icon iconName={'userList'} iconSize={'25px'} />,
      menuPage: MenuPages.UserList,
      component: <UserListContainer />,
      hasAccess: isMultiplayerRoom,
    },
    {
      path: '/room/:roomId',
      name: 'Room',
      component: <RoomPage />,
      isActiveRoute: true,
      icon: <ChatBubbleOutlineIcon />,
      exact: true,
    },
    {
      path: '/lobby',
      name: 'Lobby',
      isActiveRoute: true,
      component: <RoomPage />,
      icon: <ChatBubbleOutlineIcon />,
      exact: true,
      hasAccess: validateAccess('/lobby'),
    },
    {
      path: '/chat',
      name: t('chatPage.title'),
      isActiveRoute: false,
      icon: <Icon iconName={'chat'} iconSize={'25px'} />,
      exact: true,
      menuPage: MenuPages.Chat,
      component: <ChatContainer />,
      hasAccess: validatePreference(privateChatPreference),
    },
    {
      path: '/business-cards',
      name: t('businessCardsPage.businessCard.title'),
      isActiveRoute: false,
      icon: <Icon iconName={'businessCards'} iconSize={'25px'} />,
      menuPage: MenuPages.BusinessCards,
      hasAccess: validatePreference(businessCardPreference),
    },
    {
      path: '/token-wallet',
      name: t('tokenWalletPage.menuTitle'),
      isActiveRoute: false,
      icon: <Icon iconName={'tokenWallet'} iconSize={'25px'} />,
      exact: true,
      menuPage: MenuPages.TokenWallet,
      hasAccess: validatePreference(tokenWalletPreference),
    },
    {
      path: '/passport',
      name: t('passport.title'),
      isActiveRoute: false,
      icon: <Icon iconName={'passport'} iconSize={'25px'} />,
      exact: true,
      menuPage: MenuPages.Passport,
      hasAccess: validatePreference(passportPreference),
    },
    {
      path: '/RecruitingDashboard',
      name: 'Recruiting Dashboard',
      isActiveRoute: false,
      icon: <Icon iconName={'recruitingDashboard'} iconSize={'25px'} />,
      exact: true,
      menuPage: MenuPages.RecruitingDashboard,
      hasAccess: validateUserRole(UserRole.EventAdmin, 'Recruiting'),
    },
    {
      path: '/announcement',
      component: <EventNotificationContainer />,
      name: 'Event Notification',
      isActiveRoute: false,
      icon: <Icon iconName={'eventNotification'} iconSize={'25px'} />,
      exact: true,
      menuPage: MenuPages.EventNotification,
      hasAccess: validateAccess('/announcement'),
    },
    {
      path: '/help',
      name: t('helpMenu.title'),
      isActiveRoute: false,
      icon: <Icon iconName={'help'} iconSize={'25px'} />,
      exact: true,
      menuPage: MenuPages.Help,
      hasAccess: true,
    },

    {
      path: '/settings',
      name: t('settings.title'),
      isActiveRoute: false,
      icon: <Icon iconName={'settings'} iconSize={'25px'} />,
      exact: true,
      menuPage: MenuPages.Settings,
      hasAccess: validateAccess('/settings'),
    },
    {
      path: '/webgl-error',
      name: 'WebGL Error',
      isActiveRoute: true,
      exact: true,
    },
  ]

  const publicRoutes = [
    {
      path: '/auth/login',
      name: 'Login',
      isActiveRoute: true,
      exact: true,
      component: () => <AuthLogin />,
    },
    {
      path: '/auth/registration',
      name: 'Registration',
      isActiveRoute: true,
      exact: true,
      component: () => <AuthRegistration />,
    },
    {
      path: '/auth/registration-success',
      name: 'Registration Success',
      isActiveRoute: true,
      exact: true,
      component: () => <AuthRegistrationSuccessPage />,
    },
    {
      path: '/auth/forgot-password',
      name: 'Forgot Password',
      isActiveRoute: true,
      exact: true,
      component: () => <AuthForgotPasswordPage />,
    },
    {
      path: '/auth/forgot-password-success',
      name: 'Forgot Password Success',
      isActiveRoute: true,
      exact: true,
      component: () => <AuthForgotPasswordSuccessPage />,
    },
    {
      path: '/auth/reset-password',
      name: 'Reset Password',
      isActiveRoute: true,
      exact: true,
      component: () => <AuthResetPasswordPage />,
    },
    {
      path: '/auth/reset-password-success',
      name: 'Forget Password Success',
      isActiveRoute: true,
      exact: true,
      component: () => <AuthResetPasswordSuccessPage />,
    },
    {
      path: '/auth/callback/oauth2',
      name: 'Oauth2',
      isActiveRoute: true,
      exact: true,
      component: () => <OAuth2Page />,
    },
    {
      path: '/info',
      name: 'Info',
      isActiveRoute: true,
      exact: true,
      component: () => <EventInfo />,
    },
    {
      path: '*',
      name: 'Error',
      isActiveRoute: true,
      exact: true,
      component: () => <Error />,
    },
  ]

  const renderMenuPage = () => {
    switch (activeMenuPage) {
      case MenuPages.Menu: {
        return null
      }
      case MenuPages.Chat: {
        return <ChatContainer />
      }
      case MenuPages.RoomChat: {
        return <RoomChatContainer />
      }
      case MenuPages.UserList: {
        return <UserListContainer />
      }
      case MenuPages.ProfileSetting: {
        return <ProfileSettings />
      }
      case MenuPages.Help: {
        return <HelpContainer />
      }
      case MenuPages.BusinessCards: {
        return <BusinessCardsContainer />
      }
      case MenuPages.TokenWallet: {
        return <TokenWalletContainer />
      }
      case MenuPages.Passport: {
        return <PassportMenuContainer />
      }
      case MenuPages.RecruitingDashboard: {
        return <RecruitingDashboardMenuContainer />
      }
      case MenuPages.Settings: {
        return <SettingsContainer />
      }
      case MenuPages.EventNotification: {
        return <EventNotificationContainer />
      }
      default:
        return null
    }
  }

  // Collect Menu Item Preferences
  useEffect(() => {
    if (preferences) {
      setRoomChatPreference(preferences['room_chat'])
      setPrivateChatPreference(preferences['private_chat'])
      setBusinessCardPreference(preferences['business_cards'])
      setPassportPreference(preferences['passport_badges'])
      setMiniMapPreference(preferences['mini_map'])
      setTokenWalletPreference(preferences['enable_token_wallet'])
    }
  }, [
    preferences,
    roomChatPreference,
    privateChatPreference,
    businessCardPreference,
    tokenWalletPreference,
    passportPreference,
    miniMapPreference,
  ])

  // Handle MiniMapAtStart or MiniMap toggle conditions
  useEffect(() => {
    const miniMapStatus: any = localStorageService.getLocalStorageItemValue('enableMiniMap')
    if (!miniMapStatus?.length && preferences) {
      const miniValue = preferences['mini_map_at_start']
      setMiniMapAtStartPreference(miniValue)
      const miniStateValue = miniValue?.value === 'yes' ? true : false
      dispatch(setMiniMap(miniStateValue))
    }
  }, [currentUser, preferences])

  useEffect(() => {
    const miniMapStatus: any = localStorageService.getLocalStorageItemValue('enableMiniMap')
    if (miniMapStatus?.length) {
      miniMapStatus === 'true' ? setMiniMapState(true) : setMiniMapState(false)
    }
  }, [miniMapAtStartPreference, miniMap, miniMapState])

  useEffect(() => {
    if (socket) {
      messagingService
        .listenMessagingSocket('user_status_changes', socket)
        .subscribe((res: any) => {
          const status = JSON.parse(res)
          if (status) {
            if (status['user_id'] === currentUser?.id && status['user_status'] === 'offline') {
              authService.signOut().then(() => dispatch(updateCurrentUserStatus('logged out')))
            }
          }
          dispatch(fetchUserSummaryAsync(JSON.parse(res).user_id))
        })
    }
  }, [currentUser, socket])

  useEffect(() => {}, [isMultiplayerRoom])

  return (
    <ThemeProvider theme={muiTheme}>
      <I18nProviderWrapper i18n={i18n}>
        <div className='App'>
          <FatalErrorBoundary>
            <Router>
              <ErrorBoundaryPath>
                <Switch>
                  {protectedRoutes
                    .filter((r) => !!r.path)
                    .map((route) => (
                      <ProtectedRouter key={route.path} path={route.path} exact={route.exact}>
                        <ToastNotificationContainer>
                          <ProtectedPageContainer>
                            {renderMenuPage()}
                            <MenuContainer key={route.path} protectedRoutes={protectedRoutes} />
                            {route.component}
                            {validatePreference(miniMapPreference) && <MiniMapContainer />}
                          </ProtectedPageContainer>
                        </ToastNotificationContainer>
                      </ProtectedRouter>
                    ))}
                  {publicRoutes.map((route: any) => {
                    return <Route key={route.path} path={route.path} component={route.component} />
                  })}
                </Switch>
              </ErrorBoundaryPath>
            </Router>
          </FatalErrorBoundary>
        </div>
      </I18nProviderWrapper>
    </ThemeProvider>
  )
}

export default App
