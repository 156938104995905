import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface MiniMapResponse {
  width: number
  height: number
  room_coords_mapping: {
    id: number
    room_id: number
    room_name: string
    room_description: string
    shape: string
    coords: string
  }[]
}

export interface MiniMapState {
  miniMapResponse: MiniMapResponse | undefined
  mapImg: any
}

const initialState: MiniMapState = {
  miniMapResponse: undefined,
  mapImg: undefined,
}

export const MiniMapSlice = createSlice({
  name: 'miniMap',
  initialState,
  reducers: {
    updateMiniMap: (state, action: PayloadAction<MiniMapResponse>) => {
      state.miniMapResponse = action.payload
    },
    updateMapImg: (state, action: PayloadAction<any>) => {
      state.mapImg = action.payload
    },
  },
})

export const { updateMiniMap, updateMapImg } = MiniMapSlice.actions

export default MiniMapSlice.reducer
