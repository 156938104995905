import React, { FC, useState, useEffect } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import UserAvatar from '../../Containers/UserAvatar/UserAvatar'
import Slide from '@material-ui/core/Slide'
import { Typography, TextareaAutosize, Chip, Divider } from '@material-ui/core'
import clsx from 'clsx'
import {
  BusinessCard,
  updateIsBusinessCardChanged,
} from '../../Containers/BusinessCards/businessCardsSlice'
import { businessCardService } from '../../Services/services-index'
import { useTranslation } from 'react-i18next'
import { ConfirmationDialog } from '../../Common/common-index'
import { useAppDispatch } from '../../Reducers/hooks'
interface Params {
  businessCard?: BusinessCard
  errorMessage: string
  successMessage: string
  disableAction?: boolean
  deleteDialogOpen?: boolean
  openDeleteDialog: () => void
  onBusinessCardDeleteClick: () => void
  onCancelClick: () => void
}

const useStyles = makeStyles((theme) =>
  createStyles({
    businessCardDetail: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: '100%',
      outline: 'none',
    },
    shortInfo: {
      margin: '8px 0 40px 0',
      display: 'inline-flex',
      alignItems: 'center',
      outline: 'none',

      '& h2': {
        fontSize: '18px',
        lineHeight: '18px',
        marginBottom: '10px',
      },
    },
    userInfo: {
      marginLeft: '25px',
    },
    gray: { color: '#969696' },
    skills: {
      marginBottom: '24px',

      '& .labels': {
        display: 'flex',
        flexWrap: 'wrap',

        '& button': {
          margin: '0 8px 8px 0',
          outline: 'none',
        },
      },
      '& p[class*="body2"]': { marginTop: '10px' },
    },
    contactInfo: {
      borderBottom: '1px solid $color-ui-grey-2',
      paddingBottom: '24px',
      marginBottom: '24px',
      outline: 'none',
      '&:nth-child(1)': {
        '& p[class*="body2"]': { marginTop: '10px' },
      },
    },
    editNoteForm: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: '10px',
      width: '100%',
    },
    textarea: {
      ...theme.typography.caption,
      fontFamily: 'Graphik',
      fontSize: '14px',
      minWidth: '230px',
      minHeight: '100px',
      resize: 'vertical',
      width: 'calc(100% - 36px)',
      padding: theme.spacing(2),
    },
    noteMessage: {
      height: '100%',
      marginTop: '5px',
      overflow: 'hidden',
      whiteSpace: 'normal',
      width: '320px',
    },
    chips: {
      margin: '10px 10px 0 0',
      '& [class*="MuiChip-label"]': {
        maxWidth: '300px',
        padding: '12px',
      },
    },
    purple: {
      backgroundColor: theme.palette.primary.light,
    },
    notesHeader: {
      display: 'inline-flex',
      justifyContent: 'space-between',
      width: '100%',
      alignItems: 'center',
    },
    divider: {
      margin: '20px 0',
    },
    notes: {
      marginBottom: '20px',
    },
    smallMarginTop: {
      marginTop: '5px',
    },
    buttonItem: {
      cursor: 'pointer',
    },
  })
)

export const BusinessCardsDetailComponent: FC<Params> = ({
  businessCard,
  errorMessage,
  successMessage,
  disableAction,
  openDeleteDialog,
  deleteDialogOpen,
  onCancelClick,
  onBusinessCardDeleteClick,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const [isEditingNote, setIsEditingNote] = useState(false)
  const [inputError, setInputError] = useState<string>('')

  const [editedNoteText, setEditedNoteText] = useState(businessCard?.notes)
  const [editedBusinessCard, setEditedBusinessCard] = useState(businessCard)

  const toggleEditButton = () => {
    setIsEditingNote(!isEditingNote)
  }

  useEffect(() => {}, [isEditingNote, editedBusinessCard, editedNoteText])

  const keyDownEvent = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      ;(e.currentTarget as HTMLAreaElement).click()
      return
    }
  }

  const updateBusinessCardNote = (cardId: number) => {
    if (businessCard) {
      const card = {
        ...businessCard,
        notes: editedNoteText || businessCard.notes,
      }
      setEditedBusinessCard(card)
      businessCardService
        .editBusinessCardNotes(cardId, card.notes, true)
        .then(() => {
          dispatch(updateIsBusinessCardChanged(true))
          toggleEditButton()
        })
        .catch((error: any) => {
          setInputError(error?.error?.message)
        })
    }
  }

  const changeInputValue = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (event && businessCard) {
      event.target?.value
        ? setEditedNoteText(event.target?.value)
        : setEditedNoteText(businessCard.notes)
    }
  }

  // On Business car delete button click
  const onBusinessCardDelete = () => {
    return (
      <>
        <Typography
          id='business-card-delete'
          className={classes.buttonItem}
          variant={'button'}
          color={'primary'}
          aria-controls='edit-notes'
          onClick={openDeleteDialog}
        >
          {t('businessCardsPage.businessCard.businessCardDelete.title')}
        </Typography>
        {deleteDialogOpen && (
          <ConfirmationDialog
            titleText={t('businessCardsPage.businessCard.businessCardDelete.title')}
            contentText={t('businessCardsPage.businessCard.businessCardDelete.message')}
            cancelText={t('commonButtons.cancel')}
            confirmText={t('commonButtons.confirm')}
            open={deleteDialogOpen}
            errorMessage={errorMessage}
            successMessage={successMessage}
            disableAction={disableAction}
            onConfirm={onBusinessCardDeleteClick}
            onCancel={onCancelClick}
          />
        )}
      </>
    )
  }

  return (
    <>
      {editedBusinessCard && (
        <Slide direction='left' in={true} mountOnEnter unmountOnExit>
          <div className={classes.businessCardDetail}>
            <div className={classes.shortInfo}>
              <UserAvatar
                userId={editedBusinessCard.user_id}
                isSquare={true}
                height={100}
                width={100}
              />
              <div className={classes.userInfo}>
                <Typography variant={'subtitle2'}>
                  {editedBusinessCard.firstname} {editedBusinessCard.lastname}
                </Typography>
                <Typography variant={'body2'} className={classes.smallMarginTop}>
                  {editedBusinessCard.job_title}
                </Typography>
                <Typography variant={'body2'} className={classes.gray}>
                  {editedBusinessCard.company}
                </Typography>
              </div>
              <Typography variant={'body2'}>{editedBusinessCard.personal_quote}</Typography>
            </div>
            <div className={classes.skills}>
              <Typography variant={'subtitle1'}>
                {t('businessCardsPage.businessCard.skills')}
              </Typography>
              {editedBusinessCard.skill[0] && (
                <div className='labels'>
                  {editedBusinessCard.skill.map((everySkill, index) => (
                    <Chip
                      key={index + everySkill}
                      label={everySkill}
                      title={everySkill}
                      className={clsx(classes.chips, classes.purple)}
                    />
                  ))}
                </div>
              )}
              {!editedBusinessCard.skill[0] && (
                <Typography variant={'body2'}>
                  {t('businessCardsPage.businessCard.noSkills')}
                </Typography>
              )}
            </div>
            <div className={classes.skills}>
              <Typography variant={'subtitle1'}>
                {t('businessCardsPage.businessCard.interests')}
              </Typography>
              {editedBusinessCard.interest[0] && (
                <div className='labels' role='list'>
                  {editedBusinessCard.interest.map((everyInterest, index) => (
                    <Chip
                      key={index + everyInterest}
                      label={everyInterest}
                      title={everyInterest}
                      className={classes.chips}
                      variant={'outlined'}
                      color={'primary'}
                    />
                  ))}
                </div>
              )}
              {!editedBusinessCard.interest[0] && (
                <Typography variant={'body2'}>
                  {t('businessCardsPage.businessCard.noInterests')}
                </Typography>
              )}
            </div>
            <div className={classes.contactInfo}>
              <Typography variant={'subtitle1'}>
                {t('businessCardsPage.businessCard.info')}
              </Typography>
              <Typography variant={'body2'}>{editedBusinessCard.phone_number}</Typography>
              <Typography variant={'body2'}>{editedBusinessCard.email}</Typography>
              <Typography variant={'body2'}>{editedBusinessCard.webpage}</Typography>
              <Typography variant={'body2'}>{editedBusinessCard.linkedin}</Typography>
            </div>
            <div className={classes.notes} tab-index='0'>
              <Divider className={classes.divider} />
              <div className={classes.notesHeader}>
                <Typography variant={'subtitle1'}>
                  {t('businessCardsPage.businessCard.notes')}
                </Typography>
                {!isEditingNote && (
                  <Typography
                    id='business-card-notes-edit-open'
                    className={classes.buttonItem}
                    variant={'button'}
                    color={'primary'}
                    aria-controls='edit-notes'
                    onClick={toggleEditButton}
                    onKeyDown={(e: any) => keyDownEvent(e)}
                  >
                    {t('commonButtons.edit')}
                  </Typography>
                )}
                {isEditingNote && (
                  <Typography
                    id='business-card-notes-edit-done'
                    className={classes.buttonItem}
                    variant={'button'}
                    aria-controls='save-notes'
                    onClick={() => updateBusinessCardNote(editedBusinessCard?.card_id)}
                    onKeyDown={(e: any) => keyDownEvent(e)}
                  >
                    {t('commonButtons.done')}
                  </Typography>
                )}
              </div>
              {isEditingNote && (
                <div className={classes.editNoteForm}>
                  <TextareaAutosize
                    id='business-card-notes-edit-area'
                    minRows={3}
                    className={classes.textarea}
                    placeholder={editedNoteText}
                    value={editedNoteText}
                    maxLength={2000}
                    onChange={(e) => changeInputValue(e)}
                  />
                  {inputError && <p>{inputError}</p>}
                </div>
              )}
              {!isEditingNote && (
                <div>
                  <Typography variant={'body2'} className={classes.noteMessage}>
                    {editedBusinessCard.notes}
                  </Typography>
                </div>
              )}

              <Divider className={classes.divider} />
            </div>
            {onBusinessCardDelete()}
          </div>
        </Slide>
      )}
    </>
  )
}
