import React from 'react'
import Error from '../../../Pages/Auth/Error/Error'

interface State {
  hasError: boolean
  error?: Error
  errorInfo?: React.ErrorInfo
}

class RealErrorBoundary extends React.Component<
  // eslint-disable-next-line no-unused-vars
  { children?: React.ReactNode; setTrackPathChange: (track: boolean) => void },
  State
> {
  state: State = { hasError: false }

  static getDerivedStateFromError(error: unknown) {
    return { hasError: true, error: error }
  }

  componentDidCatch(error: Error, info: React.ErrorInfo) {
    this.props.setTrackPathChange(true)
    console.log(error, info)
    this.setState({ hasError: true, error: error, errorInfo: info })
  }

  render() {
    if (this.state.hasError) {
      const message = this.state.error?.message
      const hint = 'Unexpected error occurred'
      // we can customise the message dynamicly if it is required
      return <Error errorHint={hint} errorMessage={message} />
    } else {
      return this.props.children
    }
  }
}

export default RealErrorBoundary
