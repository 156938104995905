/* eslint-disable no-unused-vars */
export interface Room {
  id: number
  name: string
  type: string
  description?: string
  link: string
}

export interface Content {
  id: number
  name: string
  type: string
  position?: string
  rotation?: string
  link?: string
  file?: string
  object?: number
  folder?: string
  texture?: string
  target?: string
  media?: number
  media_id: string
  user?: string
  group?: string
  render_in_3d?: boolean
  attributes?: any
}

export interface RoomContent {
  id: number
  content: Content[]
}

export interface DisabledPopups {
  id: number
  isDisabled: boolean
}

export enum MediaType {
  STREAM = 'stream',
  VIDEO = 'video',
  AUDIO = 'audio',
}

export enum Shape {
  POLY = 'poly',
  RECT = 'rect',
  CIRCLE = 'circle',
}

export interface Media {
  source: string
  name: string
  type: MediaType
  external_id?: string
  external_link?: string
}

export interface EmailAssets {
  emailAssetData: FormData
  registrationEmail: string
  registrationEmailPlain: FormData
  resetEmail: FormData
  resetEmailPlain: FormData
  banner: FormData
  sampleAttachment: FormData
}

export enum RoomFileType {
  FILES = 'files',
  OBJECTS = 'objects',
  TEXTURE = 'texture',
  SLIDES = 'slides',
}

export enum FilesType {
  POSTER = 'poster',
  BANNER = 'banner',
  EMAIL_ASSETS = 'email_assets',
  SLIDES_IMAGES = 'slides_images',
  ADMIN_PORTAL_HELP = 'admin_portal_help',
  MINI_MAP = 'mini_map',
  USER_MANUAL = 'user_manual',
  THUMBNAIL = 'store_thumbnail',
}
