import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import FormTextField from '../FormTextField/FormTextField'
import { Preference } from '../../../Services/services-index'
import { ShadowedButton } from '../../../Common/common-index'

interface Props {
  onSubmit: Function
  eventCodeProp: string
  preferences: Preference[] | undefined
  errorResponse: string
  label?: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1, 0),
        padding: theme.spacing(0, 3),
        boxSizing: 'border-box',
        width: '100%',
        [theme.breakpoints.up('md')]: {
          width: 400,
          padding: theme.spacing(0),
        },
      },
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    headerWrapper: {
      fontFamily: 'Graphik',
      fontWeight: 'bold',
      textAlign: 'center',
      width: '100%',
      padding: theme.spacing(5, 0),
      [theme.breakpoints.up('md')]: {
        width: '400px',
      },
    },
    header: {
      lineHeight: '32px',
      [theme.breakpoints.up('sm')]: {
        width: '100%',
      },
    },
    button: {
      marginTop: theme.spacing(5),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        maxWidth: '400px',
      },
    },
    errorStatus: {
      fontSize: '14px',
      width: '100%',
      color: theme.palette.error.main,
      marginTop: theme.spacing(2),
      padding: theme.spacing(0, 3),
      [theme.breakpoints.up('sm')]: {
        marginLeft: 0,
        maxWidth: '400px',
      },
    },
  })
)
function isNotEmpty(str: string) {
  return str && str.length > 0
}

const RegisterForm: FC<Props> = ({
  onSubmit,
  eventCodeProp,
  preferences,
  errorResponse,
  label,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [eventCode, setEventCode] = useState(eventCodeProp)
  const [eventCodeFromQuery] = useState(isNotEmpty(eventCodeProp))
  const [firstNameValid, setFirstNameValid] = useState(true)
  const [lastNameValid, setLastNameValid] = useState(true)
  const [emailValid, setEmailValid] = useState(true)
  const [passwordValid, setPasswordValid] = useState(true)
  const [confirmPasswordValid, setConfirmPasswordValid] = useState(true)
  const [eventCodeValid, setEventCodeValid] = useState<boolean>(true)

  const [confirmPasswordMsg, setConfirmPasswordMsg] = useState('')

  const getPrefBoolValue = (fieldName: string) => {
    let fieldValue = true
    if (preferences) {
      const pref = [...preferences].filter((pref) => pref.name === fieldName)
      if (pref.length > 0) {
        fieldValue = pref[0].value == 'yes'
      }
    }
    return fieldValue
  }
  const firstNameDisplay = getPrefBoolValue('Firstname_Display')
  const lastNameDisplay = getPrefBoolValue('Lastname_Display')
  const firstNameMandatory = getPrefBoolValue('Firstname_Mandatory')
  const lastNameMandatory = getPrefBoolValue('Lastname_Mandatory')

  const getPrefLabelValue = (fieldname: string) => {
    let fieldValue
    if (preferences) {
      const pref = [...preferences].filter((pref) => pref.name === fieldname)
      if (pref.length > 0) {
        fieldValue = pref[0].value
      }
    }
    return fieldValue
  }
  const firstNameLabel = getPrefLabelValue('Firstname_Label')
  const lastNameLabel = getPrefLabelValue('Lastname_Label')
  const emailLabel = getPrefLabelValue('EMail_Label')
  const validateAndSet = (
    value: string,
    mandatory: boolean,
    setMethod: Function,
    validateMethod: Function
  ) => {
    if (!mandatory) {
      validateMethod(true)
    } else if (!value) {
      validateMethod(false)
    } else {
      validateMethod(true)
    }
    setMethod(value)
  }

  const validateConfirmPassword = (
    value: string,
    setMethod: Function,
    validateMethod: Function
  ) => {
    validateAndSet(value, true, setMethod, validateMethod)
    if (password === value) {
      setConfirmPasswordMsg('authPages.registerForm.confirmPassword.errorMessage')
    } else {
      setConfirmPasswordMsg('authPages.registerForm.confirmPassword.notMatchMessage')
      setConfirmPasswordValid(false)
    }
  }

  const validateAndSubmit = (e: any) => {
    validateAndSet(firstName, firstNameMandatory, setFirstName, setFirstNameValid)
    validateAndSet(lastName, lastNameMandatory, setLastName, setLastNameValid)
    validateAndSet(email, true, setEmail, setEmailValid)
    validateAndSet(password, true, setPassword, setPasswordValid)
    validateAndSet(confirmPassword, true, setConfirmPassword, setConfirmPasswordValid)
    if (eventCode !== '') {
      validateAndSet(eventCode, true, setEventCode, setEventCodeValid)
    }

    if (!!firstName && !!lastName && !!email && !!password && !!confirmPassword && !!eventCode) {
      e.preventDefault()
      onSubmit(firstName, lastName, email, password, eventCode)
    }
  }

  return (
    <form onSubmit={(e) => validateAndSubmit(e)} className={classes.root}>
      <div className={classes.headerWrapper}>
        {label ? (
          <Typography variant={'h4'}>{label}</Typography>
        ) : (
          <Typography variant={'h4'} data-testid='title'>
            {t('authPages.registerContainer.title')}
          </Typography>
        )}
      </div>
      {firstNameDisplay ? (
        <FormTextField
          mandatory={firstNameMandatory}
          id='register-form-first-name'
          value={firstName}
          isPasswordField={false}
          autoComplete={'firstname'}
          error={!firstNameValid}
          inputProps={{
            maxLength: 2000,
          }}
          title={firstNameLabel ? firstNameLabel : t('authPages.registerForm.firstName.header')}
          placeholder={t('authPages.registerForm.firstName.placeholder')}
          onChange={(e) => validateAndSet(e, firstNameMandatory, setFirstName, setFirstNameValid)}
          errorMessage={t('authPages.registerForm.firstName.errorMessage')}
        />
      ) : (
        <Typography></Typography>
      )}
      {lastNameDisplay ? (
        <FormTextField
          mandatory={lastNameMandatory}
          id='register-form-last-name'
          value={lastName}
          isPasswordField={false}
          autoComplete={'lastname'}
          error={!lastNameValid}
          inputProps={{
            maxLength: 2000,
          }}
          title={lastNameLabel ? lastNameLabel : t('authPages.registerForm.lastName.header')}
          placeholder={t('authPages.registerForm.lastName.placeholder')}
          onChange={(e) => validateAndSet(e, lastNameMandatory, setLastName, setLastNameValid)}
          errorMessage={t('authPages.registerForm.lastName.errorMessage')}
        />
      ) : (
        <Typography></Typography>
      )}
      <FormTextField
        id='register-form-email'
        value={email}
        isPasswordField={false}
        autoComplete={'email'}
        error={!emailValid}
        inputProps={{
          maxLength: 2000,
        }}
        title={emailLabel ? emailLabel : t('authPages.registerForm.email.header')}
        placeholder={t('authPages.registerForm.email.placeholder')}
        onChange={(e) => validateAndSet(e, true, setEmail, setEmailValid)}
        errorMessage={t('authPages.registerForm.email.errorMessage')}
      />
      <FormTextField
        id='register-form-password'
        value={password}
        isPasswordField={true}
        autoComplete={'password'}
        error={!passwordValid}
        inputProps={{
          maxLength: 2000,
        }}
        title={t('authPages.registerForm.password.header')}
        placeholder={t('authPages.registerForm.password.placeholder')}
        onChange={(e) => validateAndSet(e, true, setPassword, setPasswordValid)}
        errorMessage={t('authPages.registerForm.password.errorMessage')}
      />
      <FormTextField
        id='register-form-confirm-password'
        value={confirmPassword}
        isPasswordField={true}
        autoComplete={'confirmPassword'}
        error={!confirmPasswordValid}
        inputProps={{
          maxLength: 2000,
        }}
        title={t('authPages.registerForm.confirmPassword.header')}
        placeholder={t('authPages.registerForm.confirmPassword.placeholder')}
        onChange={(e) => validateConfirmPassword(e, setConfirmPassword, setConfirmPasswordValid)}
        errorMessage={!confirmPasswordValid ? t(confirmPasswordMsg) : undefined}
      />
      {!eventCodeFromQuery ? (
        <FormTextField
          id='register-form-event-code'
          value={eventCode}
          isPasswordField={false}
          autoComplete={'eventCode'}
          error={!eventCodeValid}
          inputProps={{
            maxLength: 2000,
          }}
          title={t('authPages.registerForm.eventCode.header')}
          placeholder={t('authPages.registerForm.eventCode.placeholder')}
          onChange={(e) => validateAndSet(e, true, setEventCode, setEventCodeValid)}
          errorMessage={
            !eventCodeValid ? t('authPages.registerForm.eventCode.errorMessage') : undefined
          }
        />
      ) : (
        <Typography></Typography>
      )}
      {errorResponse && (
        <Typography variant='subtitle2' className={classes.errorStatus}>
          {errorResponse}
        </Typography>
      )}
      <ShadowedButton
        text={t('authPages.registerForm.button.register')}
        buttonStyle={'default'}
        wrapperClasses={classes.button}
        disabled={!(firstName && lastName && email && password && confirmPassword)}
        onClick={(e) => validateAndSubmit(e)}
        id='register-form-submit'
      ></ShadowedButton>
    </form>
  )
}
export default RegisterForm
