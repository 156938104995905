import { makeStyles, Theme } from '@material-ui/core/styles'
import { MenuDrawer, MenuHeader } from '../../Common/common-index'
import React, { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import ChangePassword from '../../Components/ProfileSettings/ChangePassword'
import { useAppDispatch } from '../../Reducers/hooks'
import { MenuPages, switchMenuPage, toggleMenu, updateIsSideMenuOpen } from '../Menu/MenuSlice'
import { ProfileSettingPages, switchProfilePage } from './profileSettingsSlice'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(0, 4),
    height: '100%',
  },
  paperContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
}))

export const ChangePasswordContainer: FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(updateIsSideMenuOpen(true))
  }, [])

  // Header Actions
  const OnCloseClick = () => {
    dispatch(toggleMenu(false))
    dispatch(updateIsSideMenuOpen(false))
    dispatch(switchMenuPage(MenuPages.Menu))
    dispatch(switchProfilePage(ProfileSettingPages.ProfileSettingsHome))
  }

  const OnGoBack = () => {
    dispatch(switchProfilePage(ProfileSettingPages.ProfileSettingsHome))
  }

  return (
    <form className={classes.paperContainer}>
      <MenuDrawer
        isOpen={true}
        drawerHeader={
          <MenuHeader
            title={t('profileSettingsPage.menu.changePassword')}
            onGoBack={OnGoBack}
            onClose={OnCloseClick}
          />
        }
      >
        <div className={classes.container}>
          <ChangePassword />
        </div>
      </MenuDrawer>
    </form>
  )
}
export default ChangePasswordContainer
