import React, { useEffect } from 'react'
import { getAllUserChats } from '../../Services/Services/conversations.service'
import { useAppDispatch, useAppSelector } from '../../Reducers/hooks'
import { updateIsSideMenuOpen, toggleMenu, switchMenuPage, MenuPages } from '../Menu/MenuSlice'
import { switchProfilePage, ProfileSettingPages } from './profileSettingsSlice'
const ExportChatMessages = () => {
  const currentUserId = useAppSelector((state) => state.auth.currentUser?.id)

  useEffect(() => {
    const getUserChats = async () => {
      const messages = await getAllUserChats(currentUserId)
      const blob = new Blob([messages?.data], { type: 'csv/plain' })
      const url = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.download = 'private_chats.csv'
      link.href = url
      link.click()
      OnCloseClick()
      OnGoBack()
    }
    getUserChats().catch(console.error)
  }, [])

  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(updateIsSideMenuOpen(true))
  }, [])

  // Header Actions
  const OnCloseClick = () => {
    dispatch(toggleMenu(false))
    dispatch(updateIsSideMenuOpen(false))
    dispatch(switchMenuPage(MenuPages.Menu))
    dispatch(switchProfilePage(ProfileSettingPages.ProfileSettingsHome))
  }
  const OnGoBack = () => {
    dispatch(switchProfilePage(ProfileSettingPages.ProfileSettingsHome))
  }

  return <></>
}

export default ExportChatMessages
