import React, { FC } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Slide from '@material-ui/core/Slide'
import { useTranslation } from 'react-i18next'
import { Icon } from '../../Common/common-index'

interface Params {
  isNotificationPublished?: boolean
  isSubmissionSuccessful?: boolean
  isSubmissionFailed?: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
  paperContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
    outline: 'none',
    width: '100%',
  },
  submitInformation: {
    padding: theme.spacing(4),
    textAlign: 'center',
  },
  messageTitle: {
    fontFamily: 'Graphik',
    fontSize: '18px',
    fontWeight: 'bold',
    margin: theme.spacing(4, 0),
  },
  messageDescription: {
    fontFamily: 'Graphik',
    fontSize: '15px',
    color: theme.palette.grey[200],
  },
  iconWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  successIcon: {
    '& svg': {
      maxHeight: '93px !important',
      maxWidth: '93px !important',
      minHeight: '93px !important',
      minWidth: '93px !important',
    },
  },
  errorIcon: {
    '& svg': {
      maxHeight: '105px !important',
      maxWidth: '105px !important',
      minHeight: '105px !important',
      minWidth: '105px !important',
    },
  },
}))

export const NotificationSubmitMessage: FC<Params> = ({
  isNotificationPublished,
  isSubmissionSuccessful,
  isSubmissionFailed,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Slide direction='left' in={isNotificationPublished} mountOnEnter unmountOnExit>
      <div className={classes.paperContainer}>
        <div
          className={
            isSubmissionSuccessful
              ? `${classes.iconWrapper} ${classes.successIcon}`
              : `${classes.iconWrapper} ${classes.errorIcon}`
          }
        >
          <Icon
            iconName={isSubmissionSuccessful ? 'successCheck' : 'errorCross'}
            iconSize={isSubmissionSuccessful ? '93' : '105'}
          />
        </div>
        <div className={classes.submitInformation}>
          {isSubmissionSuccessful && (
            <>
              <Typography variant={'h2'} className={classes.messageTitle}>
                {t('eventNotification.successMessage.title')}
              </Typography>
              <Typography variant={'body1'} className={classes.messageDescription}>
                {t('eventNotification.successMessage.description')}
              </Typography>
            </>
          )}
          {isSubmissionFailed && (
            <>
              <Typography variant={'h2'} className={classes.messageTitle}>
                {t('eventNotification.failureMessage.title')}
              </Typography>
              <Typography variant={'body1'} className={classes.messageDescription}>
                {t('eventNotification.failureMessage.description')}
              </Typography>
            </>
          )}
        </div>
      </div>
    </Slide>
  )
}

export default NotificationSubmitMessage
