import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { fetchProfilePictureAsync } from '../Containers/UserAvatar/UserAvatarSlice'
import { User, userService } from '../Services/services-index'

export interface UserState {
  userList: {
    [userId: string]: User
  }
  usersInEvent: User[]
  loadingQueue: number[]
}

const initialState: UserState = {
  userList: {},
  usersInEvent: [],
  loadingQueue: [],
}

export const fetchUserSummaryAsync = createAsyncThunk(
  'user/fetchUserSummaryAsync',
  async (userId: number, { dispatch, getState }) => {
    const state: any = getState()
    try {
      const queueSet = new Set<number>(state.user.loadingQueue)

      if (queueSet.has(userId)) {
        return
      }
      queueSet.add(userId)
      dispatch(updateLoadingQueue(Array.from(queueSet)))

      const response = await userService.getUserSummary(userId)
      const userSummary = response?.data
      if (userSummary) {
        dispatch(
          fetchProfilePictureAsync({
            userId: userSummary.id,
            profilePicturePath: userSummary.profile_picture,
          })
        )
        if (Object.keys(state.user.userList).length > 0) {
          dispatch(updateUserList({ ...state.user.userList, [userSummary.id]: userSummary }))
        } else {
          dispatch(updateUserList({ [userSummary.id]: userSummary }))
        }
      }

      const newQueueSet = new Set<number>((getState() as any).user.loadingQueue)
      newQueueSet.delete(userId)
      dispatch(updateLoadingQueue(Array.from(newQueueSet)))
    } catch (err: any) {
      throw new Error(err.message)
    }
  }
)

export const fetchUserSummariesAsync = createAsyncThunk(
  'user/fetchUserSummariesAsync',
  async (userIds: number[], { dispatch, getState }) => {
    const state: any = getState()
    try {
      const ids: number[] = []
      const queueSet = new Set<number>(state.user.loadingQueue)

      userIds.forEach((id) => {
        if (queueSet.has(id)) {
          return
        }
        ids.push(id)
        queueSet.add(id)
      })
      dispatch(updateLoadingQueue(Array.from(queueSet)))
      if (!ids.length) {
        return
      }

      const response = await userService.getUsersSummary(ids)
      const userListArray = response.data
      const userList = userListArray.reduce(
        (obj: any, item: any) => Object.assign(obj, { [item.id]: { ...item } }),
        {}
      )

      dispatch(updateUserList(userList))
      ids.forEach((o) => queueSet.delete(o))
      dispatch(updateLoadingQueue(Array.from(queueSet)))
    } catch (err: any) {
      throw new Error(err.message)
    }
  }
)

export const updateProfilePictureOfUserAsync = createAsyncThunk(
  'user/updateProfilePictureOfUserAsync',
  async (
    { userId, profilePicturePath }: { userId: number; profilePicturePath: string },
    { getState, dispatch }
  ) => {
    const state: any = getState()
    try {
      const changedUser = state.user.userList[userId]
      if (changedUser) {
        dispatch(
          fetchProfilePictureAsync({
            userId,
            profilePicturePath,
          })
        )
        dispatch(
          updateUserList({
            ...state.user.userList,
            [changedUser.id]: {
              ...changedUser,
              profile_picture: profilePicturePath,
            },
          })
        )
      }
    } catch (err: any) {
      throw new Error(err.message)
    }
  }
)

export const fetchUsersInRoom = createAsyncThunk(
  'user/fetchUsersInEventAsync',
  async (roomId: number, { dispatch }) => {
    try {
      const response = await userService.getUsersInRoom(roomId)
      const usersInEvent = response.data.users
      const userIds: number[] = []

      usersInEvent.slice(0, 20).forEach((u: User) => userIds.push(u.id as number))
      await dispatch(fetchUserSummariesAsync(userIds))
      dispatch(updateUsersInEvent(usersInEvent))
    } catch (err: any) {
      throw new Error(err.message)
    }
  }
)

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateUserList: (state, action: PayloadAction<any>) => {
      state.userList = {
        ...action.payload,
      }
    },
    updateUsersInEvent: (state, action: PayloadAction<User[]>) => {
      state.usersInEvent = action.payload
    },
    updateLoadingQueue: (state, action: PayloadAction<number[]>) => {
      state.loadingQueue = [...action.payload]
    },
  },
})

export const { updateUserList, updateUsersInEvent, updateLoadingQueue } = userSlice.actions

export default userSlice.reducer
