import React, { FC } from 'react'
import { useHistory } from 'react-router-dom'

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import { Icon, ShadowedButton } from '../../../Common/common-index'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      alignItems: 'flex-start',
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      [theme.breakpoints.up('md')]: {
        alignItems: 'center',
      },
      '& .MuiTextField-root': {
        boxSizing: 'border-box',
        justifyContent: 'center',
        margin: theme.spacing(1, 0),
        padding: theme.spacing(0, 3),
        width: '100%',
        [theme.breakpoints.up('md')]: {
          padding: theme.spacing(0),
          width: '400px',
          alignItems: 'center',
        },
      },
    },
    header: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      lineHeight: '32px',
      margin: theme.spacing(5, 0),
      [theme.breakpoints.up('md')]: {
        width: '400px',
      },
    },
    contentWrapper: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center',
      [theme.breakpoints.up('md')]: {
        width: '400px',
      },
    },
    messageDescription: {
      fontFamily: 'Graphik',
      fontSize: '16px',
      fontWeight: 'bold',
      lineHeight: '24px',
    },
    successIcon: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: '40px',
      '& svg': {
        maxHeight: '93px !important',
        maxWidth: '93px !important',
        minHeight: '93px !important',
        minWidth: '93px !important',
      },
    },
    button: {
      marginTop: theme.spacing(5),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        maxWidth: '400px',
      },
    },
  })
)

interface passwordResetProps {
  title: string | undefined
  successMessage?: string | undefined
  buttonName?: string | undefined
}

const SuccessConfirmationForm: FC<passwordResetProps> = ({ title, successMessage, buttonName }) => {
  const classes = useStyles()
  const history = useHistory()

  return (
    <form className={classes.root}>
      <div className={classes.contentWrapper}>
        <Typography className={classes.header} variant={'h4'}>
          {title}
        </Typography>
        <div className={classes.successIcon}>
          <Icon iconName={'successCheck'} iconSize={'105'} />
        </div>
        <Typography
          variant={'subtitle1'}
          data-testid='title'
          className={classes.messageDescription}
        >
          {successMessage}
        </Typography>
      </div>
      <ShadowedButton
        id='password-reset-confirmation-login'
        text={buttonName}
        buttonStyle={'default'}
        wrapperClasses={classes.button}
        onClick={() => history.push('/auth/login')}
      ></ShadowedButton>
    </form>
  )
}
export default SuccessConfirmationForm
