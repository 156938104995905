import React, { FC } from 'react'

import { createStyles, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'

import UserAvatar from '../../Containers/UserAvatar/UserAvatar'
import { BusinessCard } from '../../Containers/BusinessCards/businessCardsSlice'

interface Props {
  // eslint-disable-next-line no-unused-vars
  updateSelectedCard: (businessCard: BusinessCard) => void
  allBusinessCards: BusinessCard[]
  userProfilePictures: { [p: number]: string }
}
const useStyles = makeStyles(() =>
  createStyles({
    gridContainer: { marginTop: '20px' },
    card: {
      width: '100%',
      marginTop: '20px',
      borderRadius: '0',
      '& button': {
        display: 'flex',
        flexDirection: 'row',
      },
    },
    actionArea: {
      width: '100%',
      height: '150px',
      '& img': { height: '150px', width: '150px' },
    },
    textArea: { flex: 1 },
  })
)

export const BusinessCardsComponent: FC<Props> = ({
  updateSelectedCard,
  userProfilePictures,
  allBusinessCards,
}) => {
  const classes = useStyles()

  return (
    <Grid
      container
      direction='column'
      justifyContent='center'
      alignItems='center'
      className={classes.gridContainer}
    >
      {allBusinessCards[0] &&
        allBusinessCards.map((businessCard: BusinessCard, index: any) => (
          <Card className={classes.card} elevation={5} key={index}>
            <CardActionArea
              className={classes.actionArea}
              onClick={() => updateSelectedCard(businessCard)}
            >
              {userProfilePictures && (
                <UserAvatar
                  userId={businessCard.user_id}
                  isSquare={true}
                  height={150}
                  width={150}
                />
              )}
              <CardContent className={classes.textArea}>
                <Typography gutterBottom variant='h5' component='h2'>
                  {businessCard.firstname + ' ' + businessCard.lastname}
                </Typography>
                <Typography variant='body2' color='textPrimary' component='p'>
                  {businessCard.job_title}
                </Typography>
                <Typography variant='body2' color='textSecondary' component='p'>
                  {businessCard.company}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        ))}
    </Grid>
  )
}
