import { Socket } from 'socket.io-client'
import {
  MessageBasicPayload,
  MessageTypes,
  MessageActions,
  messagingService,
} from '../Services/services-index'
import incomingMessageSound from './../Assets/audios/ChatIncomingMessageSound.mp3'

// To play sound notification for chat incoming messages
const playSoundNotification = () => {
  const audio = new Audio(incomingMessageSound)
  if (audio) {
    audio.preload = 'auto'
    audio.autoplay = true
    audio.controls = true
    audio.play()
  }
  return
}

const startConversation = (
  socket: Socket,
  socketTopic: string,
  currentUserId: number,
  receiverIds: number[],
  botId?: number
) => {
  const messagePayload: MessageBasicPayload = {
    from: currentUserId,
    to: [...receiverIds],
    timestamp: new Date().getTime().toString(),
    type: MessageTypes.text,
    action: MessageActions.start,
  }

  if (botId) {
    messagePayload.bot = botId
  }

  if (socketTopic.indexOf('chat_') < 0) {
    throw Error('the socket topic is invalid')
  }

  messagingService.emitMessagingSocket(socketTopic, messagePayload, socket)
}

const sendMessage = (
  socket: Socket,
  socketTopic: string,
  currentUserId: number,
  receiverIds: number[],
  message: string,
  roomId?: number,
  botId?: number
) => {
  const messagePayload: MessageBasicPayload = {
    from: currentUserId,
    to: [...receiverIds],
    timestamp: new Date().getTime().toString(),
    type: MessageTypes.text,
    message,
  }

  if (botId) {
    messagePayload.bot = botId
  }

  if (roomId) {
    messagePayload.room = roomId
  }

  messagingService.emitMessagingSocket(socketTopic, messagePayload, socket)
}

const formatUserItemDescription = (
  showCompany: boolean,
  showJobTitle: boolean,
  company: string | undefined,
  jobTitle: string | undefined
) => {
  let description = ''
  if (showCompany && company) {
    description += company
  }

  if (showJobTitle && jobTitle) {
    if (description.length > 0) {
      description += ' — '
    }
    description += jobTitle
  }

  return description
}

export { playSoundNotification, startConversation, sendMessage, formatUserItemDescription }
