import React, { FC, useEffect, useState } from 'react'
import Slide from '@material-ui/core/Slide'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { ConfirmationDialog, Icon, MenuListItem } from '../../Common/common-index'
import {
  ProfileSettingPages,
  switchProfilePage,
} from '../../Containers/ProfileSettings/profileSettingsSlice'
import { useAppDispatch } from '../../Reducers/hooks'
import { MenuList } from '@material-ui/core'
import { createHtmlIdString } from '../../Utils/utils'

interface Props {
  errorMessage?: string
  deleteAccount?: () => void
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  settingsRow: {
    verticalAlign: 'middle',
    height: '80px',
    display: 'flex',
    alignItems: 'center',
  },
}))

export const ProfileSettingsMenu: FC<Props> = ({ errorMessage, deleteAccount }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false)

  useEffect(() => {}, [deleteDialogOpen])

  const profileMenuItems = [
    {
      name: t('profileSettingsPage.menu.changeStatus'),
      icon: <Icon iconName={'statusChange'} iconSize={'25px'} />,
      onClick: () => dispatch(switchProfilePage(ProfileSettingPages.ChangeStatusPage)),
    },
    {
      name: t('profileSettingsPage.menu.changeAppearance'),
      icon: <Icon iconName={'changeAvatar'} iconSize={'25px'} />,
      onClick: () => dispatch(switchProfilePage(ProfileSettingPages.AppearanceSettingsPage)),
    },
    {
      name: t('profileSettingsPage.menu.profileInformation'),
      icon: <Icon iconName={'profileInfo'} iconSize={'25px'} />,
      onClick: () => dispatch(switchProfilePage(ProfileSettingPages.ProfileInformationPage)),
    },
    {
      name: t('profileSettingsPage.menu.skills'),
      icon: <Icon iconName={'skills'} iconSize={'25px'} />,
      onClick: () => dispatch(switchProfilePage(ProfileSettingPages.SkillsPage)),
    },
    {
      name: t('profileSettingsPage.menu.interests'),
      icon: <Icon iconName={'interests'} iconSize={'25px'} />,
      onClick: () => dispatch(switchProfilePage(ProfileSettingPages.InterestsPage)),
    },
    {
      name: t('profileSettingsPage.menu.changePassword'),
      icon: <Icon iconName={'passwordChange'} iconSize={'25px'} />,
      onClick: () => dispatch(switchProfilePage(ProfileSettingPages.ChangePasswordpage)),
    },
    {
      name: t('profileSettingsPage.menu.exportPrivateChats'),
      icon: <Icon iconName={'ExportPrivateChat'} iconSize={'25px'} />,
      onClick: () => dispatch(switchProfilePage(ProfileSettingPages.ExportPrivateChatsPage)),
    },
    {
      name: t('profileSettingsPage.menu.deleteAccount'),
      icon: <Icon iconName={'deleteAccount'} iconSize={'25px'} />,
      onClick: () => setDeleteDialogOpen(!deleteDialogOpen),
    },
  ]

  // Display the profile menu items
  const renderMenuItems = () => {
    return profileMenuItems?.map((item: any) => {
      return (
        <div className={classes.settingsRow} key={item.name}>
          <MenuListItem
            id={createHtmlIdString('profile-settings-menu-item-', item.name)}
            text={item.name}
            startIcon={item.icon}
            onClick={item.onClick}
          />
        </div>
      )
    })
  }

  // To delete active user account
  const onAccountDelete = () => {
    return (
      // The following "div" was used to overcome the Fragment issues
      <div>
        <ConfirmationDialog
          titleText={t('profileSettingsPage.deleteUser.dialog.title')}
          contentText={t('profileSettingsPage.deleteUser.dialog.message')}
          cancelText={t('profileSettingsPage.deleteUser.dialog.cancel')}
          confirmText={t('profileSettingsPage.deleteUser.dialog.confirm')}
          open={deleteDialogOpen}
          errorMessage={errorMessage}
          onConfirm={() => deleteAccount}
          onCancel={() => setDeleteDialogOpen(false)}
        />
      </div>
    )
  }

  return (
    <Slide direction='left' in={true} mountOnEnter unmountOnExit>
      <MenuList className={classes.container}>
        {renderMenuItems()}
        {onAccountDelete()}
      </MenuList>
    </Slide>
  )
}
export default ProfileSettingsMenu
