import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { userService } from '../../Services/services-index'
export interface UserAvatarState {
  profilePictureList: {
    [userId: number]: string
  }
  imageBase64List: {
    [path: string]: string
  }
  loadingQueue: number[]
}

const initialState: UserAvatarState = {
  profilePictureList: {},
  imageBase64List: {},
  loadingQueue: [],
}

export const imageFileReader = (image: Blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => resolve(reader.result)
    reader.onerror = reject
    reader.readAsDataURL(image)
  })
}

export const fetchProfilePictureAsync = createAsyncThunk(
  'userAvatar/fetchProfilePictureAsync',
  async (
    { userId, profilePicturePath }: { userId: number; profilePicturePath: string },
    { dispatch, getState }
  ) => {
    try {
      const { userAvatar } = getState() as any
      const queueSet = new Set<number>(userAvatar.loadingQueue)
      if (queueSet.has(userId)) {
        return
      } else {
        dispatch(updateLoadingQue(Array.from(queueSet.add(userId))))
      }
      let imageBase64: any = ''
      imageBase64 = userAvatar.imageBase64List[profilePicturePath]
      if (imageBase64) {
        dispatch(updateUserAvatarList({ userId, imageBase64 }))
      } else {
        const response = await userService.getProfilePicture(profilePicturePath)
        imageBase64 = (await imageFileReader(response?.data)) as string
        if (imageBase64) {
          dispatch(updateImageBase64List({ profilePicturePath, imageBase64 }))
          dispatch(updateUserAvatarList({ userId, imageBase64 }))
        }
      }
      const newQueueSet = new Set<number>((getState() as any).userAvatar.loadingQueue)
      newQueueSet.delete(userId)
      dispatch(updateLoadingQue(Array.from(newQueueSet)))
    } catch (err: any) {
      throw new Error(err.message)
    }
  }
)

export const userAvatarSlice = createSlice({
  name: 'userAvatar',
  initialState,
  reducers: {
    updateUserAvatarList: (
      state,
      action: PayloadAction<{ userId: number; imageBase64: string }>
    ) => {
      state.profilePictureList = {
        ...state.profilePictureList,
        [action.payload.userId]: action.payload.imageBase64,
      }
    },
    updateImageBase64List: (
      state,
      action: PayloadAction<{ profilePicturePath: string; imageBase64: string }>
    ) => {
      state.imageBase64List = {
        ...state.imageBase64List,
        [action.payload.profilePicturePath]: action.payload.imageBase64,
      }
    },
    updateLoadingQue: (state, action: PayloadAction<number[]>) => {
      state.loadingQueue = [...action.payload]
    },
  },
})

export const { updateUserAvatarList, updateImageBase64List, updateLoadingQue } =
  userAvatarSlice.actions

export default userAvatarSlice.reducer
