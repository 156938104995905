import React, { FC, ReactElement } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import { Grid } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '0px',
      height: '0px',
    },
    drawerPaper: {
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        minWidth: '100%',
      },
      width: 400,
      backgroundColor: theme.palette.background.paper,
    },
    fullMode: {
      width: '100%',
    },
    customAnchor: {
      '& div[class*="MuiDrawer-paperAnchorLeft"]': {
        left: '400px !important',
      },
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: 'center',
      position: 'relative',
      flex: 0,
      minHeight: '80px',
    },
    footer: {
      minHeight: '80px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexBasis: 'auto',
    },
    container: {
      height: '100%',
      flexWrap: 'nowrap',
      overflow: 'hidden',
      overflowX: 'hidden',
    },
    content: {
      flex: 2,
      overflow: 'auto',
      overflowX: 'hidden',
      msOverflowStyle: 'none',
      fontFamily: 'Graphik',
      backgroundColor: theme.palette.background.paper,
    },
    fullList: {
      width: 'auto',
    },
  })
)

export enum DrawerPositions {
  // eslint-disable-next-line no-unused-vars
  LEFT = 'left',
  // eslint-disable-next-line no-unused-vars
  RIGHT = 'right',
}

export interface MenuDrawerProps {
  isOpen?: boolean
  isFullMode?: boolean
  drawerPosition?: DrawerPositions
  drawerHeader?: ReactElement | null
  drawerFooter?: ReactElement | null
  children?: any
  onClose?: () => void
}

export const MenuDrawer: FC<MenuDrawerProps> = ({
  isOpen = false,
  isFullMode = false,
  drawerPosition = DrawerPositions.RIGHT,
  drawerHeader,
  drawerFooter,
  children,
  onClose = () => {},
}) => {
  const classes = useStyles()

  return (
    <Drawer
      id='menu-drawer'
      open={isOpen}
      anchor={drawerPosition}
      variant='persistent'
      classes={{
        paper: isFullMode ? `${classes.drawerPaper} ${classes.fullMode}` : classes.drawerPaper,
        root: classes.root,
      }}
      onClose={onClose}
      ModalProps={{ onClose }}
    >
      <Grid container direction={'column'} className={classes.container}>
        {drawerHeader && (
          <Grid item className={classes.header} xs={12}>
            {drawerHeader}
          </Grid>
        )}
        {children && (
          <Grid item className={classes.content} xs={12}>
            {children}
          </Grid>
        )}
        {drawerFooter && (
          <Grid item className={classes.footer} xs={12}>
            {drawerFooter}
          </Grid>
        )}
      </Grid>
    </Drawer>
  )
}
