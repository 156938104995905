import {
  Box,
  createStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  makeStyles,
  Paper,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import React, { FC } from 'react'
import JitsiComponent from '../../Components/Jitsi/JitsiComponent'
import Draggable from 'react-draggable'
import { ResizableBox, ResizeHandle } from 'react-resizable'
import 'react-resizable/css/styles.css'
import { Icon, theme } from '../../Common/common-index'

const useStyles = makeStyles(() =>
  createStyles({
    paper: {
      zIndex: 1000,
      borderRadius: '8px',
      width: 'fit-content',
      height: 'fit-content',
      padding: 0,
      margin: 0,
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
    dialogHeader: {
      cursor: 'move',
      backgroundColor: theme.palette.primary.main,
      color: '#FFFFFF',
      borderRadius: '10px 10px 0 0',
    },
    dialogContent: {
      padding: 0,
      margin: 0,
      flexGrow: 1,
    },
    dialogContainer: {
      display: 'flex',
      flexDirection: 'column',
      height: 'calc(100% - 40px)',
      backgroundColor: theme.palette.background.paper,
      margin: '20px',
      borderRadius: '10px',
      boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
    },
    resizeHandle: {
      color: theme.palette.primary.main,
      position: 'absolute',
      height: '30px',
      width: '30px',
    },
  })
)

function PaperComponent(props: any) {
  return (
    <Draggable handle='.draggable-dialog-title' cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export interface JitsiContainerProps {
  conversationId: string
  jitsiJwtToken: string
  audioOnly: boolean
  handleClose: () => void
}

export interface IconProps {
  handleAxis?: ResizeHandle
}

const CustomHandle = React.forwardRef<HTMLDivElement, IconProps>((props: IconProps, ref) => {
  const { handleAxis, ...restProps } = props
  const classes = useStyles()
  return (
    <div className={`react-resizable-handle-${handleAxis} ${classes.resizeHandle}`}>
      <Icon
        {...restProps}
        iconName={'corner'}
        svgIconProps={{
          style: { color: 'primary' },
          ref,
          ...restProps,
        }}
        iconSize='22px'
      />
    </div>
  )
})

const JitsiContainer: FC<JitsiContainerProps> = ({
  conversationId,
  audioOnly,
  jitsiJwtToken,
  handleClose,
}) => {
  const theme = useTheme()
  const classes = useStyles()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Dialog
      fullScreen={fullScreen}
      hideBackdrop
      disableEnforceFocus
      classes={{
        paper: classes.paper,
      }}
      maxWidth='xl'
      aria-labelledby='draggable-dialog-title'
      open={true}
      PaperComponent={PaperComponent}
    >
      <ResizableBox
        width={window.innerWidth * 0.6}
        height={window.innerHeight * 0.6}
        minConstraints={[window.innerWidth * 0.6, window.innerHeight * 0.6]}
        maxConstraints={[window.innerWidth, window.innerHeight]}
        handle={<CustomHandle />}
        resizeHandles={['sw', 'nw', 'se', 'ne']}
      >
        <Box className={classes.dialogContainer}>
          <DialogTitle
            className={`${classes.dialogHeader} draggable-dialog-title`}
            style={{ cursor: 'move' }}
          />
          <DialogContent className={classes.dialogContent}>
            <JitsiComponent
              conversationId={conversationId}
              jitsiJwtToken={jitsiJwtToken}
              audioOnly={audioOnly}
              handleClose={handleClose}
            ></JitsiComponent>
          </DialogContent>
        </Box>
      </ResizableBox>
    </Dialog>
  )
}
export default JitsiContainer
