/* eslint-disable no-unused-vars */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { updateUserList } from '../../Reducers/userSlice'
import { Conversation, MessagePayload, conversationsService } from '../../Services/services-index'

export enum ChatPages {
  ChatList,
  ChatMessage,
  ChatNewConversation,
  ChatParticipantList,
}

interface GroupState {
  isGroup: boolean
  count: number
}
export interface MenuState {
  isLoadingHistoryConversations: boolean
  conversations: { [conversationId: string]: Conversation } | null
  messages: { [messageId: string]: MessagePayload } | null
  activeConversationId: string
  activeConversation: Conversation
  addNewUsersIsActive: boolean
  groupConversation: GroupState
  activeChatPage: ChatPages
}

const initialState: MenuState = {
  isLoadingHistoryConversations: true,
  conversations: null,
  messages: null,
  activeConversationId: '',
  activeConversation: {} as Conversation,
  addNewUsersIsActive: false,
  groupConversation: {
    isGroup: false,
    count: 0,
  },
  activeChatPage: ChatPages.ChatList,
}

export const fetchHistoryConversations = createAsyncThunk(
  'chat/fetchHistoryConversations',
  async (userId: number, { dispatch }) => {
    const response = await conversationsService.getUserConversations(userId)
    const { conversations } = response.data
    const userIds: Set<number> = new Set()
    const userListUpdate = {}

    for (const [key, value] of Object.entries(conversations) as any) {
      const { last_message: lastMessage, room, participants, isUnread } = value

      participants.forEach((p: any) => {
        userIds.add(p.id)
        userListUpdate[p.id] = p
      })

      const isRoomConversation = key.indexOf('room_') > -1
      const isGroupConversation = participants.length > 2 && !isRoomConversation
      const conversationListItem: Conversation = {
        participants: [],
        lastMessage: lastMessage || {},
        isTemporary: false,
        isRoomConversation,
        isGroupConversation,
        isUnread: isUnread ? isUnread : false,
      }

      if (isRoomConversation) {
        conversationListItem.roomId = room?.room_id
        conversationListItem.roomName = room?.name
        conversationListItem.participants = [...participants]
      }

      if (!isRoomConversation) {
        const bot = lastMessage.bot
        if (bot) {
          conversationListItem.bot = bot
        }
        conversationListItem.participants = [...participants]
      }

      conversations[key] = conversationListItem

      const res: any = await conversationsService.getConversationMessages(key)
      const { messages, page_state } = res.data

      conversations[key].messages = messages
      conversations[key].page_state = page_state
    }

    dispatch(updateUserList(userListUpdate))

    return conversations
  }
)

export const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    updateConversations: (
      state,
      action: PayloadAction<{ [conversationId: string]: Conversation }>
    ) => {
      if (state.conversations === null) {
        state.conversations = action.payload
      } else {
        state.conversations = { ...state.conversations, ...action.payload }
      }
    },
    updateActiveConversationId: (state, action: PayloadAction<string>) => {
      state.activeConversationId = action.payload
    },
    updateActiveConversation: (state, action: PayloadAction<Conversation>) => {
      state.activeConversation = action.payload
    },
    updateAddNewUsersIsActive: (state, action: PayloadAction<boolean>) => {
      state.addNewUsersIsActive = action.payload
    },
    updateGroupConversaiton: (state, action: PayloadAction<GroupState>) => {
      state.groupConversation = action.payload
    },
    switchChatPage: (state, action: PayloadAction<ChatPages>) => {
      state.activeChatPage = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchHistoryConversations.pending, (state) => {
        state.isLoadingHistoryConversations = true
      })
      .addCase(fetchHistoryConversations.fulfilled, (state, action) => {
        state.isLoadingHistoryConversations = false
        state.conversations = Object.assign({}, state.conversations, action.payload)
      })
  },
})

export const {
  updateConversations,
  switchChatPage,
  updateActiveConversationId,
  updateActiveConversation,
  updateAddNewUsersIsActive,
  updateGroupConversaiton,
} = chatSlice.actions

export default chatSlice.reducer
