import React, { FC } from 'react'

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import { useAppSelector } from '../../Reducers/hooks'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      bottom: '20px',
      position: 'absolute',
      right: '20px',
      zIndex: 1000,
      transition: 'all 0.4s ease-out',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    menuOpenState: {
      bottom: '20px',
      position: 'absolute',
      right: '420px',
    },
    errorStatus: {
      alignItems: 'center',
      color: '#b71c1c',
      display: 'flex',
      fontSize: '14px',
      height: '100%',
      justifyContent: 'center',
    },
  })
)

export interface DialogProps {
  errorMessage?: string
  buttonOn?: any
  buttonOff?: any
}

export const MiniMapDialog: FC<DialogProps> = ({ errorMessage, buttonOn, buttonOff }) => {
  const classes = useStyles()

  const isSideMenuOpen = useAppSelector((state) => state.menu.isSideMenuOpen)
  const miniMapResponse = useAppSelector((state) => state.miniMap.miniMapResponse)
  const mapImg = useAppSelector((state) => state.miniMap.mapImg)
  const mapVisible = useAppSelector((state) => state.settings.miniMap)

  return (
    <div className={isSideMenuOpen ? `${classes.paper} ${classes.menuOpenState}` : classes.paper}>
      {!mapVisible ? (
        <div>{buttonOn}</div>
      ) : (
        mapImg && (
          <>
            <div>
              <div>{buttonOff}</div>
              <img
                src={mapImg}
                alt={'event room map'}
                width={miniMapResponse?.width}
                height={miniMapResponse?.height}
                useMap={'#minimap'}
              />
            </div>
            <map name='minimap'>
              {miniMapResponse &&
                miniMapResponse?.room_coords_mapping.map((coordinate: any) => {
                  return (
                    <div key={coordinate.room_id}>
                      {coordinate && (
                        <area
                          id={'mini-map-area-' + coordinate.room_id ? coordinate.room_id + '' : ''}
                          shape={coordinate.shape}
                          coords={coordinate.coords}
                          href={'/room/' + coordinate.room_id}
                          alt={coordinate.room_name}
                          title={coordinate.room_name}
                        ></area>
                      )}
                    </div>
                  )
                })}
            </map>
            {errorMessage && (
              <Typography variant='body2' className={classes.errorStatus}>
                {errorMessage}
              </Typography>
            )}
          </>
        )
      )}
    </div>
  )
}
