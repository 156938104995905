import React, { FC, useEffect, useState } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { useLocation } from 'react-router-dom'
import { Box } from '@material-ui/core'
import { contentService, localStorageService } from '../../../Services/services-index'

const userStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      height: '100vh',
      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
      },
    },
    posterContainer: {
      flex: 1,
      backgroundPosition: 'center',
      backgroundColor: 'white',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
    },
    image: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
    formContainer: {
      flex: 4,
      overflowX: 'hidden',
      overflowY: 'auto',
      [theme.breakpoints.up('sm')]: {
        flex: 1,
      },
    },
  })
)

function useQuery() {
  return new URLSearchParams(decodeURI(useLocation().search))
}

const AuthLayout: FC = ({ children }) => {
  const classes = userStyles()
  const query = useQuery()
  const [posterImage, setPosterImage] = useState<string>()

  useEffect(() => {
    let eventCode = query.get('event')
    const accessCode = query.get('access_code')
    const ssoCode = query.get('code')

    if (eventCode == null && ssoCode === null) {
      localStorageService.clearLocalStorageItemValue('eventCode')
    } else {
      let storageEvent
      if (localStorageService.getLocalStorageItemValue('eventCode')) {
        storageEvent = JSON.parse(localStorageService.getLocalStorageItemValue('eventCode'))
      }
      eventCode = eventCode || storageEvent
    }

    if (accessCode == null && ssoCode === null) {
      localStorageService.clearLocalStorageItemValue('accessCode')
    }

    contentService.getPosterFile(eventCode).then((res) => {
      const image = URL.createObjectURL(res.data)
      setPosterImage(image)
    })
  }, [])

  return (
    <Box className={classes.container}>
      <Box className={classes.posterContainer} style={{ backgroundImage: `url(${posterImage})` }} />
      <Box className={classes.formContainer}>{children}</Box>
    </Box>
  )
}

export default AuthLayout
