import axios from 'axios'
import Config from '../../config.json'

const reactAppDomain = Config.VSF_K8S_API_URL
const apiVersion = Config.VSF_K8S_API_VERSION
const servicePrefix = `${reactAppDomain}/businesscard${apiVersion}`

export const sendBusinessCardRequest = (ofUserId: number, toUserId: number) => {
  const body = {
    of_user_id: ofUserId,
    to_user_id: toUserId,
  }
  return axios.post(`${servicePrefix}/businesscard`, body)
}

export const getBusinessCardInfo = (ofUserId: number, toUserId: number) => {
  return axios.get(`${servicePrefix}/businesscard/of/${ofUserId}/to/${toUserId}`)
}

export const checkBusinessCardApproved = (fromUserId: number, currentUserId: number) => {
  return axios.get(`${servicePrefix}/businesscard/of/${fromUserId}/to/${currentUserId}`)
}

export const approveBusinessCard = (cardId: number, editedNotes: string) => {
  return axios.patch(`${servicePrefix}` + '/businesscard/' + cardId, {
    approved: true,
    notes: editedNotes,
  })
}

export const UpdateBusinessCard = (cardId: number, approved?: boolean, notes?: string) => {
  if (!approved && !notes) throw new Error('approved or notes must be specified')
  const body = {}
  if (approved) Object.assign(body, approved)
  if (notes) Object.assign(body, notes)
  return axios.patch(`${servicePrefix}/businesscard/of/${cardId}`, body)
}

export const deleteBusinessCard = (cardId: number) => {
  return axios.delete(`${servicePrefix}/businesscard/${cardId}`)
}

export const getAllBusinessCards = (userId: number) => {
  return axios.get(`${servicePrefix}/businesscards/user/${userId}`)
}

export const exportAllBusinessCards = (userId: number) => {
  return axios.get(`${servicePrefix}/user/${userId}/businesscards/all`)
}

export const editBusinessCardNotes = (
  cardId: number,
  editedNotes: string,
  approvedValue: boolean
) => {
  return axios.patch(`${servicePrefix}/businesscard/` + cardId, {
    approved: approvedValue,
    notes: editedNotes,
  })
}
