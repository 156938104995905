import React, { FC } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles(() =>
  createStyles({
    description: {
      display: 'inline',
      fontSize: '16px',
      lineHeight: '22px',
    },
  })
)

export interface Props {
  text?: string
}

export const FormatText: FC<Props> = ({ text }) => {
  const classes = useStyles()

  // To formate the text as clickable links
  const formateText = (text: string) => {
    let messageFormatted
    if (/\[[ ]*([a-zA-Z0-9_]+)[ ]*\|[ ]*([a-zA-Z0-9_./-]+)[ ]*\]/g.test(text)) {
      messageFormatted = text.replace(
        /\[[ ]*([a-zA-Z0-9_]+)[ ]*\|[ ]*([a-zA-Z0-9_.:/-]+)[ ]*\]/g,
        '<a href="https://$2" target="_blank">$1</a>'
      )
    } else if (
      /\[[ ]*([a-zA-Z0-9_]+)[ ]*\|[ ]*(http:\/\/|https:\/\/)?([a-zA-Z0-9_.:/-]+)[ ]*\]/g.test(text)
    ) {
      messageFormatted = text.replace(
        /\[[ ]*([a-zA-Z0-9_]+)[ ]*\|[ ]*(http:\/\/|https:\/\/)?([a-zA-Z0-9_.:/-]+)[ ]*\]/g,
        '<a href="$2$3" target="_blank">$1</a>'
      )
    } else {
      messageFormatted = text
    }
    return (
      <Typography variant={'body2'} className={classes.description}>
        {messageFormatted}
      </Typography>
    )
  }

  return <>{text && formateText(text)}</>
}
