import React, { FC } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import ErrorForm from '../../../Components/Authentication/Error/ErrorForm'
interface errorProps {
  errorMessage?: string
  errorHint?: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    item: {
      width: '100%',
    },
    root: {
      '& div[class*="MuiGrid-spacing"]': {
        margin: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
          height: '100vh',
        },
      },
      '& div[class*="MuiGrid-item"]': {
        padding: '0 24px',
      },
    },
  })
)

const ErrorContainer: FC<errorProps> = ({ errorMessage, errorHint }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Grid container justifyContent='center' alignItems='center' direction='column' spacing={6}>
        <Grid item className={classes.item}>
          <ErrorForm errorMessage={errorMessage} errorHint={errorHint}></ErrorForm>
        </Grid>
        <Grid item className={classes.item}></Grid>
      </Grid>
    </div>
  )
}
export default ErrorContainer
