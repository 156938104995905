import React from 'react'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Grid, Typography } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import { Icon } from '../../Common/common-index'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    businessCardDetail: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      outline: 'none',
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
    },
    emptyConversations: {
      alignItems: 'center',
      backgroundColor: theme.palette.background.default,
      borderRadius: '50%',
      display: 'flex',
      height: '70px',
      justifyContent: 'center',
      padding: '32px',
      width: '70px',
    },
    text: {
      width: '80%',
      marginTop: theme.spacing(2),
    },
  })
)

export const EmptyBusinessCards: FC = ({}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <div className={classes.businessCardDetail}>
      <Grid container justifyContent={'center'} alignItems={'center'} className={classes.wrapper}>
        <Box className={classes.emptyConversations} component={'span'}>
          <Icon iconName={'businessCards'} iconSize={'65px'} />
        </Box>
        <Typography variant={'body2'} align={'center'} className={classes.text}>
          {t('businessCardsPage.emptyPage.info')}
        </Typography>
      </Grid>
    </div>
  )
}
export default EmptyBusinessCards
