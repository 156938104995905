import { Button, createStyles, makeStyles } from '@material-ui/core'
import React, { FC, MouseEventHandler } from 'react'
import { Icon } from '../../common-index'

export interface ButtonProps {
  onClick?: MouseEventHandler<HTMLButtonElement>
  id?: string
  classes?: string
  wrapperClasses?: string
  autoFocus?: boolean
  iconName?: string
  iconButtonProps?: any
  svgIconProps?: any
  iconWrapperClasses?: string
  title?: string
  disabled?: boolean
}

const useStyles = makeStyles(() =>
  createStyles({
    buttonWrapper: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
    },
    button: {
      backgroundColor: 'transparent',
      borderRadius: '100%',
      transition: 'all 0.3s ease-in-out',
      height: '56px',
      '&:hover': {
        transform: 'translate(-3px, -1px)',
        backgroundColor: 'transparent',
      },
      width: '56px',
      minWidth: '0px',
    },
    iconWrapper: {
      paddingTop: '12px',
    },
    disable: {
      opacity: 0.5,
      cursor: 'default !important',
      pointerEvents: 'none',
    },
  })
)

export const CircleIconButton: FC<ButtonProps> = (props) => {
  const classes = useStyles()

  return (
    <div className={`${classes.buttonWrapper} ${props.wrapperClasses}`}>
      <Button
        variant={'contained'}
        className={`
          ${props.disabled ? `${classes.disable} ${classes.button}` : classes.button}
          ${props.classes}`}
        autoFocus={!!props.autoFocus}
        onClick={props.onClick}
        color='primary'
        id={props.id}
        title={props.title}
      >
        {props.iconName ? (
          <span className={`${classes.iconWrapper} ${props.iconWrapperClasses}`}>
            <Icon
              iconName={props.iconName as any}
              iconButtonProps={props.iconButtonProps}
              svgIconProps={{
                style: { color: 'secondary' },
                ...props.svgIconProps,
              }}
              iconSize='22px'
            />
          </span>
        ) : (
          props.children
        )}
      </Button>
    </div>
  )
}
