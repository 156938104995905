import React, { FC } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'

import AuthLayout from '../../../Containers/AuthenticationContainer/Auth/AuthLayout'
import ResetPasswordSuccessContainer from '../../../Containers/AuthenticationContainer/ResetPasswordContainer/ResetPasswordSuccessContainer'

const useStyles = makeStyles(() =>
  createStyles({
    containerWrapper: {
      height: '100vh',
    },
  })
)

const AuthResetPasswordSuccessPage: FC = () => {
  const classes = useStyles()

  return (
    <AuthLayout>
      <div className={classes.containerWrapper}>
        <ResetPasswordSuccessContainer />
      </div>
    </AuthLayout>
  )
}
export default AuthResetPasswordSuccessPage
