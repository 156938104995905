import React, { FC } from 'react'
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inline: {
      display: 'inline',
    },
    container: {
      display: 'flex',
      justifyContent: 'space-evenly',
      alignItems: 'center',
      width: '100%',
      padding: '40px 0',
    },
    line: {
      borderBottom: '1px solid gray',
      width: '70%',
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      padding: '0 10px 0 10px',
      textAlign: 'center',
      whiteSpace: 'nowrap',
      margin: '0 5px 0 5px',
      color: theme.palette.grey[600],
      fontSize: 15,
      lineHeight: 2,
      fontFamily: 'Graphik',
    },
  })
)

export interface ChatTimestampProps {
  timestamp: Date
  text?: string
}

export const ChatTimestamp: FC<ChatTimestampProps> = ({ timestamp, text }) => {
  const classes = useStyles()
  const time = timestamp.toDateString()

  return (
    <div className={classes.container}>
      <div className={classes.line}> </div>
      <div className={classes.content}>
        {text && <span> {text} </span>}
        <span> {time} </span>
      </div>
      <div className={classes.line}> </div>
    </div>
  )
}
