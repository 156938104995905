import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Divider from '@material-ui/core/Divider'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'

import { useAppDispatch, useAppSelector } from '../../Reducers/hooks'
import { MenuPages, switchMenuPage, toggleMenu, updateIsSideMenuOpen } from '../Menu/MenuSlice'
import UserAvatar from '../UserAvatar/UserAvatar'
import {
  ChatPages,
  switchChatPage,
  updateActiveConversation,
  updateActiveConversationId,
  updateAddNewUsersIsActive,
  updateGroupConversaiton,
} from './ChatSlice'
import { updateIsRoomConversation } from '../RoomChat/RoomChatSlice'
import { ChatMenuItem, Icon, MenuDrawer, MenuHeader, SearchBar } from '../../Common/common-index'
import { Conversation, User } from '../../Services/services-index'
import { BusinessCardPages, switchBusinessCardPagePage } from '../BusinessCards/businessCardsSlice'
import { createHtmlIdString } from '../../Utils/utils'

const userStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      flexWrap: 'nowrap',
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '400px',
      },
    },
    containerSpace: {
      marginTop: '110px',
    },
    searchBar: {
      width: 'calc(100% - 48px)',
      position: 'absolute',
      top: '72px',
      left: '0',
      height: '80px',
      zIndex: 1000,
      padding: theme.spacing(0, 3),
      display: 'flex',
      alignItems: 'center',
      backgroundColor: theme.palette.background.paper,
    },
    searchInput: {
      fontFamily: 'Graphik-Regular',
      fontSize: '14px',
      fontWeight: 'normal',
      lineHeight: '20px',
      width: '100%',
      height: '40px',
      backgroundColor: theme.palette.background.default,
    },
    searchFocusIcon: {
      '& svg': {
        color: theme.palette.primary.main,
      },
    },
    defaultSearchIcon: {
      '& svg': {
        color: theme.palette.grey[100],
      },
    },
    listItem: {
      alignItems: 'center',
      display: 'flex',
      minHeight: '124px',
      '& div[class*="MuiListItem-root"]': {
        alignItems: 'center',
        display: 'flex',
        padding: '0 24px',
        width: '100%',
        '&:hover': { minHeight: '124px' },
      },
    },
    avatar: {
      border: '3px solid white',
      width: 50,
      height: 50,
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(0, 1),
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%,-50%)',
    },
    emptyConversations: {
      alignItems: 'center',
      backgroundColor: theme.palette.background.default,
      borderRadius: '50%',
      display: 'flex',
      height: '70px',
      justifyContent: 'center',
      padding: '32px',
      width: '70px',
    },
    text: {
      width: '80%',
      marginTop: theme.spacing(2),
    },
    buttonWrapper: {
      display: 'flex',
      justifyContent: 'center',
      paddingTop: 6,
      paddingBottom: 30,
      flexBasis: 0,
    },
    button: {
      textDecoration: 'underline',
      cursor: 'pointer',
      color: theme.palette.primary.main,
    },
    textCenter: {
      textAlign: 'center',
      margin: theme.spacing(1, 0),
    },
    noUserFound: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      height: '100%',
      margin: theme.spacing(2, 0),
      width: '100%',
    },
    divider: {
      marginLeft: '10%',
      marginRight: '10%',
    },
  })
)

const ChatListContainer: FC = () => {
  const { t } = useTranslation()
  const classes = userStyles()
  const dispatch = useAppDispatch()

  const currentUser = useAppSelector((state) => state.auth.currentUser)
  const allConversations = useAppSelector((state) => state.chat.conversations)

  const [searchTextInput, setSearchTextInput] = useState<string>('')
  const [hasConversation, setHasConversation] = useState<boolean>(false)
  const [noSearchResult, setNoSearchResult] = useState<boolean>(false)

  const [filteredConversations, setFilteredConversations] = useState(allConversations)
  const [chatConversations, setChatConversations] = useState<any>()

  useEffect(() => {
    dispatch(updateIsSideMenuOpen(true))
    if (allConversations) {
      dispatch(updateIsRoomConversation(false))
      setHasConversation(!!Object.keys(allConversations).length)
      setChatConversations(renderChatConversationList())
    }
  }, [allConversations, hasConversation, filteredConversations])

  useEffect(() => {}, [chatConversations])

  const handleConversationClick = (conversationId: string) => {
    if (allConversations !== null) {
      const activeConversation = allConversations[conversationId]
      dispatch(updateActiveConversationId(conversationId))
      dispatch(updateActiveConversation(activeConversation))
      activeConversation?.participants?.length > 2
        ? dispatch(
            updateGroupConversaiton({
              isGroup: true,
              count: activeConversation?.participants?.length,
            })
          )
        : dispatch(
            updateGroupConversaiton({
              isGroup: false,
              count: activeConversation?.participants?.length,
            })
          )
    }
    dispatch(switchChatPage(ChatPages.ChatMessage))
  }

  const onEmptyConversationList = () => {
    return (
      <Grid container justifyContent={'center'} alignItems={'center'} className={classes.wrapper}>
        <Box className={classes.emptyConversations} component={'span'}>
          <Icon iconName={'chat'} iconSize={'65px'} />
        </Box>
        <Typography variant={'body2'} align={'center'} className={classes.text}>
          {t('chatPage.chatList.emptyConversation')}
        </Typography>
      </Grid>
    )
  }

  const renderChatConversationList = () => {
    let userConversationList: any
    if (!searchTextInput?.length) {
      if (!allConversations) {
        return null
      }
      userConversationList = allConversations
    } else {
      if (!filteredConversations) {
        return null
      }
      userConversationList = filteredConversations
    }

    return Object.keys(userConversationList)
      .sort((firstEl: string, secondEl: string) => {
        return (
          userConversationList[secondEl]?.lastMessage?.timestamp -
          userConversationList[firstEl]?.lastMessage?.timestamp
        )
      })
      .map((conversationId) => {
        const conversation = userConversationList[conversationId]
        return renderConversationListItem(conversation, conversationId)
      })
  }

  const renderConversationListItem = (conversation: any, conversationId: string) => {
    if (!conversation) {
      return
    }
    const currentUserId = currentUser?.id || undefined

    const participants = conversation?.participants.filter((p: any) => p.id !== currentUserId)
    if (!participants.length) {
      return
    }

    const title = conversation.isGroupConversation
      ? participants[0].firstname + ' ' + participants[0].lastname + ' +' + participants.length
      : participants[0].firstname + ' ' + participants[0].lastname

    const lastMessage = (conversation?.lastMessage?.message as string) || ''
    return (
      <div key={conversationId}>
        {(conversation.message?.length ||
          conversation.lastMessage?.message?.length ||
          conversation.lastMessage?.cards?.id ||
          conversation.lastMessage?.type === 'video' ||
          conversation.lastMessage?.type === 'audio') && (
          <>
            <div className={classes.listItem}>
              <ChatMenuItem
                id={createHtmlIdString('chat-list-menu-item-', conversationId)}
                key={conversationId}
                title={title}
                description={
                  lastMessage.length < 100 ? lastMessage : lastMessage.substring(0, 100) + '...'
                }
                onClick={() => handleConversationClick(conversationId)}
                avatar={
                  <UserAvatar
                    userId={participants[0].id ? participants[0].id : undefined}
                    hasUnreadMessage={conversation.isUnread}
                    isGroup={conversation?.participants?.length > 2}
                    width={50}
                    height={50}
                    tooltipPlacement='bottom'
                  />
                }
              />
            </div>
            <Divider className={classes.divider} />
          </>
        )}
      </div>
    )
  }

  const filterConversations = (event: ChangeEvent<HTMLInputElement>) => {
    if (!allConversations) {
      return null
    }

    if (event.target.value === '' || event.target.value === ' ' || !event) {
      setFilteredConversations(allConversations)
      setSearchTextInput('')
      setNoSearchResult(false)
      return
    } else {
      setFilteredConversations({})
      const searchValue = event.target.value
      setSearchTextInput(searchValue)
      setNoSearchResult(false)

      let searchedConversations = {}
      Object.keys(allConversations).forEach((conversationId) => {
        const conversation = allConversations[conversationId]
        if (conversation) {
          const results = conversation.participants.filter((user: User) => {
            const userName = `${user.firstname} ${user.lastname}`.toLowerCase()
            return userName.includes(searchValue.toLowerCase())
          })
          if (!!results.length) {
            searchedConversations = {
              ...searchedConversations,
              [conversationId]: conversation,
            }
          }
        }
      })
      setFilteredConversations(searchedConversations)
      if (Object.keys(searchedConversations)?.length === 0) {
        setNoSearchResult(true)
      }
    }
    return
  }

  const renderSearchbar = () => {
    return (
      <>
        <div className={classes.searchBar}>
          <SearchBar
            id='chat-list-search'
            placeholderText={t('chatPage.chatList.searchBar.placeholder')}
            searchTextInput={searchTextInput}
            onChange={(event) => filterConversations(event as ChangeEvent<HTMLInputElement>)}
          />
        </div>
      </>
    )
  }

  const newConversationClick = () => {
    dispatch(updateActiveConversationId(''))
    dispatch(updateActiveConversation({} as Conversation))
    dispatch(updateAddNewUsersIsActive(false))
    dispatch(switchChatPage(ChatPages.ChatNewConversation))
  }

  // Header Actions
  const OnCloseClick = () => {
    dispatch(toggleMenu(false))
    dispatch(updateIsSideMenuOpen(false))
    dispatch(switchMenuPage(MenuPages.Menu))
    dispatch(switchChatPage(ChatPages.ChatList))
  }

  const OnGoBack = () => {
    dispatch(switchMenuPage(MenuPages.Menu))
    dispatch(toggleMenu(true))
    dispatch(switchBusinessCardPagePage(BusinessCardPages.BusinessCardsListPage))
    dispatch(switchChatPage(ChatPages.ChatList))
  }

  return (
    <MenuDrawer
      isOpen={true}
      drawerHeader={
        <MenuHeader
          title={t('chatPage.chatList.title')}
          hasDivider={true}
          onGoBack={OnGoBack}
          onClose={OnCloseClick}
        />
      }
      drawerFooter={
        <Typography
          id='start-new-conversation'
          variant='body1'
          onClick={newConversationClick}
          className={classes.button}
        >
          {t('chatPage.chatList.newConversationButton')}
        </Typography>
      }
    >
      <Grid
        className={
          hasConversation ? `${classes.container} ${classes.containerSpace}` : classes.container
        }
        container
      >
        {hasConversation && renderSearchbar()}
        {!hasConversation && !noSearchResult && onEmptyConversationList()}
        {hasConversation && !noSearchResult && chatConversations}
        {noSearchResult && (
          <div>
            <Typography className={classes.noUserFound} variant={'body2'}>
              {t('chatPage.chatNewConversation.noSearchResult')}
            </Typography>
          </div>
        )}
      </Grid>
    </MenuDrawer>
  )
}

export default ChatListContainer
