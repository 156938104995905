import React, { FC } from 'react'
import Typography from '@material-ui/core/Typography'

import { makeStyles, Theme } from '@material-ui/core/styles'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'

export interface DropdownItem {
  value?: number
  name?: string
  id?: number
}

export interface InputProps {
  title?: string
  itemsList: DropdownItem[]
  selecteditem?: []
  variant?: any
  // eslint-disable-next-line no-unused-vars
  onOptionChange: (event?: any, value?: any) => void
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& .MuiSelect-select': {
      fontFamily: 'Graphik',
      fontSize: '16px',
      backgroundColor: theme.palette.background.default,
    },
    '& div[class*="MuiOutlinedInput-root"]': {
      borderRadius: '0',
      backgroundColor: theme.palette.background.default,
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  listLabel: {
    fontFamily: 'Graphik',
    fontWeight: 'bold',
    paddingBottom: '10px',
  },
}))

export const DropdownFiltered: FC<InputProps> = ({
  title,
  variant = 'standard',
  itemsList,
  selecteditem,
  onOptionChange,
}) => {
  const classes = useStyles()

  return (
    <div className={`${classes.root} ${classes.container}`}>
      {title && (
        <Typography variant={'body2'} className={classes.listLabel}>
          {title}
        </Typography>
      )}
      <FormControl variant={variant} size='small'>
        <Autocomplete
          multiple
          filterSelectedOptions
          id='dropdown-filtered'
          options={itemsList}
          value={selecteditem}
          getOptionLabel={(option) => option.name || ''}
          onChange={(event, value) => onOptionChange(event, value)}
          renderInput={(params) => <TextField {...params} variant='standard' />}
        />
      </FormControl>
    </div>
  )
}

export default DropdownFiltered
