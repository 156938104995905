import axios from 'axios'
import { FrontendTypes } from '../Models/app.model'
import Config from '../../config.json'

const reactAppDomain = Config.VSF_K8S_API_URL
const apiVersion = Config.VSF_K8S_API_VERSION
const servicePrefix = `${reactAppDomain}/conversation${apiVersion}`

export const getUserConversations = (
  userId: number,
  room_id?: number,
  frontend?: FrontendTypes
) => {
  const params = {
    room_id,
    frontend,
  }
  return axios.get(`${servicePrefix}/conversations/user/${userId}`, { params })
}

export const getConversationMessages = (
  conversationId: string,
  limit?: number,
  page_state?: string
) => {
  const params = {
    limit,
    page_state,
  }
  return axios.get(`${servicePrefix}/conversations/${conversationId}`, {
    params,
  })
}

export const getUsersAndRooms = (frontend?: FrontendTypes, room_id?: number) => {
  const params = {
    frontend,
    room_id,
  }
  return axios.get(`${servicePrefix}/conversations`, { params })
}

export const getAllUserChats = (userId?: number, utc_offset?: number) => {
  const params = {
    utc_offset,
  }
  return axios.get(`${servicePrefix}/user/${userId}/chats/all`, { params })
}

export const getRoomConversationId = (roomId: number) => {
  return axios.get(`${servicePrefix}/conversations/rooms/${roomId}`)
}

export const deleteMessage = (messageId: string | number) => {
  return axios.delete(`${servicePrefix}/conversations/messages/${messageId}`)
}

export const deleteAllMessages = (conversationId: string | number) => {
  return axios.delete(`${servicePrefix}/conversations/${conversationId}/messages`)
}

export const sendNewConversationMessage = (
  messageText: string,
  roomId: number,
  conversationId: string
) => {
  const temporaryBody = {
    message: messageText,
    room_id: roomId,
    conversation_id: conversationId,
  }
  return axios.post(`${servicePrefix}/conversations/messages`, temporaryBody)
}

export const getAllBotChats = (botName: number, utcOffset?: string) => {
  const temporaryBody = {
    bot_name: botName,
  }
  if (utcOffset) Object.assign(temporaryBody, { utc_offset: utcOffset })
  return axios.post(`${servicePrefix}/bot-chat/all`, temporaryBody)
}
