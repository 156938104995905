import React, { FC } from 'react'

import { useAppSelector } from '../../Reducers/hooks'
import { PassportPages } from './PassportBadgesSlice'
import PassportClaimedContainer from './PassportClaimedContainer'
import PassportContainer from './PassportContainer'
import RedeemCoinsContainer from './RedeemCoinsContainer'

const PassportMenuContainer: FC = () => {
  const activePassportPage = useAppSelector((state) => state.passport.activePassportPage)

  switch (activePassportPage) {
    case PassportPages.PassportMainPage:
      return <PassportContainer />
    case PassportPages.PassportClaimedPage:
      return <PassportClaimedContainer />
    case PassportPages.RedeemCoinsPage:
      return <RedeemCoinsContainer />
    default: {
      return null
    }
  }
}

export default PassportMenuContainer
