/* eslint-disable no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export enum TokenWalletPages {
  TokenWalletListPage,
}

export interface Token {
  access_code: String
  hotspot_label: String
  token_id: String
}

export interface TokenWalletState {
  activeTokenWalletPage: TokenWalletPages
  tokens: Token[]
}

const initialState: TokenWalletState = {
  activeTokenWalletPage: TokenWalletPages.TokenWalletListPage,
  tokens: [],
}

export const tokenWalletSlice = createSlice({
  name: 'tokenWallet',
  initialState,
  reducers: {
    updateTokens: (state, action: PayloadAction<Token[]>) => {
      state.tokens = action.payload
    },
  },
})

export const { updateTokens } = tokenWalletSlice.actions

export default tokenWalletSlice.reducer
