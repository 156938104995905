/* eslint-disable no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Passport } from '../../Services/services-index'

export enum PassportPages {
  PassportMainPage,
  PassportClaimedPage,
  RedeemCoinsPage,
}

export interface StoreItem {
  id: number
  name: string
  description: string
  coin_price: number
  filename: string
}
export interface PassportBadgesState {
  passportBadges: Passport
  activePassportPage: PassportPages
  goodiesList: StoreItem[]
}

const initialState: PassportBadgesState = {
  passportBadges: {},
  activePassportPage: PassportPages.PassportMainPage,
  goodiesList: [],
}

export const passportBadgesSlice = createSlice({
  name: 'passport',
  initialState,
  reducers: {
    updatePassportBadges: (state, action: PayloadAction<any>) => {
      state.passportBadges = action.payload
    },
    updateGoodiesList: (state, action: PayloadAction<StoreItem[]>) => {
      state.goodiesList = action.payload
    },
    switchPassportPage: (state, action: PayloadAction<PassportPages>) => {
      state.activePassportPage = action.payload
    },
  },
})

export const { updatePassportBadges, switchPassportPage, updateGoodiesList } =
  passportBadgesSlice.actions

export default passportBadgesSlice.reducer
