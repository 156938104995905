/* eslint-disable no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export enum HelpPages {
  HelpMenuPage,
  HelpUserManualpage,
  HelpDeskFormPage,
  HelpDeskSuccessPage,
  HelpFaqPage,
  HelpPrivacy,
  HelpCookiePolicyPage,
}

export interface HelpState {
  activeHelpPage: HelpPages
}

const initialState: HelpState = {
  activeHelpPage: HelpPages.HelpMenuPage,
}

export const helpSlice = createSlice({
  name: 'help',
  initialState,
  reducers: {
    switchHelpPage: (state, action: PayloadAction<HelpPages>) => {
      state.activeHelpPage = action.payload
    },
  },
})

export const { switchHelpPage } = helpSlice.actions

export default helpSlice.reducer
