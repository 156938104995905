import React, { FC, ReactElement } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

export interface ChatToolbarProps {
  items: ReactElement[]
  gridSize?: boolean
}

const userStyles = makeStyles(() =>
  createStyles({
    container: {
      height: 60,
      justifyContent: 'center',
    },
  })
)

export const ChatToolbar: FC<ChatToolbarProps> = ({ items, gridSize }) => {
  const classes = userStyles()

  return (
    <Grid container className={classes.container}>
      {items.map((item, index) => {
        return (
          <Grid
            item
            key={index}
            container
            xs={gridSize ? 2 : 4}
            spacing={1}
            justifyContent={'center'}
            alignItems={'center'}
          >
            {item}
          </Grid>
        )
      })}
    </Grid>
  )
}
