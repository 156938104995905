import axios from 'axios'
import { Agenda, AgendaType } from '../Models/presentation.model'
import Config from '../../config.json'

const reactAppDomain = Config.VSF_K8S_API_URL
const apiVersion = Config.VSF_K8S_API_VERSION
const servicePrefix = `${reactAppDomain}/presentation${apiVersion}`

export const retrieveAgenda = (body: any) => {
  axios.get(`${servicePrefix}/agenda`, { params: { body } })
}

export const retrieveEventAgenda = (event_code: string, roomId?: number) => {
  const body = { type: AgendaType.EVENT }
  if (event_code) Object.assign(body, { event_code })
  if (roomId) Object.assign(body, { id: roomId })
  retrieveAgenda(body)
}

export const retrievePresenterAgenda = (event_code: string, roomId: number) => {
  const body = { type: AgendaType.PRESENTER, event_code }
  if (roomId) Object.assign(body, { id: roomId })
  retrieveAgenda(body)
}

export const retrieveRoomAgenda = (roomId: number, event_code?: string) => {
  const body = { type: AgendaType.ROOM, id: roomId }
  if (event_code) Object.assign(body, { event_code })
  retrieveAgenda(body)
}

export const createAgenda = (roomId: number, body: Agenda) => {
  axios.post(`${servicePrefix}/agenda/rooms/${roomId}`, body)
}

export const updateAgenda = (roomId: number, body: Agenda) => {
  axios.patch(`${servicePrefix}/agenda/presentation/${roomId}`, body)
}

export const deleteAgenda = (presentationId: number) => {
  axios.delete(`${servicePrefix}/agenda/presentation/${presentationId}`)
}
