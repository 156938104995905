import React, { FC, useEffect, useState } from 'react'
import JitsiContainer from '../JitsiContainer/JitsiContainer'
import { IncomingCallDialog } from '../../Components/IncomingCallDialog/IncomingCallDialog'
import { selectCurrentUser } from '../../Reducers/authSlice'
import { useAppDispatch, useAppSelector } from '../../Reducers/hooks'
import {
  MessageActions,
  MessageBasicPayload,
  MessagePayload,
  MessageTypes,
  messagingService,
  MessagingTopics,
} from '../../Services/services-index'
import { setCallStarted } from './CallSlice'
import { CallType } from '../../Services/Models/CallModel'
import { setBackgroundMusic } from '../SettingsContainer/SettingsSlice'

export interface CallContainerProps {
  messageReceived: MessagePayload | null
}

const CallContainer: FC<CallContainerProps> = ({ messageReceived }) => {
  const dispatch = useAppDispatch()

  const currentUser = useAppSelector(selectCurrentUser)
  const socket = useAppSelector((state) => state.socket.socket)
  const callStarted = useAppSelector((state) => state.call.callStarted)
  const activeConversationId = useAppSelector((state) => state.chat.activeConversationId)
  const allConversations = useAppSelector((state) => state.chat.conversations)
  const backgroundMusicOn = useAppSelector((state) => state.settings.backgroundMusicOn)

  const [callIncoming, setCallIncoming] = useState(false)
  const [callInProgress, setCallInProgress] = useState(false)
  const [callParticipants, setCallParticipants] = useState([] as number[])
  const [primaryCaller, setPrimaryCaller] = useState({} as any)
  const [audioOnly, setAudioOnly] = useState(false)
  const [jitsiJwtToken, setJitsiJwtToken] = useState('')
  const [hasBgMusicBeforeCall, setHasBgMusicBeforeCall] = useState(false)

  useEffect(() => {
    if (messageReceived) {
      handleVideoAudioMessageAction(messageReceived)
    }
  }, [messageReceived])

  useEffect(() => {
    // Call started is true when either user starts the call,
    //  if the current user is not the caller, callInProgress is not true until the call is confirmed in the incomingCallDialog
    //  otherwise when the current user starts the call, callInProgress is set to true when the call request is confirmed by the server
    if (callStarted && !callInProgress) {
      initiateCall(callStarted === 'audio' ? MessageTypes.audio : MessageTypes.video)
    } else if (!callStarted) {
      setCallInProgress(false)
    }
  }, [callStarted])

  useEffect(() => {}, [backgroundMusicOn, hasBgMusicBeforeCall])

  function initiateCall(type: MessageTypes) {
    if (!currentUser || !currentUser.id || !allConversations || !socket) return
    const chatTopic = `${MessagingTopics.CHAT}_${currentUser.event_code}`
    const participants = allConversations[activeConversationId].participants
    const tempCallParticipants = participants
      .filter((p) => p.id !== currentUser.id)
      .map((p) => p.id) as number[]

    if (!tempCallParticipants?.length) return
    setCallParticipants(tempCallParticipants)

    const callMessage: MessageBasicPayload = {
      from: currentUser.id,
      to: tempCallParticipants,
      timestamp: new Date().getTime().toString(),
      type,
      action: MessageActions.start,
    }

    messagingService.emitMessagingSocket(chatTopic, callMessage, socket)
  }

  const handleVideoAudioMessageAction = (message: MessagePayload) => {
    // To turn off background music on call start
    if (message?.action === 'start' && backgroundMusicOn && !hasBgMusicBeforeCall) {
      setHasBgMusicBeforeCall(true)
      dispatch(setBackgroundMusic(false))
    }
    // To turn on background music on call end
    if (message?.action === 'end' && hasBgMusicBeforeCall && !backgroundMusicOn) {
      dispatch(setBackgroundMusic(true))
      setHasBgMusicBeforeCall(false)
    }
    switch (message.action) {
      case MessageActions.start: {
        const userIsCaller = message.from === currentUser?.id
        // TODO: sends post message to iframe window? - (see frontend-main externalRoomComp line 203)
        if (!message.token) return

        setJitsiJwtToken(message.token)

        if (userIsCaller) {
          setCallInProgress(true)
          setAudioOnly(callStarted === 'audio')
          setCallParticipants([...message.to.filter((id) => id !== currentUser?.id)])
        } else {
          setCallParticipants([message.from, ...message.to.filter((id) => id !== currentUser?.id)])
          const caller = message.user_summary_info.find((user: any) => user.id !== currentUser?.id)
          setPrimaryCaller(caller)
          setCallIncoming(true)
        }
        break
      }
      case MessageActions.end: {
        setCallInProgress(false)
        setCallIncoming(false)
        dispatch(setCallStarted(null))
        break
      }
      default:
        break
    }
  }

  const handleDeclineCall = () => {
    if (!currentUser || !currentUser.id || !socket) return
    setCallIncoming(false)
    dispatch(setCallStarted(null))
    const { event_code, id: currentUserId } = currentUser
    const chatTopic = `${MessagingTopics.CHAT}_${event_code}`
    const declineMessage: MessageBasicPayload = {
      from: currentUserId,
      to: callParticipants,
      timestamp: new Date().getTime().toString(),
      type: MessageTypes.video,
      action: MessageActions.end,
    }

    messagingService.emitMessagingSocket(chatTopic, declineMessage, socket)
    setCallParticipants([])
    setPrimaryCaller(null)
  }

  const handleAnswerCall = (type: CallType) => {
    setCallIncoming(false)
    setCallInProgress(true)
    dispatch(setCallStarted(type))
    setAudioOnly(type === 'audio')
  }

  const handleEndCall = () => {
    if (!currentUser?.id || !socket) return
    const endChatMessage: MessageBasicPayload = {
      from: currentUser.id,
      to: [...callParticipants],
      timestamp: new Date().getTime().toString(),
      type: MessageTypes.video,
      action: MessageActions.end,
    }

    const chatTopic = `${MessagingTopics.CHAT}_${currentUser.event_code}`
    messagingService.emitMessagingSocket(chatTopic, endChatMessage, socket)
    setCallStarted(null)
  }

  return (
    <>
      <IncomingCallDialog
        open={callIncoming}
        onConfirm={(type: CallType) => {
          handleAnswerCall(type)
        }}
        onCancel={() => {
          handleDeclineCall()
        }}
        callerIds={callParticipants}
        caller={primaryCaller}
      />
      {callInProgress && (messageReceived?.conversation || callStarted) && (
        <JitsiContainer
          conversationId={messageReceived?.conversation || activeConversationId}
          jitsiJwtToken={jitsiJwtToken}
          audioOnly={audioOnly}
          handleClose={handleEndCall}
        />
      )}
    </>
  )
}

export default CallContainer
