const createHtmlIdString = (...args: string[]): string => {
  // Concatenates arbitrary number of strings, converting to lowercase and replacing spaces with hyphens
  let id = ''
  for (const str of args) {
    id += str.toLowerCase().replace(/ /g, '-')
  }

  return id
}

export { createHtmlIdString }
