import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface UserListState {
  isUserListPageActive?: boolean
}

const initialState: UserListState = {
  isUserListPageActive: false,
}

export const userList = createSlice({
  name: 'userList',
  initialState,
  reducers: {
    updateIsUserListPageActive: (state, action: PayloadAction<boolean>) => {
      state.isUserListPageActive = action.payload
    },
  },
})

export const { updateIsUserListPageActive } = userList.actions

export default userList.reducer
