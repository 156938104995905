import React, { FC, useState } from 'react'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { ShadowedButton } from '../Buttons/ShadowedButton'
import { Theme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      alignItems: 'left',
      padding: 20,
      borderRadius: '8px',
      overflowX: 'hidden',
    },
    dialogTitle: {
      marginBottom: '40px',
      display: 'flex',
      justifyContent: 'left',
      fontFamily: 'Graphik-Semibold',
      textAlign: 'left',
    },
    dialogContent: {
      fontFamily: 'Graphik-Regular',
      fontSize: '21px',
      lineHeight: '28px',
      display: 'flex',
      justifyContent: 'left',
      textAlign: 'left',
      overflowX: 'hidden',
    },
    dialogTextField: {
      marginLeft: '24px',
      marginRight: '24px',
    },
    dialogInput: {
      borderRadius: '8px',
      paddingTop: '0',
    },
    dialogActions: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
      marginTop: '40px',
      alignItems: 'center',
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
      },
    },
    buttonWrapper: {
      width: '65%',
      padding: '16px',
      [theme.breakpoints.up('sm')]: {
        width: '50%',
      },
      '& button[class*="makeStyles-buttonDark"]': {
        marginLeft: 0,
      },
    },
    errorStatus: {
      color: '#b71c1c',
    },
    successStatus: {
      color: '#460073',
      fontWeight: 'bold',
    },
  })
)

export interface AccessCodeDialogProps {
  open: boolean
  invalidCodeEntered: boolean
  titleText: string
  contentText: string
  placeholder: string
  cancelText: string
  confirmText: string
  errorMessage: string
  onCancel: () => void
  // eslint-disable-next-line no-unused-vars
  onConfirm: (accessCode: string) => void
}

export const AccessCodeDialog: FC<AccessCodeDialogProps> = ({
  open = false,
  invalidCodeEntered = false,
  titleText,
  contentText,
  placeholder,
  cancelText,
  confirmText,
  errorMessage,
  onCancel = () => {},
  onConfirm,
  ...other
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [accessCode, setAccessCode] = useState<string>('')

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAccessCode(e.target.value)
  }

  return (
    <Dialog
      classes={{
        paper: classes.paper,
      }}
      disableEscapeKeyDown
      maxWidth='sm'
      aria-labelledby='access-code-dialog-title'
      open={open}
      {...other}
    >
      {titleText && (
        <DialogTitle
          id='access-code-dialog-title'
          className={classes.dialogTitle}
          disableTypography={true}
        >
          <Typography variant={'h3'}>{t(titleText)}</Typography>
        </DialogTitle>
      )}

      {contentText && (
        <DialogContent className={classes.dialogContent}>{t(contentText)}</DialogContent>
      )}

      <TextField
        id='access-code-text-field'
        className={classes.dialogTextField}
        variant='outlined'
        size='medium'
        InputProps={{
          classes: {
            notchedOutline: classes.dialogInput,
          },
        }}
        inputProps={{
          maxLength: 2000,
        }}
        value={accessCode}
        autoComplete={'off'}
        label={placeholder}
        onChange={handleChange}
      />

      {errorMessage && invalidCodeEntered && (
        <DialogContent className={`${classes.dialogContent} ${classes.errorStatus}`}>
          {errorMessage}
        </DialogContent>
      )}

      <DialogActions disableSpacing>
        <div className={classes.dialogActions}>
          {cancelText && (
            <ShadowedButton
              id='confirmatin-dialog-cancel'
              text={t(cancelText)}
              buttonStyle={'dark'}
              hasDialogButtonShadow={true}
              wrapperClasses={classes.buttonWrapper}
              onClick={onCancel}
            ></ShadowedButton>
          )}
          {confirmText && (
            <ShadowedButton
              id='access-code-dialog-confirm'
              text={t(confirmText)}
              buttonStyle={'default'}
              disabled={accessCode ? false : true}
              hasDialogButtonShadow={true}
              wrapperClasses={classes.buttonWrapper}
              onClick={() => onConfirm(accessCode)}
            ></ShadowedButton>
          )}
        </div>
      </DialogActions>
    </Dialog>
  )
}
