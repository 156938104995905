import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Grid from '@material-ui/core/Grid'
import MenuList from '@material-ui/core/MenuList'
import Typography from '@material-ui/core/Typography'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import UserAvatar from '../UserAvatar/UserAvatar'
import { ChatPages, switchChatPage } from './ChatSlice'
import { useAppDispatch, useAppSelector } from '../../Reducers/hooks'
import { MenuPages, switchMenuPage, toggleMenu, updateIsSideMenuOpen } from '../Menu/MenuSlice'
import { updateIsRoomConversation } from '../RoomChat/RoomChatSlice'
import { ChatMenuItem, MenuDrawer, MenuHeader } from '../../Common/common-index'
import { User } from '../../Services/services-index'
import { createHtmlIdString } from '../../Utils/utils'
import { formatUserItemDescription } from '../../Utils/chat.utils'

const userStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.background.default,
      minHeight: '100%',
      flexWrap: 'nowrap',
    },
    chatList: {
      flexGrow: 1,
      flexBasis: 0,
    },
    emptyMessage: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      height: '100%',
    },
    avatar: {
      border: '3px solid white',
      width: '50px',
      height: '50px',
    },
    participantWrapper: {
      pointerEvents: 'none',
      height: '90px',
      '& div[class*="MuiListItem-root"]': {
        alignItems: 'center',
        display: 'flex',
        padding: '0 24px',
        height: '90px',
        width: '100%',
      },
    },
  })
)

const ChatParticipantListContainer: FC = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const classes = userStyles()

  const groupConversation = useAppSelector((state) => state.chat.activeConversation)
  const selectedPreferences = useAppSelector((state) => state.preferences.selectedPreferences)

  const [groupParticipants, setGroupParticipants] = useState<User[]>([])

  const showCompany = selectedPreferences?.show_company?.value === 'yes' ? true : false
  const showJobTitle = selectedPreferences?.show_job_title?.value === 'yes' ? true : false

  useEffect(() => {
    dispatch(updateIsSideMenuOpen(true))
    dispatch(updateIsRoomConversation(false))
    if (groupConversation) {
      setGroupParticipants(groupConversation?.participants)
    }
  }, [groupConversation])

  const displayGroupParticipants = () => {
    if (groupParticipants?.length === 0 || undefined) {
      return (
        <div className={classes.emptyMessage}>
          <Typography>{'Participant list is empty.!'}</Typography>
        </div>
      )
    }

    if (groupParticipants) {
      return groupParticipants.map((participant: User) => {
        const title = `${participant.firstname} ${participant.lastname}`
        const description = formatUserItemDescription(
          showCompany,
          showJobTitle,
          participant.company,
          participant.job_title
        )

        return (
          <div className={classes.participantWrapper} key={participant.id}>
            <MenuList disablePadding>
              <ChatMenuItem
                id={createHtmlIdString('chat-participant-list-menu-item-', participant.id + '')}
                key={participant.id}
                title={title}
                description={description}
                onClick={() => undefined}
                avatar={
                  <UserAvatar
                    userId={participant.id as number}
                    hasUnreadMessage={false}
                    width={50}
                    height={50}
                    tooltipPlacement='bottom'
                  />
                }
              />
            </MenuList>
          </div>
        )
      })
    }
    return
  }

  // User Header Actions
  const OnCloseClick = () => {
    dispatch(toggleMenu(false))
    dispatch(updateIsSideMenuOpen(false))
    dispatch(switchMenuPage(MenuPages.Menu))
    dispatch(switchChatPage(ChatPages.ChatMessage))
  }

  const OnGoBack = () => {
    dispatch(switchChatPage(ChatPages.ChatMessage))
  }

  return (
    <MenuDrawer
      isOpen={true}
      drawerHeader={
        <MenuHeader
          title={t('chatPage.chatParticipantList.groupTitle')}
          onGoBack={OnGoBack}
          onClose={OnCloseClick}
        />
      }
    >
      <Grid container direction='column' justifyContent='center' className={classes.container}>
        <Grid item xs={12} className={classes.chatList}>
          {displayGroupParticipants()}
        </Grid>
      </Grid>
    </MenuDrawer>
  )
}

export default ChatParticipantListContainer
