import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface UserActionBody {
  externalUserId?: string
  muted?: boolean
  videoEnabled?: boolean
  nid?: number
}

export interface MenuState {
  voiceRegion: string
  currentUser: UserActionBody
  userList: UserActionBody[]
  selectedAvatar: string
  profilePictureUpdate: boolean
  isExpandViewActive: boolean
  videoAllowed: boolean
  microphoneAllowed: boolean
  muteAll: boolean
  muteAllAllowed: boolean
  muteUser?: number | null
}

const initialState: MenuState = {
  currentUser: {
    muted: true,
    videoEnabled: false,
  },
  voiceRegion: '',
  userList: [{}],
  selectedAvatar: '',
  isExpandViewActive: false,
  profilePictureUpdate: false,
  videoAllowed: false,
  microphoneAllowed: false,
  muteAll: false,
  muteAllAllowed: false,
}

export const toolButtonSlice = createSlice({
  name: 'toolButtons',
  initialState,
  reducers: {
    updateCurrentUserButtonState: (state, action: PayloadAction<UserActionBody>) => {
      state.currentUser = action.payload
    },
    updateAllowedButtonState: (state, action: PayloadAction<any>) => {
      const buttonType = Object.keys(action.payload)[0]
      const buttonValue = Object.values(action.payload)[0]
      const isEventAdmin = action.payload.isEventAdmin
      switch (buttonValue) {
        case 0:
          state[buttonType] = true
          break
        case 1:
          isEventAdmin ? (state[buttonType] = true) : (state[buttonType] = false)
          break
        case 2:
          state[buttonType] = false
          break
      }
    },
    updateUserListState: (state, action: PayloadAction<UserActionBody[]>) => {
      if (Object.keys(state.userList).length > action.payload.length) {
        state.userList = { ...action.payload }
      } else {
        state.userList = { ...state.userList, ...action.payload }
      }
    },
    updateActiveVoiceRegion: (state, action: PayloadAction<string>) => {
      state.voiceRegion = action.payload
    },
    setAvatar: (state, action: PayloadAction<any>) => {
      state.selectedAvatar = action.payload
    },
    setProfilePictureUpdate: (state, action: PayloadAction<any>) => {
      state.profilePictureUpdate = action.payload
    },
    updateExpandViewActive: (state, action: PayloadAction<boolean>) => {
      state.isExpandViewActive = action.payload
    },
    triggerMuteAll: (state) => {
      state.muteAll = !state.muteAll
    },
    triggerMuteUser: (state, action: PayloadAction<number | null>) => {
      state.muteUser = action.payload
    },
  },
})

export const {
  updateCurrentUserButtonState,
  updateAllowedButtonState,
  updateUserListState,
  updateActiveVoiceRegion,
  setAvatar,
  setProfilePictureUpdate,
  updateExpandViewActive,
  triggerMuteAll,
  triggerMuteUser,
} = toolButtonSlice.actions

export default toolButtonSlice.reducer
