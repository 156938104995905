import React, { FC, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import FormTextField from '../FormTextField/FormTextField'
import { ShadowedButton } from '../../../Common/common-index'

interface Props {
  onSubmit: Function
  registerFailMsg: string | undefined
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1, 0),
        padding: theme.spacing(0, 3),
        boxSizing: 'border-box',
        width: '100%',
        [theme.breakpoints.up('sm')]: {
          width: 400,
          padding: theme.spacing(0),
        },
      },
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    headerWrapper: {
      fontFamily: 'Graphik',
      fontWeight: 'bold',
      textAlign: 'start',
      width: '100%',
      padding: theme.spacing(5, 0),
      [theme.breakpoints.up('md')]: {
        width: '400px',
      },
    },
    header: {
      lineHeight: '32px',
      [theme.breakpoints.up('md')]: {
        width: '100%',
      },
    },
    alignCenter: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    button: {
      marginTop: theme.spacing(5),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        maxWidth: '400px',
      },
    },
    errorStatus: {
      marginTop: theme.spacing(2),
      color: '#b71c1c',
      padding: theme.spacing(0, 3),
      [theme.breakpoints.up('sm')]: {
        marginLeft: 0,
        maxWidth: '400px',
      },
    },
  })
)
const ResetSetPasswordForm: FC<Props> = ({ onSubmit }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [newPassword, setNewPassword] = useState<string>('')
  const [confirmNewPassword, setConfirmNewPassword] = useState<string>('')
  const [newPasswordTouched, setNewPasswordTouched] = useState(false)
  const [confirmPasswordTouched, setConfirmPasswordTouched] = useState(false)
  const [newPasswordFieldHasError, setNewPasswordFieldHasError] = useState(false)
  const [confirmPasswordFieldHasError, setConfirmPasswordFieldHasError] = useState(false)

  const onClick = (e: any) => {
    e.preventDefault()
    onSubmit(newPassword, confirmNewPassword)
  }

  const formValidation = () => {
    newPasswordTouched && !newPassword
      ? setNewPasswordFieldHasError(true)
      : setNewPasswordFieldHasError(false)
    confirmPasswordTouched && !confirmNewPassword
      ? setConfirmPasswordFieldHasError(true)
      : setConfirmPasswordFieldHasError(false)
  }

  // On Password field  value change
  const onNewPasswordFieldValueChange = (value: string) => {
    setNewPasswordTouched(true)
    setNewPassword(value)
  }

  // On EventCode field  value change
  const onConfirmPasswordFieldValueChange = (value: string) => {
    setConfirmPasswordTouched(true)
    setConfirmNewPassword(value)
  }

  useEffect(() => {
    formValidation()
  }, [newPassword, confirmNewPassword])

  return (
    <form className={classes.root} onSubmit={onClick}>
      <div className={classes.headerWrapper}>
        <Typography className={classes.header} variant={'h4'} data-testid='title'>
          {t('authPages.resetPasswordContainer.title')}
        </Typography>
      </div>
      <FormTextField
        id='reset-password-new-password'
        value={newPassword}
        isPasswordField={true}
        autoComplete={'newPassword'}
        error={newPasswordFieldHasError}
        title={t('authPages.resetPasswordForm.newPassword.header')}
        placeholder={t('authPages.resetPasswordForm.newPassword.placeholder')}
        onChange={(e) => onNewPasswordFieldValueChange(e)}
        errorMessage={t('authPages.registerForm.password.errorMessage')}
      />
      <FormTextField
        id='reset-password-confirm-password'
        title={t('authPages.resetPasswordForm.confirmPassword.header')}
        placeholder={t('authPages.resetPasswordForm.confirmPassword.placeholder')}
        value={confirmNewPassword}
        onChange={(e) => onConfirmPasswordFieldValueChange(e)}
        isPasswordField={true}
        autoComplete={'confirm-password'}
        error={confirmPasswordFieldHasError}
        errorMessage={t('authPages.registerForm.password.errorMessage')}
      />
      <ShadowedButton
        id='reset-password-submit'
        text={t('authPages.resetPasswordForm.button.reset')}
        buttonStyle={'default'}
        wrapperClasses={classes.button}
        disabled={!newPassword || !confirmNewPassword}
        onClick={onClick}
      ></ShadowedButton>
    </form>
  )
}
export default ResetSetPasswordForm
