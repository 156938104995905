import { createStyles, makeStyles } from '@material-ui/core/styles'
import React, { FC } from 'react'

interface Props {
  roomURL: string
  elementRef: any
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
      height: '100%',
    },
  })
)

const Room: FC<Props> = ({ roomURL, elementRef }) => {
  const classes = useStyles()

  const properties = {
    allowFullScreen: true,
    allowvr: 'yes',
  }

  return (
    <iframe
      id={`externalIframe`}
      className={classes.root}
      src={roomURL}
      allow='camera *;microphone *; fullscreen; display-capture; xr-spatial-tracking'
      frameBorder='0'
      ref={elementRef}
      {...properties}
    ></iframe>
  )
}

export default Room
