import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { makeStyles, Theme } from '@material-ui/core/styles'

import { useAppDispatch, useAppSelector } from '../../Reducers/hooks'
import { MenuPages, switchMenuPage, toggleMenu, updateIsSideMenuOpen } from '../Menu/MenuSlice'
import {
  BusinessCardPages,
  switchBusinessCardPagePage,
  updateIsBusinessCardDeleted,
  updateIsBusinessCardApproved,
} from './businessCardsSlice'
import { BusinessCardsDetailComponent } from '../../Components/BusinessCards/BusinessCardDetail'
import { ChatPages, switchChatPage } from '../Chat/ChatSlice'
import { MenuDrawer, MenuHeader } from '../../Common/common-index'
import { businessCardService } from '../../Services/services-index'

const useStyles = makeStyles((theme: Theme) => ({
  paperContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  container: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(4),
  },
}))

export const BusinessCardDetailsContainer: FC = ({}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const approvedBusinessCard = useAppSelector((state) => state.businessCards.approvedBusinessCard)
  const isChatMenuBusinessCardView = useAppSelector(
    (state) => state.businessCards.isChatMenuBusinessCardView
  )

  const [errorMessage, setErrorMessage] = useState<any>()
  const [successMessage, setSuccessMessage] = useState<string>('')
  const [disableAction, setDisableAction] = useState<boolean>(false)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false)

  useEffect(() => {
    dispatch(updateIsSideMenuOpen(true))
  }, [isChatMenuBusinessCardView, approvedBusinessCard])

  const onBusinessCardDeleteClick = () => {
    if (approvedBusinessCard) {
      businessCardService
        .deleteBusinessCard(approvedBusinessCard?.card_id)
        .then((res) => {
          if (res.status === 200) {
            setErrorMessage('')
            setDisableAction(true)
            setSuccessMessage(res?.data?.message)
            dispatch(updateIsBusinessCardDeleted(true))
            dispatch(updateIsBusinessCardApproved(false))
            // Turn of success message
            setTimeout(() => {
              setSuccessMessage('')
              dispatch(switchBusinessCardPagePage(BusinessCardPages.BusinessCardsListPage))
              setDisableAction(false)
            }, 3500)
          }
        })
        .catch((error) => {
          const errorText = error?.response?.data?.message
          setErrorMessage(errorText)
          setSuccessMessage('')

          // Turn of error message
          setTimeout(() => {
            setErrorMessage('')
          }, 10000)
        })
    }
  }

  // On cancel click on pop-up dailog
  const onCancelClick = () => {
    setErrorMessage('')
    setDisableAction(false)
    if (deleteDialogOpen) {
      setDeleteDialogOpen(false)
    }
  }

  // Header Actions
  const OnCloseClick = () => {
    dispatch(toggleMenu(false))
    dispatch(updateIsSideMenuOpen(false))
    dispatch(switchMenuPage(MenuPages.Menu))
    dispatch(switchBusinessCardPagePage(BusinessCardPages.BusinessCardsListPage))
  }

  const OnGoBack = () => {
    if (isChatMenuBusinessCardView) {
      dispatch(switchMenuPage(MenuPages.Chat))
      dispatch(switchChatPage(ChatPages.ChatMessage))
    }
    dispatch(switchBusinessCardPagePage(BusinessCardPages.BusinessCardsListPage))
  }

  return (
    <div className={classes.paperContainer}>
      <MenuDrawer
        isOpen={true}
        drawerHeader={
          <MenuHeader
            title={t('businessCardsPage.businessCard.title')}
            onGoBack={OnGoBack}
            onClose={OnCloseClick}
          />
        }
      >
        <div className={classes.container}>
          {approvedBusinessCard && (
            <BusinessCardsDetailComponent
              businessCard={approvedBusinessCard}
              errorMessage={errorMessage}
              successMessage={successMessage}
              disableAction={disableAction}
              deleteDialogOpen={deleteDialogOpen}
              openDeleteDialog={() => setDeleteDialogOpen(!deleteDialogOpen)}
              onCancelClick={onCancelClick}
              onBusinessCardDeleteClick={onBusinessCardDeleteClick}
            ></BusinessCardsDetailComponent>
          )}
        </div>
      </MenuDrawer>
    </div>
  )
}

export default BusinessCardDetailsContainer
