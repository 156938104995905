import axios from 'axios'
import {
  Content,
  EmailAssets,
  RoomFileType,
  MediaType,
  Room,
  Shape,
  FilesType,
} from '../Models/content.model'
import Config from '../../config.json'

const reactAppDomain = Config.VSF_K8S_API_URL
const apiVersion = Config.VSF_K8S_API_VERSION
const servicePrefix = `${reactAppDomain}/content${apiVersion}`

export const getRooms = (event_code?: string) => {
  const params = {}
  if (event_code) Object.assign(params, event_code)
  return axios.get(`${servicePrefix}/rooms`, {
    params,
  })
}

export const getLobby = () => {
  return axios.get(`${servicePrefix}/rooms/lobby`)
}

export const getRoom = (roomId: number) => {
  return axios.get(`${servicePrefix}/rooms/${roomId}`)
}

export const createRoom = (room: Room) => {
  return axios.post(`${servicePrefix}/rooms`, room)
}

export const updateRoom = (
  roomId: number,
  link?: string,
  name?: string,
  type?: string,
  restricted?: boolean
) => {
  if (!link && !name && !type && !restricted) throw new Error('one field must be specified')
  if (!type && type === 'external' && !link)
    throw new Error('link must be specified for type external')
  const room = {}
  if (link) Object.assign(room, link)
  if (name) Object.assign(room, name)
  if (type) Object.assign(room, type)
  if (restricted) Object.assign(room, restricted)
  return axios.patch(`${servicePrefix}/rooms/${roomId}`, room)
}

export const deleteRoom = (roomId: number) => {
  return axios.delete(`${servicePrefix}/rooms/${roomId}`)
}

export const getAllContent = (
  roomId: number,
  render3d?: boolean,
  returnInactive?: boolean,
  transition?: boolean,
  type?: string
) => {
  const body = {}
  if (render3d) Object.assign(body, render3d)
  if (returnInactive) Object.assign(body, returnInactive)
  if (transition) Object.assign(body, transition)
  if (type) Object.assign(body, type)
  return axios.get(`${servicePrefix}/rooms/${roomId}/content`, { params: body })
}

export const getContentById = (roomId: number, contentId: number) => {
  return axios.delete(`${servicePrefix}/rooms/${roomId}/content/${contentId}`)
}

export const exportContent = (eventId: string) => {
  return axios.get(`${servicePrefix}/export-content/event/${eventId}`)
}

export const createContent = (roomId: number, content: Content[]) => {
  return axios.post(`${servicePrefix}/rooms/${roomId}/content`, {
    content: content,
  })
}

export const updateContent = (roomId: number, contentId: number, content: Content[]) => {
  return axios.patch(`${servicePrefix}/rooms/${roomId}/content/${contentId}`, {
    content: content,
  })
}

export const deleteContent = (roomId: number, contentId: number) => {
  return axios.delete(`${servicePrefix}/rooms/${roomId}/content/${contentId}`)
}

export const copyContent = (sourceRoomId: number, targetRoomId: number) => {
  const body = {
    sourceRoomId,
    targetRoomId,
  }
  return axios.post(`${servicePrefix}/rooms/content`, body)
}

export const updateExistingContent = (
  roomId: number,
  name: string,
  new_name: string,
  active: boolean
) => {
  const body = { new_name, active }
  return axios.patch(`${servicePrefix}/rooms/${roomId}/content-group/${name}`, body)
}

export const getFiles = (file: string, roomId: number) => {
  return axios.get(`${servicePrefix}/rooms/${roomId}/files`, {
    responseType: 'blob' as 'json',
    params: { file },
  })
}

export const getStaticFiles = (path: string, roomId: number) => {
  return axios.get(`${servicePrefix}/rooms/${roomId}/files/static/${path}`)
}

export const downloadFile = (file: string, roomId: number) => {
  return axios.get(`${servicePrefix}/rooms/${roomId}/download`, {
    params: { file },
  })
}

export const getListOfFiles = (filetype: RoomFileType, roomId: number) => {
  return axios.get(`${servicePrefix}/rooms/${roomId}/files/all/${filetype}`)
}

export const uploadFiles = (
  filetype: RoomFileType,
  roomId: number,
  content: File,
  name: string
) => {
  const body = {
    content,
    name,
  }
  return axios.post(`${servicePrefix}/rooms/${roomId}/download/${filetype}`, body)
}

export const getMediaInfo = (internalId: string, id_value?: string) => {
  return axios.get(`${servicePrefix}/media/${internalId}`, {
    params: { id_value },
  })
}

export const postMedia = (content: Content) => {
  return axios.post(`${servicePrefix}/media`, content)
}

export const updateMedia = (id: number, external_id: string, external_link: string) => {
  return axios.patch(`${servicePrefix}/media/${id}`, {
    external_id,
    external_link,
  })
}

export const getRtmpsMediaInfo = (id: string) => {
  return axios.get(`${servicePrefix}/media/${id}/rtmps`)
}

export const getMedia = (type: MediaType) => {
  return axios.get(`${servicePrefix}/media`, { params: { type } })
}

export const getMediaById = (id: any) => {
  return axios.get(`${servicePrefix}/media/${id}`)
}

export const getFileSummary = (id: number) => {
  return axios.get(`${servicePrefix}/files/${id}/summary`)
}

export const retrieveEFSFile = (eventCode: string, file: File) => {
  return axios.get(`${servicePrefix}/emailAssets/${eventCode}/${file}`)
}

export const retrieveEFSEventPost = (eventCode: string) => {
  return axios.get(`${servicePrefix}/poster/${eventCode}`, {
    responseType: 'blob',
  })
}

export const uploadPoster = (event_code: string, poster: File) => {
  return axios.post(`${servicePrefix}/poster`, { params: { event_code } }, { data: { poster } })
}

export const uploadBanner = (event_code: string, banner: File) => {
  return axios.post(`${servicePrefix}/banner`, { params: { event_code } }, { data: { banner } })
}

export const getBanner = (event_code: string) => {
  return axios.get(`${servicePrefix}/banner/${event_code}`)
}

export const postEmailAssets = (event_code: string, emailAssets: EmailAssets) => {
  return axios.post(`${servicePrefix}/emailAssets/${event_code}`, emailAssets)
}

export const getDeactivatedRooms = () => {
  return axios.get(`${servicePrefix}/rooms/deactivated`)
}

export const activateRoom = (roomId: number) => {
  return axios.patch(`${servicePrefix}/rooms/${roomId}/activate`)
}

export const validateContent = (event_code: string) => {
  return axios.get(`${servicePrefix}/validate-content/event/${event_code}`)
}

export const getMiniMap = (event_code: string) => {
  return axios.get(`${servicePrefix}/mini-map/event/${event_code}`)
}

export const updateMiniMap = (miniMapId: string, roomId: number, shape: Shape, coords: string) => {
  if (!roomId && !shape && !coords)
    throw Error('Must have at least one attribute (roomid, shape, coords)')
  return axios.patch(`${servicePrefix}/mini-map/${miniMapId}`)
}

export const deleteMiniMap = (miniMapId: string) => {
  return axios.delete(`${servicePrefix}/mini-map/${miniMapId}`)
}

export const getFile = (body?: any) => {
  const params = {}
  if (body) Object.assign(params, { ...body })
  return axios.get<Blob>(`${servicePrefix}/files`, {
    params,
    responseType: 'blob' as 'json',
  })
}

export const getAdminPortalHelpFile = (filetype: FilesType.ADMIN_PORTAL_HELP, filename: string) => {
  const body = { filetype, filename }
  return getFile(body)
}

/**
 * if the event_code is an empty string or null, there is no event_code field
 * in the
 * params
 * @param event_code
 */
export const getPosterFile = (event_code: string | null) => {
  const body = { filetype: FilesType.POSTER }
  if (event_code) Object.assign(body, { event_code })
  return getFile(body)
}

export const getBannerFile = (filetype: FilesType.BANNER, event_code?: number) => {
  const body = { filetype }
  if (event_code) Object.assign(body, { event_code })
  return getFile(body)
}

export const getEmailAssetsFile = (
  filetype: FilesType.EMAIL_ASSETS,
  filename: string,
  event_code?: number
) => {
  const body = { filetype, filename }
  if (event_code) Object.assign(body, { event_code })
  return getFile(body)
}

export const getObjectFile = (filetype: RoomFileType.OBJECTS, room_id: number) => {
  const body = { filetype, room_id }
  return getFile(body)
}

export const getTextureFile = (filetype: RoomFileType.TEXTURE, room_id: number) => {
  const body = { filetype, room_id }
  return getFile(body)
}

export const getSlidesFile = (filetype: RoomFileType.SLIDES, room_id: number) => {
  const body = { filetype, room_id }
  return getFile(body)
}

export const getSlidesImagesFile = (filetype: FilesType.SLIDES_IMAGES, room_id: number) => {
  const body = { filetype, room_id }
  return getFile(body)
}

export const getMiniMapFile = (filetype: FilesType.MINI_MAP, event_code: string) => {
  const body = { filetype, event_code }
  return getFile(body)
}

export const getThumbnail = (
  filetype: FilesType.THUMBNAIL,
  event_code: string,
  filename: string
) => {
  const body = { filetype, event_code, filename }
  return getFile(body)
}

export const getUserManualFile = (filetype?: FilesType.USER_MANUAL, event_code?: string) => {
  const body = { filetype, event_code }
  return getFile(body)
}

export const postFile = (file: File, body: any) => {
  const formData = new FormData()
  formData.append(file.name, file)
  return axios.post(`${servicePrefix}/files`, formData, {
    params: {
      body,
    },
  })
}

export const postAdminPortalHelpFile = (file: File) => {
  return postFile(file, {})
}

export const postPosterFile = (file: File, filetype: FilesType.POSTER, event_code?: number) => {
  const body = { filetype }
  if (event_code) Object.assign(body, { event_code })
  return postFile(file, body)
}

export const postBannerFile = (file: File, filetype: FilesType.BANNER, event_code?: number) => {
  const body = { filetype }
  if (event_code) Object.assign(body, { event_code })
  return postFile(file, body)
}

export const postEmailAssetsFile = (
  file: File,
  filetype: FilesType.EMAIL_ASSETS,
  event_code?: number
) => {
  const body = { filetype }
  if (event_code) Object.assign(body, { event_code })
  return postFile(file, body)
}

export const postObjectFile = (file: File, filetype: RoomFileType.OBJECTS, room_id: number) => {
  const body = { filetype, room_id }
  return postFile(file, body)
}

export const postTextureFile = (file: File, filetype: RoomFileType.TEXTURE, room_id: number) => {
  const body = { filetype, room_id }
  return postFile(file, body)
}

export const postSlidesFile = (file: File, filetype: RoomFileType.SLIDES, room_id: number) => {
  const body = { filetype, room_id }
  return postFile(file, body)
}

export const postSlidesImagesFile = (
  file: File,
  filetype: FilesType.SLIDES_IMAGES,
  room_id: number
) => {
  const body = { filetype, room_id }
  return postFile(file, body)
}

export const postMiniMapFile = (file: File, filetype: FilesType.MINI_MAP, event_code: number) => {
  const body = { filetype, event_code }
  return postFile(file, body)
}

export const postUserManualFile = (
  file: File,
  filetype: FilesType.USER_MANUAL,
  event_code: number
) => {
  const body = { filetype, event_code }
  return postFile(file, body)
}
