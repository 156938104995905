import React, { FC } from 'react'

import { useAppSelector } from '../../Reducers/hooks'
import BusinessCardDetailsContainer from './BusinessCardDetailsContainer'
import BusinessCardListContainer from './BusinessCardListContainer'
import { BusinessCardPages } from './businessCardsSlice'
import RequestBusinessCardContainer from './RequestBusinessCardContainer'

const BusinessCardsContainer: FC = () => {
  const activeBusinessCardPage = useAppSelector(
    (state) => state.businessCards.activeBusinessCardPage
  )

  switch (activeBusinessCardPage) {
    case BusinessCardPages.BusinessCardsListPage:
      return <BusinessCardListContainer />
    case BusinessCardPages.BusinessCardDetailspage:
      return <BusinessCardDetailsContainer />
    case BusinessCardPages.RequestBusinessCardPage:
      return <RequestBusinessCardContainer />
    default: {
      return null
    }
  }
}

export default BusinessCardsContainer
