import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AppearanceTypes, useToasts } from 'react-toast-notifications'

import { Socket } from 'socket.io-client'
import parse from 'html-react-parser'

import { updatePassportBadges } from '../PassportContainer/PassportBadgesSlice'
import { selectCurrentUser, updateRoomAccess } from '../../Reducers/authSlice'
import { useAppDispatch, useAppSelector } from '../../Reducers/hooks'
import { MessagingTopics } from '../../Services/services-index'

interface RoomAccessMessage {
  userId: number
  eventId: number
  accessibleRooms: Room[]
}

interface Room {
  roomId: number
  roomName: string
}

export const EventNotificationFeedContainer: FC<any> = () => {
  const { addToast } = useToasts()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const socket: Socket | null = useAppSelector((state) => state.socket.socket)
  const activeRoomId = useAppSelector((state) => state.room.activeRoomId)
  const currentUser = useAppSelector(selectCurrentUser)

  const [hasChatListener, setHasChatListener] = useState<boolean>(false)
  const [hasBadgeListener, setHasBadgeListener] = useState<boolean>(false)

  // Add new messages to toast notification
  useEffect(() => {
    if (socket) {
      if (!hasChatListener) {
        setHasChatListener(true)

        socket.on(MessagingTopics.ANNOUNCEMENTS, (data: string) => {
          const messageJson: any = JSON.parse(data)

          if (messageJson) {
            const messageType = messageJson.type
            let appearanceValue: AppearanceTypes = 'info'
            if (messageType === 'information') {
              appearanceValue = 'info'
            } else if (messageType === 'error') {
              appearanceValue = 'error'
            } else if (messageType === 'success') {
              appearanceValue = 'success'
            } else if (messageType === 'warning') {
              appearanceValue = 'warning'
            }

            return addToast(parse(messageJson.message), {
              onDismiss: () => {},
              title: messageJson.title,
              appearance: appearanceValue,
              autoDismiss: messageJson.duration,
              autoDismissTimeout: messageJson.duration,
            })
          }
        })
      }

      if (!hasBadgeListener) {
        setHasBadgeListener(true)

        socket.on(MessagingTopics.USER_ACHIEVEMENT_EVENTS, (data: string) => {
          const response: any = JSON.parse(data)

          const passportUpdate = {
            coin_balance: response.coin_balance,
            badges: response.badges,
            passport_complete: response.passport_complete,
            passport_claimed: response.passport_claimed,
          }
          if (passportUpdate) {
            dispatch(updatePassportBadges(passportUpdate))
          }

          let notificationMessage: string
          let appearanceValue: AppearanceTypes = 'success'

          if (response.trigger_badge.multi_action) {
            // isClaimed will be false on the first action related to a badge, and true thereafter
            // isComplete will return as true upon hitting the minimum number of required actions to complete the badge and remain true on additional actions linked to the badge
            if (response.isComplete) {
              notificationMessage = t('eventNotification.userAchievement.badgeCompleted')
            } else if (response.isClaimed) {
              notificationMessage = t('eventNotification.userAchievement.additionalProgress')
            } else {
              notificationMessage = t('eventNotification.userAchievement.madeProgress')
            }
          } else {
            if (response.isClaimed) {
              notificationMessage = t('eventNotification.userAchievement.alreadyCollected')
              appearanceValue = 'info'
            } else {
              notificationMessage = t('eventNotification.userAchievement.foundBadge')
            }
          }

          return addToast([notificationMessage], {
            appearance: appearanceValue,
            autoDismiss: true,
            autoDismissTimeout: 5000,
          })
        })
      }

      // Variables inside the socket listener are passed by value, so we need to remove
      // and attach a new listener every time activeRoomId or currentUser changes
      if (activeRoomId && currentUser) {
        socket.removeAllListeners(MessagingTopics.USER_ACCESS_CHANGE)

        socket.on(MessagingTopics.USER_ACCESS_CHANGE, (data: any) => {
          const messageJson: RoomAccessMessage = JSON.parse(data)

          if (messageJson) {
            // Compare new array of accessible rooms with current array
            const grantedRoomNames: string[] = []
            let isCurrentRoomAccessRevoked = true

            messageJson.accessibleRooms.forEach((room) => {
              // Access to new room granted
              if (!currentUser.room_access?.includes(room.roomId)) {
                grantedRoomNames.push(room.roomName)
              }
              // Access to current room revoked
              else if (room.roomId === activeRoomId) {
                isCurrentRoomAccessRevoked = false
              }
            })

            if (grantedRoomNames && grantedRoomNames.length) {
              addToast(
                t('eventNotification.roomAccessGranted.description', { rooms: grantedRoomNames }),
                {
                  onDismiss: () => {},
                  title: t('eventNotification.roomAccessGranted.title'),
                  appearance: 'success',
                  autoDismiss: true,
                  autoDismissTimeout: 5000,
                }
              )
            }
            if (isCurrentRoomAccessRevoked) {
              addToast(t('eventNotification.roomAccessRevoked.description'), {
                onDismiss: () => {},
                title: t('eventNotification.roomAccessRevoked.title'),
                appearance: 'error',
                autoDismiss: true,
                autoDismissTimeout: 5000,
              })
              setTimeout(() => {
                window.location.href = '/lobby'
              }, 5000)
            }

            dispatch(updateRoomAccess(messageJson.accessibleRooms.map((room) => room.roomId)))

            return
          }
        })
      }
    }
  }, [socket, activeRoomId, currentUser])

  return <></>
}
export default EventNotificationFeedContainer
