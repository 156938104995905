import React from 'react'

class FatalErrorBoundary extends React.Component<
  { children?: React.ReactNode },
  { error: unknown }
> {
  state = { error: undefined }

  static getDerivedStateFromError(error: unknown) {
    return { error }
  }

  render() {
    if (this.state.error) {
      return (
        <p>
          A fatal error happened. You can only try ro reload.
          <button onClick={() => window.location.reload()}>Reload</button>
        </p>
      )
    } else {
      return this.props.children
    }
  }
}

export default FatalErrorBoundary
