/* eslint-disable no-unused-vars */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Conversation, MessagePayload, conversationsService } from '../../Services/services-index'

export enum RoomChatPages {
  RoomChatMessage,
  RoomChatParticipantList,
}

export interface MenuState {
  isLoadingHistoryConversations: boolean
  conversations: { [conversationId: string]: Conversation } | null
  messages: { [messageId: string]: MessagePayload } | null
  activeConversationId: string
  activeConversation: Conversation
  activeRoomChatpage: RoomChatPages
  activeRoomParticipantsCount: number
  isRoomConversation: boolean
}

const initialState: MenuState = {
  isLoadingHistoryConversations: true,
  conversations: null,
  messages: null,
  activeConversationId: '',
  activeConversation: {} as Conversation,
  activeRoomParticipantsCount: 0,
  activeRoomChatpage: RoomChatPages.RoomChatMessage,
  isRoomConversation: false,
}

export const fetchRoomChatHistoryConversations = createAsyncThunk(
  'chat/fetchRoomChatHistoryConversations',
  async (
    payload: {
      userId: number
      room_id: number
    },
    { dispatch }
  ) => {
    const room_id = payload.room_id
    const userId = payload.userId
    const response = await conversationsService.getUserConversations(userId, room_id)
    const { conversations } = response?.data
    const userIds: Set<number> = new Set()
    for (const [key, value] of Object.entries(conversations) as any) {
      const { last_message, room, participants } = value
      if (participants) {
        dispatch(updateActiveRoomParticipantsCount(participants.length))
      }

      participants.forEach((p: any) => {
        userIds.add(p.id)
      })

      const isRoomConversation = key.indexOf('room_') > -1
      const conversationListItem: Conversation = {
        participants: [],
        lastMessage: last_message || {},
        isTemporary: false,
        isRoomConversation,
        isGroupConversation: false,
      }

      if (isRoomConversation) {
        conversationListItem.roomId = room?.room_id
        conversationListItem.roomName = room?.name
        conversationListItem.participants = [...participants]
      }

      conversations[key] = conversationListItem
      const res: any = await conversationsService.getConversationMessages(key)
      const { messages, page_state } = res.data
      conversations[key].messages = messages
      conversations[key].page_state = page_state
    }
    return conversations
  }
)

export const roomChatSlice = createSlice({
  name: 'roomChat',
  initialState,
  reducers: {
    updateRoomConversations: (
      state,
      action: PayloadAction<{ [conversationId: string]: Conversation }>
    ) => {
      if (state.conversations === null) {
        state.conversations = action.payload
      } else {
        state.conversations = Object.assign(state.conversations, action.payload)
      }
    },
    updateActiveRoomConversationId: (state, action: PayloadAction<string>) => {
      state.activeConversationId = action.payload
    },
    updateIsRoomConversation: (state, action: PayloadAction<boolean>) => {
      state.isRoomConversation = action.payload
    },
    updateActiveRoomConversation: (state, action: PayloadAction<Conversation>) => {
      state.activeConversation = action.payload
    },
    updateActiveRoomParticipantsCount: (state, action: PayloadAction<number>) => {
      state.activeRoomParticipantsCount = action.payload
    },
    switchRoomChatPage: (state, action: PayloadAction<RoomChatPages>) => {
      state.activeRoomChatpage = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRoomChatHistoryConversations.pending, (state) => {
        state.isLoadingHistoryConversations = true
      })
      .addCase(fetchRoomChatHistoryConversations.fulfilled, (state, action) => {
        state.isLoadingHistoryConversations = false
        state.conversations = Object.assign({}, state.conversations, action.payload)
      })
  },
})

export const {
  updateRoomConversations,
  updateActiveRoomConversationId,
  updateIsRoomConversation,
  updateActiveRoomConversation,
  updateActiveRoomParticipantsCount,
  switchRoomChatPage,
} = roomChatSlice.actions

export default roomChatSlice.reducer
