/* eslint-disable no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export enum MenuPages {
  Menu,
  ProfileSetting,
  RoomChat,
  UserList,
  Chat,
  BusinessCards,
  Passport,
  RecruitingDashboard,
  Settings,
  Help,
  EventNotification,
  TokenWallet,
}

export interface MenuState {
  activeMenuPage: MenuPages
  isOpen: boolean
  isSideMenuOpen: boolean
}

const initialState: MenuState = {
  isOpen: false,
  isSideMenuOpen: false,
  activeMenuPage: MenuPages.Menu,
}

export const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    switchMenuPage: (state, action: PayloadAction<MenuPages>) => {
      state.activeMenuPage = action.payload
      state.isOpen = false
    },
    toggleMenu: (state, action: PayloadAction<boolean>) => {
      state.isOpen = action.payload
    },
    closeMenu: (state) => {
      state.isOpen = false
    },
    updateIsSideMenuOpen: (state, action: PayloadAction<boolean>) => {
      state.isSideMenuOpen = action.payload
    },
  },
})

export const { switchMenuPage, toggleMenu, closeMenu, updateIsSideMenuOpen } = menuSlice.actions

export default menuSlice.reducer
