import React, { FC } from 'react'
import { useAppSelector } from '../../Reducers/hooks'
import { ChatPages } from './ChatSlice'
import ChatListContainer from './ChatListContainer'
import ChatMessagesContainer from './ChatMessagesContainer'
import ChatNewConversationContainer from './ChatNewConversationContainer'
import ChatParticipantListContainer from './ChatParticipantListContainer'

const ChatContainer: FC = () => {
  const activeChatPage = useAppSelector((state) => state.chat.activeChatPage)

  switch (activeChatPage) {
    case ChatPages.ChatList:
      return <ChatListContainer />
    case ChatPages.ChatMessage:
      return <ChatMessagesContainer />
    case ChatPages.ChatNewConversation:
      return <ChatNewConversationContainer />
    case ChatPages.ChatParticipantList:
      return <ChatParticipantListContainer />
    default: {
      return null
    }
  }
}

export default ChatContainer
