import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { makeStyles, Theme } from '@material-ui/core/styles'
import { Slide, Typography } from '@material-ui/core'

import {
  BusinessCard,
  BusinessCardPages,
  switchBusinessCardPagePage,
  updateBusinessCards,
  updateApprovedBusinessCard,
  updateIsBusinessCardDeleted,
  updateIsBusinessCardRequestApproved,
} from './businessCardsSlice'
import { useAppDispatch, useAppSelector } from '../../Reducers/hooks'
import { BusinessCardsComponent } from '../../Components/BusinessCards/BusinessCards'
import { MenuPages, switchMenuPage, toggleMenu, updateIsSideMenuOpen } from '../Menu/MenuSlice'
import { businessCardService, userService } from '../../Services/services-index'
import { MenuDrawer, MenuHeader, SearchBar } from '../../Common/common-index'
import { EmptyBusinessCards } from '../../Components/BusinessCards/EmptyBusinessCards'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '100px',
    marginBottom: '60px',
    padding: '0 32px',
    '& div[class*="MuiOutlinedInput-root"]': {
      borderRadius: '25px !important',
      height: '40px',
    },
    '& label[class*="MuiInputLabel-outlined"]': {
      transform: 'translate(60px, 7px) scale(1)',
      marginLeft: '10px',
      ...theme.typography.caption,
    },
    '& div[class*="MuiInputBase-root"]': {
      ...theme.typography.body2,
    },
  },
  paperContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  paper: {
    cursor: 'pointer',
  },
  searchBar: {
    padding: theme.spacing(0, 3),
    width: 'calc(100% - 48px)',
    position: 'absolute',
    top: '70px',
    left: '0',
    height: '80px',
    zIndex: 1000,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.background.paper,
  },
  searchInput: {
    width: '100%',
    margin: '20px 0',
    padding: '0 10px',
    backgroundColor: '#ffffff',
    color: '#bebebe',
  },
  cardlist: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      maxWidth: '338px',
    },
    '& div[class*="MuiGrid-root"]': {
      marginTop: 0,
    },
  },
  emptyCard: { alignItems: 'center', display: 'flex', justifyContent: 'center', height: '100%' },
  noUserFound: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    margin: theme.spacing(1, 0),
    width: '100%',
  },
}))

export const BusinessCardListContainer: FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const allBusinessCards = useAppSelector((state) => state.businessCards.businessCards)
  const isBusinessCardDeleted = useAppSelector((state) => state.businessCards.isBusinessCardDeleted)
  const isBusinessCardChanged = useAppSelector((state) => state.businessCards.isBusinessCardChanged)
  const isBusinessCardRequestApproved = useAppSelector(
    (state) => state.businessCards.isBusinessCardRequestApproved
  )
  const currentUser = useAppSelector((state) => state.auth.currentUser)
  const userProfilePictures = useAppSelector((state) => state.userAvatar.profilePictureList)

  const [inputValue, setInputValue] = useState<string>('')
  const [noBusinessCardsCollected, setNoBusinessCardsCollected] = useState(false)
  const [filteredCards, setFilteredCards] = useState(allBusinessCards)
  const [businessCardList, setbusinessCardList] = useState<any>()
  const [noSearchResult, setNoSearchResult] = useState<boolean>(false)

  const getBusinessCardsInfo = async (currentUserId?: number) => {
    if (!currentUserId) {
      return
    }
    return (await businessCardService.getAllBusinessCards(currentUserId)).data
  }

  useEffect(() => {
    if (currentUser && currentUser.id && !noBusinessCardsCollected) {
      ;(async () => {
        try {
          const businessCards = await getBusinessCardsInfo(currentUser.id)
          dispatch(updateBusinessCards(businessCards))
        } catch (err) {
          console.log(err)
        }
      })()
    }
  }, [])

  useEffect(() => {
    if (
      (isBusinessCardDeleted ||
        isBusinessCardChanged ||
        isBusinessCardRequestApproved ||
        (allBusinessCards && !allBusinessCards[0] && !noBusinessCardsCollected)) &&
      currentUser &&
      currentUser.id
    ) {
      ;(async () => {
        try {
          const res = await getBusinessCardsInfo(currentUser.id)
          res?.length ? setNoBusinessCardsCollected(false) : setNoBusinessCardsCollected(true)
          dispatch(updateBusinessCards(res))
          dispatch(updateIsBusinessCardDeleted(false))
          dispatch(updateIsBusinessCardRequestApproved(false))
        } catch (err) {
          console.log(err)
        }
      })()
    }
    if (allBusinessCards) {
      setFilteredCards(allBusinessCards)
    }
  }, [
    allBusinessCards,
    noBusinessCardsCollected,
    isBusinessCardDeleted,
    isBusinessCardRequestApproved,
  ])

  useEffect(() => {
    if (filteredCards) {
      setbusinessCardList(renderBusinessCardList(filteredCards))
    }
  }, [filteredCards])

  useEffect(() => {
    filterBusinessCards(inputValue)
  }, [inputValue])

  const filterBusinessCards = (searchInputValue: string) => {
    if (searchInputValue) {
      const lowerCaseInput = searchInputValue.toLowerCase()
      const cards = allBusinessCards?.filter((businessCard?: any) => {
        const firstname = businessCard.firstname.toLowerCase()
        const lastname = businessCard.lastname.toLowerCase()
        const company = businessCard?.company?.toLowerCase()
        return (
          firstname.includes(lowerCaseInput) ||
          lastname.includes(lowerCaseInput) ||
          (firstname + ' ' + lastname).includes(lowerCaseInput) ||
          company?.includes(lowerCaseInput)
        )
      })
      !cards?.length ? setNoSearchResult(true) : setNoSearchResult(false)
      setFilteredCards(cards)
    } else {
      setFilteredCards(allBusinessCards)
      setNoSearchResult(false)
      setInputValue('')
    }
  }

  useEffect(() => {
    dispatch(updateIsSideMenuOpen(true))
    allBusinessCards?.forEach((businessCard: BusinessCard) => {
      if (!userProfilePictures[businessCard.user_id]) {
        userService.getProfilePicture(businessCard.profile_picture_path).then((res) => res)
      }
    })
  }, [userProfilePictures])

  // On Businesscard selection
  const onBusinessCardClick = (businessCard: BusinessCard) => {
    dispatch(updateApprovedBusinessCard(businessCard))
    dispatch(switchBusinessCardPagePage(BusinessCardPages.BusinessCardDetailspage))
  }

  const renderBusinessCardList = (cardsList: any) => {
    return (
      <Slide direction='left' in={true} mountOnEnter unmountOnExit>
        <div className={classes.cardlist}>
          <BusinessCardsComponent
            updateSelectedCard={onBusinessCardClick}
            allBusinessCards={cardsList}
            userProfilePictures={userProfilePictures}
          ></BusinessCardsComponent>
        </div>
      </Slide>
    )
  }

  // Header Actions
  const OnCloseClick = () => {
    dispatch(toggleMenu(false))
    dispatch(updateIsSideMenuOpen(false))
    dispatch(switchMenuPage(MenuPages.Menu))
    dispatch(switchBusinessCardPagePage(BusinessCardPages.BusinessCardsListPage))
  }

  const OnGoBack = () => {
    dispatch(switchMenuPage(MenuPages.Menu))
    dispatch(toggleMenu(true))
    dispatch(updateIsSideMenuOpen(true))
  }

  return (
    <div className={classes.paperContainer}>
      <MenuDrawer
        isOpen={true}
        drawerHeader={
          <MenuHeader
            title={t('businessCardsPage.businessCardsList.title')}
            hasDivider={true}
            onGoBack={OnGoBack}
            onClose={OnCloseClick}
          />
        }
      >
        {noBusinessCardsCollected && (
          <>
            <div className={noBusinessCardsCollected ? classes.emptyCard : ''}>
              <EmptyBusinessCards />
            </div>
          </>
        )}
        <div className={!noBusinessCardsCollected ? classes.container : ''}>
          {!noBusinessCardsCollected && (
            <>
              <div className={classes.searchBar}>
                <SearchBar
                  id='business-card-search'
                  placeholderText={t('businessCardsPage.searchBarPlaceHolder')}
                  searchTextInput={inputValue}
                  onChange={(event) => setInputValue(event.target?.value)}
                />
              </div>
              {!noSearchResult && businessCardList}
              {noSearchResult && (
                <Typography className={classes.noUserFound} variant={'body2'}>
                  {t('chatPage.chatNewConversation.noSearchResult')}
                </Typography>
              )}
            </>
          )}
        </div>
      </MenuDrawer>
    </div>
  )
}

export default BusinessCardListContainer
