import React, { FC } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Slide from '@material-ui/core/Slide'
import { Icon } from '../../Common/common-index'
import { Badge } from '../../Services/services-index'

interface Params {
  passportBadge?: Badge
  achievementStorePreference?: any
}

const useStyles = makeStyles((theme: Theme) => ({
  passportBadge: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(0, 4),
    outline: 'none',
    marginBottom: '20px',
  },
  badgeContainer: {
    display: 'inline-flex',
    boxShadow: '0px 0px 10px #0000002E',
    opacity: '1',
    outline: 'none',
    padding: theme.spacing(3),
    height: '100%',
  },
  badgeInformation: {
    textAlign: 'left',
    width: '80%',
    marginLeft: '10px',
  },
  badgeTitle: {
    fontFamily: 'Graphik',
    fontSize: '18px',
    fontWeight: 'bold',
    marginBottom: '12px',
  },
  badgeDescription: {
    color: theme.palette.grey[200],
    fontFamily: 'Graphik',
    fontSize: '15px',
    marginBottom: '14px',
  },
  coinWrapper: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    height: '30px',
  },
  collected: {
    color: '#FEC302',
  },
  coinCount: {
    margin: '0 3px',
  },
  points: {
    fontSize: '14px',
    fontFamily: 'Graphik-Regular',
    lineHeight: '22px',
  },
  claimedBadge: {
    color: theme.palette.primary.main,
    width: '20%',
    '& svg': {
      height: '52px !important',
      width: '43px !important',
    },
  },
  unClaimedBadge: {
    color: '#00000043',
    width: '20%',
    '& svg': {
      height: '52px !important',
      width: '43px !important',
    },
  },
}))

export const PassportBadge: FC<Params> = ({ passportBadge, achievementStorePreference }) => {
  const classes = useStyles()

  return (
    <Slide direction='left' in={true} mountOnEnter unmountOnExit>
      {passportBadge && (
        <div className={classes.passportBadge}>
          <div className={classes.badgeContainer}>
            <div
              className={
                passportBadge.badge_complete ? classes.claimedBadge : classes.unClaimedBadge
              }
            >
              <Icon iconName={'passport'} iconSize={'100px'} />
            </div>
            <div className={classes.badgeInformation}>
              <Typography variant={'h2'} className={classes.badgeTitle}>
                {passportBadge.title}
              </Typography>
              {achievementStorePreference?.value === 'yes' ? (
                <div className={classes.coinWrapper}>
                  <Icon
                    iconName={passportBadge.badge_complete ? 'miniCoinGold' : 'miniCoin'}
                    iconSize={'16px'}
                  />
                  <Typography className={`${classes.coinCount} ${classes.points}`}>
                    {passportBadge.coin_value ? passportBadge.coin_value : 'none'}
                  </Typography>
                </div>
              ) : null}
              <Typography variant={'h2'} className={classes.badgeDescription}>
                {passportBadge.description}
              </Typography>
            </div>
          </div>
        </div>
      )}
    </Slide>
  )
}

export default PassportBadge
