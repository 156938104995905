import React, { FC } from 'react'

import { useAppSelector } from '../../Reducers/hooks'
import { RecruitingDashboardPages } from './RecruitingDashboardSlice'

import RecruitingDashboardContainer from './RecruitingDashboardContainer'
import RecruitingDashboardChatContainer from './RecruitingDashboardChatContainer'
import RecruitingDashboardUserListContainer from './RecruitingDashboardUserListContainer'
import RecruitingDashboardBadgeListContainer from './RecruitingDashboardBadgeListContainer'
import RecruitingDashboardUserProfileContainer from './RecruitingDashboardUserProfileContainer'

const RecruitingDashboardMenuContainer: FC = () => {
  const activeRecruitingPage = useAppSelector(
    (state) => state.recruiting.activeRecruitingDashboardPage
  )

  switch (activeRecruitingPage) {
    case RecruitingDashboardPages.RecruitingDashboardMainMenu:
      return <RecruitingDashboardContainer />
    case RecruitingDashboardPages.RecruitingDashboardUserListPage:
      return <RecruitingDashboardUserListContainer />
    case RecruitingDashboardPages.RecruitingDashboardProfilePage:
      return <RecruitingDashboardUserProfileContainer />
    case RecruitingDashboardPages.RecruitingDashboarChatPage:
      return <RecruitingDashboardChatContainer />
    case RecruitingDashboardPages.RecruitingDashboardBadgeListPage:
      return <RecruitingDashboardBadgeListContainer />
    default: {
      return null
    }
  }
}

export default RecruitingDashboardMenuContainer
