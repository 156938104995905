import React, { FC } from 'react'
import { useAppSelector } from '../../Reducers/hooks'
import { LanguageSettingsContainer } from './LanguageSettingsContainer'
import { SettingsListContainer } from './SettingsListContainer'
import { SettingsPages } from './SettingsSlice'

const SettingsContainer: FC = () => {
  const activeSettingsPage = useAppSelector((state) => state.settings.activeSettingsPage)

  switch (activeSettingsPage) {
    case SettingsPages.SettingsList:
      return <SettingsListContainer />
    case SettingsPages.Language:
      return <LanguageSettingsContainer />
    default: {
      return null
    }
  }
}

export default SettingsContainer
