import axios from 'axios'
import Config from '../../config.json'

const reactAppDomain = Config.VSF_K8S_COLLAB_BACKEND_URL

const servicePrefix = `${reactAppDomain}/api/database`

export const getAvatars = (event_code?: string) => {
  const eventCodeUrlAppend = event_code ? `/${event_code}` : ''
  return axios.get(`${servicePrefix}/avatars${eventCodeUrlAppend}`)
}

export const getThumbnailImagePath = async (thumbnail: string) => {
  let imageLink = thumbnail
  const apiHost = Config.VSF_K8S_COLLAB_URL
  if (thumbnail.startsWith(apiHost!)) {
    imageLink = thumbnail
  } else if (thumbnail !== '/static/images/thumb-detault.png') {
    imageLink = reactAppDomain + thumbnail
  } else {
    imageLink = apiHost + thumbnail
    //Default images are not protected by authentication, we can return it immediately
    return imageLink
  }
  const newUrl = await getObjectUrlWithAuth(imageLink)
  return newUrl
}

export const getRoomInstances = (meeting_id: number, breakout_group?: string) => {
  return axios.get(`${servicePrefix}/meeting/instances`, {
    params: { meeting_id: meeting_id, breakout_group: breakout_group },
  })
}

/**
 * Gets object with authorization header and returns new object url accessible without needing authorization header
 */
const getObjectUrlWithAuth = async (path: string): Promise<string> => {
  const res = await axios.get<Blob>(path, {
    responseType: 'blob' as 'json',
  })
  return URL.createObjectURL(res.data)
}
